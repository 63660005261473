//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_REVENUERECOGNITION,
    GET_ALL_REVENUERECOGNITION,
    GET_REVENUERECOGNITION,
    GET_ALL_SELECTBOX_REVENUERECOGNITION,
    DELETE_REVENUERECOGNITION,
    UPDATE_REVENUERECOGNITION,
    ADD_DIALOG_REVENUERECOGNITION,
    EDIT_DIALOG_REVENUERECOGNITION,
    CLOSE_DIALOG_REVENUERECOGNITION
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createRevenueRecognition = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/RevenueRecognition/Create', formValues);    
    dispatch(responseHandler(response, CREATE_REVENUERECOGNITION));
    if(response.data?.succeeded){
        dispatch(reset('formRevenueRecognition'));
    }
}

export const getAllRevenueRecognitions = () => async (dispatch) => {
    const response = await api.get('/api/v1/RevenueRecognition/GetAll');
    dispatch(responseHandler(response, GET_ALL_REVENUERECOGNITION));
}

export const getRevenueRecognition = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/RevenueRecognition/Get/' + id);
    dispatch(responseHandler(response, GET_REVENUERECOGNITION));
}

export const getSelectBoxRevenueRecognitions = () => async (dispatch) => {
    const response = await api.get('/api/v1/RevenueRecognition/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_REVENUERECOGNITION));
}

export const updateRevenueRecognition = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/RevenueRecognition/Update', formValues);
    dispatch(responseHandler(response, UPDATE_REVENUERECOGNITION));
}

export const deleteRevenueRecognition = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/RevenueRecognition/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_REVENUERECOGNITION));
}

export const addDialogRevenueRecognition = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_REVENUERECOGNITION,
        payload: null
    });
}

export const editDialogRevenueRecognition = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_REVENUERECOGNITION,
        payload: item
    });
}

export const closeDialogRevenueRecognition = () => async (dispatch) => {
    dispatch(reset('formRevenueRecognition'));
    dispatch({
        type: CLOSE_DIALOG_REVENUERECOGNITION,
        payload: undefined
    });
}