//Mojo-AG
import { 
    CREATE_LOANAPPROVALSETTINGS,
    GET_ALL_LOANAPPROVALSETTINGS,
    GET_LOANAPPROVALSETTINGS,
    DELETE_LOANAPPROVALSETTINGS,
    UPDATE_LOANAPPROVALSETTINGS
} from '../../actions/types';

const loanApprovalSettingsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANAPPROVALSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANAPPROVALSETTINGS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANAPPROVALSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANAPPROVALSETTINGS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANAPPROVALSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanApprovalSettingsReducer;