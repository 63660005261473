import api from "../api/myApi";
import { 
    CREATE_BLTURNOVER,
    GET_ALL_BLTURNOVER,
    GET_BLTURNOVER,
    GET_ALL_SELECTBOX_BLTURNOVER,
    DELETE_BLTURNOVER,
    UPDATE_BLTURNOVER,
    ADD_DIALOG_BLTURNOVER,
    EDIT_DIALOG_BLTURNOVER,
    CLOSE_DIALOG_BLTURNOVER,
    CLEAR_BLTURNOVER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBLTurnover = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/BLTurnover/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BLTURNOVER));
    if(response.data?.succeeded){
        dispatch(reset('formBLTurnover'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllByCashBalanceBLTurnovers = (data) => async (dispatch) => {
    dispatch({
        type: CLEAR_BLTURNOVER,
        payload: null
    });
    const response = await api.post('/api/v1/BLTurnover/GetAllByCashBalance', data);
    dispatch(responseHandler(response, GET_ALL_BLTURNOVER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getBLTurnover = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/BLTurnover/Get/' + id);
    dispatch(responseHandler(response, GET_BLTURNOVER));
}

export const getSelectBoxBLTurnovers = () => async (dispatch) => {
    const response = await api.get('/api/v1/BLTurnover/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BLTURNOVER));
}

export const updateBLTurnover = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/BLTurnover/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BLTURNOVER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteBLTurnover = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/BLTurnover/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BLTURNOVER));
}

export const addDialogBLTurnover = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BLTURNOVER,
        payload: null
    });
}

export const editDialogBLTurnover = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BLTURNOVER,
        payload: item
    });
}

export const closeDialogBLTurnover = () => async (dispatch) => {
    dispatch(reset('formBLTurnover'));
    dispatch({
        type: CLOSE_DIALOG_BLTURNOVER,
        payload: undefined
    });
}