import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
//import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateLoanQualityStatus from './CreateLoanQualityStatus';
import EditLoanQualityStatus from './EditLoanQualityStatus';
import { addDialogLoanQualityStatus, editDialogLoanQualityStatus, deleteLoanQualityStatus } from "../../actions/loanQualityStatusAction";
import { getSelectBoxLoanQualities } from '../../actions/loanQualityAction';
import i18next from '../../language/i18n';
//import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableLoanQualityStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxLoanQualities();
  }

  loanName = (props) => {
    return this.props.loans.find(el => el.value === props.row.loanID)?.text;
  }
  loanQualityName = (props) => {
    return this.props.loanQualities.find(el => el.value === props.row.loanQualityID)?.text;
  }
  columns = [
    { field: 'remainingPrincipal', headerName: i18next.t('RemainingPrincipal'), minWidth: 150, flex: 1 },
    { field: 'principalDue', headerName: i18next.t('PrincipalDue'), minWidth: 150, flex: 1 },
    { field: 'interestDue', headerName: i18next.t('InterestDue'), minWidth: 150, flex: 1 },

    {
      field: 'LoanQualityID',
      headerName: i18next.t('LoanQuality'),
      width: 160,
      valueGetter: this.loanQualityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanQualityName(cellParams1).localeCompare(this.loanQualityName(cellParams2))
    },
    { field: 'previousClasification', headerName: i18next.t('PreviousClasification'), minWidth: 150, flex: 1 },
    { field: 'currentClasification', headerName: i18next.t('CurrentClasification'), minWidth: 150, flex: 1 },
    { field: 'lastClasificationChangeDate', headerName: i18next.t('LastClasificationChangeDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'active', headerName: i18next.t('Active'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'lastGarantorRepayingDate', headerName: i18next.t('LastGarantorRepayingDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'crkDate', headerName: i18next.t('CRKDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'exportedToCRK', headerName: i18next.t('ExportedToCRK'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'remainingPrincipalChange', headerName: i18next.t('RemainingPrincipalChange'), minWidth: 150, flex: 1 },
    { field: 'interestDueChange', headerName: i18next.t('InterestDueChange'), minWidth: 150, flex: 1 },
    { field: 'penalties', headerName: i18next.t('Penalties'), minWidth: 150, flex: 1 },
    { field: 'warningCosts', headerName: i18next.t('WarningCosts'), minWidth: 150, flex: 1 },
    { field: 'isCurrentVersion', headerName: i18next.t('IsCurrentVersion'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1, valueGetter: dateFormat }
  ];
  /*
  create = () => {
    this.props.addDialogLoanQualityStatus();
  }
  edit = (params) => {
    this.props.editDialogLoanQualityStatus(params.row);
  }
  delete = (params) => {
    this.setState({IsOpenConfirmDialog: true, ID: params.row.id});
  }*/
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.loanQualityStatus}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          //componentsProps={{ toolbar: { createNew:this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateLoanQualityStatus></CreateLoanQualityStatus>
        <EditLoanQualityStatus></EditLoanQualityStatus>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLoanQualityStatus(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loanQualityStatus: Object.values(state.loanQualityStatus),
    loans: Object.values(state.loansSelectBox),
    loanQualities: Object.values(state.loanQualitiesSelectBox)
  };
}

export default connect(mapStateToProps, { addDialogLoanQualityStatus, editDialogLoanQualityStatus, deleteLoanQualityStatus, getSelectBoxLoanQualities })(TableLoanQualityStatus);