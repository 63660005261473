import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import i18next from '../../language/i18n';
import FormReferenceNumberLegalService_Warning from './FormReferenceNumberLegalService_Warning';
import { createLegalService_Warning, getAllLegalService_Debs } from "../../actions/legalService_WarningAction";


class TableLegalService_Warning_AllDebts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenReferenceNumberDialog: false, id: null, latePaymentDays: 0 }
  }
  componentDidMount = () => {
    this.props.getAllLegalService_Debs();
  }
  columns = [
    { field: 'loanNumber', headerName: i18next.t('LoanNumber'), minWidth: 100, flex: 1 },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 2 },
    { field: 'amount', headerName: i18next.t('Amount'), minWidth: 50, flex: 1 },
    { field: 'latePaymentDays', headerName: i18next.t('LatePaymentDays'), minWidth: 50, flex: 1 },
    { field: 'strategy', headerName: i18next.t('Strategy'), minWidth: 150, flex: 1 },
    {
      field: 'edit',
      headerName: ' ',
      minWidth: 150, 
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('CreateAWarning')}
          </Button>
        </strong>
      ),
    }
  ];
  onSubmit = (formValues) => {
    formValues.parnters = this.props.loanPartners;
    this.props.createLegalService_Warning(
      {
        loanID: this.state.id, 
        referenceNumber: formValues.referenceNumber,
        latePaymentDays: this.state.latePaymentDays
      });
    this.onEditClose();
  }
  edit = (params) => {
    console.log(params);
    this.setState({IsOpenReferenceNumberDialog : true, id: params.row.id, latePaymentDays: params.row.latePaymentDays});
  }
  onEditClose = () => {
    this.setState({IsOpenReferenceNumberDialog : false, id: null, latePaymentDays: 0});
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.legalService_Debs}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
        <FormReferenceNumberLegalService_Warning
          IsOpen={this.state.IsOpenReferenceNumberDialog}
          onSubmit={this.onSubmit}
          onClose={this.onEditClose}
        ></FormReferenceNumberLegalService_Warning>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    legalService_Debs: Object.values(state.legalService_Debs)
  };
}

export default connect(mapStateToProps, {
  createLegalService_Warning,
  getAllLegalService_Debs
})(TableLegalService_Warning_AllDebts);