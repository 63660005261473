import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCompanyDetails from './CreateCompanyDetails';
import EditCompanyDetails from './EditCompanyDetails';
import { addDialogCompanyDetails, editDialogCompanyDetails, deleteCompanyDetails, getAllCompanyDetails } from "../../actions/companyDetailsAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCities } from '../../actions/cityAction';
import { getSelectBoxEntities } from '../../actions/entityAction';
import { getSelectBoxRegions } from '../../actions/regionAction';
import i18next from '../../language/i18n';
import { dateFormat } from '../Share/Table/DateFormat';
import DeleteButton from '../Share/Button/DeleteButton';


class TableCompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxCompanies();
    this.props.getSelectBoxCities();
    this.props.getSelectBoxEntities();
    this.props.getSelectBoxRegions();

    this.props.getAllCompanyDetails();
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  cityName = (props) => {
    return this.props.cities.find(el => el.value === props.row.cityID)?.text;
  }
  entityName = (props) => {
    return this.props.entities.find(el => el.value === props.row.entityID)?.text;
  }
  regionName = (props) => {
    return this.props.regions.find(el => el.value === props.row.regionID)?.text;
  }
  columns = [
    {
      field: 'CompanyID',
      headerName: i18next.t('Company'),
      width: 160,
      valueGetter: this.companyName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
    },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    {
      field: 'CityID',
      headerName: i18next.t('City'),
      width: 160,
      valueGetter: this.cityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cityName(cellParams1).localeCompare(this.cityName(cellParams2))
    },
    { field: 'address', headerName: i18next.t('Address'), minWidth: 150, flex: 1 },
    { field: 'phone', headerName: i18next.t('Phone'), minWidth: 150, flex: 1 },
    { field: 'fax', headerName: i18next.t('Fax'), minWidth: 150, flex: 1 },
    { field: 'bankAccount', headerName: i18next.t('BankAccount'), minWidth: 150, flex: 1 },
    { field: 'foreignCurrencyAccount', headerName: i18next.t('ForeignCurrencyAccount'), minWidth: 150, flex: 1 },

    {
      field: 'EntityID',
      headerName: i18next.t('Entity'),
      width: 160,
      valueGetter: this.entityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.entityName(cellParams1).localeCompare(this.entityName(cellParams2))
    },

    {
      field: 'RegionID',
      headerName: i18next.t('Region'),
      width: 160,
      valueGetter: this.regionName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.regionName(cellParams1).localeCompare(this.regionName(cellParams2))
    },
    { field: 'jIB', headerName: i18next.t('JIB'), minWidth: 150, flex: 1 },
    { field: 'isMain', headerName: i18next.t('IsMain'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'warningsArePaid', headerName: i18next.t('WarningsArePaid'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'maxAutoWriteOffAmount', headerName: i18next.t('MaxAutoWriteOffAmount'), minWidth: 150, flex: 1 },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogCompanyDetails();
  }
  edit = (params) => {
    this.props.editDialogCompanyDetails(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.companyDetails}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateCompanyDetails></CreateCompanyDetails>
        <EditCompanyDetails></EditCompanyDetails>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteCompanyDetails(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    companyDetails: Object.values(state.companyDetails),
    companies: Object.values(state.companiesSelectBox),
    cities: Object.values(state.citiesSelectBox),
    entities: Object.values(state.entitiesSelectBox),
    regions: Object.values(state.regionsSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogCompanyDetails,
    editDialogCompanyDetails,
    deleteCompanyDetails,
    getAllCompanyDetails,
    getSelectBoxCompanies,
    getSelectBoxCities,
    getSelectBoxEntities,
    getSelectBoxRegions,
    showLoadingDataInProgress
  })(TableCompanyDetails);