import api from "../api/myApi";
import { 
    CREATE_LOANQUALITY,
    GET_ALL_LOANQUALITY,
    GET_LOANQUALITY,
    GET_ALL_SELECTBOX_LOANQUALITY,
    DELETE_LOANQUALITY,
    UPDATE_LOANQUALITY,
    ADD_DIALOG_LOANQUALITY,
    EDIT_DIALOG_LOANQUALITY,
    CLOSE_DIALOG_LOANQUALITY,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanQuality = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanQuality/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANQUALITY));
    if(response.data?.succeeded){
        dispatch(reset('formLoanQuality'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLoanQualities = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQuality/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANQUALITY));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLoanQuality = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQuality/Get/' + id);
    dispatch(responseHandler(response, GET_LOANQUALITY));
}

export const getSelectBoxLoanQualities = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQuality/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANQUALITY));
}

export const updateLoanQuality = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanQuality/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANQUALITY));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoanQuality = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanQuality/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANQUALITY));
}

export const addDialogLoanQuality = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANQUALITY,
        payload: null
    });
}

export const editDialogLoanQuality = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANQUALITY,
        payload: item
    });
}

export const closeDialogLoanQuality = () => async (dispatch) => {
    dispatch(reset('formLoanQuality'));
    dispatch({
        type: CLOSE_DIALOG_LOANQUALITY,
        payload: undefined
    });
}