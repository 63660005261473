//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCRKArchive } from '../../actions/cRKArchiveAction';
import { EditSelectField, EditDatePickerField, EditIntegerNumberField, EditTextField, EditCheckbox } from '../Share/EditComponents';

class FormCRKArchive extends React.Component{
    handleClose = () => {
        this.props.closeDialogCRKArchive();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    />
                            </div>
            
                            <div>
                                <Field
                                    name='date'
                                    component={this.props.EditDatePickerField}
                                    label='Date'
                                />
                            </div>
                            <div>
                                <Field
                                    name='dailyCounter'
                                    component={this.props.EditIntegerNumberField}
                                    label='DailyCounter'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fileName'
                                    component={this.props.EditTextField}
                                    label='FileName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fileLocation'
                                    component={this.props.EditTextField}
                                    label='FileLocation'
                                />
                            </div>
                            <div>
                                <Field
                                    name='contentType'
                                    component={this.props.EditTextField}
                                    label='ContentType'
                                />
                            </div>
                            <div>
                                <Field
                                    name='hasBackup'
                                    component={this.props.EditCheckbox}
                                    label='HasBackup'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.companyID){
        errors.companyID = 'Polje je obavezno!';
    }
    if(!formValues.date){
        errors.date = 'Polje je obavezno!';
    }
    if(!formValues.dailyCounter){
        errors.dailyCounter = 'Polje je obavezno!';
    }
    if(!formValues.fileName){
        errors.fileName = 'Polje je obavezno!';
    }
    if(!formValues.fileLocation){
        errors.fileLocation = 'Polje je obavezno!';
    }
    if(!formValues.contentType){
        errors.contentType = 'Polje je obavezno!';
    }
    if(!formValues.hasBackup){
        errors.hasBackup = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formCRKArchive',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormCRKArchive);

export default connect(mapStateToProps, { closeDialogCRKArchive, EditSelectField, EditDatePickerField, EditIntegerNumberField, EditTextField, EditCheckbox})(formWrapper);