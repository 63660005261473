import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogBroker, updateBroker } from '../../actions/brokerAction';
import EditFormBroker from './EditFormBroker';

class EditBroker extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBroker(formValues);
    }
    
    render(){
        return (
            <EditFormBroker 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </EditFormBroker>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BrokerDialog === undefined || state.dialog.BrokerDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BrokerDialog};
}


export default connect(mapStateToProps, { closeDialogBroker, updateBroker })(EditBroker);
