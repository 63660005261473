import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLoanType } from '../../actions/loanTypeAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditCheckbox, EditIntegerNumberField } from '../Share/EditComponents';

class FormLoanType extends React.Component{
    handleClose = () => {
        this.props.closeDialogLoanType();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanPurpose'
                                    component={this.props.EditTextField}
                                    label='LoanPurpose'
                                />
                            </div>
                            <div>
                                <Field
                                    name='amountMin'
                                    component={this.props.EditDecimalNumberField}
                                    label='AmountMin'
                                />
                            </div>
                            <div>
                                <Field
                                    name='amountMax'
                                    component={this.props.EditDecimalNumberField}
                                    label='AmountMax'
                                />
                            </div>
                            <div>
                                <Field
                                    name='commissionPercent'
                                    component={this.props.EditDecimalNumberField}
                                    label='CommissionPercent'
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCostPercent'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCostPercent'
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCostAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCostAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='interestRate'
                                    component={this.props.EditDecimalNumberField}
                                    label='InterestRate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='legalPenaltyRate'
                                    component={this.props.EditCheckbox}
                                    label='LegalPenaltyRate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='indexedToCurrency'
                                    component={this.props.EditTextField}
                                    label='IndexedToCurrency'
                                />
                            </div>
                            <div>
                                <Field
                                    name='alternativeIR'
                                    component={this.props.EditDecimalNumberField}
                                    label='AlternativeIR'
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCostMin'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCostMin'
                                />
                            </div>
                            <div>
                                <Field
                                    name='monthlyCost'
                                    component={this.props.EditDecimalNumberField}
                                    label='MonthlyCost'
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCostFBIH'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCostFBIH'
                                />
                            </div>
                            <div>
                                <Field
                                    name='commisionPercent13Plus'
                                    component={this.props.EditDecimalNumberField}
                                    label='CommisionPercent13Plus'
                                />
                            </div>
                            <div>
                                <Field
                                    name='commisionPercent10Plus'
                                    component={this.props.EditDecimalNumberField}
                                    label='CommisionPercent10Plus'
                                />
                            </div>
                            <div>
                                <Field
                                    name='defaultPaymentWay'
                                    component={this.props.EditIntegerNumberField}
                                    label='DefaultPaymentWay'
                                />
                            </div>
                            <div>
                                <Field
                                    name='defaultAssignee'
                                    component={this.props.EditIntegerNumberField}
                                    label='DefaultAssignee'
                                />
                            </div>  
                            <div>
                                <Field
                                    name='isNonPurposeLoan'
                                    component={this.props.EditIntegerNumberField}
                                    label='IsNonPurposeLoan'
                                />
                            </div>                           
                            <div>
                                <Field
                                    name='expensesOnAccountAmount'
                                    component={this.props.EditCheckbox}
                                    label='ExpensesOnAccountAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='commissiontoDeduct'
                                    component={this.props.EditCheckbox}
                                    label='CommissiontoDeduct'
                                />
                            </div>
                            <div>
                                <Field
                                    name='hasLifeInsurance'
                                    component={this.props.EditCheckbox}
                                    label='HasLifeInsurance'
                                />
                            </div>
                            <div>
                                <Field
                                    name='hasPawnOnTheVehicle'
                                    component={this.props.EditCheckbox}
                                    label='HasPawnOnTheVehicle'
                                />
                            </div>
                            <div>
                                <Field
                                    name='hasPolicy'
                                    component={this.props.EditCheckbox}
                                    label='HasPolicy'
                                />
                            </div>
                            <div>
                                <Field
                                    name='hasLBO'
                                    component={this.props.EditCheckbox}
                                    label='HasLBO'
                                />
                            </div>
                            <div>
                                <Field
                                    name='firstMonthAdd'
                                    component={this.props.EditIntegerNumberField}
                                    label='FirstMonthAdd'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.companyID){
        errors.companyID = 'Polje je obavezno!';
    }
    if(!formValues.name){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.loanPurpose){
        errors.loanPurpose = 'Polje je obavezno!';
    }
    if(!formValues.amountMin){
        errors.amountMin = 'Polje je obavezno!';
    }
    if(!formValues.amountMax){
        errors.amountMax = 'Polje je obavezno!';
    }
    if(!formValues.commissionPercent){
        errors.commissionPercent = 'Polje je obavezno!';
    }
    if(!formValues.approvalCostPercent){
        errors.approvalCostPercent = 'Polje je obavezno!';
    }
    if(!formValues.approvalCostAmount){
        errors.approvalCostAmount = 'Polje je obavezno!';
    }
    if(!formValues.interestRate){
        errors.interestRate = 'Polje je obavezno!';
    }
    if(!formValues.legalPenaltyRate){
        errors.legalPenaltyRate = 'Polje je obavezno!';
    }
    if(!formValues.indexedToCurrency){
        errors.indexedToCurrency = 'Polje je obavezno!';
    }
    if(!formValues.alternativeIR){
        errors.alternativeIR = 'Polje je obavezno!';
    }
    if(!formValues.approvalCostMin){
        errors.approvalCostMin = 'Polje je obavezno!';
    }
    if(!formValues.monthlyCost){
        errors.monthlyCost = 'Polje je obavezno!';
    }
    if(!formValues.approvalCostFBIH){
        errors.approvalCostFBIH = 'Polje je obavezno!';
    }
    if(!formValues.expensesOnAccountAmount){
        errors.expensesOnAccountAmount = 'Polje je obavezno!';
    }
    if(!formValues.commissiontoDeduct){
        errors.commissiontoDeduct = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formLoanType',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormLoanType);

export default connect(mapStateToProps, { closeDialogLoanType, EditSelectField, EditTextField, EditDecimalNumberField, EditCheckbox, EditIntegerNumberField})(formWrapper);