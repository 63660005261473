import { 
    CREATE_ENTITY,
    GET_ALL_ENTITY,
    GET_ENTITY,
    DELETE_ENTITY,
    UPDATE_ENTITY
} from '../../actions/types';

const entityReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_ENTITY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_ENTITY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_ENTITY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_ENTITY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_ENTITY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default entityReducer;