//Mojo-AG
import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createRelatedPerson } from '../../actions/relatedPersonAction';
import FormRelatedPerson from './FormRelatedPerson';

class CreateRelatedPerson extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createRelatedPerson(formValues);
    }
    
    render(){
        return (
            <FormRelatedPerson 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormRelatedPerson>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RelatedPersonDialog === undefined || state.dialog.RelatedPersonDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.RelatedPersonDialog};
}


export default connect(mapStateToProps, { createRelatedPerson })(CreateRelatedPerson);
