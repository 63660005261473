//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogCRKResponse, updateCRKResponse } from '../../actions/cRKResponseAction';
import FormCRKResponse from './FormCRKResponse';

class EditCRKResponse extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCRKResponse(formValues);
    }
    
    render(){
        return (
            <FormCRKResponse 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormCRKResponse>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKResponseDialog === undefined || state.dialog.CRKResponseDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CRKResponseDialog};
}


export default connect(mapStateToProps, { closeDialogCRKResponse, updateCRKResponse })(EditCRKResponse);
