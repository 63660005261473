//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateEarlyRepaymentSettings from './CreateEarlyRepaymentSettings';
import EditEarlyRepaymentSettings from './EditEarlyRepaymentSettings';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogEarlyRepaymentSettings, editDialogEarlyRepaymentSettings, deleteEarlyRepaymentSettings, getAllEarlyRepaymentSettings } from "../../actions/earlyRepaymentSettingsAction";
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';


class TableEarlyRepaymentSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxCompanyDetails();

    this.props.getAllEarlyRepaymentSettings();
  }

  companyDetailsName = (props) => {
    return this.props.companyDetails.find(el => el.value == props.row.companyDetailsID)?.text;
  }
  columns = [

    {
      field: 'CompanyDetailsID',
      headerName: i18next.t('Company'),
      width: 160,
      valueGetter: this.companyDetailsName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyDetailsName(cellParams1).localeCompare(this.companyDetailsName(cellParams2))
    },
    { field: 'fromAmount', headerName: i18next.t('FromAmount'), width: 150 },
    { field: 'toAmount', headerName: i18next.t('ToAmount'), width: 150 },
    { field: 'numberOfMonthsUntilTheEndFrom', headerName: i18next.t('NumberOfMonthsUntilTheEndFrom'), width: 150 },
    { field: 'numberOfMonthsUntilTheEndTo', headerName: i18next.t('NumberOfMonthsUntilTheEndTo'), width: 150 },
    { field: 'percentage', headerName: i18next.t('Percentage'), width: 150 },
    { field: 'canBeHigherThanTheApprovalAmount', headerName: i18next.t('CanBeHigherThanTheApprovalAmount'), width: 150 },
    { field: 'validFrom', headerName: i18next.t('ValidFrom'), width: 150 },
    { field: 'validTo', headerName: i18next.t('ValidTo'), width: 150 },
    //{ field: 'createDate', headerName: i18next.t('CreateDate'), width: 150 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogEarlyRepaymentSettings();
  }
  edit = (params) => {
    this.props.editDialogEarlyRepaymentSettings(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.earlyRepaymentSettings}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateEarlyRepaymentSettings></CreateEarlyRepaymentSettings>
        <EditEarlyRepaymentSettings></EditEarlyRepaymentSettings>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteEarlyRepaymentSettings(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { earlyRepaymentSettings: Object.values(state.earlyRepaymentSettings), companyDetails: Object.values(state.companyDetailsSelectBox) };
}

export default connect(mapStateToProps, { addDialogEarlyRepaymentSettings, editDialogEarlyRepaymentSettings, deleteEarlyRepaymentSettings, getAllEarlyRepaymentSettings, getSelectBoxCompanyDetails })(TableEarlyRepaymentSettings);