import api from "../api/myApi";
import { 
    CREATE_PLAN,
    GET_ALL_PLAN,
    GET_PLAN,
    GET_ALL_SELECTBOX_PLAN,
    DELETE_PLAN,
    UPDATE_PLAN,
    ADD_DIALOG_PLAN,
    EDIT_DIALOG_PLAN,
    CLOSE_DIALOG_PLAN,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createPlan = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Plan/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PLAN));
    if(response.data?.succeeded){
        dispatch(reset('formPlan'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPlans = () => async (dispatch) => {
    const response = await api.get('/api/v1/Plan/GetAll');
    dispatch(responseHandler(response, GET_ALL_PLAN));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPlan = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Plan/Get/' + id);
    dispatch(responseHandler(response, GET_PLAN));
}

export const getSelectBoxPlans = () => async (dispatch) => {
    const response = await api.get('/api/v1/Plan/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PLAN));
}

export const updatePlan = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Plan/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PLAN));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePlan = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Plan/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PLAN));
}

export const addDialogPlan = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PLAN,
        payload: null
    });
}

export const editDialogPlan = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PLAN,
        payload: item
    });
}

export const closeDialogPlan = () => async (dispatch) => {
    dispatch(reset('formPlan'));
    dispatch({
        type: CLOSE_DIALOG_PLAN,
        payload: undefined
    });
}