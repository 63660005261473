import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createRegion } from '../../actions/regionAction';
import FormRegion from './FormRegion';

class CreateRegion extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createRegion(formValues);
    }
    
    render(){
        return (
            <FormRegion 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormRegion>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RegionDialog === undefined || state.dialog.RegionDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.RegionDialog};
}


export default connect(mapStateToProps, { createRegion })(CreateRegion);
