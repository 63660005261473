//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_CRKREQUESTTYPE,
    GET_ALL_CRKREQUESTTYPE,
    GET_CRKREQUESTTYPE,
    GET_ALL_SELECTBOX_CRKREQUESTTYPE,
    DELETE_CRKREQUESTTYPE,
    UPDATE_CRKREQUESTTYPE,
    ADD_DIALOG_CRKREQUESTTYPE,
    EDIT_DIALOG_CRKREQUESTTYPE,
    CLOSE_DIALOG_CRKREQUESTTYPE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCRKRequestType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequestType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CRKREQUESTTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formCRKRequestType'));
    }
}

export const getAllCRKRequestTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKRequestType/GetAll');
    dispatch(responseHandler(response, GET_ALL_CRKREQUESTTYPE));
}

export const getCRKRequestType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CRKRequestType/Get/' + id);
    dispatch(responseHandler(response, GET_CRKREQUESTTYPE));
}

export const getSelectBoxCRKRequestTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKRequestType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CRKREQUESTTYPE));
}

export const updateCRKRequestType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequestType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CRKREQUESTTYPE));
}

export const deleteCRKRequestType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequestType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CRKREQUESTTYPE));
}

export const addDialogCRKRequestType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CRKREQUESTTYPE,
        payload: null
    });
}

export const editDialogCRKRequestType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CRKREQUESTTYPE,
        payload: item
    });
}

export const closeDialogCRKRequestType = () => async (dispatch) => {
    dispatch(reset('formCRKRequestType'));
    dispatch({
        type: CLOSE_DIALOG_CRKREQUESTTYPE,
        payload: undefined
    });
}