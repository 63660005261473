import React from "react";
import TableLoan from '../../components/Loan/TableLoan';
import i18next from '../../language/i18n';
import DateProvider from '../../share/dateProvider';

class AllLoansPage extends React.Component{
    columns = [
        { field: 'writtenOffDate', headerName: i18next.t('WrittenOffDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },
        { field: 'isWrittenOff', headerName: i18next.t('IsWrittenOff'), minWidth: 150, flex: 1, type: 'boolean' }
    ]
    render(){
        return(<TableLoan
            Status={null}
            columns={this.columns}
        />);
    }
}

export default AllLoansPage;