import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { getOrdersInImbalance } from '../../actions/orderAction';
import { dateFormat } from '../Share/Table/DateFormat';


class TableOrdersInImbalance extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.props.getOrdersInImbalance();
  }

  columns = [
    { field: 'year', headerName: i18next.t('Year'), minWidth: 150, flex: 1 },
    {
      field: 'number',
      headerName: i18next.t('Number'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link href={"#/PostingAccount/" + params.row.year+"/"+params.row.number}
          >
            {params.row.number ?? i18next.t('Number')}
          </Link>
        </strong>
      ),
    },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, valueGetter: dateFormat },  
    { field: 'sumOwes', headerName: i18next.t('Owes'), minWidth: 150, flex: 1 },
    { field: 'sumClaim', headerName: i18next.t('Claim'), minWidth: 150, flex: 1 },
    { field: 'difference', headerName: i18next.t('Difference'), minWidth: 150, flex: 1 }
  ];
  edit = (params) => {
    this.props.editDialogPenaltyInterestSettings(params.row);
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.ordersInImbalance}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ordersInImbalance: Object.values(state.ordersInImbalance),
  };
}

export default connect(mapStateToProps, {
  getOrdersInImbalance
})(TableOrdersInImbalance);