import { 
    CREATE_LEGALSERVICE_COURT,
    GET_ALL_LEGALSERVICE_COURT,
    GET_LEGALSERVICE_COURT,
    DELETE_LEGALSERVICE_COURT,
    UPDATE_LEGALSERVICE_COURT
} from '../../actions/types';

const legalService_CourtReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LEGALSERVICE_COURT:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LEGALSERVICE_COURT:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LEGALSERVICE_COURT:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LEGALSERVICE_COURT: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LEGALSERVICE_COURT:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default legalService_CourtReducer;