//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_COMMISSIONSETTINGS,
    GET_ALL_COMMISSIONSETTINGS,
    GET_COMMISSIONSETTINGS,
    GET_ALL_SELECTBOX_COMMISSIONSETTINGS,
    DELETE_COMMISSIONSETTINGS,
    UPDATE_COMMISSIONSETTINGS,
    ADD_DIALOG_COMMISSIONSETTINGS,
    EDIT_DIALOG_COMMISSIONSETTINGS,
    CLOSE_DIALOG_COMMISSIONSETTINGS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCommissionSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CommissionSettings/Create', formValues);    
    dispatch(responseHandler(response, CREATE_COMMISSIONSETTINGS));
    if(response.data?.succeeded){
        dispatch(reset('formCommissionSettings'));
    }
}

export const getAllCommissionSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/CommissionSettings/GetAll');
    dispatch(responseHandler(response, GET_ALL_COMMISSIONSETTINGS));
}

export const getCommissionSettings = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CommissionSettings/Get/' + id);
    dispatch(responseHandler(response, GET_COMMISSIONSETTINGS));
}

export const getSelectBoxCommissionSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/CommissionSettings/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_COMMISSIONSETTINGS));
}

export const updateCommissionSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CommissionSettings/Update', formValues);
    dispatch(responseHandler(response, UPDATE_COMMISSIONSETTINGS));
}

export const deleteCommissionSettings = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CommissionSettings/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_COMMISSIONSETTINGS));
}

export const addDialogCommissionSettings = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_COMMISSIONSETTINGS,
        payload: null
    });
}

export const editDialogCommissionSettings = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_COMMISSIONSETTINGS,
        payload: item
    });
}

export const closeDialogCommissionSettings = () => async (dispatch) => {
    dispatch(reset('formCommissionSettings'));
    dispatch({
        type: CLOSE_DIALOG_COMMISSIONSETTINGS,
        payload: undefined
    });
}