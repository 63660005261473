import React from 'react';
import {Grid} from '@mui/material';

class ComponentInLine extends React.Component{
    renderElements = () => {
        if(this.props.children === undefined){
            return null;
        }
        let children = this.props.children.filter(el => el != null);
        if(children.length == 5){
            return children.map((el, index) => (
                <Grid item key={index}
                xs={this.props.xs?.length == children.length ? this.props.xs[index] : (index<3 ? 2:3)}>
                    {el}                       
                </Grid>
            ));
        }
        else{
            let xs = 12 / children.length;
            
            return children.map((el, index) => (
                <Grid item key={index}
                    xs={this.props.xs?.length == children.length ? this.props.xs[index] : xs}>
                    {el}                       
                </Grid>
            ));
        }
    }
    render(){        
        return(
            <Grid container item className={this.props.className}
                xs={12}
                spacing={1}>
                {this.renderElements()}
            </Grid>
        );
    };
}

export default ComponentInLine;