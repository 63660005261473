import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogBroker } from '../../actions/brokerAction';
import { EditTextField, EditSelectField, EditDecimalNumberField } from '../Share/EditComponents';

class EditFormBroker extends React.Component{
    handleClose = () => {
        this.props.closeDialogBroker();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='firstName'
                                    component={this.props.EditTextField}
                                    label='FirstName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastName'
                                    component={this.props.EditTextField}
                                    label='LastName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='percentage'
                                    component={this.props.EditDecimalNumberField}
                                    label='Percentage'
                                />
                            </div>
            
                            <DialogActions>
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.firstName){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.lastName){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.percentage){
        errors.name = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { usersSelectBox: Object.values(state.usersSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'editFormBroker',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(EditFormBroker);

export default connect(mapStateToProps, { closeDialogBroker, EditTextField, EditSelectField, EditDecimalNumberField})(formWrapper);