import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
//import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
// import ConfirmDialog from '../Share/ConfirmDialog';
// import CreateBLTurnover from './CreateBLTurnover';
// import EditBLTurnover from './EditBLTurnover';
import { addDialogBLTurnover, editDialogBLTurnover, deleteBLTurnover/*, getAllBLTurnovers */} from "../../actions/bLTurnoverAction";
import { getSelectBoxDocumentTurnoverTypes } from '../../actions/documentTurnoverTypeAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxPlans } from '../../actions/planAction';
import { getSelectBoxCashBalances } from '../../actions/cashBalanceAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxLoans } from '../../actions/loanAction';
import i18next from '../../language/i18n';
//import DeleteButton from '../Share/Button/DeleteButton';


class TableBLTurnover extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxDocumentTurnoverTypes();
    //this.props.getSelectBoxPartners();
    this.props.getSelectBoxPlans();
    this.props.getSelectBoxCashBalances();
    //this.props.getSelectBoxBranches();
    //this.props.getSelectBoxLoans();

    //this.props.getAllBLTurnovers();
  }

  documentTurnoverTypeName = (props) => {
    return this.props.documentTurnoverTypes.find(el => el.value === props.row.documentTurnoverTypeID)?.text;
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  planName = (props) => {
    return this.props.plans.find(el => el.value === props.row.planID)?.text;
  }
  cashBalanceName = (props) => {
    return this.props.cashBalances.find(el => el.value === props.row.cashBalanceID)?.text;
  }
  branchName = (props) => {
    return this.props.branches.find(el => el.value === props.row.branchID)?.text;
  }
  loanName = (props) => {
    return this.props.loans.find(el => el.value === props.row.loanID)?.text;
  }
  columns = [
    //{ field: 'pageNumber', headerName: i18next.t('PageNumber'), minWidth: 150, flex: 1 },

    {
      field: 'DocumentTurnoverTypeID',
      headerName: i18next.t('DocumentTurnoverType'),
      width: 160,
      valueGetter: this.documentTurnoverTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.documentTurnoverTypeName(cellParams1).localeCompare(this.documentTurnoverTypeName(cellParams2))
    },
    { field: 'payIn', headerName: i18next.t('PayIn'), minWidth: 100, flex: 1 },
    { field: 'payOut', headerName: i18next.t('PayOut'), minWidth: 100, flex: 1 },

    // {
    //   field: 'PartnerID',
    //   headerName: i18next.t('Partner'),
    //   width: 160,
    //   valueGetter: this.partnerName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    // },
    //{ field: 'bRVD', headerName: i18next.t('BRVD'), minWidth: 150, flex: 1 },
    // { field: 'dPayIn', headerName: i18next.t('DPayIn'), minWidth: 150, flex: 1 },
    // { field: 'dPayOut', headerName: i18next.t('DPayOut'), minWidth: 150, flex: 1 },

    {
      field: 'PlanID',
      headerName: i18next.t('Plan'),
      minWidth: 150, flex: 2, 
      valueGetter: this.planName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.planName(cellParams1).localeCompare(this.planName(cellParams2))
    },
    { field: 'link', headerName: i18next.t('Link'), minWidth: 150, flex: 1 },
    { field: 'bankAccount', headerName: i18next.t('BankAccount'), minWidth: 150, flex: 1 },
    { field: 'purposeOfPayment', headerName: i18next.t('PurposeOfPayment'), minWidth: 450, flex: 3 },

    // {
    //   field: 'CashBalanceID',
    //   headerName: i18next.t('CashBalance'),
    //   width: 160,
    //   valueGetter: this.cashBalanceName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.cashBalanceName(cellParams1).localeCompare(this.cashBalanceName(cellParams2))
    // },

    // {
    //   field: 'BranchID',
    //   headerName: i18next.t('Branch'),
    //   width: 160,
    //   valueGetter: this.branchName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    // },

    // {
    //   field: 'LoanID',
    //   headerName: i18next.t('Loan'),
    //   width: 160,
    //   valueGetter: this.loanName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
    // },
    // { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    // { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    // {
    //   field: 'edit',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ marginLeft: 16 }}
    //         onClick={() => this.edit(params)}
    //       >
    //         {i18next.t('Edit')}
    //       </Button>
    //     </strong>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>
    //       <DeleteButton
    //         onClick={() => this.delete(params)}
    //       ></DeleteButton>
    //     </strong>
    //   )
    // }
  ];
  // create = () => {
  //   this.props.addDialogBLTurnover();
  // }
  // edit = (params) => {
  //   this.props.editDialogBLTurnover(params.row);
  // }
  // delete = (params) => {
  //   this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  // }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.items}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          //componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        {/* <CreateBLTurnover></CreateBLTurnover>
        <EditBLTurnover></EditBLTurnover>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteBLTurnover(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    //bLTurnovers: Object.values(state.bLTurnovers),
    documentTurnoverTypes: Object.values(state.documentTurnoverTypesSelectBox),
    //partners: Object.values(state.partnersSelectBox),
    plans: Object.values(state.plansSelectBox),
    cashBalances: Object.values(state.cashBalancesSelectBox),
    //branches: Object.values(state.branchesSelectBox),
    //loans: Object.values(state.loansSelectBox)
  };
}

export default connect(mapStateToProps, {
  addDialogBLTurnover,
  editDialogBLTurnover,
  deleteBLTurnover,
  //getAllBLTurnovers,
  getSelectBoxDocumentTurnoverTypes,
  getSelectBoxPartners,
  getSelectBoxPlans,
  getSelectBoxCashBalances,
  getSelectBoxBranches,
  getSelectBoxLoans
})(TableBLTurnover);