import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogUser } from '../../actions/userAction';
import { EditSelectField, EditTextField, EditDatePickerField } from '../Share/EditComponents';

class UserPartnerForm extends React.Component{
    render(){
        return (
            <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                           <div>
                                    <Field
                                        name='password'
                                        component={this.props.EditTextField}
                                        label='Password'
                                        type="password"
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='confirmPassword'
                                        component={this.props.EditTextField}
                                        label='ConfirmPassword'
                                        type="password"
                                    />
                                </div>
                            <DialogActions>
                            <Button onClick={this.props.handleClose} color="secondary" variant="outlined">
                                {i18next.t('Cancel')}
                            </Button>
                            <Button color="primary" variant="contained" type="Submit">
                                {i18next.t('Submit')}
                            </Button>
                            </DialogActions>
                        </form>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(formValues.password != formValues.confirmPassword){
        errors.confirmPassword = 'Potvrda se ne poklapa sa lozinkom';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
      roles: Object.values(state.rolesSelectBox), 
    };
}

const formWrapper = reduxForm({
    form: 'formPartnerUser',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(UserPartnerForm);

export default connect(mapStateToProps, { closeDialogUser, EditSelectField, EditTextField, EditDatePickerField})(formWrapper);