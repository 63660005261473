import React from 'react';
import { connect } from 'react-redux';
import FormPartnerPerson from './FormPartnerPerson';
import FormPartnerLegalEntity from './FormPartnerLegalEntity';
import i18next from '../../language/i18n';

class ViewPartner extends React.Component{   
    constructor(props) {
        super(props);
        this.state = {
            partnerType: null,
            currentValue: null,
            showUserForm: false,
            partnerData: null
        }
    }
    componentDidMount = () => {
      }
onSubmit = () => {}
handleClose = () => {}
      renderElement = () => {
        if (this.props.partnerSelected?.partnerData?.partnerTypeID === 3) {
            return (
                <FormPartnerPerson onSubmit={
                    this.onSubmit
                }
                disabled={true}
                initialValues={this.props.partnerSelected?.partnerData}
                handleClose={this.handleClose}></FormPartnerPerson>
            )
        }
        else if (this.props.partnerSelected?.partnerData?.partnerTypeID === 1 || this.props.partnerSelected?.partnerData?.partnerTypeID === 7) {
            return (
                <FormPartnerLegalEntity onSubmit={
                    this.onSubmit
                }
                disabled={true}
                initialValues={this.props.partnerSelected?.partnerData}
                handleClose={this.handleClose}
                buttonText={i18next.t('Submit')}></FormPartnerLegalEntity>
            )
        } 
        else {
            return (
                <div>Test
                </div>
            );
        }
    }
    render(){
        return (
            this.renderElement()
        );
    }
}

const mapStateToProps = state => {
  return {
    partnerSelected: state.partnerSelected
    };
}


export default connect(mapStateToProps, {
     })(ViewPartner);
