import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateBLTurnover from './CreateBLTurnover';
import EditBLTurnover from './EditBLTurnover';
import { addDialogBLTurnover, editDialogBLTurnover, deleteBLTurnover, getAllByCashBalanceBLTurnovers } from "../../actions/bLTurnoverAction";
import { getSelectBoxDocumentTurnoverTypes } from '../../actions/documentTurnoverTypeAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxPlans } from '../../actions/planAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxLoans } from '../../actions/loanAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableBLTurnoverDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxDocumentTurnoverTypes();
    this.props.getSelectBoxPartners();
    this.props.getSelectBoxPlans();
    //this.props.getSelectBoxBranches();
    //this.props.getSelectBoxLoans();
    if (this.props.CashBalanceID !== null) {
      this.props.getAllByCashBalanceBLTurnovers({ CashBalanceID: this.props.CashBalanceID });
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.CashBalanceID !== nextProps.CashBalanceID && nextProps.CashBalanceID !== null) {
      this.props.getAllByCashBalanceBLTurnovers({ CashBalanceID: nextProps.CashBalanceID });
    }
    return true;
  }

  documentTurnoverTypeName = (props) => {
    return this.props.documentTurnoverTypes.find(el => el.value === props.row.documentTurnoverTypeID)?.text;
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  planName = (props) => {
    return this.props.plans.find(el => el.value === props.row.planID)?.text;
  }
  cashBalanceName = (props) => {
    return this.props.cashBalances.find(el => el.value === props.row.cashBalanceID)?.text;
  }
  branchName = (props) => {
    return this.props.branches.find(el => el.value === props.row.branchID)?.text;
  }
  loanName = (props) => {
    return this.props.loans.find(el => el.value === props.row.loanID)?.text;
  }
  columns = [
    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      minWidth: 160, flex: 2,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    { field: 'link', headerName: i18next.t('Link'), minWidth: 150, flex: 1 },
    {
      field: 'DocumentTurnoverTypeID',
      headerName: i18next.t('DocumentTurnoverType'),
      minWidth: 160, flex: 1,
      valueGetter: this.documentTurnoverTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.documentTurnoverTypeName(cellParams1).localeCompare(this.documentTurnoverTypeName(cellParams2))
    }, {
      field: 'PlanID',
      headerName: i18next.t('Plan'),
      minWidth: 160, flex: 2,
      valueGetter: this.planName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.planName(cellParams1).localeCompare(this.planName(cellParams2))
    },
    { field: 'payIn', headerName: i18next.t('PayIn'), minWidth: 150, flex: 1 },
    { field: 'payOut', headerName: i18next.t('PayOut'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogBLTurnover();
  }
  edit = (params) => {
    this.props.editDialogBLTurnover(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <Dialog
          fullWidth
          maxWidth="xl"
          contentS
          open={this.props.IsOpen}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent style={{ height: '80vh' }}>
            <DataGrid style={{ height: '90%' }}
              rows={this.props.bLTurnovers}
              columns={this.columns}
              components={{
                Toolbar: CustomToolbar
              }}
              componentsProps={{ toolbar: { createNew: this.create } }}
              checkboxSelection
              disableSelectionOnClick
            />
            <DialogActions>
              <Button color="primary" variant="contained" onClick={this.props.close}>
                {i18next.t('Cancel')}
              </Button>
            </DialogActions>
            <CreateBLTurnover CashBalanceID={this.props.CashBalanceID}></CreateBLTurnover>
            <EditBLTurnover CashBalanceID={this.props.CashBalanceID}></EditBLTurnover>
            <ConfirmDialog
              IsOpen={this.state.IsOpenConfirmDialog}
              close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
              confirm={() => {
                this.props.deleteBLTurnover(this.state.ID);
                this.setState({ IsOpenConfirmDialog: false, ID: null });
              }}
              message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
            />

          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bLTurnovers: Object.values(state.bLTurnovers),
    documentTurnoverTypes: Object.values(state.documentTurnoverTypesSelectBox),
    partners: Object.values(state.partnersSelectBox),
    plans: Object.values(state.plansSelectBox),
    cashBalances: Object.values(state.cashBalancesSelectBox),
    branches: Object.values(state.branchesSelectBox),
    loans: Object.values(state.loansSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogBLTurnover,
    editDialogBLTurnover,
    deleteBLTurnover,
    getAllByCashBalanceBLTurnovers,
    getSelectBoxDocumentTurnoverTypes,
    getSelectBoxPartners,
    getSelectBoxPlans,
    getSelectBoxBranches,
    getSelectBoxLoans
  })(TableBLTurnoverDetails);