//Mojo-AG
import { 
    CREATE_REVENUERECOGNITION,
    GET_ALL_REVENUERECOGNITION,
    GET_REVENUERECOGNITION,
    DELETE_REVENUERECOGNITION,
    UPDATE_REVENUERECOGNITION,
    GET_LOAN
} from '../../actions/types';

const revenueRecognitionReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_REVENUERECOGNITION:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_REVENUERECOGNITION:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_REVENUERECOGNITION:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_REVENUERECOGNITION: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case GET_LOAN:
                let result3 = {  };
                for(let i=0; i<action.payload.revenueRecognition.length;i++) {result3[action.payload.revenueRecognition[i].id]=action.payload.revenueRecognition[i];}
                return result3;
        case UPDATE_REVENUERECOGNITION:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default revenueRecognitionReducer;