import api from "../api/myApi";
import { 
    CREATE_BRANCH,
    GET_ALL_BRANCH,
    GET_BRANCH,
    GET_ALL_SELECTBOX_BRANCH,
    DELETE_BRANCH,
    UPDATE_BRANCH,
    ADD_DIALOG_BRANCH,
    EDIT_DIALOG_BRANCH,
    CLOSE_DIALOG_BRANCH,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBranch = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Branch/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BRANCH));
    if(response.data?.succeeded){
        dispatch(reset('formBranch'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllBranches = () => async (dispatch) => {
    const response = await api.get('/api/v1/Branch/GetAll');
    dispatch(responseHandler(response, GET_ALL_BRANCH));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getBranch = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Branch/Get/' + id);
    dispatch(responseHandler(response, GET_BRANCH));
}

export const getSelectBoxBranches = () => async (dispatch) => {
    const response = await api.get('/api/v1/Branch/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BRANCH));
}

export const updateBranch = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Branch/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BRANCH));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteBranch = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Branch/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BRANCH));
}

export const addDialogBranch = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BRANCH,
        payload: null
    });
}

export const editDialogBranch = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BRANCH,
        payload: item
    });
}

export const closeDialogBranch = () => async (dispatch) => {
    dispatch(reset('formBranch'));
    dispatch({
        type: CLOSE_DIALOG_BRANCH,
        payload: undefined
    });
}