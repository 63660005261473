import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import i18next from '../../language/i18n';
import CreateLegalService_ExecutionType from './CreateLegalService_ExecutionType';
import EditLegalService_ExecutionType from './EditLegalService_ExecutionType';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogLegalService_ExecutionType, editDialogLegalService_ExecutionType, deleteLegalService_ExecutionType, getAllLegalService_ExecutionTypes } from "../../actions/legalService_ExecutionTypeAction";


class TableLegalService_ExecutionType extends React.Component{
    constructor(props){
        super(props);
        this.state = {IsOpenConfirmDialog: false, ID: null}
      }
    componentDidMount = () =>{

      this.props.getAllLegalService_ExecutionTypes();
    }
    
    columns = [
        { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },

          {
            field: 'edit',
            headerName: ' ',
            renderCell: (params) => (
              <strong>                
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={() => this.edit(params)}
                >
                  {i18next.t('Edit')}
                </Button>
              </strong>
            ),
          },
          {
            field: 'delete',
            headerName: ' ',
            renderCell: (params) => (
              <strong>                
                <DeleteButton
                  onClick={() => this.delete(params)}
                ></DeleteButton> 
              </strong>
            )
          }
      ];
      create = () => {
        this.props.addDialogLegalService_ExecutionType();
      }
      edit = (params) => {
        this.props.editDialogLegalService_ExecutionType(params.row);
      }
      delete = (params) => {
        this.setState({IsOpenConfirmDialog: true, ID: params.row.id});
      }
    render(){
        return (
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid  
                  rows={this.props.legalService_ExecutionTypes} 
                  columns={this.columns}
                  components={{
                      Toolbar: CustomToolbar
                  }}
                  componentsProps={{ toolbar: { createNew:this.create } }}
                  checkboxSelection 
                  disableSelectionOnClick 
                 />
              <CreateLegalService_ExecutionType></CreateLegalService_ExecutionType>
              <EditLegalService_ExecutionType></EditLegalService_ExecutionType>
              <ConfirmDialog 
                  IsOpen={this.state.IsOpenConfirmDialog}
                  close={() => this.setState({IsOpenConfirmDialog: false, ID: null})}
                  confirm={() => {
                    this.props.deleteLegalService_ExecutionType(this.state.ID);
                    this.setState({IsOpenConfirmDialog: false, ID: null});
                  }}
                  message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
                />
            </div>
          );
    }
}

const mapStateToProps = state => {
    return {legalService_ExecutionTypes: Object.values(state.legalService_ExecutionTypes) };
}

export default connect(mapStateToProps, { addDialogLegalService_ExecutionType, editDialogLegalService_ExecutionType, deleteLegalService_ExecutionType, getAllLegalService_ExecutionTypes  })(TableLegalService_ExecutionType);