import api from "../api/myApi";
import {
    GET_ALL_SELECTBOX_LOANPURPOSE
} from './types';
import { responseHandler } from "../api/responseHandler";


export const getSelectBoxLoanPurpose = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanPurpose/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANPURPOSE));
}