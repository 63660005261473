//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_CRKRESPONSE,
    GET_ALL_CRKRESPONSE,
    GET_CRKRESPONSE,
    GET_ALL_SELECTBOX_CRKRESPONSE,
    DELETE_CRKRESPONSE,
    UPDATE_CRKRESPONSE,
    ADD_DIALOG_CRKRESPONSE,
    EDIT_DIALOG_CRKRESPONSE,
    CLOSE_DIALOG_CRKRESPONSE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCRKResponse = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKResponse/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CRKRESPONSE));
    if(response.data?.succeeded){
        dispatch(reset('formCRKResponse'));
    }
}

export const getAllCRKResponses = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKResponse/GetAll');
    dispatch(responseHandler(response, GET_ALL_CRKRESPONSE));
}

export const getCRKResponse = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CRKResponse/Get/' + id);
    dispatch(responseHandler(response, GET_CRKRESPONSE));
}

export const getSelectBoxCRKResponses = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKResponse/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CRKRESPONSE));
}

export const updateCRKResponse = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKResponse/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CRKRESPONSE));
}

export const deleteCRKResponse = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKResponse/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CRKRESPONSE));
}

export const addDialogCRKResponse = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CRKRESPONSE,
        payload: null
    });
}

export const editDialogCRKResponse = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CRKRESPONSE,
        payload: item
    });
}

export const closeDialogCRKResponse = () => async (dispatch) => {
    dispatch(reset('formCRKResponse'));
    dispatch({
        type: CLOSE_DIALOG_CRKRESPONSE,
        payload: undefined
    });
}