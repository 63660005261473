import api from "../api/myApi";
import { 
    CREATE_ORDER,
    GET_ALL_ORDER,
    GET_ORDER,
    GET_ALL_SELECTBOX_ORDER,
    DELETE_ORDER,
    UPDATE_ORDER,
    ADD_DIALOG_ORDER,
    EDIT_DIALOG_ORDER,
    CLOSE_DIALOG_ORDER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS,
    SHOW_LOADING_DATA_PROGRESS,
    GET_ALL_ORDER_IN_IMBALANCE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
/*
export const createOrder = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Order/Create', formValues);    
    dispatch(responseHandler(response, CREATE_ORDER));
    if(response.data?.succeeded){
        dispatch(reset('formOrder'));
    }
}
*/
export const createOrder = (year, orderTypeID) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Order/Create', {Year: year, OrderTypeID: orderTypeID}); 
    
    dispatch(responseHandler(response, 'success'));  
    //dispatch(responseHandler(response, CREATE_ORDER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
    return response?.data?.data?.number ?? -1;
}
export const getAllOrders = () => async (dispatch) => {
    const response = await api.get('/api/v1/Order/GetAll');
    dispatch(responseHandler(response, GET_ALL_ORDER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getOrder = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Order/Get/' + id);
    dispatch(responseHandler(response, GET_ORDER));
}

export const getSelectBoxOrders = () => async (dispatch) => {
    const response = await api.get('/api/v1/Order/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_ORDER));
}

export const updateOrder = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_LOADING_DATA_PROGRESS});
    const response = await api.post('/api/v1/Order/Update', formValues);
    dispatch(responseHandler(response, UPDATE_ORDER));
    dispatch(responseHandler(response, 'success'));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const deleteOrder = (year, number) => async (dispatch) => {
    const response = await api.post('/api/v1/Order/Delete/', { Year: year, Number: number });
    dispatch(responseHandler(response, 'success'));
    //dispatch(responseHandler(response, DELETE_ORDER));
}

export const addDialogOrder = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_ORDER,
        payload: null
    });
}

export const editDialogOrder = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_ORDER,
        payload: item
    });
}

export const closeDialogOrder = () => async (dispatch) => {
    dispatch(reset('formOrder'));
    dispatch({
        type: CLOSE_DIALOG_ORDER,
        payload: undefined
    });
}

export const maxNumberByYear = (year) => async (dispatch) => {
    const response = await api.post('/api/v1/Order/MaxNumberByYear', { Year: year });
    //dispatch(responseHandler(response, 'success'));
    return response.data.data;
    //dispatch(responseHandler(response, DELETE_ORDER));
}

export const cancelOrder = (year, number) => async (dispatch) => {
    const response = await api.post('/api/v1/Order/CancelOrder', { Year: year, Number: number });
    dispatch(responseHandler(response, 'success'));
    return response.data.data;
    //dispatch(responseHandler(response, DELETE_ORDER));
}

export const copyOrder = (year, number) => async (dispatch) => {
    const response = await api.post('/api/v1/Order/CopyOrder', { Year: year, Number: number }); 
    dispatch(responseHandler(response, 'success'));
    return response.data.data;
    //dispatch(responseHandler(response, DELETE_ORDER));
}
export const getOrdersInImbalance = () => async (dispatch) => {
    const response = await api.get('/api/v1/Order/OrdersInImbalance');
    dispatch(responseHandler(response, GET_ALL_ORDER_IN_IMBALANCE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}
