import api from "../api/myApi";
import { 
    CREATE_OPINIONOFTHEREFERENT,
    GET_ALL_OPINIONOFTHEREFERENT,
    GET_OPINIONOFTHEREFERENT,
    GET_ALL_SELECTBOX_OPINIONOFTHEREFERENT,
    DELETE_OPINIONOFTHEREFERENT,
    UPDATE_OPINIONOFTHEREFERENT,
    ADD_DIALOG_OPINIONOFTHEREFERENT,
    EDIT_DIALOG_OPINIONOFTHEREFERENT,
    CLOSE_DIALOG_OPINIONOFTHEREFERENT,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createOpinionOfTheReferent = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/OpinionOfTheReferent/Create', formValues);    
    dispatch(responseHandler(response, CREATE_OPINIONOFTHEREFERENT));
    if(response.data?.succeeded){
        dispatch(reset('formOpinionOfTheReferent'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllOpinionOfTheReferents = () => async (dispatch) => {
    const response = await api.get('/api/v1/OpinionOfTheReferent/GetAll');
    dispatch(responseHandler(response, GET_ALL_OPINIONOFTHEREFERENT));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getOpinionOfTheReferent = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/OpinionOfTheReferent/Get/' + id);
    dispatch(responseHandler(response, GET_OPINIONOFTHEREFERENT));
}

export const getSelectBoxOpinionOfTheReferents = () => async (dispatch) => {
    const response = await api.get('/api/v1/OpinionOfTheReferent/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_OPINIONOFTHEREFERENT));
}

export const updateOpinionOfTheReferent = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/OpinionOfTheReferent/Update', formValues);
    dispatch(responseHandler(response, UPDATE_OPINIONOFTHEREFERENT));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteOpinionOfTheReferent = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/OpinionOfTheReferent/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_OPINIONOFTHEREFERENT));
}

export const addDialogOpinionOfTheReferent = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_OPINIONOFTHEREFERENT,
        payload: null
    });
}

export const editDialogOpinionOfTheReferent = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_OPINIONOFTHEREFERENT,
        payload: item
    });
}

export const closeDialogOpinionOfTheReferent = () => async (dispatch) => {
    dispatch(reset('formOpinionOfTheReferent'));
    dispatch({
        type: CLOSE_DIALOG_OPINIONOFTHEREFERENT,
        payload: undefined
    });
}