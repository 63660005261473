//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCRKArchive from './CreateCRKArchive';
import EditCRKArchive from './EditCRKArchive';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { deleteCRKArchive, getAllCRKArchives, getFile } from "../../actions/cRKArchiveAction";
import { dateFormat } from '../Share/Table/DateFormat';


class TableCRKArchive extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getAllCRKArchives();
  }
  columns = [

   
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'dailyCounter', headerName: i18next.t('DailyCounter'), minWidth: 150, flex: 1 },
    //{ field: 'fileName', headerName: i18next.t('FileName'), width: 150 },
    //{ field: 'fileLocation', headerName: i18next.t('FileLocation'), width: 150 },
    //{ field: 'contentType', headerName: i18next.t('ContentType'), width: 150 },
    { field: 'hasBackup', headerName: i18next.t('HasBackup'), minWidth: 150, flex: 1, type: 'boolean' },
    //{ field: 'createDate', headerName: i18next.t('CreateDate'), width: 150 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },

    // {
    //   field: 'edit',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>                
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ marginLeft: 16 }}
    //         onClick={() => this.edit(params)}
    //       >
    //         {i18next.t('Edit')}
    //       </Button>
    //     </strong>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>                
    //       <DeleteButton onClick={() => this.delete(params)} />
    //     </strong>
    //   )
    // }
    {
      field: 'download',
      headerName: ' ', 
      minWidth: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16, marginRight: 16 }}
            onClick={() => this.download(params)}
          >
            {i18next.t('Download')}
          </Button>
        </strong>
      ),
    },
  ];
  download = (params) => {
    this.props.getFile(params.row.id, params.row.fileName+".zip");
  }
  // create = () => {
  //   this.props.addDialogCRKArchive();
  // }
  // edit = (params) => {
  //   this.props.editDialogCRKArchive(params.row);
  // }
  // delete = (params) => {
  //   this.setState({IsOpenConfirmDialog: true, ID: params.row.id});
  // }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.cRKArchives}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps
          checkboxSelection
          disableSelectionOnClick
        />
        {/* <CreateCRKArchive></CreateCRKArchive>
              <EditCRKArchive></EditCRKArchive> */}
        {/* <ConfirmDialog 
                  IsOpen={this.state.IsOpenConfirmDialog}
                  close={() => this.setState({IsOpenConfirmDialog: false, ID: null})}
                  confirm={() => {
                    this.props.deleteCRKArchive(this.state.ID);
                    this.setState({IsOpenConfirmDialog: false, ID: null});
                  }}
                  message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
                /> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cRKArchives: Object.values(state.cRKArchives).sort(function(a,b) {
      return b.id - a.id;
  })
  };
}

export default connect(mapStateToProps,
  {
    deleteCRKArchive,
    getAllCRKArchives,
    getFile
  })(TableCRKArchive);