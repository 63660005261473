import api from "../api/myApi";
import { 
    CREATE_PAYMENTWAY,
    GET_ALL_PAYMENTWAY,
    GET_PAYMENTWAY,
    GET_ALL_SELECTBOX_PAYMENTWAY,
    DELETE_PAYMENTWAY,
    UPDATE_PAYMENTWAY,
    ADD_DIALOG_PAYMENTWAY,
    EDIT_DIALOG_PAYMENTWAY,
    CLOSE_DIALOG_PAYMENTWAY,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler, responseHandlerWithTranslate } from "../api/responseHandler";

export const createPaymentWay = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PaymentWay/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PAYMENTWAY));
    if(response.data?.succeeded){
        dispatch(reset('formPaymentWay'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPaymentWays = () => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentWay/GetAll');
    dispatch(responseHandler(response, GET_ALL_PAYMENTWAY));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPaymentWay = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentWay/Get/' + id);
    dispatch(responseHandler(response, GET_PAYMENTWAY));
}

export const getSelectBoxPaymentWays = () => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentWay/GetSelectBox');
    dispatch(responseHandlerWithTranslate(response, GET_ALL_SELECTBOX_PAYMENTWAY));
}

export const updatePaymentWay = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PaymentWay/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PAYMENTWAY));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePaymentWay = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/PaymentWay/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PAYMENTWAY));
}

export const addDialogPaymentWay = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PAYMENTWAY,
        payload: null
    });
}

export const editDialogPaymentWay = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PAYMENTWAY,
        payload: item
    });
}

export const closeDialogPaymentWay = () => async (dispatch) => {
    dispatch(reset('formPaymentWay'));
    dispatch({
        type: CLOSE_DIALOG_PAYMENTWAY,
        payload: undefined
    });
}