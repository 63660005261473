//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogCRKArchive, updateCRKArchive } from '../../actions/cRKArchiveAction';
import FormCRKArchive from './FormCRKArchive';

class EditCRKArchive extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCRKArchive(formValues);
    }
    
    render(){
        return (
            <FormCRKArchive 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormCRKArchive>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKArchiveDialog === undefined || state.dialog.CRKArchiveDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CRKArchiveDialog};
}


export default connect(mapStateToProps, { closeDialogCRKArchive, updateCRKArchive })(EditCRKArchive);
