import { 
    CREATE_LEGALSERVICE_ISKUP,
    GET_ALL_ISKUP_LEGALSERVICE,
    UPDATE_LEGALSERVICE_ISKUP,
    CREATE_LEGALSERVICE_EXECUTION
} from '../../actions/types';

const legalService_IskupReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LEGALSERVICE_ISKUP:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_ISKUP_LEGALSERVICE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case UPDATE_LEGALSERVICE_ISKUP:
            return { ...state, [action.payload.id]: action.payload };
        case CREATE_LEGALSERVICE_EXECUTION: {
            let result = {...state};
            delete result[action.payload.id];
            return result;
        }
        default:
            return state;
    }
}

export default legalService_IskupReducer;