import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { addDialogLoan, editDialogLoan, deleteLoan, getPageLoans } from "../../actions/loanAction";
import DateProvider from '../../share/dateProvider';
import history from "../../share/history";
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxLoanTypes } from '../../actions/loanTypeAction';
import { getSelectBoxLoanStatus } from '../../actions/loanStatusAction';


class TableLoanByPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      IsOpenConfirmDialog: false, 
      ID: null,
      rowCountState: 1000,
      isLoading: false,
      tablePageData:{        
        pageSize: 20,
        pageNumber: 0,
        filter:[],
        sortData: null
      }
    }
    if(props.columns !== undefined){
      for(let c in props.columns){
        this.columns.push(props.columns[c]);
      }
    }
  }
  componentDidMount = async () => {
    await this.props.getSelectBoxBranches();
    await this.props.getSelectBoxLoanTypes();
    await this.props.getSelectBoxLoanStatus();
  }

  branchName = (props) => {
    try
    {
      return this.props.branches.find(el => el.value === props.row.branchID)?.text;
    }catch(err){
      if(props.value != undefined && props.value !== null){
        return props.value;
      };
    }    
  }
  loanTypeName = (props) => {
    try
    {
      return this.props.loanTypes.find(el => el.value === props.row.loanTypeID)?.text;
    }catch(err){
      if(props.value != undefined && props.value !== null){
        return props.value;
      };
    }    
  }
 
  loanStatusName = (props) => {
    try
    {
      return this.props.loanStatus.find(el => el.value === props.row.loanStatusID)?.text;
    }catch(err){
      if(props.value != undefined && props.value !== null){
        return props.value;
      };
    }
  }

  openLoan = (params) => {
    history.push("/Loan/" + params.row.id);
  }
  columns = [
    {
      field: 'BranchID',
      headerName: i18next.t('Branch'),
      minWidth: 150, flex: 1,
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },
    { field: 'loanAppID', headerName: i18next.t('LoanApp'), minWidth: 150, flex: 1 },
    
    { field: 'appDate', headerName: i18next.t('AppDate'), minWidth: 150, flex: 1, type:'date', valueGetter: DateProvider.GetDate},

    {
      field: 'LoanTypeID',
      headerName: i18next.t('LoanType'),
      width: 160,
      valueGetter: this.loanTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanTypeName(cellParams1).localeCompare(this.loanTypeName(cellParams2)),
      type:'singleSelect',
      valueOptions: this.props.loanTypes.map(el => ({value: el.value, label: el.text}))
    },
    { field: 'requestedAmount', headerName: i18next.t('RequestedAmount'), minWidth: 150, flex: 1, type:'number' },
    { 
      field: 'loanStatusID', 
      headerName: i18next.t('LoanStatus'), 
      minWidth: 150, 
      flex: 1, 
      hide: this.props.Status !== null,
      valueGetter: this.loanStatusName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanStatusName(cellParams1).localeCompare(this.loanStatusName(cellParams2)),
      type:'singleSelect',
      valueOptions: this.props.loanStatus.map(el => ({value: el.value, label: el.text}))
    },
    {
      field: 'request',
      headerName: ' ',
      flex: 1,
      renderCell: (params) => (
        <strong>                
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.openLoan(params)}
          >
            {i18next.t('Request')}
          </Button>
        </strong>
      ),
    },
  ];
  
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.partnerSelected.loans}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
    branches: Object.values(state.branchesSelectBox), 
    partners: Object.values(state.partnersSelectBox), 
    loanTypes: Object.values(state.loanTypesSelectBox), 
    loanStatus: Object.values(state.loanStatusSelectBox),
    partnerSelected: state.partnerSelected
   };
}

export default connect(mapStateToProps, { 
  addDialogLoan, 
  editDialogLoan, 
  deleteLoan, 
  getPageLoans, 
  getSelectBoxBranches,
  getSelectBoxLoanTypes, 
  getSelectBoxLoanStatus, 
})(TableLoanByPartner);