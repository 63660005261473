//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_FILEUPLOADTYPE,
    GET_ALL_FILEUPLOADTYPE,
    GET_FILEUPLOADTYPE,
    GET_ALL_SELECTBOX_FILEUPLOADTYPE,
    DELETE_FILEUPLOADTYPE,
    UPDATE_FILEUPLOADTYPE,
    ADD_DIALOG_FILEUPLOADTYPE,
    EDIT_DIALOG_FILEUPLOADTYPE,
    CLOSE_DIALOG_FILEUPLOADTYPE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createFileUploadType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/FileUploadType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_FILEUPLOADTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formFileUploadType'));
    }
}

export const getAllFileUploadTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/FileUploadType/GetAll');
    dispatch(responseHandler(response, GET_ALL_FILEUPLOADTYPE));
}

export const getFileUploadType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/FileUploadType/Get/' + id);
    dispatch(responseHandler(response, GET_FILEUPLOADTYPE));
}

export const getSelectBoxFileUploadTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/FileUploadType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_FILEUPLOADTYPE));
}

export const updateFileUploadType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/FileUploadType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_FILEUPLOADTYPE));
}

export const deleteFileUploadType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/FileUploadType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_FILEUPLOADTYPE));
}

export const addDialogFileUploadType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_FILEUPLOADTYPE,
        payload: null
    });
}

export const editDialogFileUploadType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_FILEUPLOADTYPE,
        payload: item
    });
}

export const closeDialogFileUploadType = () => async (dispatch) => {
    dispatch(reset('formFileUploadType'));
    dispatch({
        type: CLOSE_DIALOG_FILEUPLOADTYPE,
        payload: undefined
    });
}