//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, change } from "redux-form";
import { closeDialogRefinancing } from '../../actions/refinancingAction';
import { EditSelectField, EditDecimalNumberField, EditCheckbox2, EditTextField } from '../Share/EditComponents';

class FormRefinancing extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isInternal: true }
    }
    handleClose = () => {
        this.props.closeDialogRefinancing();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.initialValues?.id !== this.props.initialValues?.id) {
            this.setInteral(this.props.initialValues?.partnerID === null || this.props.initialValues?.partnerID === undefined);
        }
    }
    setInteral = (value) => {
        this.setState({ isInternal: value }, () => {
            if(this.state.isInternal){
                this.props.change('partnerID', null);
                this.props.change('amount', null);
            }
            else{
                this.props.change('oldLoanID', null);
            }
        })
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <EditCheckbox2
                                name='isInternal'
                                value={this.state.isInternal}
                                label='IsInternal'
                                onChange={(e) => { this.setInteral(!this.state.isInternal); }}>
                            </EditCheckbox2>
                            {/* <div>
                                <Field
                                    name='loanID'
                                    component ={this.props.EditSelectField}
                                    label = 'Loan'
                                    dataSource={this.props.loansSelectBox}
                                    >
                                </Field>
                            </div> */}
                            {this.state.isInternal ? <div>
                                <Field
                                    name='oldLoanID'
                                    component={this.props.EditSelectField}
                                    label='Loan'
                                    dataSource={this.props.partnerLoansSelectBox.map(el => ({value: el.id, text: el.loanNumber}))}
                                >
                                </Field>
                            </div> :
                                (<>
                                    <div>
                                        <Field
                                            name='partnerID'
                                            component={this.props.EditSelectField}
                                            label='Partner'
                                            dataSource={this.props.partnersSelectBox}
                                        >
                                        </Field>
                                    </div>

                                    <div>
                                        <Field
                                            name='amount'
                                            component={this.props.EditDecimalNumberField}
                                            label='Amount'
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name='loanNumber'
                                            component={this.props.EditTextField}
                                            label='LoanNumber'
                                        />
                                    </div>
                                </>)}



                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.loanID) {
        errors.loanID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        loansSelectBox: Object.values(state.loansSelectBox),
        partnerLoansSelectBox: Object.values(state.partnerLoansSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox)
    }
}
const formWrapper = reduxForm({
    form: 'formRefinancing',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormRefinancing);

export default connect(mapStateToProps, { closeDialogRefinancing, EditSelectField, EditDecimalNumberField, EditTextField, change })(formWrapper);