import api from "../api/myApi";
import { 
    CREATE_LOANTAKENOUT,
    GET_ALL_LOANTAKENOUT,
    GET_IMPORT_LOANTAKENOUT,
    GET_LOANTAKENOUT,
    GET_ALL_SELECTBOX_LOANTAKENOUT,
    DELETE_LOANTAKENOUT,
    UPDATE_LOANTAKENOUT,
    ADD_DIALOG_LOANTAKENOUT,
    EDIT_DIALOG_LOANTAKENOUT,
    CLOSE_DIALOG_LOANTAKENOUT,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanTakenOut = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanTakenOut/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANTAKENOUT));
    if(response.data?.succeeded){
        dispatch(reset('formLoanTakenOut'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const importFromCRK = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanTakenOut/importFromCRK', formValues);
    dispatch(responseHandler(response, GET_IMPORT_LOANTAKENOUT));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLoanTakenOuts = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanTakenOut/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANTAKENOUT));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLoanTakenOut = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanTakenOut/Get/' + id);
    dispatch(responseHandler(response, GET_LOANTAKENOUT));
}

export const getSelectBoxLoanTakenOuts = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanTakenOut/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANTAKENOUT));
}

export const updateLoanTakenOut = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanTakenOut/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANTAKENOUT));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoanTakenOut = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanTakenOut/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANTAKENOUT));
}

export const addDialogLoanTakenOut = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANTAKENOUT,
        payload: null
    });
}

export const editDialogLoanTakenOut = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANTAKENOUT,
        payload: item
    });
}

export const closeDialogLoanTakenOut = () => async (dispatch) => {
    dispatch(reset('formLoanTakenOut'));
    dispatch({
        type: CLOSE_DIALOG_LOANTAKENOUT,
        payload: undefined
    });
}