import React from "react";
import { connect } from 'react-redux';
import EditLoan from '../../components/Loan/EditLoan';
import TableLoanRepaymentScheduleRead from '../../components/LoanRepaymentSchedule/TableLoanRepaymentScheduleRead';
import LoanDetails from '../../components/Loan/LoanDetails';
import TableIncomeSource from '../../components/IncomeSource/TableIncomeSource';
import TableRealEstates from '../../components/RealEstates/TableRealEstates';
import TableVehicle from '../../components/Vehicle/TableVehicle';
import TableLoanTakenOut from '../../components/LoanTakenOut/TableLoanTakenOut';
import TableRefinancing from '../../components/Refinancing/TableRefinancing';
import TablePartnerNote from '../../components/PartnerNote/TablePartnerNote';
import TableLoanQualityStatus from '../../components/LoanQualityStatus/TableLoanQualityStatus';
import TableBLTurnover from '../../components/BLTurnover/TableBLTurnover';
import TableBankStatementImportByLoan from '../../components/BankStatementImport/TableBankStatementImportByLoan';
import TableFileUpload from '../../components/FileUpload/TableFileUpload';
import TableCRKRequest from '../../components/CRKRequest/TableCRKRequest';
import TableLoanWriteOff from '../../components/LoanWriteOff/TableLoanWriteOff';
import TableRevenueRecognition from '../../components/RevenueRecognition/TableRevenueRecognition';

import LoanReports from './LoanReports';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { getLoan } from '../../actions/loanAction';
import { getSelectBoxLoanRepaymentScheduleTypes } from '../../actions/loanRepaymentScheduleTypeAction';
import { getSelectBoxPlans } from '../../actions/planAction';

class LoanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: "1", expanded01: true, expanded02: true, expanded03: true, expanded04: true, currentID: 0 }
  }
  componentDidMount = () => {
    this.props.getSelectBoxLoanRepaymentScheduleTypes();
    this.props.getSelectBoxPlans();
    this.props.getLoan(this.props.match.params.id);

  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if(this.props.match.params.id != prevProps.match.params.id){
      this.props.getLoan(this.props.match.params.id);
    }
  }
  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  isDisabled = () => {
    return this.props.loan?.loan?.loanStatusID !== 0;
  }
  render() {
    return (<Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={this.state.value}>
        <TabList onChange={this.handleChange}
          variant="fullWidth">
          <Tab label="Detalji" value="1" />
          <Tab label="Plan" value="2" />
          <Tab label="Primanje" value="3" />
          <Tab label="Kolaterali" value="5" />
          <Tab label="Zaduženost" value="6" />
          <Tab label="Refinansiranje" value="7" />
          <Tab label="Napomene" value="8" />
          <Tab label="Klasifikacije" value="9" />
          <Tab label="Uplate" value="10" />
          <Tab label="Otpis i prihodovanje" value="11" />
          <Tab label="Izvještaji" value="12" />
          <Tab label="CRK" value="13" />
        </TabList>
        <TabPanel value="1"><EditLoan /></TabPanel>
        <TabPanel value="2"><LoanDetails /><TableLoanRepaymentScheduleRead /></TabPanel>
        <TabPanel value="3"><TableIncomeSource disabled={this.isDisabled()} /></TabPanel>

        <TabPanel value="5">
          <Accordion expanded={this.state.expanded01} onChange={() => { this.setState({ expanded01: !this.state.expanded01 }) }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2-header"
            >
              Nekretnine
            </AccordionSummary>
            <AccordionDetails>
              <TableRealEstates disabled={this.isDisabled()} />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={this.state.expanded02} onChange={() => { this.setState({ expanded02: !this.state.expanded02 }) }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2-header"
            >
              Pokretna imovina
            </AccordionSummary>
            <AccordionDetails>
              <TableVehicle disabled={this.isDisabled()} />
            </AccordionDetails>
          </Accordion>
        </TabPanel>

        <TabPanel value="6"><TableLoanTakenOut disabled={this.isDisabled()} /></TabPanel>
        <TabPanel value="7"><TableRefinancing disabled={this.isDisabled()} /></TabPanel>
        <TabPanel value="8"><TablePartnerNote disabled={this.isDisabled()} /></TabPanel>
        <TabPanel value="9"><TableLoanQualityStatus disabled={this.isDisabled()} /></TabPanel>
        <TabPanel value="10"><TableBankStatementImportByLoan disabled={this.isDisabled()} /></TabPanel>
        
        <TabPanel value="11">
          <Accordion expanded={this.state.expanded03} onChange={() => { this.setState({ expanded03: !this.state.expanded03 }) }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2-header"
            >
              Mali otpis
            </AccordionSummary>
            <AccordionDetails>
              <TableLoanWriteOff />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={this.state.expanded04} onChange={() => { this.setState({ expanded04: !this.state.expanded04 }) }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel2-header"
            >
              Odluka o prihodovanju
            </AccordionSummary>
            <AccordionDetails>
              <TableRevenueRecognition />
            </AccordionDetails>
          </Accordion>
        </TabPanel>

        <TabPanel value="12"><LoanReports /><TableFileUpload /></TabPanel>
        <TabPanel value="13"><TableCRKRequest
          disabled={this.isDisabled()}
          partners={this.props.loanPartners?.filter(el => el.id > 0).map(el => el.partnerID)}
        />
        </TabPanel>
      </TabContext>
    </Box>);
  }
}

const mapStateToProps = state => {
  return {
    loanRepaymentSchedules: Object.values(state.loanRepaymentSchedules),
    loan: state.loanSelected,
    loanRepaymentScheduleTypes: Object.values(state.loanRepaymentScheduleTypesSelectBox),
    loanPartners: Object.values(state.loanPartners),
    plans: Object.values(state.plansSelectBox)
  };
}

export default connect(mapStateToProps, {
  getLoan,
  getSelectBoxLoanRepaymentScheduleTypes,
  getSelectBoxPlans
})(LoanPage);