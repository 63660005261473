import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogDebtCollectionCostDetails } from '../../actions/debtCollectionCostDetailsAction';
import { EditDatePickerField, EditSelectField, EditIntegerNumberField, EditDecimalNumberField } from '../Share/EditComponents';

class FormDebtCollectionCostDetails extends React.Component {
    handleClose = () => {
        this.props.closeDialogDebtCollectionCostDetails();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='loanID'
                                    component={this.props.EditSelectField}
                                    label='Loan'
                                    dataSource={this.props.loans}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='debtCollectionCostID'
                                    component={this.props.EditSelectField}
                                    label='DebtCollectionCostType'
                                    dataSource={this.props.debtCollectionCosts}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='costAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='CostAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='date'
                                    component={this.props.EditDatePickerField}
                                    label='Date'
                                />
                            </div>

                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.loanID) {
        errors.loanID = 'Polje je obavezno!';
    }
    if (!formValues.debtCollectionCostID) {
        errors.debtCollectionCostID = 'Polje je obavezno!';
    }
    if (!formValues.costAmount) {
        errors.costAmount = 'Polje je obavezno!';
    }
    if (!formValues.date) {
        errors.date = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        loans: Object.values(state.loansSelectBox),
        debtCollectionCosts: Object.values(state.debtCollectionCostsSelectBox),
    }
}
const formWrapper = reduxForm({
    form: 'formDebtCollectionCostDetails',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormDebtCollectionCostDetails);

export default connect(mapStateToProps, { closeDialogDebtCollectionCostDetails, EditDatePickerField, EditSelectField, EditIntegerNumberField, EditDecimalNumberField })(formWrapper);