import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogLoan, updateLoan, updateLoanStartDate } from '../../actions/loanAction';
import FormLoan from './FormLoan';

class EditLoan extends React.Component{
    onSubmit = (formValues) => {
        if(formValues.loanStatusID > 1){
            this.props.updateLoanStartDate({
                id: formValues.id,
                firstRepaymentDate: formValues.firstRepaymentDate,
                interestBearingFrom: formValues.interestBearingFrom,
            });
        }
        else{
            formValues.parnters = this.props.loanPartners;
            this.props.updateLoan(formValues);
        }
    }
    initialValues={

    }
    render(){
        return (
            <FormLoan 
                onSubmit={this.onSubmit}
                initialValues={this.props.loan.loan}
                title={i18next.t('Edit')}>
            </FormLoan>
        );
    }
}

const mapStateToProps = state => {
  return {
      loan: state.loanSelected,     
      loanPartners: Object.values(state.loanPartners)
  };
}


export default connect(mapStateToProps, { closeDialogLoan, updateLoan, updateLoanStartDate })(EditLoan);
