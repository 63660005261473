import React from "react";
import TableOrdersInImbalance from '../../components/Order/TableOrdersInImbalance';

class OrderInImbalancePage extends React.Component{
    render(){
        return(<TableOrdersInImbalance/>)
    }
}

export default OrderInImbalancePage;
