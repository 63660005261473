import { 
    CREATE_LOANTAKENOUT,
    GET_ALL_LOANTAKENOUT,
    GET_IMPORT_LOANTAKENOUT,
    GET_LOANTAKENOUT,
    DELETE_LOANTAKENOUT,
    UPDATE_LOANTAKENOUT,
    GET_LOAN,
    UPDATE_LOAN
} from '../../actions/types';

const loanTakenOutReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANTAKENOUT:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANTAKENOUT:
            return { ...action.payload };
        case GET_IMPORT_LOANTAKENOUT:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_LOANTAKENOUT:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANTAKENOUT: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_LOANTAKENOUT:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
        case UPDATE_LOAN:
            return { ...action.payload.loanTakenOut };
        default:
            return state;
    }
}

export default loanTakenOutReducer;