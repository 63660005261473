import { 
    CREATE_ASSIGNEE,
    GET_ALL_ASSIGNEE,
    GET_ASSIGNEE,
    DELETE_ASSIGNEE,
    UPDATE_ASSIGNEE
} from '../../actions/types';

const assigneeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_ASSIGNEE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_ASSIGNEE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_ASSIGNEE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_ASSIGNEE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_ASSIGNEE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default assigneeReducer;