//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateOrderType from './CreateOrderType';
import EditOrderType from './EditOrderType';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogOrderType, editDialogOrderType, deleteOrderType, getAllOrderTypes } from "../../actions/orderTypeAction";

class TableOrderType extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getAllOrderTypes();
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value == props.row.companyID)?.text;
  }
  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },


    { field: 'isSystemStatus', headerName: i18next.t('IsSystemStatus'), width: 150, type: 'boolean' },
    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        params.row.isSystemStatus ? null : (<strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>)
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        params.row.isSystemStatus ? null : (<strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>)
      )
    }
  ];
  create = () => {
    this.props.addDialogOrderType();
  }
  edit = (params) => {
    this.props.editDialogOrderType(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.orderTypes}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateOrderType></CreateOrderType>
        <EditOrderType></EditOrderType>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteOrderType(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { orderTypes: Object.values(state.orderTypes), companies: Object.values(state.companiesSelectBox) };
}

export default connect(mapStateToProps, { addDialogOrderType, editDialogOrderType, deleteOrderType, getAllOrderTypes })(TableOrderType);