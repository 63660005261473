import { 
    CREATE_REALESTATES,
    GET_ALL_REALESTATES,
    GET_REALESTATES,
    DELETE_REALESTATES,
    UPDATE_REALESTATES,
    GET_LOAN
} from '../../actions/types';

const realEstatesReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_REALESTATES:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_REALESTATES:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_REALESTATES:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_REALESTATES: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_REALESTATES:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = {  };
                for(let i=0; i<action.payload.realEstates.length;i++) {result3[action.payload.realEstates[i].id]=action.payload.realEstates[i];}
                return result3;
        default:
            return state;
    }
}

export default realEstatesReducer;