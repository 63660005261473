import api from "../api/myApi";
import { 
    CREATE_PARTNERNOTE,
    GET_ALL_PARTNERNOTE,
    GET_PARTNERNOTE,
    GET_ALL_SELECTBOX_PARTNERNOTE,
    DELETE_PARTNERNOTE,
    UPDATE_PARTNERNOTE,
    ADD_DIALOG_PARTNERNOTE,
    EDIT_DIALOG_PARTNERNOTE,
    CLOSE_DIALOG_PARTNERNOTE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createPartnerNote = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PartnerNote/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PARTNERNOTE));
    if(response.data?.succeeded){
        dispatch(reset('formPartnerNote'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPartnerNotes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PartnerNote/GetAll');
    dispatch(responseHandler(response, GET_ALL_PARTNERNOTE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPartnerNote = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/PartnerNote/Get/' + id);
    dispatch(responseHandler(response, GET_PARTNERNOTE));
}

export const getSelectBoxPartnerNotes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PartnerNote/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PARTNERNOTE));
}

export const updatePartnerNote = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PartnerNote/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PARTNERNOTE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePartnerNote = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/PartnerNote/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PARTNERNOTE));
}

export const addDialogPartnerNote = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PARTNERNOTE,
        payload: null
    });
}

export const editDialogPartnerNote = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PARTNERNOTE,
        payload: item
    });
}

export const closeDialogPartnerNote = () => async (dispatch) => {
    dispatch(reset('formPartnerNote'));
    dispatch({
        type: CLOSE_DIALOG_PARTNERNOTE,
        payload: undefined
    });
}