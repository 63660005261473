import api from "../api/myApi";
import { 
    GET_ALL_SELECTBOX_RELATEDPERSONTYPE
} from './types';
import { responseHandler } from "../api/responseHandler";


export const getSelectBoxRelatedPersonTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/RelatedPersonType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_RELATEDPERSONTYPE));
}