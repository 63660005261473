import { combineReducers } from "redux";
import { reducer as formReducer } from 'redux-form';
import dialogReducer from "./dialogReducer";
//import actionLogReducer from './model/Z-actionLogReducer';
//import actionLogSelectBoxReducer from './selectBox/Z-actionLogSelectBoxReducer';
//import actionLogTypeReducer from './model/Z-actionLogTypeReducer';
//import actionLogTypeSelectBoxReducer from './selectBox/Z-actionLogTypeSelectBoxReducer';
import assigneeReducer from './model/assigneeReducer';
import assigneeSelectBoxReducer from './selectBox/assigneeSelectBoxReducer';
import bankReducer from './model/bankReducer';
import bankSelectBoxReducer from './selectBox/bankSelectBoxReducer';
import bankStatementImportReducer from './model/bankStatementImportReducer';
import bankStatementImportByLoanReducer from './model/bankStatementImportByLoanReducer'
import bankStatementImportSelectBoxReducer from './selectBox/bankStatementImportSelectBoxReducer';
import bankStatementImportSplitReducer from './model/bankStatementImportSplitReducer';
import bLTurnoverReducer from './model/bLTurnoverReducer';
import bLTurnoverSelectBoxReducer from './selectBox/bLTurnoverSelectBoxReducer';
import boardMemberReducer from './model/boardMemberReducer';
import boardMemberSelectBoxReducer from './selectBox/boardMemberSelectBoxReducer';
import boardMemberTypeReducer from './model/boardMemberTypeReducer';
import boardMemberTypeSelectBoxReducer from './selectBox/boardMemberTypeSelectBoxReducer';
import branchReducer from './model/branchReducer';
import branchSelectBoxReducer from './selectBox/branchSelectBoxReducer';
import brokerReducer from './model/brokerReducer';
import brokerSelectBoxReducer from './selectBox/brokerSelectBoxReducer';
import cashBalanceReducer from './model/cashBalanceReducer';
import cashBalanceSelectBoxReducer from './selectBox/cashBalanceSelectBoxReducer';
import cashierReducer from './model/cashierReducer';
import cashierSelectBoxReducer from './selectBox/cashierSelectBoxReducer';
import cityReducer from './model/cityReducer';
import citySelectBoxReducer from './selectBox/citySelectBoxReducer';
import companyReducer from './model/companyReducer';
import companySelectBoxReducer from './selectBox/companySelectBoxReducer';
import companyDetailsReducer from './model/companyDetailsReducer';
import companyDetailsSelectBoxReducer from './selectBox/companyDetailsSelectBoxReducer';
import currencyExchangeRateReducer from './model/currencyExchangeRateReducer';
import currencyExchangeRateSelectBoxReducer from './selectBox/currencyExchangeRateSelectBoxReducer';
import debtCollectionCostReducer from './model/debtCollectionCostReducer';
import debtCollectionCostSelectBoxReducer from './selectBox/debtCollectionCostSelectBoxReducer';
import debtCollectionCostDetailsReducer from './model/debtCollectionCostDetailsReducer';
import debtCollectionCostDetailsSelectBoxReducer from './selectBox/debtCollectionCostDetailsSelectBoxReducer';
import documentReducer from './model/documentReducer';
import documentSelectBoxReducer from './selectBox/documentSelectBoxReducer';
import documentTurnoverTypeReducer from './model/documentTurnoverTypeReducer';
import documentTurnoverTypeSelectBoxReducer from './selectBox/documentTurnoverTypeSelectBoxReducer';
import documentTypeReducer from './model/documentTypeReducer';
import documentTypeSelectBoxReducer from './selectBox/documentTypeSelectBoxReducer';
import employmentTypeReducer from './model/employmentTypeReducer';
import employmentTypeSelectBoxReducer from './selectBox/employmentTypeSelectBoxReducer';
import entityReducer from './model/entityReducer';
import entitySelectBoxReducer from './selectBox/entitySelectBoxReducer';
import fileUploadReducer from './model/fileUploadReducer';
import fileUploadTypeReducer from './model/fileUploadTypeReducer';
import fileUploadTypeSelectBoxReducer from './selectBox/fileUploadTypeSelectBoxReducer';
import loanPartnerTypeSelectBoxReducer from './selectBox/loanPartnerTypeSelectBoxReducer';
import genderReducer from './model/genderReducer';
import genderSelectBoxReducer from './selectBox/genderSelectBoxReducer';
import groupReducer from './model/groupReducer';
import groupSelectBoxReducer from './selectBox/groupSelectBoxReducer';
import incomeSourceReducer from './model/incomeSourceReducer';
import incomeSourceSelectBoxReducer from './selectBox/incomeSourceSelectBoxReducer';
import incomeSourceTypeReducer from './model/incomeSourceTypeReducer';
import incomeSourceTypeSelectBoxReducer from './selectBox/incomeSourceTypeSelectBoxReducer';
import penaltyInterestPeriodSelectBoxReducer from './selectBox/penaltyInterestPeriodSelectBoxReducer';
import journalReducer from './model/journalReducer';
import journalSelectBoxReducer from './selectBox/journalSelectBoxReducer';
import journalHistoryReducer from './model/journalHistoryReducer';
import journalHistorySelectBoxReducer from './selectBox/journalHistorySelectBoxReducer';
import kUFReducer from './model/kUFReducer';
import kUFSelectBoxReducer from './selectBox/kUFSelectBoxReducer';
import legalService_ActivityReducer from './model/legalService_ActivityReducer';
import legalService_ActivitySelectBoxReducer from './selectBox/legalService_ActivitySelectBoxReducer';
import legalService_CourtReducer from './model/legalService_CourtReducer';
import legalService_CourtSelectBoxReducer from './selectBox/legalService_CourtSelectBoxReducer';
import legalService_DocumentReducer from './model/legalService_DocumentReducer';
import legalService_DocumentSelectBoxReducer from './selectBox/legalService_DocumentSelectBoxReducer';
import legalService_ExecutionTypeReducer from './model/legalService_ExecutionTypeReducer';
import legalService_ExecutionTypeSelectBoxReducer from './selectBox/legalService_ExecutionTypeSelectBoxReducer';
import legalService_DebsReducer from './model/legalService_DebsReducer';
import legalService_WarningReducer from './model/legalService_WarningReducer';
import legalService_IskupReducer from './model/legalService_IskupReducer';
import legalService_ExecutionReducer from './model/legalService_ExecutionReducer';
import legalService_DoneReducer from './model/legalService_DoneReducer';
import legalService_WarningSelectBoxReducer from './selectBox/legalService_WarningSelectBoxReducer';
import loanReducer from './model/loanReducer';
import loanHalcomReducer from './model/loanHalcomReducer';
import loanBaseDataReducer from './model/loanBaseDataReducer';
import loanSelectedReducer from './model/loanSelectedReducer';
import loanSelectBoxReducer from './selectBox/loanSelectBoxReducer';
import loanApprovalReducer from './model/loanApprovalReducer';
import loanApprovalSettingsReducer from './model/loanApprovalSettingsReducer';
import loanHistoryReducer from './model/loanHistoryReducer';
import loanHistorySelectBoxReducer from './selectBox/loanHistorySelectBoxReducer';
import loanMoratoriumReducer from './model/loanMoratoriumReducer';
import loanMoratoriumSelectBoxReducer from './selectBox/loanMoratoriumSelectBoxReducer';
import loanPartnerReducer from './model/loanPartnerReducer';
import loanQualityReducer from './model/loanQualityReducer';
import loanQualitySelectBoxReducer from './selectBox/loanQualitySelectBoxReducer';
import loanQualityStatusReducer from './model/loanQualityStatusReducer';
import loanQualityStatusSelectBoxReducer from './selectBox/loanQualityStatusSelectBoxReducer';
import loanRepaymentScheduleReducer from './model/loanRepaymentScheduleReducer';
import loanRepaymentScheduleSelectBoxReducer from './selectBox/loanRepaymentScheduleSelectBoxReducer';
import loanRepaymentScheduleTypeReducer from './model/loanRepaymentScheduleTypeReducer';
import loanRepaymentScheduleTypeSelectBoxReducer from './selectBox/loanRepaymentScheduleTypeSelectBoxReducer';
import loanRequestHistoryReducer from './model/loanRequestHistoryReducer';
import loanStatusReducer from './model/loanStatusReducer';
import loanStatusSelectBoxReducer from './selectBox/loanStatusSelectBoxReducer';
import loanPurposeSelectBoxReducer from './selectBox/loanPurposeSelectBoxReducer';
import loanTakenOutReducer from './model/loanTakenOutReducer';
import loanTakenOutSelectBoxReducer from './selectBox/loanTakenOutSelectBoxReducer';
import loanTypeReducer from './model/loanTypeReducer';
import loanTypeSelectBoxReducer from './selectBox/loanTypeSelectBoxReducer';
import loanTypeSelectBoxExtendedReducer from './selectBox/loanTypeSelectBoxExtendedReducer'; //
import loanMaintenanceReducer from './model/loanMaintenanceReducer';
import maritalStatusReducer from './model/maritalStatusReducer';
import maritalStatusSelectBoxReducer from './selectBox/maritalStatusSelectBoxReducer';
import opinionOfTheReferentReducer from './model/opinionOfTheReferentReducer';
import opinionOfTheReferentSelectBoxReducer from './selectBox/opinionOfTheReferentSelectBoxReducer';
import orderReducer from './model/orderReducer';
import orderInImbalanceReducer from './model/orderInImbalanceReducer';
import orderSelectBoxReducer from './selectBox/orderSelectBoxReducer';
import partnerReducer from './model/partnerReducer';
import partnerSelectBoxReducer from './selectBox/partnerSelectBoxReducer';
import partnerNoteReducer from './model/partnerNoteReducer';
import partnerNoteSelectBoxReducer from './selectBox/partnerNoteSelectBoxReducer';
import partnerTypeReducer from './model/partnerTypeReducer';
import partnerTypeSelectBoxReducer from './selectBox/partnerTypeSelectBoxReducer';
import partnerSelectedReducer from './model/partnerSelectedReducer';
import paymentNoteTypeReducer from './model/paymentNoteTypeReducer';
import paymentNoteTypeSelectBoxReducer from './selectBox/paymentNoteTypeSelectBoxReducer';
import paymentWayReducer from './model/paymentWayReducer';
import paymentWaySelectBoxReducer from './selectBox/paymentWaySelectBoxReducer';
import payWarningReducer from './model/payWarningReducer';
import payWarningSelectBoxReducer from './selectBox/payWarningSelectBoxReducer';
import payWarningTypeReducer from './model/payWarningTypeReducer';
import payWarningTypeSelectBoxReducer from './selectBox/payWarningTypeSelectBoxReducer';
import penaltyInterestReducer from './model/penaltyInterestReducer';
import penaltyInterestSelectBoxReducer from './selectBox/penaltyInterestSelectBoxReducer';
import penaltyInterestDetailsReducer from './model/penaltyInterestDetailsReducer';
import penaltyInterestDetailsSelectBoxReducer from './selectBox/penaltyInterestDetailsSelectBoxReducer';
import planReducer from './model/planReducer';
import planSelectBoxReducer from './selectBox/planSelectBoxReducer';
import qualificationsReducer from './model/qualificationsReducer';
import qualificationsSelectBoxReducer from './selectBox/qualificationsSelectBoxReducer';
import realEstatesReducer from './model/realEstatesReducer';
import realEstatesSelectBoxReducer from './selectBox/realEstatesSelectBoxReducer';
import refinancingReducer from './model/refinancingReducer';
import regionReducer from './model/regionReducer';
import regionSelectBoxReducer from './selectBox/regionSelectBoxReducer';
import riskLevelSelectBoxReducer from './selectBox/riskLevelSelectBoxReducer';
import settingsReducer from './model/settingsReducer';
import settingsSelectBoxReducer from './selectBox/settingsSelectBoxReducer';
import sMSReducer from './model/sMSReducer';
import sMSSelectBoxReducer from './selectBox/sMSSelectBoxReducer';
import taxGroupsReducer from './model/taxGroupsReducer';
import taxGroupsSelectBoxReducer from './selectBox/taxGroupsSelectBoxReducer';
import turnoverReducer from './model/turnoverReducer';
import turnoverSelectBoxReducer from './selectBox/turnoverSelectBoxReducer';
import typeOfLoanChangeReducer from './model/typeOfLoanChangeReducer';
import typeOfLoanChangeSelectBoxReducer from './selectBox/typeOfLoanChangeSelectBoxReducer';
import userReducer from './model/userReducer';
import userSelectBoxReducer from './selectBox/userSelectBoxReducer';
import roleSelectBoxReducer from './selectBox/roleSelectBoxReducer';
import userActionReducer from './model/userActionReducer';
import userActionSelectBoxReducer from './selectBox/userActionSelectBoxReducer';
import userBranchReducer from './model/userBranchReducer';
import userBranchSelectBoxReducer from './selectBox/userBranchSelectBoxReducer';
import userCompanyDetailsReducer from './model/userCompanyDetailsReducer';
import vehicleReducer from './model/vehicleReducer';
import vehicleSelectBoxReducer from './selectBox/vehicleSelectBoxReducer';
import wayOfKnowingReducer from './model/wayOfKnowingReducer';
import wayOfKnowingSelectBoxReducer from './selectBox/wayOfKnowingSelectBoxReducer';
import workerReducer from './model/workerReducer';
import workerSelectBoxReducer from './selectBox/workerSelectBoxReducer';
import workspaceReducer from './model/workspaceReducer';
import workspaceSelectBoxReducer from './selectBox/workspaceSelectBoxReducer';
import snackbarMessageReducer from './snackbarMessageReducer';
import authUserReducer from './authUserReducer';
import titleReducer from './titleReducer';
import lastPartnerReducer from './lastPartnerReducer';
import progressBarReducer from './progressBarReducer';
import loansByPartnerReducer from './selectBox/loansByPartnerReducer'
import loansBySearchReducer from './selectBox/loansBySearchReducer';
import postingAccountConnectionsReducer from './selectBox/postingAccountConnectionsReducer';
import orderSelectedReducer from './model/orderSelectedReducer';
import relatedPersonTypeSelectBoxReducer from './selectBox/relatedPersonTypeSelectBoxReducer';
import relatedPersonReducer from './model/relatedPersonReducer';
import notificationReducer from './model/notificationReducer'
import statisticReducer from './statisticReducer';
import partnerLoansSelectBoxReducer from './selectBox/partnerLoansSelectBoxReducer';
import typeOfContractWithTheEmployerSelectBoxReducer from './selectBox/typeOfContractWithTheEmployerSelectBoxReducer';

import cRKRequestReducer from './model/cRKRequestReducer';
import cRKRequestSelectBoxReducer from './selectBox/cRKRequestSelectBoxReducer';
import cRKRequestTypeReducer from './model/cRKRequestTypeReducer';
import cRKRequestTypeSelectBoxReducer from './selectBox/cRKRequestTypeSelectBoxReducer';
import cRKResponseReducer from './model/cRKResponseReducer';
import companyActivityCodeReducer from './model/companyActivityCodeReducer';
import companyActivityCodeSelectBoxReducer from './selectBox/companyActivityCodeSelectBoxReducer';

import earlyRepaymentSettingsReducer from './model/earlyRepaymentSettingsReducer';
import earlyRepaymentSettingsSelectBoxReducer from './selectBox/earlyRepaymentSettingsSelectBoxReducer';
import myCompanyDataReducer from './model/myCompanyDataReducer';
import cRKArchiveReducer from './model/cRKArchiveReducer';
import cRKArchiveSelectBoxReducer from './selectBox/cRKArchiveSelectBoxReducer';
import loanWriteOffReducer from './model/loanWriteOffReducer';
import loanWriteOffSelectBoxReducer from './selectBox/loanWriteOffSelectBoxReducer';
import revenueRecognitionReducer from './model/revenueRecognitionReducer';
import revenueRecognitionSelectBoxReducer from './selectBox/revenueRecognitionSelectBoxReducer';

import uNPartnerDetailsReducer from './model/uNPartnerDetailsReducer';
import uNPartnerDetailsSelectBoxReducer from './selectBox/uNPartnerDetailsSelectBoxReducer';

import orderTypeReducer from './model/orderTypeReducer';
import orderTypeSelectBoxReducer from './selectBox/orderTypeSelectBoxReducer';
import commissionSettingsReducer from './model/commissionSettingsReducer';
import commissionSettingsSelectBoxReducer from './selectBox/commissionSettingsSelectBoxReducer';
import sourceOfFinancingReducer from './model/sourceOfFinancingReducer';
import sourceOfFinancingSelectBoxReducer from './selectBox/sourceOfFinancingSelectBoxReducer';


export default combineReducers({
    form: formReducer,
    dialog: dialogReducer,
    // actionLogs: actionLogReducer,
    // actionLogsSelectBox: actionLogSelectBoxReducer,
    // actionLogTypes: actionLogTypeReducer,
    // actionLogTypesSelectBox: actionLogTypeSelectBoxReducer,
    assignees: assigneeReducer,
    assigneesSelectBox: assigneeSelectBoxReducer,
    banks: bankReducer,
    banksSelectBox: bankSelectBoxReducer,
    bankStatementImports: bankStatementImportReducer,
    bankStatementImportsByLoan: bankStatementImportByLoanReducer,
    bankStatementImportsSelectBox: bankStatementImportSelectBoxReducer,
    bankStatementImportSplits: bankStatementImportSplitReducer,
    bLTurnovers: bLTurnoverReducer,
    bLTurnoversSelectBox: bLTurnoverSelectBoxReducer,
    boardMembers: boardMemberReducer,
    boardMembersSelectBox: boardMemberSelectBoxReducer,
    boardMemberTypes: boardMemberTypeReducer,
    boardMemberTypesSelectBox: boardMemberTypeSelectBoxReducer,
    branches: branchReducer,
    branchesSelectBox: branchSelectBoxReducer,
    brokers: brokerReducer,
    brokersSelectBox: brokerSelectBoxReducer,
    cashBalances: cashBalanceReducer,
    cashBalancesSelectBox: cashBalanceSelectBoxReducer,
    cashiers: cashierReducer,
    cashiersSelectBox: cashierSelectBoxReducer,
    cities: cityReducer,
    citiesSelectBox: citySelectBoxReducer,
    companies: companyReducer,
    companiesSelectBox: companySelectBoxReducer,
    companyDetails: companyDetailsReducer,
    companyDetailsSelectBox: companyDetailsSelectBoxReducer,
    currencyExchangeRates: currencyExchangeRateReducer,
    currencyExchangeRatesSelectBox: currencyExchangeRateSelectBoxReducer,
    debtCollectionCosts: debtCollectionCostReducer,
    debtCollectionCostsSelectBox: debtCollectionCostSelectBoxReducer,
    debtCollectionCostDetails: debtCollectionCostDetailsReducer,
    debtCollectionCostDetailsSelectBox: debtCollectionCostDetailsSelectBoxReducer,
    documents: documentReducer,
    documentsSelectBox: documentSelectBoxReducer,
    documentTurnoverTypes: documentTurnoverTypeReducer,
    documentTurnoverTypesSelectBox: documentTurnoverTypeSelectBoxReducer,
    documentTypes: documentTypeReducer,
    documentTypesSelectBox: documentTypeSelectBoxReducer,
    employmentTypes: employmentTypeReducer,
    employmentTypesSelectBox: employmentTypeSelectBoxReducer,
    entities: entityReducer,
    entitiesSelectBox: entitySelectBoxReducer,
    fileUploads: fileUploadReducer,
    fileUploadTypes: fileUploadTypeReducer,
    fileUploadTypesSelectBox: fileUploadTypeSelectBoxReducer,
    loanPartnerTypesSelectBox: loanPartnerTypeSelectBoxReducer,
    genders: genderReducer,
    gendersSelectBox: genderSelectBoxReducer,
    groups: groupReducer,
    groupsSelectBox: groupSelectBoxReducer,
    incomeSources: incomeSourceReducer,
    incomeSourcesSelectBox: incomeSourceSelectBoxReducer,
    incomeSourceTypes: incomeSourceTypeReducer,
    incomeSourceTypesSelectBox: incomeSourceTypeSelectBoxReducer,
    penaltyInterestPeriodSelectBox: penaltyInterestPeriodSelectBoxReducer,
    journals: journalReducer,
    journalsSelectBox: journalSelectBoxReducer,
    journalHistories: journalHistoryReducer,
    journalHistoriesSelectBox: journalHistorySelectBoxReducer,
    kUFs: kUFReducer,
    kUFsSelectBox: kUFSelectBoxReducer,
    legalService_Activities: legalService_ActivityReducer,
    legalService_ActivitiesSelectBox: legalService_ActivitySelectBoxReducer,
    legalService_Courts: legalService_CourtReducer,
    legalService_CourtsSelectBox: legalService_CourtSelectBoxReducer,
    legalService_Documents: legalService_DocumentReducer,
    legalService_DocumentsSelectBox: legalService_DocumentSelectBoxReducer,
    legalService_ExecutionTypes: legalService_ExecutionTypeReducer,
    legalService_ExecutionTypesSelectBox: legalService_ExecutionTypeSelectBoxReducer,
    legalService_Debs: legalService_DebsReducer,
    legalService_Warnings: legalService_WarningReducer,
    legalService_Iskups: legalService_IskupReducer,
    legalService_Executions: legalService_ExecutionReducer,
    legalService_Dones: legalService_DoneReducer,
    legalService_WarningsSelectBox: legalService_WarningSelectBoxReducer,
    loans: loanReducer,
    loansHalcom: loanHalcomReducer,
    loansBaseData: loanBaseDataReducer,
    loanSelected: loanSelectedReducer,
    loansSelectBox: loanSelectBoxReducer,
    loanApprovals: loanApprovalReducer,
    loanApprovalSettings: loanApprovalSettingsReducer,
    loanHistories: loanHistoryReducer,
    loanHistoriesSelectBox: loanHistorySelectBoxReducer,
    loanMoratoriums: loanMoratoriumReducer,
    loanMoratoriumsSelectBox: loanMoratoriumSelectBoxReducer,
    loanPartners: loanPartnerReducer,
    loanQualities: loanQualityReducer,
    loanQualitiesSelectBox: loanQualitySelectBoxReducer,
    loanQualityStatus: loanQualityStatusReducer,
    loanQualityStatusSelectBox: loanQualityStatusSelectBoxReducer,
    loanRepaymentSchedules: loanRepaymentScheduleReducer,
    loanRepaymentSchedulesSelectBox: loanRepaymentScheduleSelectBoxReducer,
    loanRepaymentScheduleTypes: loanRepaymentScheduleTypeReducer,
    loanRepaymentScheduleTypesSelectBox: loanRepaymentScheduleTypeSelectBoxReducer,
	loanRequestHistories: loanRequestHistoryReducer,
    loanStatus: loanStatusReducer,
    loanStatusSelectBox: loanStatusSelectBoxReducer,
    loanPurposeSelectBox: loanPurposeSelectBoxReducer,
    loanTakenOuts: loanTakenOutReducer,
    loanTakenOutsSelectBox: loanTakenOutSelectBoxReducer,
    loanTypes: loanTypeReducer,
    loanTypesSelectBox: loanTypeSelectBoxReducer,
    loanTypesSelectBoxExtended: loanTypeSelectBoxExtendedReducer,
    loanMaintenance: loanMaintenanceReducer,
    maritalStatus: maritalStatusReducer,
    maritalStatusSelectBox: maritalStatusSelectBoxReducer,
    opinionOfTheReferents: opinionOfTheReferentReducer,
    opinionOfTheReferentsSelectBox: opinionOfTheReferentSelectBoxReducer,
    orders: orderReducer,
    ordersInImbalance: orderInImbalanceReducer,
    ordersSelectBox: orderSelectBoxReducer,
    partners: partnerReducer,
    partnersSelectBox: partnerSelectBoxReducer,
    partnerNotes: partnerNoteReducer,
    partnerNotesSelectBox: partnerNoteSelectBoxReducer,
    partnerTypes: partnerTypeReducer,
    partnerTypesSelectBox: partnerTypeSelectBoxReducer,
    partnerSelected: partnerSelectedReducer,
    paymentNoteTypes: paymentNoteTypeReducer,
    paymentNoteTypesSelectBox: paymentNoteTypeSelectBoxReducer,
    paymentWays: paymentWayReducer,
    paymentWaysSelectBox: paymentWaySelectBoxReducer,
    payWarnings: payWarningReducer,
    payWarningsSelectBox: payWarningSelectBoxReducer,
    payWarningTypes: payWarningTypeReducer,
    payWarningTypesSelectBox: payWarningTypeSelectBoxReducer,
    penaltyInterests: penaltyInterestReducer,
    penaltyInterestsSelectBox: penaltyInterestSelectBoxReducer,
    penaltyInterestDetails: penaltyInterestDetailsReducer,
    penaltyInterestDetailsSelectBox: penaltyInterestDetailsSelectBoxReducer,
    plans: planReducer,
    plansSelectBox: planSelectBoxReducer,
    qualifications: qualificationsReducer,
    qualificationsSelectBox: qualificationsSelectBoxReducer,
    realEstates: realEstatesReducer,
    realEstatesSelectBox: realEstatesSelectBoxReducer,
    refinancings: refinancingReducer,
    regions: regionReducer,
    regionsSelectBox: regionSelectBoxReducer,
    riskLevelSelectBox: riskLevelSelectBoxReducer,
    settings: settingsReducer,
    settingsSelectBox: settingsSelectBoxReducer,
    sMS: sMSReducer,
    sMSSelectBox: sMSSelectBoxReducer,
    taxGroups: taxGroupsReducer,
    taxGroupsSelectBox: taxGroupsSelectBoxReducer,
    turnovers: turnoverReducer,
    turnoversSelectBox: turnoverSelectBoxReducer,
    typeOfLoanChanges: typeOfLoanChangeReducer,
    typeOfLoanChangesSelectBox: typeOfLoanChangeSelectBoxReducer,
    users: userReducer,
    usersSelectBox: userSelectBoxReducer,
    rolesSelectBox: roleSelectBoxReducer,
    userActions: userActionReducer,
    userActionsSelectBox: userActionSelectBoxReducer,
    userBranches: userBranchReducer,
    userBranchesSelectBox: userBranchSelectBoxReducer,
    userCompanyDetails: userCompanyDetailsReducer,
    vehicles: vehicleReducer,
    vehiclesSelectBox: vehicleSelectBoxReducer,
    wayOfKnowings: wayOfKnowingReducer,
    wayOfKnowingsSelectBox: wayOfKnowingSelectBoxReducer,
    workers: workerReducer,
    workersSelectBox: workerSelectBoxReducer,
    workspaces: workspaceReducer,
    workspacesSelectBox: workspaceSelectBoxReducer,
    snackbarMessage: snackbarMessageReducer,
    authUser: authUserReducer,
    title: titleReducer,
    lastPartnerID: lastPartnerReducer,
    progressBar: progressBarReducer,
    loansByPartner: loansByPartnerReducer,
    loansBySearch: loansBySearchReducer,
    postingAccountConnections: postingAccountConnectionsReducer,
    orderSelected: orderSelectedReducer,
    relatedPersonTypesSelectBox: relatedPersonTypeSelectBoxReducer,
    relatedPersons: relatedPersonReducer,
    notifications: notificationReducer,
    statistics: statisticReducer,
    partnerLoansSelectBox: partnerLoansSelectBoxReducer,
    typeOfContractWithTheEmployerSelectBox: typeOfContractWithTheEmployerSelectBoxReducer,
    cRKRequests: cRKRequestReducer,
    cRKRequestsSelectBox: cRKRequestSelectBoxReducer,
    cRKRequestTypes: cRKRequestTypeReducer,
    cRKRequestTypesSelectBox: cRKRequestTypeSelectBoxReducer,
    cRKResponses: cRKResponseReducer,
    companyActivityCodes: companyActivityCodeReducer,
    companyActivityCodesSelectBox: companyActivityCodeSelectBoxReducer,    
    earlyRepaymentSettings: earlyRepaymentSettingsReducer,
    earlyRepaymentSettingsSelectBox: earlyRepaymentSettingsSelectBoxReducer,
    myCompanyData: myCompanyDataReducer,
    cRKArchives: cRKArchiveReducer,
    cRKArchivesSelectBox: cRKArchiveSelectBoxReducer,
    loanWriteOffs: loanWriteOffReducer,
    loanWriteOffsSelectBox: loanWriteOffSelectBoxReducer,
    revenueRecognitions: revenueRecognitionReducer,
    revenueRecognitionsSelectBox: revenueRecognitionSelectBoxReducer,
    uNPartnerDetails: uNPartnerDetailsReducer,
    uNPartnerDetailsSelectBox: uNPartnerDetailsSelectBoxReducer,
    orderTypes: orderTypeReducer,
    orderTypesSelectBox: orderTypeSelectBoxReducer,
    commissionSettings: commissionSettingsReducer,
    commissionSettingsSelectBox: commissionSettingsSelectBoxReducer,
    sourceOfFinancings: sourceOfFinancingReducer,
    sourceOfFinancingsSelectBox: sourceOfFinancingSelectBoxReducer,

});