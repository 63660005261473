import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
//import CreateLoanPartner from './CreateLoanPartner';
//import EditLoanPartner from './EditLoanPartner';
import { addDialogLoanPartner, editDialogLoanPartner, deleteLoanPartner } from "../../actions/loanPartnerAction";
import { getSelectBoxLoans } from '../../actions/loanAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxLoanPartnerTypes } from '../../actions/loanPartnerTypeAction';
import { getSelectBoxCompanyActivityCodes } from '../../actions/companyActivityCodeAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableLoanPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxPartners();
    this.props.getSelectBoxCompanyActivityCodes();
    this.props.getSelectBoxLoanPartnerTypes();
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value == props.row.partnerID)?.text;
  }
  loanPartnerTypeName = (props) => {
    return this.props.loanPartnerTypes.find(el => el.value == props.row.loanPartnerTypeID)?.text;
  }
  riskLevelName = (props) => {
    try {
      return this.props.riskLevels.find(el => el.value == props.row.riskLevelID)?.text;
    } catch (err) {
      if (props.value != undefined && props.value !== null) {
        return props.value;
      };
    }
  }
  columns = [
    {
      field: 'partnerID',
      headerName: i18next.t('Partner'),
      width: 160,
      flex: 4,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },

    {
      field: 'loanPartnerTypeID',
      headerName: i18next.t('LoanPartnerType'),
      width: 160,
      flex: 2,
      valueGetter: this.loanPartnerTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanPartnerTypeName(cellParams1).localeCompare(this.loanPartnerTypeName(cellParams2))
    },
    { field: 'billNumber', headerName: i18next.t('BillNumber'), minWidth: 150, flex: 2 },
    {
      field: 'riskLevelID',
      headerName: i18next.t('RiskLevel'),
      width: 160,
      flex: 2,
      valueGetter: this.riskLevelName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.riskLevelName(cellParams1).localeCompare(this.riskLevelName(cellParams2))
    },
    { field: 'isRelatedPerson', headerName: i18next.t('IsRelatedPerson'), minWidth: 150, flex: 1, type: 'boolean' },
    
    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
            disabled={this.props.disabled}
          >
            Edit
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogLoanPartner();
  }
  edit = (params) => {
    this.props.editDialogLoanPartner(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  /*
  bilo je pre ConfirmDialog 
        
        
        */
  render() {
    return (
      <div style={{ height: 350, width: '100%' }}>
        <DataGrid
          rows={this.props.loanPartners}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.props.disabled ? null : this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        {
          /*
          <CreateLoanPartner/>
        <EditLoanPartner/>
        */
        }
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLoanPartner(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    //loanPartners: Object.values(state.loanPartners),
    //loans: state.loansSelectBox,
    partners: Object.values(state.partnersSelectBox),
    loanPartners: Object.values(state.loanPartners),
    loanPartnerTypes: Object.values(state.loanPartnerTypesSelectBox),
    riskLevels: Object.values(state.riskLevelSelectBox)

  };
}

export default connect(mapStateToProps, {
  addDialogLoanPartner,
  editDialogLoanPartner,
  getSelectBoxLoans,
  getSelectBoxPartners,
  getSelectBoxCompanyActivityCodes,
  getSelectBoxLoanPartnerTypes,
  deleteLoanPartner
})(TableLoanPartner);