import {
    GET_PARTNER,
} from '../../actions/types';

const partnerSelectedReducer = (state = {}, action) => {
    switch(action.type){
        case GET_PARTNER:
            return { ...action.payload };
        default:
            return state;
    }
}

export default partnerSelectedReducer;