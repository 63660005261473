import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createPenaltyInterestSettings } from '../../actions/penaltyInterestSettingsAction';
import FormPenaltyInterestSettings from './FormPenaltyInterestSettings';

class CreatePenaltyInterestSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createPenaltyInterestSettings(formValues);
    }
    
    render(){
        return (
            <FormPenaltyInterestSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormPenaltyInterestSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PenaltyInterestSettingsDialog === undefined || state.dialog.PenaltyInterestSettingsDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.PenaltyInterestSettingsDialog};
}


export default connect(mapStateToProps, { createPenaltyInterestSettings })(CreatePenaltyInterestSettings);
