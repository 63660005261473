import { 
    GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULE
} from '../../actions/types';

 const loanRepaymentScheduleSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULE:
            return [...action.payload];
        default:
            return state;
    }
}
export default loanRepaymentScheduleSelectBoxReducer;