import { 
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    SHOW_LOADING_DATA_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from '../actions/types';

const progressBarReducer = (state = {isSubmitButtonInProgress: false, isLoadingDataInProgress: false}, action) => {
    switch(action.type){
        case SHOW_SUBMIT_BUTTON_PROGRESS:
            return {...state, isSubmitButtonInProgress:true};
        case HIDE_SUBMIT_BUTTON_PROGRESS:
            return  {...state, isSubmitButtonInProgress:false};
        case SHOW_LOADING_DATA_PROGRESS:
            return {...state, isLoadingDataInProgress:true};
        case HIDE_LOADING_DATA_PROGRESS:
            return  {...state, isLoadingDataInProgress:false};
         default:
            return state;
    }
}
export default progressBarReducer;