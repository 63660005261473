import api from "../api/myApi";
import { 
    CREATE_INCOMESOURCE,
    GET_ALL_INCOMESOURCE,
    GET_INCOMESOURCE,
    GET_ALL_SELECTBOX_INCOMESOURCE,
    DELETE_INCOMESOURCE,
    UPDATE_INCOMESOURCE,
    ADD_DIALOG_INCOMESOURCE,
    EDIT_DIALOG_INCOMESOURCE,
    CLOSE_DIALOG_INCOMESOURCE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createIncomeSource = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/IncomeSource/Create', formValues);    
    dispatch(responseHandler(response, CREATE_INCOMESOURCE));
    if(response.data?.succeeded){
        dispatch(reset('formIncomeSource'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllIncomeSources = () => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSource/GetAll');
    dispatch(responseHandler(response, GET_ALL_INCOMESOURCE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getIncomeSource = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSource/Get/' + id);
    dispatch(responseHandler(response, GET_INCOMESOURCE));
}

export const getSelectBoxIncomeSources = () => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSource/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_INCOMESOURCE));
}

export const updateIncomeSource = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/IncomeSource/Update', formValues);
    dispatch(responseHandler(response, UPDATE_INCOMESOURCE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteIncomeSource = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/IncomeSource/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_INCOMESOURCE));
}

export const addDialogIncomeSource = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_INCOMESOURCE,
        payload: null
    });
}

export const editDialogIncomeSource = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_INCOMESOURCE,
        payload: item
    });
}

export const closeDialogIncomeSource = () => async (dispatch) => {
    dispatch(reset('formIncomeSource'));
    dispatch({
        type: CLOSE_DIALOG_INCOMESOURCE,
        payload: undefined
    });
}