import api from "../api/myApi";
import { 
    CREATE_KUF,
    GET_ALL_KUF,
    GET_KUF,
    GET_ALL_SELECTBOX_KUF,
    DELETE_KUF,
    UPDATE_KUF,
    ADD_DIALOG_KUF,
    EDIT_DIALOG_KUF,
    CLOSE_DIALOG_KUF,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createKUF = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/KUF/Create', formValues);    
    dispatch(responseHandler(response, CREATE_KUF));
    if(response.data?.succeeded){
        dispatch(reset('formKUF'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllKUFs = () => async (dispatch) => {
    const response = await api.get('/api/v1/KUF/GetAll');
    dispatch(responseHandler(response, GET_ALL_KUF));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getKUF = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/KUF/Get/' + id);
    dispatch(responseHandler(response, GET_KUF));
}

export const getSelectBoxKUFs = () => async (dispatch) => {
    const response = await api.get('/api/v1/KUF/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_KUF));
}

export const updateKUF = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/KUF/Update', formValues);
    dispatch(responseHandler(response, UPDATE_KUF));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteKUF = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/KUF/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_KUF));
}

export const addDialogKUF = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_KUF,
        payload: null
    });
}

export const editDialogKUF = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_KUF,
        payload: item
    });
}

export const closeDialogKUF = () => async (dispatch) => {
    dispatch(reset('formKUF'));
    dispatch({
        type: CLOSE_DIALOG_KUF,
        payload: undefined
    });
}