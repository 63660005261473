import { 
    GET_ALL_SELECTBOX_WORKER
} from '../../actions/types';

 const workerSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_WORKER:
            return [...action.payload];
        default:
            return state;
    }
}
export default workerSelectBoxReducer;