import {
    GET_ALL_JOURNAL_BY_ORDER
} from '../../actions/types';

const orderSelectedReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_JOURNAL_BY_ORDER:
            return { ...action.payload.order };
        default:
            return state;
    }
}

export default orderSelectedReducer;