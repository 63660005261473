import React from 'react';
import { connect } from 'react-redux';
import { createLoanType } from '../../actions/loanTypeAction';
import FormLoanType from './FormLoanType';
import i18next from '../../language/i18n';

class CreateLoanType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createLoanType(formValues);
    }
    
    render(){
        return (
            <FormLoanType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormLoanType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanTypeDialog === undefined || state.dialog.LoanTypeDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.LoanTypeDialog};
}


export default connect(mapStateToProps, { createLoanType })(CreateLoanType);
