//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogFileUploadType, updateFileUploadType } from '../../actions/fileUploadTypeAction';
import FormFileUploadType from './FormFileUploadType';

class EditFileUploadType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateFileUploadType(formValues);
    }
    
    render(){
        return (
            <FormFileUploadType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormFileUploadType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.FileUploadTypeDialog === undefined || state.dialog.FileUploadTypeDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.FileUploadTypeDialog};
}


export default connect(mapStateToProps, { closeDialogFileUploadType, updateFileUploadType })(EditFileUploadType);
