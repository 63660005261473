//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateLoanApprovalSettings from './CreateLoanApprovalSettings';
import EditLoanApprovalSettings from './EditLoanApprovalSettings';
import i18next from '../../language/i18n';
import { addDialogLoanApprovalSettings, editDialogLoanApprovalSettings, deleteLoanApprovalSettings, getAllLoanApprovalSettings } from "../../actions/loanApprovalSettingsAction";
import DeleteButton from '../Share/Button/DeleteButton';


class TableLoanApprovalSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getAllLoanApprovalSettings();
  }
  columns = [
    { field: 'amount', minWidth: 150,  headerName: i18next.t('AmountGreaterThen'), flex: 1   },
    { field: 'numberOfMembers', minWidth: 150, headerName: i18next.t('NumberOfMembers'), flex: 1 },
    //{ field: 'createDate', headerName: 'CreateDate', width: 150 },
    //{ field: 'editDate', headerName: 'EditDate', width: 150 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogLoanApprovalSettings();
  }
  edit = (params) => {
    this.props.editDialogLoanApprovalSettings(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.loanApprovalSettings}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateLoanApprovalSettings></CreateLoanApprovalSettings>
        <EditLoanApprovalSettings></EditLoanApprovalSettings>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLoanApprovalSettings(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { loanApprovalSettings: Object.values(state.loanApprovalSettings)};
}

export default connect(mapStateToProps, { 
  addDialogLoanApprovalSettings, 
  editDialogLoanApprovalSettings, 
  deleteLoanApprovalSettings,
  getAllLoanApprovalSettings
   })(TableLoanApprovalSettings);