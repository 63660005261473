import React from "react";
import TableLoan from '../../components/Loan/TableLoan';

class DraftPage extends React.Component{
    render(){
        return(<TableLoan
            Status={0}
        />);
    }
}

export default DraftPage;