import React from "react";
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import i18next from '../../../language/i18n';
import { connect } from 'react-redux';
//import { showSubmitButtonInProgress } from "../../../actions/submitButtonAction";

class SubmitButton extends React.Component {
    /* onClick = (e) => {
         this.props.showSubmitButtonInProgress();
         //e.preventDefault();
         return true;
     }*/
    render() {
        return (
            <LoadingButton
                color="primary"
                variant="contained"
                type="Submit"
                loadingPosition="start"
                loading={this.props.progressBar.isSubmitButtonInProgress}
                startIcon={<CheckIcon />}
            // onClick={this.onClick}
            >
                {i18next.t('Submit')}
            </LoadingButton>
        );
    }
}
const mapStateToProps = state => {
    return {
        progressBar: state.progressBar
    }
}
export default connect(mapStateToProps, {})(SubmitButton);