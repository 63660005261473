import { 
    CREATE_DEBTCOLLECTIONCOSTDETAILS,
    GET_ALL_DEBTCOLLECTIONCOSTDETAILS,
    GET_DEBTCOLLECTIONCOSTDETAILS,
    DELETE_DEBTCOLLECTIONCOSTDETAILS,
    UPDATE_DEBTCOLLECTIONCOSTDETAILS
} from '../../actions/types';

const debtCollectionCostDetailsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_DEBTCOLLECTIONCOSTDETAILS:
            let result = {  };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_DEBTCOLLECTIONCOSTDETAILS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default debtCollectionCostDetailsReducer;