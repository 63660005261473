import React from "react";
import { EditSelectField, EditTextField, EditDatePickerField, EditCheckbox, EditIntegerNumberField } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Validation from '../../share/validation';
import {
    getLoanPerBranch,
    getLoanPerCities,
    getSumByBranch,
    getLoanTerms,
    getWeeklyFlows,
    getMonthlyCashFlows,
    getMethodOfKnowledge,
    getPaymentDelay,
    getLoanProducts,
    getLoanProductsTerms,
    getLoanTermsFull,
    getLoanPerPerson,
    getPaymentDelayDetails,
    getCategories,
    getCategoriesPerBranch,
    getPaymentDelayPerBroker,
    getPaymentDelayPerBranch,
    getLifeInsurance,
    getCalled,
    getCallToday,
    getLoanProductsTermsFBiHSum,
    getGetBrokerPerLoanType,
    getApprovedLoansByEmployee,
    getApprovedLoansByBranch,
    getApprovedLoansByInterestRate,
    getApprovedLoansByLoanType,
    getApprovedLoansByCycle,
    getPerfByLoanOfficers
} from '../../actions/reportAction';
import { getSelectBoxLoanTypes } from '../../actions/loanTypeAction';
import { getSelectBoxBrokers } from '../../actions/brokerAction';
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxUsers } from '../../actions/userAction';
import { getSelectBoxSourceOfFinancings } from '../../actions/sourceOfFinancingAction';

class MatureAnnuitiesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentValue: 1 }
    }
    componentDidMount = () => {
        this.props.getSelectBoxLoanTypes();
        this.props.getSelectBoxBrokers();
        this.props.getSelectBoxCompanyDetails();
        this.props.getSelectBoxBranches();
        this.props.getSelectBoxUsers();
        this.props.getSelectBoxSourceOfFinancings();
    }
    reports = [
        { value: 1, text: 'Dodeljeni krediti' },
        { value: 2, text: 'Krediti po opštinama' },
        { value: 3, text: 'Zbirno po poslovnicama' },
        //{ value: 4, text: 'Kratkoročni krediti' },
        //{ value: 5, text: 'Dugoročni krediti' },
        //{ value: 6, text: 'Sedmični tokovi' },
        { value: 7, text: 'Uplate u periodu' },
        { value: 8, text: 'Preporuka' },
        //{ value: 9, text: 'Krediti u kašnjenju sintetika i analitika' },
        //{ value: 15, text: 'Krediti u kašnjenju sintetika i analitika detaljno' },
        { value: 10, text: 'Proizvod detaljno' },
        { value: 11, text: 'Kratkoročni proizvodi' },
        { value: 12, text: 'Dugoročni proizvodi' },
        { value: 13, text: 'Kreditni proizvodi' },
        { value: 14, text: 'Krediti po licima' },
        //{ value: 16, text: 'Kategorije' },
        //{ value: 17, text: 'Kategorije po poslovnicama' },
        //{ value: 18, text: 'Kašnjenje po zastupnicima analitika' },
        //{ value: 19, text: 'Krediti u kašnjenju sintetika i analitika po poslovnicama' },
        { value: 20, text: 'Životno osiguranje' },
        { value: 21, text: 'Prozvano' },
        { value: 22, text: 'Prozvati danas' },
        //{ value: 23, text: 'Kamatna stopa' },
        //{ value: 24, text: 'Kamatna stopa FBA' },
        //{ value: 25, text: 'Provizija po brokerima' },
        //{ value: 9, text: 'Zbirno po statusu' },
        //{ value: 20, text: 'Provizija zastupnika' },
        //{ value: 22, text: 'Fond PIO' },
        //{ value: 24, text: 'Privremena otplata' },
        //{ value: 25, text: 'Privremena otplata eksport' },
        //{ value: 26, text: 'Eksport kredita' }, xlsx
        //{ value: 27, text: 'Pregled kredita' },
        { value: 28, text: 'Odobreni krediti po ročnosti' },
        { value: 29, text: 'Odobreni krediti - grupisani po službenicima' },
        { value: 30, text: 'Odobreni krediti - grupisani po tipu kredita' },
        { value: 31, text: 'Odobreni krediti - grupisani po kamatnim stopama' },
        { value: 32, text: 'Odobreni krediti - grupisani po kancelarijama' },
        { value: 33, text: 'Odobreni krediti - grupisani po ciklusima' },
        { value: 34, text: 'Učinak po kreditnim službenicima' },
    ];
    loanTypes = [
        { value: 0, text: 'Svi' },
        { value: 1, text: 'Nenamjenski krediti' },
        { value: 2, text: 'Ostali krediti' }
    ]
    onSubmit = (formValues) => {
        let data = {
            fromAccount: formValues.fromAccount,
            toAccount: formValues.toAccount,
            fromDate: formValues.fromDate,
            toDate: formValues.toDate,
            companyDetailsID: formValues.companyDetailsID,
            branchID: formValues.branchID,
            employeeUserID: formValues.employeeUserID,
            sourceOfFinancingID: formValues.sourceOfFinancingID,
        };
        let reportType = parseInt(formValues.reportType);

        if (reportType === 1) {
            this.props.getLoanPerBranch(data);
        }
        else if (reportType === 2) {
            this.props.getLoanPerCities(data);
        }
        else if (reportType === 3) {
            this.props.getSumByBranch(data);
        }
        else if (reportType === 4 || reportType === 5) {
            data.loanTerm = reportType === 4 ? 1 : 0; //kratrkoročni/dugoročni
            this.props.getLoanTerms(data);
        }
        else if (reportType === 6) {
            this.props.getWeeklyFlows({
                companyDetailsID: formValues.companyDetailsID,
                branchID: formValues.branchID,
                employeeUserID: formValues.employeeUserID,
                sourceOfFinancingID: formValues.sourceOfFinancingID,
            });
        }
        else if (reportType === 7) {
            this.props.getMonthlyCashFlows({
                companyDetailsID: formValues.companyDetailsID,
                branchID: formValues.branchID,
                employeeUserID: formValues.employeeUserID,
                sourceOfFinancingID: formValues.sourceOfFinancingID,
            });
        }
        else if (reportType === 8) {
            this.props.getMethodOfKnowledge(data);
        }
        else if (reportType === 9) {
            this.props.getPaymentDelay(data);
        }
        else if (reportType === 10) {
            data.loanTypeID = formValues.loanTypeID;
            this.props.getLoanProducts(data);
        }
        else if (reportType === 11 || reportType === 12 || reportType === 13) {
            if (reportType === 11) { data.LoanTerm = 1; }
            else if (reportType === 12) { data.LoanTerm = 0; }
            else if (reportType === 13) { data.LoanTerm = 2; }
            data.LoanTypeEnum = formValues.loanTypes;

            this.props.getLoanProductsTerms(data);
        }
        else if (reportType === 14) {
            this.props.getLoanPerPerson(data);
        }
        else if (reportType === 15) {
            data.brokerID = formValues.brokerID;
            this.props.getPaymentDelayDetails(data);
        }
        else if (reportType === 16) {
            this.props.getCategories(data);
        }
        else if (reportType === 17) {
            data.loanTypeID = formValues.loanTypeID;
            this.props.getCategoriesPerBranch(data);
        }
        else if (reportType === 18) {
            data.brokerID = formValues.brokerID;
            this.props.getPaymentDelayPerBroker(data);
        }
        else if (reportType === 19) {
            data.loanTypeID = formValues.loanTypeID;
            this.props.getPaymentDelayPerBranch(data);
        }
        else if (reportType === 20) {
            this.props.getLifeInsurance(data);
        }
        else if (reportType === 21) {
            this.props.getCalled({
                fromAccount: formValues.fromAccount,
                toAccount: formValues.toAccount,
                sourceOfFinancingID: formValues.sourceOfFinancingID,
            });
        }
        else if (reportType === 22) {
            this.props.getCallToday({});
        }
        // else if (reportType === 23) {
        //     this.props.getABRSInterestRates(data);
        // }
        else if (reportType === 24) {
            this.props.getLoanProductsTermsFBiHSum(data);
        }
        else if (reportType === 25) {
            this.props.getGetBrokerPerLoanType(data);
        }
        else if (reportType === 28) {
            this.props.getLoanTermsFull(data);
        }
        else if (reportType == 29) {
            this.props.getApprovedLoansByEmployee(data);
        }
        else if (reportType == 30) {
            this.props.getApprovedLoansByLoanType(data);
        }
        else if (reportType == 31) {
            this.props.getApprovedLoansByInterestRate(data);
        }
        else if (reportType == 32) {
            this.props.getApprovedLoansByBranch(data);
        }
        else if (reportType == 33) {
            this.props.getApprovedLoansByCycle(data);
        }
        else if (reportType == 34) {
            this.props.getPerfByLoanOfficers(data);
        }

    }
    render() {
        return (<div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                    name='reportType'
                    component={this.props.EditSelectField}
                    label='Reports'
                    onChange={
                        (e, child) => {
                            this.setState({ currentValue: parseInt(child) })
                        }
                    }
                    dataSource={this.reports}>
                </Field>
                {
                    [6, 7, 22].indexOf(this.state.currentValue) === -1 ?
                        (
                            <>
                                <Field
                                    name='fromDate'
                                    component={this.props.EditDatePickerField}
                                    label='FromDate'
                                />
                                <Field
                                    name='toDate'
                                    component={this.props.EditDatePickerField}
                                    label='ToDate'
                                />
                            </>
                        ) : null
                }
                {
                    [21, 22].indexOf(this.state.currentValue) === -1 ?
                        (
                            <Field
                                name='companyDetailsID'
                                component={this.props.EditSelectField}
                                label='CompanyDetails'
                                dataSource={this.props.companyDetailsSelectBox}
                            >
                            </Field>
                        ) : null
                }
                {
                    [21, 22].indexOf(this.state.currentValue) === -1 ?
                        (
                            <Field
                                name='branchID'
                                component={this.props.EditSelectField}
                                label='Branch'
                                dataSource={this.props.branchesSelectBox}
                            >
                            </Field>
                        ) : null
                }
                {
                    [21, 22].indexOf(this.state.currentValue) === -1 ?
                        (
                            <Field
                                name='employeeUserID'
                                component={this.props.EditSelectField}
                                label='Employee'
                                dataSource={this.props.usersSelectBox}
                            >
                            </Field>
                        ) : null
                }


                {
                    [10, 17, 19].indexOf(this.state.currentValue) !== -1 ?
                        (
                            <Field
                                name='loanTypeID'
                                component={this.props.EditSelectField}
                                label='LoanType'
                                dataSource={this.props.loanTypesSelectBox}
                            >
                            </Field>
                        ) : null
                }
                {
                    [15, 18].indexOf(this.state.currentValue) !== -1 ?
                        (
                            <Field
                                name='brokerID'
                                component={this.props.EditSelectField}
                                label='Broker'
                                dataSource={this.props.brokersSelectBox}
                            >
                            </Field>
                        ) : null
                }
                {
                    [11, 12, 13].indexOf(this.state.currentValue) !== -1 ?
                        (
                            <Field
                                name='loanTypes'
                                component={this.props.EditSelectField}
                                label='LoanType'
                                dataSource={this.loanTypes}
                                hasEmpty={false}
                            >
                            </Field>
                        ) : null
                }

                {
                    [21, 22].indexOf(this.state.currentValue) === -1 ?
                        (
                            <Field
                                name='sourceOfFinancingID'
                                component={this.props.EditSelectField}
                                label='SourceOfFinancing'
                                dataSource={this.props.sourceOfFinancingsSelectBox}
                            >
                            </Field>
                        ) : null
                }

                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                        {i18next.t('Report')}
                    </Button>
                </Box>
            </form>
        </div>);
    }
}


const validate = (formValues) => {
    const errors = {};
    if (!formValues.reportType) {
        errors.reportType = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.fromAccount)) {
        errors.fromAccount = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.toAccount)) {
        errors.toAccount = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formGeneralLedgerReport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportType: '1',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
        fromAccount: 0,
        toAccount: 9,
        loanTypes: 0
    }
})(MatureAnnuitiesPage);
const mapStateToProps = state => {
    return {
        loanTypesSelectBox: Object.values(state.loanTypesSelectBox),
        brokersSelectBox: Object.values(state.brokersSelectBox),
        companyDetailsSelectBox: Object.values(state.companyDetailsSelectBox),
        branchesSelectBox: Object.values(state.branchesSelectBox),
        usersSelectBox: Object.values(state.usersSelectBox),
        sourceOfFinancingsSelectBox: Object.values(state.sourceOfFinancingsSelectBox),
    }
}
export default connect(mapStateToProps, {
    EditSelectField,
    EditTextField,
    EditDatePickerField,
    EditCheckbox,
    EditIntegerNumberField,
    getSelectBoxLoanTypes,
    getSelectBoxBrokers,
    getSelectBoxCompanyDetails,
    getSelectBoxBranches,
    getSelectBoxUsers,
    getSelectBoxSourceOfFinancings,

    getLoanPerBranch,
    getLoanPerCities,
    getSumByBranch,
    getLoanTerms,
    getWeeklyFlows,
    getMonthlyCashFlows,
    getMethodOfKnowledge,
    getPaymentDelay,
    getLoanProducts,
    getLoanProductsTerms,
    getLoanTermsFull,
    getLoanPerPerson,
    getPaymentDelayDetails,
    getCategories,
    getCategoriesPerBranch,
    getPaymentDelayPerBroker,
    getPaymentDelayPerBranch,
    getLifeInsurance,
    getCalled,
    getCallToday,
    //getABRSInterestRates,
    getLoanProductsTermsFBiHSum,
    getGetBrokerPerLoanType,
    getApprovedLoansByEmployee,
    getApprovedLoansByBranch,
    getApprovedLoansByInterestRate,
    getApprovedLoansByLoanType,
    getApprovedLoansByCycle,
    getPerfByLoanOfficers
})(formWrapper);
