import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateBroker from './CreateBroker';
import EditBroker from './EditBroker';
import { addDialogBroker, editDialogBroker, deleteBroker, getAllBrokers } from "../../actions/brokerAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
//import { getSelectBoxUsers } from '../../actions/userAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableBroker extends React.Component{
    constructor(props){
        super(props);
        this.state = {IsOpenConfirmDialog: false, ID: null}
      }
    componentDidMount = () =>{
      this.props.showLoadingDataInProgress();
      //this.props.getSelectBoxUsers();

      this.props.getAllBrokers();
    }
    
    userName = (props) => {
      return this.props.users.find(el => el.value === props.row.userID)?.text;
    }
    columns = [
        { field: 'firstName', headerName: i18next.t('FirstName'), minWidth: 150, flex: 1 },
        { field: 'lastName', headerName: i18next.t('LastName'), minWidth: 150, flex: 1 },
        { field: 'percentage', headerName: i18next.t('Percentage'), minWidth: 150, flex: 1 },
       
          {
            field: 'edit',
            headerName: ' ',
            renderCell: (params) => (
              <strong>                
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  style={{ marginLeft: 16 }}
                  onClick={() => this.edit(params)}
                >
                  {i18next.t('Edit')}
                </Button>
              </strong>
            ),
          },
          {
            field: 'delete',
            headerName: ' ',
            renderCell: (params) => (
              <strong>                
                <DeleteButton
                  onClick={() => this.delete(params)}
                ></DeleteButton> 
              </strong>
            )
          }
      ];
      create = () => {
        this.props.addDialogBroker();
      }
      edit = (params) => {
        this.props.editDialogBroker(params.row);
      }
      delete = (params) => {
        this.setState({IsOpenConfirmDialog: true, ID: params.row.id});
      }
    render(){
        return (
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid  
                  rows={this.props.brokers} 
                  columns={this.columns}
                  components={{
                      Toolbar: CustomToolbar
                  }}
                  componentsProps={{ toolbar: { createNew:this.create } }}
                  checkboxSelection 
                  disableSelectionOnClick 
                 />
              <CreateBroker></CreateBroker>
              <EditBroker></EditBroker>
              <ConfirmDialog 
                  IsOpen={this.state.IsOpenConfirmDialog}
                  close={() => this.setState({IsOpenConfirmDialog: false, ID: null})}
                  confirm={() => {
                    this.props.deleteBroker(this.state.ID);
                    this.setState({IsOpenConfirmDialog: false, ID: null});
                  }}
                  message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
                />
            </div>
          );
    }
}

const mapStateToProps = state => {
    return {
      brokers: Object.values(state.brokers), 
      //users: Object.values(state.usersSelectBox)
    };
}

export default connect(mapStateToProps, { addDialogBroker, editDialogBroker, deleteBroker, getAllBrokers /* , getSelectBoxUsers*/, showLoadingDataInProgress})(TableBroker);