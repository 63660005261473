//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createRefinancing } from '../../actions/refinancingAction';
import FormRefinancing from './FormRefinancing';

class CreateRefinancing extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loan.loan.id;
        this.props.createRefinancing(formValues);
    }
    
    render(){
        return (
            <FormRefinancing 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormRefinancing>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RefinancingDialog === undefined || state.dialog.RefinancingDialog.type !== 'add'? 
  {
    IsOpen:false,
    loan: state.loanSelected
} : {
    ...state.dialog.RefinancingDialog,
    loan: state.loanSelected
};
}


export default connect(mapStateToProps, { createRefinancing })(CreateRefinancing);
