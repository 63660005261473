import api from "../api/myApi";
import { 
    CREATE_LOANQUALITYSTATUS,
    GET_ALL_LOANQUALITYSTATUS,
    GET_LOANQUALITYSTATUS,
    GET_ALL_SELECTBOX_LOANQUALITYSTATUS,
    DELETE_LOANQUALITYSTATUS,
    UPDATE_LOANQUALITYSTATUS,
    ADD_DIALOG_LOANQUALITYSTATUS,
    EDIT_DIALOG_LOANQUALITYSTATUS,
    CLOSE_DIALOG_LOANQUALITYSTATUS,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanQualityStatus = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanQualityStatus/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANQUALITYSTATUS));
    if(response.data?.succeeded){
        dispatch(reset('formLoanQualityStatus'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLoanQualityStatus = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQualityStatus/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANQUALITYSTATUS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLoanQualityStatus = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQualityStatus/Get/' + id);
    dispatch(responseHandler(response, GET_LOANQUALITYSTATUS));
}

export const getSelectBoxLoanQualityStatus = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanQualityStatus/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANQUALITYSTATUS));
}

export const updateLoanQualityStatus = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanQualityStatus/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANQUALITYSTATUS));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoanQualityStatus = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanQualityStatus/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANQUALITYSTATUS));
}

export const addDialogLoanQualityStatus = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANQUALITYSTATUS,
        payload: null
    });
}

export const editDialogLoanQualityStatus = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANQUALITYSTATUS,
        payload: item
    });
}

export const closeDialogLoanQualityStatus = () => async (dispatch) => {
    dispatch(reset('formLoanQualityStatus'));
    dispatch({
        type: CLOSE_DIALOG_LOANQUALITYSTATUS,
        payload: undefined
    });
}