import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateUserBranch from './CreateUserBranch';
import EditUserBranch from './EditUserBranch';
import { addDialogUserBranch, editDialogUserBranch, deleteUserBranch, getAllUserBranches } from "../../actions/userBranchAction";
import { getSelectBoxBranches } from '../../actions/branchAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableUserBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null, userID: null }
  }
  
  componentDidMount = () => {
    this.props.getSelectBoxBranches();

    //this.props.getAllUserBranches();
  }
  componentDidUpdate(prevProps, prevState, snapshot) { 
    if (this.props.userID !== this.state.userID && this.props.userID !== undefined && this.props.userID != null) {
      this.setState({userID: this.props.userID});
      this.props.getAllUserBranches(this.props.userID);
    }
  }

  branchName = (props) => {
    return this.props.branches.find(el => el.value === props.row.branchID)?.text;
  }
  columns = [
    {
      field: 'BranchID',
      headerName: i18next.t('Branch'),
      minWidth: 160,
      flex:1,
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogUserBranch();
  }
  edit = (params) => {
    this.props.editDialogUserBranch(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.userBranches.filter(el => el.userID === this.state.userID)}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateUserBranch userID={this.props.userID}></CreateUserBranch>
        <EditUserBranch></EditUserBranch>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteUserBranch(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userBranches: Object.values(state.userBranches),
    branches: Object.values(state.branchesSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogUserBranch,
    editDialogUserBranch,
    deleteUserBranch,
    getAllUserBranches,
    getSelectBoxBranches
  })(TableUserBranch);