import React from 'react';
import { connect } from 'react-redux';
import { createUserBranch } from '../../actions/userBranchAction';
import FormUserBranch from './FormUserBranch';
import i18next from '../../language/i18n';

class CreateUserBranch extends React.Component{
    onSubmit  = (formValues) => {
        console.log(formValues);
        console.log(this.props.userID);
        formValues.userID = this.props.userID;
        this.props.createUserBranch(formValues);
    }
    
    render(){
        return (
            <FormUserBranch 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormUserBranch>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserBranchDialog === undefined || state.dialog.UserBranchDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.UserBranchDialog};
}


export default connect(mapStateToProps, { createUserBranch })(CreateUserBranch);
