 import { 
    SHOW_SUBMIT_BUTTON_PROGRESS, 
    HIDE_SUBMIT_BUTTON_PROGRESS,
    SHOW_LOADING_DATA_PROGRESS, 
    HIDE_LOADING_DATA_PROGRESS
 } from './types';

export const showSubmitButtonInProgress = () => ({
    type: SHOW_SUBMIT_BUTTON_PROGRESS
});

export const hideSubmitButtonInProgress = () => ({
    type: HIDE_SUBMIT_BUTTON_PROGRESS
});
export const showLoadingDataInProgress = () => ({
    type: SHOW_LOADING_DATA_PROGRESS
});

export const hideLoadingDataInProgress = () => ({
    type: HIDE_LOADING_DATA_PROGRESS
});