import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateIncomeSource from './CreateIncomeSource';
import EditIncomeSource from './EditIncomeSource';
import { addDialogIncomeSource, editDialogIncomeSource, deleteIncomeSource } from "../../actions/incomeSourceAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxIncomeSourceTypes } from '../../actions/incomeSourceTypeAction';
import { getSelectBoxEmploymentTypes } from '../../actions/employmentTypeAction';
import { getSelectBoxTypeOfContractWithTheEmployer } from '../../actions/typeOfContractWithTheEmployerAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';

class TableIncomeSource extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxIncomeSourceTypes();
    this.props.getSelectBoxEmploymentTypes();
    this.props.getSelectBoxTypeOfContractWithTheEmployer();
  }

  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  incomeSourceTypeName = (props) => {
    return this.props.incomeSourceTypes.find(el => el.value === props.row.incomeSourceTypeID)?.text;
  }
  employmentTypesName = (props) => {
    return this.props.employmentTypes.find(el => el.value === props.row.employmentTypeID)?.text;
  }
  employerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.employerID)?.text;
  }

  typeOfContractWithTheEmployerName = (props) => {
    return this.props.typeOfContractWithTheEmployerSelectBox.find(el => el.value === props.row.typeOfContractWithTheEmployer)?.text;
  }


  columns = () => {
    return ([
      {
        field: 'PartnerID',
        headerName: i18next.t('Partner'),
        width: 160,
        valueGetter: this.partnerName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
      },
      {
        field: 'IncomeSourceTypeID',
        headerName: i18next.t('IncomeSourceType'),
        width: 160,
        valueGetter: this.incomeSourceTypeName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.incomeSourceTypeName(cellParams1).localeCompare(this.incomeSourceTypeName(cellParams2))
      },
      {
        field: 'TypeOfContractWithTheEmployer',
        headerName: i18next.t('TypeOfContractWithTheEmployer'),
        width: 160,
        valueGetter: this.typeOfContractWithTheEmployerName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.typeOfContractWithTheEmployerName(cellParams1).localeCompare(this.typeOfContractWithTheEmployerName(cellParams2))
      },
      {
        field: 'EmploymentTypeID',
        headerName: i18next.t('EmploymentType'),
        width: 160,
        valueGetter: this.employmentTypesName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.employmentTypesName(cellParams1).localeCompare(this.employmentTypesName(cellParams2))
      },
      {
        field: 'EmployerID',
        headerName: i18next.t('Employer'),
        width: 160,
        valueGetter: this.employerName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.employerName(cellParams1).localeCompare(this.employerName(cellParams2))
      },
      { field: 'administrativeBan', headerName: i18next.t('AdministrativeBan'), minWidth: 150, flex: 1 },
      // { field: 'source', headerName: i18next.t('Source'), minWidth: 150, flex: 1 },
      { field: 'income', headerName: i18next.t('Income'), minWidth: 150, flex: 1 },
      { field: 'lastThreeMonthsIncome', headerName: i18next.t('LastThreeMonthsIncome'), minWidth: 150, flex: 1 },
      { field: 'householdCost', headerName: i18next.t('HouseholdCost'), minWidth: 150, flex: 1 },
      { field: 'suspensionsLastMonth', headerName: i18next.t('SuspensionsLastMonth'), minWidth: 150, flex: 1 },
      { field: 'netIncome', headerName: i18next.t('NetIncome'), minWidth: 150, flex: 1 },
      { field: 'annualIncome', headerName: i18next.t('AnnualIncome'), minWidth: 150, flex: 1 },
      { field: 'annualExpenses', headerName: i18next.t('AnnualExpenses'), minWidth: 150, flex: 1 },
      { field: 'isValid', headerName: i18next.t('IsValid'), minWidth: 150, flex: 1, type: 'boolean' },
      { field: 'description', headerName: i18next.t('Description'), minWidth: 150, flex: 1 },

      {
        field: 'edit',
        headerName: ' ',
        hide: this.props.disabled,
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => this.edit(params)}
            >
              {i18next.t('Edit')}
            </Button>
          </strong>
        ),
      },
      {
        field: 'delete',
        headerName: ' ',
        hide: this.props.disabled,
        renderCell: (params) => (
          <strong>
            <DeleteButton
              onClick={() => this.delete(params)}
            ></DeleteButton>
          </strong>
        )
      }
    ])
  }
  create = () => {
    this.props.addDialogIncomeSource();
  }
  edit = (params) => {
    this.props.editDialogIncomeSource(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.incomeSources}
          columns={this.columns()}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.props.disabled ? null : this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateIncomeSource></CreateIncomeSource>
        <EditIncomeSource></EditIncomeSource>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteIncomeSource(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    incomeSources: Object.values(state.incomeSources),
    partners: Object.values(state.partnersSelectBox),
    incomeSourceTypes: Object.values(state.incomeSourceTypesSelectBox),
    employmentTypes: Object.values(state.employmentTypesSelectBox),
    typeOfContractWithTheEmployerSelectBox: Object.values(state.typeOfContractWithTheEmployerSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogIncomeSource,
    editDialogIncomeSource,
    deleteIncomeSource,
    getSelectBoxPartners,
    getSelectBoxIncomeSourceTypes,
    getSelectBoxEmploymentTypes,
    getSelectBoxTypeOfContractWithTheEmployer
  })(TableIncomeSource);