import { 
    CREATE_LEGALSERVICE_WARNING,
    GET_ALL_LEGALSERVICE_WARNING,
    UPDATE_LEGALSERVICE_WARNING,
    CREATE_LEGALSERVICE_ISKUP
} from '../../actions/types';

const legalService_WarningReducer = (state = {}, action) => {
    switch(action.type){
        // case CREATE_LEGALSERVICE_WARNING:
        //     return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LEGALSERVICE_WARNING:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case UPDATE_LEGALSERVICE_WARNING:{
            let data = state[action.payload.id];
            data.dateOfReceipt = action.payload.warningReceivingDate;
            return { ...state, [action.payload.id]: data };
        }
        case CREATE_LEGALSERVICE_ISKUP: {
            let result = {...state};
            delete result[action.payload.id];
            return result;
        }
        default:
            return state;
    }
}

export default legalService_WarningReducer;