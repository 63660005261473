import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLegalService_Warning } from '../../actions/legalService_WarningAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditDatePickerField, EditCheckbox } from '../Share/EditComponents';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';

class FormLegalService_Warning extends React.Component{
    handleClose = () => {
        //this.props.closeDialogLegalService_Warning();
        this.props.onClose();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>                                       
                            <div>
                                <Field
                                    name='dateOfReceipt'
                                    component={this.props.EditDatePickerField}
                                    label='DateOfReceipt'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if(!formValues.referenceNumber){
        errors.referenceNumber = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formLegalService_Warning',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormLegalService_Warning);

export default connect(null, { closeDialogLegalService_Warning, EditSelectField, EditTextField, EditDecimalNumberField, EditDatePickerField, EditCheckbox})(formWrapper);