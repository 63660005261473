//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createSourceOfFinancing } from '../../actions/sourceOfFinancingAction';
import FormSourceOfFinancing from './FormSourceOfFinancing';

class CreateSourceOfFinancing extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createSourceOfFinancing(formValues);
    }
    
    render(){
        return (
            <FormSourceOfFinancing 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormSourceOfFinancing>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.SourceOfFinancingDialog === undefined || state.dialog.SourceOfFinancingDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.SourceOfFinancingDialog};
}


export default connect(mapStateToProps, { createSourceOfFinancing })(CreateSourceOfFinancing);
