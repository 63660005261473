//Mojo-AG
import { 
    GET_ALL_SELECTBOX_SOURCEOFFINANCING
} from '../../actions/types';

 const sourceOfFinancingSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_SOURCEOFFINANCING:
            return [...action.payload];
        default:
            return state;
    }
}
export default sourceOfFinancingSelectBoxReducer;