//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createCRKResponse } from '../../actions/cRKResponseAction';
import FormCRKResponse from './FormCRKResponse';

class CreateCRKResponse extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createCRKResponse(formValues);
    }
    
    render(){
        return (
            <FormCRKResponse 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormCRKResponse>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKResponseDialog === undefined || state.dialog.CRKResponseDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CRKResponseDialog};
}


export default connect(mapStateToProps, { createCRKResponse })(CreateCRKResponse);
