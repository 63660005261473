//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogEarlyRepaymentSettings } from '../../actions/earlyRepaymentSettingsAction';
import { EditSelectField, EditDecimalNumberField, EditIntegerNumberField, EditCheckbox, EditDatePickerField } from '../Share/EditComponents';

class FormEarlyRepaymentSettings extends React.Component{
    handleClose = () => {
        this.props.closeDialogEarlyRepaymentSettings();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='companyDetailsID'
                                    component ={this.props.EditSelectField}
                                    label = 'CompanyDetails'
                                    dataSource={this.props.companyDetailsSelectBox}
                                    />
                            </div>
            
                            <div>
                                <Field
                                    name='fromAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='FromAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='toAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='ToAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='numberOfMonthsUntilTheEndFrom'
                                    component={this.props.EditIntegerNumberField}
                                    label='NumberOfMonthsUntilTheEndFrom'
                                />
                            </div>
                            <div>
                                <Field
                                    name='numberOfMonthsUntilTheEndTo'
                                    component={this.props.EditIntegerNumberField}
                                    label='NumberOfMonthsUntilTheEndTo'
                                />
                            </div>
                            <div>
                                <Field
                                    name='percentage'
                                    component={this.props.EditDecimalNumberField}
                                    label='Percentage'
                                />
                            </div>
                            <div>
                                <Field
                                    name='canBeHigherThanTheApprovalAmount'
                                    component={this.props.EditCheckbox}
                                    label='CanBeHigherThanTheApprovalAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='validFrom'
                                    component={this.props.EditDatePickerField}
                                    label='ValidFrom'
                                />
                            </div>
                            <div>
                                <Field
                                    name='validTo'
                                    component={this.props.EditDatePickerField}
                                    label='ValidTo'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.companyDetailsID){
        errors.companyDetailsID = 'Polje je obavezno!';
    }
    if(!formValues.fromAmount){
        errors.fromAmount = 'Polje je obavezno!';
    }
    if(!formValues.toAmount){
        errors.toAmount = 'Polje je obavezno!';
    }
    if(!formValues.numberOfMonthsUntilTheEndFrom){
        errors.numberOfMonthsUntilTheEndFrom = 'Polje je obavezno!';
    }
    if(!formValues.numberOfMonthsUntilTheEndTo){
        errors.numberOfMonthsUntilTheEndTo = 'Polje je obavezno!';
    }
    if(!formValues.percentage){
        errors.percentage = 'Polje je obavezno!';
    }
    
    if(!formValues.validFrom){
        errors.validFrom = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companyDetailsSelectBox: Object.values(state.companyDetailsSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formEarlyRepaymentSettings',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormEarlyRepaymentSettings);

export default connect(mapStateToProps, { closeDialogEarlyRepaymentSettings, EditSelectField, EditDecimalNumberField, EditIntegerNumberField, EditCheckbox, EditDatePickerField})(formWrapper);