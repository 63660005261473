//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogRevenueRecognition, updateRevenueRecognition } from '../../actions/revenueRecognitionAction';
import FormRevenueRecognition from './FormRevenueRecognition';

class EditRevenueRecognition extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateRevenueRecognition(formValues);
    }
    
    render(){
        return (
            <FormRevenueRecognition 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormRevenueRecognition>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RevenueRecognitionDialog === undefined || state.dialog.RevenueRecognitionDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.RevenueRecognitionDialog};
}


export default connect(mapStateToProps, { closeDialogRevenueRecognition, updateRevenueRecognition })(EditRevenueRecognition);
