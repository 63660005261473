import React from "react";
import MainLayout from './MainLayout';
import { connect } from 'react-redux';

class MainLayoutWrapper extends React.Component {
    render() {
      return (<MainLayout title={this.props.title} userName={this.props.userName}/>);
    };
  }
  
  const mapStateToProps = state => {
    return { title: state.title?.text,
      userName: state.authUser?.userName  };
  }
  
  
  export default connect(mapStateToProps, null)(MainLayoutWrapper);