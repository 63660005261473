import api from "../api/myApi";
import { 
    CREATE_USER,
    GET_ALL_USER,
    GET_USER,
    GET_ALL_SELECTBOX_USER,
    DELETE_USER,
    UPDATE_USER,
    ADD_DIALOG_USER,
    EDIT_DIALOG_USER,
    CLOSE_DIALOG_USER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createUser = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/User/Create', formValues);    
    dispatch(responseHandler(response, CREATE_USER));
    if(response.data?.succeeded){
        dispatch(reset('formUser'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllUsers = () => async (dispatch) => {
    const response = await api.get('/api/v1/User/GetAll');
    dispatch(responseHandler(response, GET_ALL_USER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getUser = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/User/Get/' + id);
    dispatch(responseHandler(response, GET_USER));
}

export const getSelectBoxUsers = () => async (dispatch) => {
    const response = await api.get('/api/v1/User/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_USER));
}

export const updateUser = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/User/Update', formValues);
    dispatch(responseHandler(response, UPDATE_USER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteUser = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/User/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_USER));
}

export const addDialogUser = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_USER,
        payload: null
    });
}

export const editDialogUser = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_USER,
        payload: item
    });
}

export const closeDialogUser = () => async (dispatch) => {
    dispatch(reset('formUser'));
    dispatch({
        type: CLOSE_DIALOG_USER,
        payload: undefined
    });
}