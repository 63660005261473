//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_COMPANYACTIVITYCODE,
    GET_ALL_COMPANYACTIVITYCODE,
    GET_COMPANYACTIVITYCODE,
    GET_ALL_SELECTBOX_COMPANYACTIVITYCODE,
    DELETE_COMPANYACTIVITYCODE,
    UPDATE_COMPANYACTIVITYCODE,
    ADD_DIALOG_COMPANYACTIVITYCODE,
    EDIT_DIALOG_COMPANYACTIVITYCODE,
    CLOSE_DIALOG_COMPANYACTIVITYCODE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCompanyActivityCode = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CompanyActivityCode/Create', formValues);    
    dispatch(responseHandler(response, CREATE_COMPANYACTIVITYCODE));
    if(response.data?.succeeded){
        dispatch(reset('formCompanyActivityCode'));
    }
}

export const getAllCompanyActivityCodes = () => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyActivityCode/GetAll');
    dispatch(responseHandler(response, GET_ALL_COMPANYACTIVITYCODE));
}

export const getCompanyActivityCode = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyActivityCode/Get/' + id);
    dispatch(responseHandler(response, GET_COMPANYACTIVITYCODE));
}

export const getSelectBoxCompanyActivityCodes = () => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyActivityCode/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_COMPANYACTIVITYCODE));
}

export const updateCompanyActivityCode = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CompanyActivityCode/Update', formValues);
    dispatch(responseHandler(response, UPDATE_COMPANYACTIVITYCODE));
}

export const deleteCompanyActivityCode = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CompanyActivityCode/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_COMPANYACTIVITYCODE));
}

export const addDialogCompanyActivityCode = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_COMPANYACTIVITYCODE,
        payload: null
    });
}

export const editDialogCompanyActivityCode = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_COMPANYACTIVITYCODE,
        payload: item
    });
}

export const closeDialogCompanyActivityCode = () => async (dispatch) => {
    dispatch(reset('formCompanyActivityCode'));
    dispatch({
        type: CLOSE_DIALOG_COMPANYACTIVITYCODE,
        payload: undefined
    });
}