import api from "../api/myApi";
import { 
    CREATE_INCOMESOURCETYPE,
    GET_ALL_INCOMESOURCETYPE,
    GET_INCOMESOURCETYPE,
    GET_ALL_SELECTBOX_INCOMESOURCETYPE,
    DELETE_INCOMESOURCETYPE,
    UPDATE_INCOMESOURCETYPE,
    ADD_DIALOG_INCOMESOURCETYPE,
    EDIT_DIALOG_INCOMESOURCETYPE,
    CLOSE_DIALOG_INCOMESOURCETYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler, responseHandlerWithTranslate } from "../api/responseHandler";

export const createIncomeSourceType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/IncomeSourceType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_INCOMESOURCETYPE));
    if(response.data?.succeeded){
        dispatch(reset('formIncomeSourceType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllIncomeSourceTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSourceType/GetAll');
    dispatch(responseHandler(response, GET_ALL_INCOMESOURCETYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getIncomeSourceType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSourceType/Get/' + id);
    dispatch(responseHandler(response, GET_INCOMESOURCETYPE));
}

export const getSelectBoxIncomeSourceTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/IncomeSourceType/GetSelectBox');
    dispatch(responseHandlerWithTranslate(response, GET_ALL_SELECTBOX_INCOMESOURCETYPE));
}

export const updateIncomeSourceType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/IncomeSourceType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_INCOMESOURCETYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteIncomeSourceType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/IncomeSourceType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_INCOMESOURCETYPE));
}

export const addDialogIncomeSourceType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_INCOMESOURCETYPE,
        payload: null
    });
}

export const editDialogIncomeSourceType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_INCOMESOURCETYPE,
        payload: item
    });
}

export const closeDialogIncomeSourceType = () => async (dispatch) => {
    dispatch(reset('formIncomeSourceType'));
    dispatch({
        type: CLOSE_DIALOG_INCOMESOURCETYPE,
        payload: undefined
    });
}