import React from "react";
import { DataGrid, GridFooter } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateJournal from './CreateJournal';
import EditJournal from './EditJournal';
import i18next from '../../language/i18n';
import { addDialogJournal, editDialogJournal, deleteJournal, copyJournal } from "../../actions/journalAction";
//import { getSelectBoxCompanies } from '../../actions/companyAction';
//import { getSelectBoxOrders } from '../../actions/orderAction';
import { getSelectBoxPlans } from '../../actions/planAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
//import { getSelectBoxDocumentTypes } from '../../actions/documentTypeAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
//import { getSelectBoxLoans } from '../../actions/loanAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import DeleteButton from '../Share/Button/DeleteButton';
import DateProvider from '../../share/dateProvider';
import { Box, Grid } from '@mui/material';


class TableJournal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      orderID: 0,
      dialogMessage: '',
      dialogAction: null
    }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    //this.props.getSelectBoxCompanies();
    //this.props.getSelectBoxOrders();
    this.props.getSelectBoxPlans();
    this.props.getSelectBoxPartners();
    //this.props.getSelectBoxDocumentTypes();
    this.props.getSelectBoxBranches();
    //this.props.getSelectBoxLoans();

    //this.props.getAllJournals();

  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  orderName = (props) => {
    return this.props.orders.find(el => el.value === props.row.orderID)?.text;
  }
  planName = (props) => {
    return this.props.plans.find(el => el.value === props.row.planID)?.text;
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  // documentTypeName = (props) => {
  //   return this.props.documentTypes.find(el => el.value === props.row.documentTypeID)?.text;
  // }
  branchName = (props) => {
    return this.props.branches.find(el => el.value === props.row.branchID)?.text;
  }
  loanName = (props) => {
    return this.props.loans.find(el => el.value === props.row.loanID)?.text;
  }
  columns = [
    {
      field: 'BranchID',
      headerName: i18next.t('Branch'),
      width: 160,
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },

    {
      field: 'PlanID',
      headerName: i18next.t('Plan'),
      minWidth: 250, flex: 2,
      valueGetter: this.planName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.planName(cellParams1).localeCompare(this.planName(cellParams2))
    },

    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      minWidth: 250, flex: 2,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },

    { field: 'description', headerName: i18next.t('Description'), minWidth: 250, flex: 2 },
    // {
    //   field: 'DocumentTypeID',
    //   headerName: i18next.t('DocumentType'),
    //   width: 160,
    //   valueGetter: this.documentTypeName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.documentTypeName(cellParams1).localeCompare(this.documentTypeName(cellParams2))
    // },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },
    { field: 'link', headerName: i18next.t('Link'), minWidth: 150, flex: 1 },
    { field: 'owes', headerName: i18next.t('Owes'), minWidth: 150, flex: 1, groupable: false },
    { field: 'claim', headerName: i18next.t('Claim'), minWidth: 150, flex: 1 },
    /*{ field: 'deadline', headerName: i18next.t('Deadline'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },
    
    { field: 'bRVD', headerName: i18next.t('BRVD'), minWidth: 150, flex: 1 },
    { field: 'locked', headerName: i18next.t('Locked'), minWidth: 150, flex: 1 },
    { field: 'dateDPO', headerName: i18next.t('DateDPO'), minWidth: 150, flex: 1 },
    
    
            {
                field: 'LoanID',
                headerName: i18next.t('Loan'),
                width: 160,
                valueGetter: this.loanName,
                sortComparator: (v1, v2, cellParams1, cellParams2) =>
                this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
              },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },
    */
    {
      field: 'copy',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => this.copy(params)}
          >
            {i18next.t('Copy')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogJournal();
  }
  copy = (params) => {
    this.setState({
      IsOpenConfirmDialog: true,
      ID: params.row.id,
      orderID: params.row.orderID,
      dialogMessage: 'Da li ste sigurni da želite da kopirate ovaj unos?',
      dialogAction: this.props.copyJournal,

    });
  }
  edit = (params) => {
    this.props.editDialogJournal(params.row);
  }
  delete = (params) => {
    this.setState({
      IsOpenConfirmDialog: true,
      ID: params.row.id,
      orderID: params.row.orderID,
      dialogMessage: 'Da li ste sigurni da želite da obrišete ovaj unos?',
      dialogAction: this.props.deleteJournal,

    });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.journals}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar,
            Footer: () => (
              <Box>
                <Box sx={{ display: 'flex', justifyContent: 'end', padding: '10px' }} style={{ fontWeight: 'bold' }}>
                  <div>{i18next.t('Owes')}: {this.props.journals.reduce((sum, current) => sum + current.owes, 0).toFixed(2)}</div>
                  <div style={{ paddingLeft: '20px' }}>{i18next.t('Claim')}: {this.props.journals.reduce((sum, current) => sum + current.claim, 0).toFixed(2)}</div>
                </Box>
                <GridFooter ></GridFooter>
              </Box>
            ),
          }}

          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateJournal year={this.props.year} number={this.props.number}></CreateJournal>
        <EditJournal></EditJournal>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.state.dialogAction({ id: this.state.ID, orderID: this.state.orderID });
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={this.state.dialogMessage}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    journals: Object.values(state.journals),
    companies: Object.values(state.companiesSelectBox),
    orders: Object.values(state.ordersSelectBox),
    plans: Object.values(state.plansSelectBox),
    partners: Object.values(state.partnersSelectBox),
    documentTypes: Object.values(state.documentTypesSelectBox),
    branches: Object.values(state.branchesSelectBox),
    loans: Object.values(state.loansSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogJournal,
    editDialogJournal,
    deleteJournal,
    copyJournal,
    //getSelectBoxCompanies,
    //getSelectBoxOrders,
    getSelectBoxPlans,
    getSelectBoxPartners,
    //getSelectBoxDocumentTypes,
    getSelectBoxBranches,
    //getSelectBoxLoans,
    showLoadingDataInProgress
  })(TableJournal);