import api from "../api/myApi";
import { 
    CREATE_PARTNERTYPE,
    GET_ALL_PARTNERTYPE,
    GET_PARTNERTYPE,
    GET_ALL_SELECTBOX_PARTNERTYPE,
    DELETE_PARTNERTYPE,
    UPDATE_PARTNERTYPE,
    ADD_DIALOG_PARTNERTYPE,
    EDIT_DIALOG_PARTNERTYPE,
    CLOSE_DIALOG_PARTNERTYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler, responseHandlerWithTranslate } from "../api/responseHandler";

// export const createPartnerType = (formValues) => async (dispatch) => {
//     const response = await api.post('/api/v1/PartnerType/Create', formValues);    
//     dispatch(responseHandler(response, CREATE_PARTNERTYPE));
//     if(response.data?.succeeded){
//         dispatch(reset('formPartnerType'));
//     }
// }

// export const getAllPartnerTypes = () => async (dispatch) => {
//     const response = await api.get('/api/v1/PartnerType/GetAll');
//     dispatch(responseHandler(response, GET_ALL_PARTNERTYPE));
// }

// export const getPartnerType = (id) => async (dispatch) => {
//     const response = await api.get('/api/v1/PartnerType/Get/' + id);
//     dispatch(responseHandler(response, GET_PARTNERTYPE));
// }

export const getSelectBoxPartnerTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PartnerType/GetSelectBox');
    dispatch(responseHandlerWithTranslate(response, GET_ALL_SELECTBOX_PARTNERTYPE));
}

// export const updatePartnerType = (formValues) => async (dispatch) => {
//     const response = await api.post('/api/v1/PartnerType/Update', formValues);
//     dispatch(responseHandler(response, UPDATE_PARTNERTYPE));
// }

// export const deletePartnerType = (id) => async (dispatch) => {
//     const response = await api.post('/api/v1/PartnerType/Delete/' + id, { ID: id });
//     dispatch(responseHandler(response, DELETE_PARTNERTYPE));
// }

// export const addDialogPartnerType = () => async (dispatch) => {
//     dispatch({
//         type: ADD_DIALOG_PARTNERTYPE,
//         payload: null
//     });
// }

// export const editDialogPartnerType = (item) => async (dispatch) => {
//     dispatch({
//         type: EDIT_DIALOG_PARTNERTYPE,
//         payload: item
//     });
// }

// export const closeDialogPartnerType = () => async (dispatch) => {
//     dispatch(reset('formPartnerType'));
//     dispatch({
//         type: CLOSE_DIALOG_PARTNERTYPE,
//         payload: undefined
//     });
// }