import { 
    CREATE_WORKER,
    GET_ALL_WORKER,
    GET_WORKER,
    DELETE_WORKER,
    UPDATE_WORKER
} from '../../actions/types';

const workerReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_WORKER:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_WORKER:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_WORKER:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_WORKER: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_WORKER:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default workerReducer;