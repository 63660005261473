import React from 'react';
import { connect } from 'react-redux';
import { closeDialogPartnerNote, updatePartnerNote } from '../../actions/partnerNoteAction';
import FormPartnerNote from './FormPartnerNote';
import history from "../../share/history";

class EditPartnerNote extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updatePartnerNote(formValues);
    }
    isPartnerLogic = () => {
        return history.location.pathname === '/PartnerDetails';
    }
    
    render(){
        return (
            <FormPartnerNote 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit"
                showPartner={!this.isPartnerLogic()}>
            </FormPartnerNote>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PartnerNoteDialog === undefined || state.dialog.PartnerNoteDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.PartnerNoteDialog};
}


export default connect(mapStateToProps, { closeDialogPartnerNote, updatePartnerNote })(EditPartnerNote);
