import React from "react";
import { connect } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import history from "../../share/history";
import { getLoansBySearch } from '../../actions/loanAction'
import {
    Avatar,
    Badge,
    Button,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    Paper,
    Popper,
    TextField,
    Autocomplete,
    InputLabel,
    InputBase
} from '@mui/material';
import Transitions from './Transitions'
import MainCard from '../thema/MainCard';
import CloseIcon from '@mui/icons-material/Close';
import i18next from '../../language/i18n';

const SearchArea = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    flexShrink: 0,
    ml: 0.75,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false, search: '' }
        this.anchorRef = React.createRef();
        //this.matchesXs = useMediaQuery(theme.breakpoints.down('md'));
        this.iconBackColorOpen = 'grey.300';
        this.iconBackColor = 'grey.100';
    }
    componentDidMount = () => {
        this.props.getLoansBySearch('');
    }
    avatarSX = {
        width: 36,
        height: 36,
        fontSize: '1rem'
    };

    actionSX = {
        mt: '6px',
        ml: 1,
        top: 'auto',
        right: 'auto',
        alignSelf: 'flex-start',

        transform: 'none'
    };

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ isOpen: false });
    };
    handleToggle = () => {
        this.setState({ isOpen: true });
    };
    getData = () => {
        let result = this.props.loansBySearch ?? [];
        let defaultLength = 20;
        if (this.state.search === null || this.state.search === undefined || this.state.search.length === 0) {
            return result.slice(0, defaultLength);
        }
        let filters = this.state.search.toLowerCase().split(" ");

        for (let i = 0; i < filters.length; i++) {
            if (filters[i] != "") {

                result = result.filter(el => el.branchName.toLowerCase().indexOf(filters[i]) != -1 ||
                    (el.loanNumber != null && el.loanNumber?.toLowerCase().indexOf(filters[i]) != -1) ||
                    el.partnerName.toLowerCase().indexOf(filters[i]) != -1);
            }
        }
        return result.slice(0, defaultLength);
    }

    openLoan = (id) => {
        history.push("/Loan/" + id);
        this.setState({ isOpen: false });
    }
    openPartner = (id) => {
        history.push("/PartnerDetails/" + id);
        this.setState({ isOpen: false });        
    }
    render() {
        return (
            <SearchArea >
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    ref={this.anchorRef}
                    placeholder={i18next.t('Search')}
                    inputProps={{ 'aria-label': 'search' }}
                    // onFocus={this.handleToggle}
                    onClick={this.handleToggle}
                    onChange={(e) => { this.setState({ search: e.target.value }); }}
                /><Popper
                    // placement={this.matchesXs ? 'bottom' : 'bottom-end'}
                    open={this.state.isOpen}
                    anchorEl={this.anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    // offset: [this.matchesXs ? -5 : 0, 9]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={this.state.isOpen} {...TransitionProps}>
                            <Paper
                                sx={{
                                    // boxShadow: theme.customShadows.z1,
                                    width: '100%',
                                    minWidth: 750,
                                    maxWidth: 900,
                                    // [theme.breakpoints.down('md')]: {
                                    //     maxWidth: 285
                                    // }
                                }}
                            >
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MainCard
                                        title={i18next.t('SearchResult')}
                                        elevation={0}
                                        border={false}
                                        content={false}
                                        secondary={
                                            <IconButton size="small" onClick={this.handleClose}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    >
                                        <List
                                            component="nav"
                                            sx={{
                                                p: 0,
                                                '& .MuiListItemButton-root': {
                                                    py: 0.5,
                                                    '& .MuiAvatar-root': this.avatarSX,
                                                    '& .MuiListItemSecondaryAction-root': { ...this.actionSX, position: 'relative' }
                                                }
                                            }}
                                        >
                                            {this.getData()?.map(el => (
                                                <>
                                                    <Divider />
                                                    <Box component="li" sx={{
                                                        padding: '3px 15px'
                                                    }}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: 16 }}
                                                            onClick={() => this.openLoan(el.loanID)}
                                                        >
                                                            {i18next.t('Loan')}
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginRight: 16 }}
                                                            onClick={() => this.openPartner(el.partnerID)}
                                                        >
                                                            {i18next.t('Partner')}
                                                        </Button>
                                                        {el.loanNumber} - {el.partnerName} ({el.branchName})


                                                    </Box>
                                                </>))}
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </SearchArea>
        );
    };
}
const mapStateToProps = state => {
    return {
        loansBySearch: Object.values(state.loansBySearch)
    };
}


export default connect(mapStateToProps, { getLoansBySearch })(Search);