import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton,  GridToolbarDensitySelector } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@mui/icons-material/Print';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import i18next from '../../language/i18n';

class TableSMSToolbar extends React.Component{

//<GridToolbarExport />
    render(){
        return(
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={this.props.create}>
                    {i18next.t('CreateAWarning')}
                </Button>
                <Button color="primary" startIcon={<FileDownloadIcon />} onClick={this.props.csv}>
                    {i18next.t('CSV')}
                </Button>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }
}

export default TableSMSToolbar;