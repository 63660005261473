class Validation {
    static isSelected = (value) => {
        return this.hasValue(value);
    }
    static hasValue = (value) => {
        return value !== null && value !== undefined;
    }
    static hasNonNegativeValue(value){
        return value !== null && value !== undefined && value >= 0;
    }
  }
  export default Validation;