import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
//import Button from '@mui/material/Button';
import TableSMSToolbar from './TableSMSToolbar';
//import ConfirmDialog from '../Share/ConfirmDialog';
//import CreateSMS from './CreateSMS';
//import EditSMS from './EditSMS';
import i18next from '../../language/i18n';
import { getAllSMS, createSMS, exportSMSToCSV } from "../../actions/sMSAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import ComponentInLine from '../Share/ComponentInLine';
import { EditDatePickerField, EditIntegerNumberField, EditDecimalNumberField } from '../../components/Share/EditComponents';
import { Field, reduxForm, getFormValues } from "redux-form";
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableSMS extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ID: null
    }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getAllSMS(new Date());
  }

  columns = [
    { field: 'warningDate', headerName: i18next.t('WarningDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'debtAmount', headerName: i18next.t('DebtAmount'), minWidth: 150, flex: 1 },
    { field: 'dospjelaRata', headerName: i18next.t('DospjelaRata'), minWidth: 150, flex: 1 },
    { field: 'isPrinted', headerName: i18next.t('IsPrinted'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'firstName', headerName: i18next.t('FirstName'), minWidth: 150, flex: 1 },
    { field: 'lastName', headerName: i18next.t('LastName'), minWidth: 150, flex: 1 },
    { field: 'phoneNumber', headerName: i18next.t('PhoneNumber'), minWidth: 150, flex: 1 },
    { field: 'description', headerName: i18next.t('Description'), minWidth: 150, flex: 1 },
    //{ field: 'journalPageNumber', headerName: i18next.t('JournalPageNumber'), minWidth: 150, flex: 1 },

    /*{
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>                
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>                
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton> 
        </strong>
      )
    }*/
  ];
  create = () => {
    this.props.createSMS(this.props.formValues);
  }
  csv = () => {
    this.props.exportSMSToCSV({ date: this.props.formValues.date });
  }
  onSubmit = () => {

  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <form onSubmit={this.onSubmit}>
          <ComponentInLine>
            <div>
              <Field
                name='date'
                component={this.props.EditDatePickerField}
                label='Date'
                onChange={(e) => {
                  this.props.showLoadingDataInProgress();
                  this.props.getAllSMS(e);
                }}
              />
            </div>
            <div>
              <Field
                name='daysOfDelay'
                component={this.props.EditIntegerNumberField}
                label='DaysOfDelay'
              />
            </div>
            <div>
              <Field
                name='greaterThen'
                component={this.props.EditDecimalNumberField}
                label='GreaterThen'
              />
            </div>
          </ComponentInLine>
        </form>
        <DataGrid
          rows={this.props.sMS}
          columns={this.columns}
          components={{
            Toolbar: TableSMSToolbar
          }}
          componentsProps={{
            toolbar:
            {
              create: this.create,
              csv: this.csv
            }
          }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    sMS: Object.values(state.sMS),
    formValues: getFormValues('smsSearch')(state)
  };
}
const formWrapper = reduxForm({
  form: 'smsSearch',
  //validate: validate,
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues: {
    date: new Date(),
    daysOfDelay: 1,
    greaterThen: 20.0
  }
})(TableSMS);

export default connect(mapStateToProps, {
  EditDatePickerField,
  EditIntegerNumberField,
  EditDecimalNumberField,
  getAllSMS,
  createSMS,
  exportSMSToCSV,
  showLoadingDataInProgress
})(formWrapper);