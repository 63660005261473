import api from "../api/myApi";
import { 
    CREATE_VEHICLE,
    GET_ALL_VEHICLE,
    GET_VEHICLE,
    GET_ALL_SELECTBOX_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    ADD_DIALOG_VEHICLE,
    EDIT_DIALOG_VEHICLE,
    CLOSE_DIALOG_VEHICLE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createVehicle = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Vehicle/Create', formValues);    
    dispatch(responseHandler(response, CREATE_VEHICLE));
    if(response.data?.succeeded){
        dispatch(reset('formVehicle'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllVehicles = () => async (dispatch) => {
    const response = await api.get('/api/v1/Vehicle/GetAll');
    dispatch(responseHandler(response, GET_ALL_VEHICLE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getVehicle = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Vehicle/Get/' + id);
    dispatch(responseHandler(response, GET_VEHICLE));
}

export const getSelectBoxVehicles = () => async (dispatch) => {
    const response = await api.get('/api/v1/Vehicle/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_VEHICLE));
}

export const updateVehicle = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Vehicle/Update', formValues);
    dispatch(responseHandler(response, UPDATE_VEHICLE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteVehicle = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Vehicle/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_VEHICLE));
}

export const addDialogVehicle = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_VEHICLE,
        payload: null
    });
}

export const editDialogVehicle = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_VEHICLE,
        payload: item
    });
}

export const closeDialogVehicle = () => async (dispatch) => {
    dispatch(reset('formVehicle'));
    dispatch({
        type: CLOSE_DIALOG_VEHICLE,
        payload: undefined
    });
}