//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogEarlyRepaymentSettings, updateEarlyRepaymentSettings } from '../../actions/earlyRepaymentSettingsAction';
import FormEarlyRepaymentSettings from './FormEarlyRepaymentSettings';

class EditEarlyRepaymentSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateEarlyRepaymentSettings(formValues);
    }
    
    render(){
        return (
            <FormEarlyRepaymentSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormEarlyRepaymentSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.EarlyRepaymentSettingsDialog === undefined || state.dialog.EarlyRepaymentSettingsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.EarlyRepaymentSettingsDialog};
}


export default connect(mapStateToProps, { closeDialogEarlyRepaymentSettings, updateEarlyRepaymentSettings })(EditEarlyRepaymentSettings);
