//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogBoardMember, updateBoardMember } from '../../actions/boardMemberAction';
import FormBoardMember from './FormBoardMember';

class EditBoardMember extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBoardMember(formValues);
    }
    
    render(){
        return (
            <FormBoardMember 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormBoardMember>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BoardMemberDialog === undefined || state.dialog.BoardMemberDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BoardMemberDialog};
}


export default connect(mapStateToProps, { closeDialogBoardMember, updateBoardMember })(EditBoardMember);
