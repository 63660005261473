import api from "../api/myApi";
import {     
    GET_ALL_SELECTBOX_PENALTYINTERESTPERIOD
} from './types';
import { responseHandler } from "../api/responseHandler";


export const getSelectBoxInterestPeriod = () => async (dispatch) => {
    const response = await api.get('/api/v1/PenaltyInterestPeriod/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PENALTYINTERESTPERIOD));
}
