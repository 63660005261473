import api from "../api/myApi";
import { 
    CREATE_JOURNAL,
    //GET_ALL_JOURNAL,
    GET_ALL_JOURNAL_BY_ORDER,
    GET_JOURNAL,
    GET_ALL_SELECTBOX_JOURNAL,
    DELETE_JOURNAL,
    UPDATE_JOURNAL,
    ADD_DIALOG_JOURNAL,
    EDIT_DIALOG_JOURNAL,
    CLOSE_DIALOG_JOURNAL,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS,
    SHOW_LOADING_DATA_PROGRESS,
    GET_POSTING_ACCOUNT_CONNECTIONS,
    GET_MYCOMPANY_DATA_YEAR
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createJournal = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Journal/Create', formValues);    
    dispatch(responseHandler(response, CREATE_JOURNAL));
    if(response.data?.succeeded){
        dispatch(reset('formJournal'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}
/*
export const getAllJournals = () => async (dispatch) => {
    const response = await api.get('/api/v1/Journal/GetAll');
    dispatch(responseHandler(response, GET_ALL_JOURNAL));
}
*/
export const getAllJournalsByOrder = (data) => async (dispatch) => {
    dispatch({type: SHOW_LOADING_DATA_PROGRESS});
    const response = await api.post('/api/v1/Journal/GetAllJournalsByOrder', data);
    dispatch(responseHandler(response, GET_ALL_JOURNAL_BY_ORDER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}


export const getJournal = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Journal/Get/' + id);
    dispatch(responseHandler(response, GET_JOURNAL));
}

export const getSelectBoxJournals = () => async (dispatch) => {
    const response = await api.get('/api/v1/Journal/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_JOURNAL));
}

export const updateJournal = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Journal/Update', formValues);
    dispatch(responseHandler(response, UPDATE_JOURNAL));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteJournal = (data) => async (dispatch) => {
    const response = await api.post('/api/v1/Journal/Delete/', data);
    dispatch(responseHandler(response, DELETE_JOURNAL));
}

export const copyJournal = (data) => async (dispatch) => {
    const response = await api.post('/api/v1/Journal/Copy/', data);
    dispatch(responseHandler(response, CREATE_JOURNAL));
}

export const addDialogJournal = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_JOURNAL,
        payload: null
    });
}

export const editDialogJournal = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_JOURNAL,
        payload: item
    });
}

export const closeDialogJournal = () => async (dispatch) => {
    dispatch(reset('formJournal'));
    dispatch({
        type: CLOSE_DIALOG_JOURNAL,
        payload: undefined
    });
}

export const getPostingAccountConnections = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_LOADING_DATA_PROGRESS});
    const response = await api.post('/api/v1/Journal/GetPostingAccountConnections', formValues);
    dispatch(responseHandler(response, GET_POSTING_ACCOUNT_CONNECTIONS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const yearLock = () => async (dispatch) => {
    dispatch({type: SHOW_LOADING_DATA_PROGRESS});
    const response = await api.post('/api/v1/Journal/YearLock', {});
    dispatch(responseHandler(response, 'success'));
    dispatch(responseHandler(response, GET_MYCOMPANY_DATA_YEAR));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}


