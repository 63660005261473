import React from 'react';
import { connect } from 'react-redux';
import { createCashBalance } from '../../actions/cashBalanceAction';
import FormCashBalance from './FormCashBalance';

class CreateCashBalance extends React.Component{
    onSubmit  = (formValues) => {
        formValues.cashierID = this.props.cashierID;
        this.props.createCashBalance(formValues);
    }
    
    render(){
        return (
            <FormCashBalance 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create"
                >
            </FormCashBalance>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CashBalanceDialog === undefined || state.dialog.CashBalanceDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CashBalanceDialog};
}


export default connect(mapStateToProps, { createCashBalance })(CreateCashBalance);
