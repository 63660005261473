import React from 'react';
import { connect } from 'react-redux';
import { createLegalService_Court } from '../../actions/legalService_CourtAction';
import FormLegalService_Court from './FormLegalService_Court';
import i18next from '../../language/i18n';

class CreateLegalService_Court extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createLegalService_Court(formValues);
    }
    
    render(){
        return (
            <FormLegalService_Court 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormLegalService_Court>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LegalService_CourtDialog === undefined || state.dialog.LegalService_CourtDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.LegalService_CourtDialog};
}


export default connect(mapStateToProps, { createLegalService_Court })(CreateLegalService_Court);
