import api from "../api/myApi";
import { 
    CREATE_CITY,
    GET_ALL_CITY,
    GET_CITY,
    GET_ALL_SELECTBOX_CITY,
    DELETE_CITY,
    UPDATE_CITY,
    ADD_DIALOG_CITY,
    EDIT_DIALOG_CITY,
    CLOSE_DIALOG_CITY,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCity = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/City/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CITY));
    if(response.data?.succeeded){
        dispatch(reset('formCity'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllCities = () => async (dispatch) => {
    const response = await api.get('/api/v1/City/GetAll');
    dispatch(responseHandler(response, GET_ALL_CITY));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getCity = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/City/Get/' + id);
    dispatch(responseHandler(response, GET_CITY));
}

export const getSelectBoxCities = () => async (dispatch) => {
    const response = await api.get('/api/v1/City/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CITY));
}

export const updateCity = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/City/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CITY));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteCity = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/City/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CITY));
}

export const addDialogCity = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CITY,
        payload: null
    });
}

export const editDialogCity = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CITY,
        payload: item
    });
}

export const closeDialogCity = () => async (dispatch) => {
    dispatch(reset('formCity'));
    dispatch({
        type: CLOSE_DIALOG_CITY,
        payload: undefined
    });
}