import api from "../api/myApi";
import { 
    CREATE_LOANREPAYMENTSCHEDULETYPE,
    GET_ALL_LOANREPAYMENTSCHEDULETYPE,
    GET_LOANREPAYMENTSCHEDULETYPE,
    GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULETYPE,
    DELETE_LOANREPAYMENTSCHEDULETYPE,
    UPDATE_LOANREPAYMENTSCHEDULETYPE,
    ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE,
    EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE,
    CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanRepaymentScheduleType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanRepaymentScheduleType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANREPAYMENTSCHEDULETYPE));
    if(response.data?.succeeded){
        dispatch(reset('formLoanRepaymentScheduleType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLoanRepaymentScheduleTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanRepaymentScheduleType/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANREPAYMENTSCHEDULETYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLoanRepaymentScheduleType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanRepaymentScheduleType/Get/' + id);
    dispatch(responseHandler(response, GET_LOANREPAYMENTSCHEDULETYPE));
}

export const getSelectBoxLoanRepaymentScheduleTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanRepaymentScheduleType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULETYPE));
}

export const updateLoanRepaymentScheduleType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanRepaymentScheduleType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANREPAYMENTSCHEDULETYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoanRepaymentScheduleType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanRepaymentScheduleType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANREPAYMENTSCHEDULETYPE));
}

export const addDialogLoanRepaymentScheduleType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE,
        payload: null
    });
}

export const editDialogLoanRepaymentScheduleType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE,
        payload: item
    });
}

export const closeDialogLoanRepaymentScheduleType = () => async (dispatch) => {
    dispatch(reset('formLoanRepaymentScheduleType'));
    dispatch({
        type: CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE,
        payload: undefined
    });
}