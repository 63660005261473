import api from "../api/myApi";
import { 
    CREATE_DEBTCOLLECTIONCOST,
    GET_ALL_DEBTCOLLECTIONCOST,
    GET_DEBTCOLLECTIONCOST,
    GET_ALL_SELECTBOX_DEBTCOLLECTIONCOST,
    DELETE_DEBTCOLLECTIONCOST,
    UPDATE_DEBTCOLLECTIONCOST,
    ADD_DIALOG_DEBTCOLLECTIONCOST,
    EDIT_DIALOG_DEBTCOLLECTIONCOST,
    CLOSE_DIALOG_DEBTCOLLECTIONCOST,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createDebtCollectionCost = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DebtCollectionCost/Create', formValues);    
    dispatch(responseHandler(response, CREATE_DEBTCOLLECTIONCOST));
    if(response.data?.succeeded){
        dispatch(reset('formDebtCollectionCost'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllDebtCollectionCosts = () => async (dispatch) => {
    const response = await api.get('/api/v1/DebtCollectionCost/GetAll');
    dispatch(responseHandler(response, GET_ALL_DEBTCOLLECTIONCOST));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getDebtCollectionCost = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/DebtCollectionCost/Get/' + id);
    dispatch(responseHandler(response, GET_DEBTCOLLECTIONCOST));
}

export const getSelectBoxDebtCollectionCosts = () => async (dispatch) => {
    const response = await api.get('/api/v1/DebtCollectionCost/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_DEBTCOLLECTIONCOST));
}

export const updateDebtCollectionCost = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DebtCollectionCost/Update', formValues);
    dispatch(responseHandler(response, UPDATE_DEBTCOLLECTIONCOST));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteDebtCollectionCost = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/DebtCollectionCost/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_DEBTCOLLECTIONCOST));
}

export const addDialogDebtCollectionCost = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_DEBTCOLLECTIONCOST,
        payload: null
    });
}

export const editDialogDebtCollectionCost = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_DEBTCOLLECTIONCOST,
        payload: item
    });
}

export const closeDialogDebtCollectionCost = () => async (dispatch) => {
    dispatch(reset('formDebtCollectionCost'));
    dispatch({
        type: CLOSE_DIALOG_DEBTCOLLECTIONCOST,
        payload: undefined
    });
}