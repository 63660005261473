import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ComponentInLine from '../Share/ComponentInLine';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogVehicle } from '../../actions/vehicleAction';
import { EditSelectField, EditTextField, EditIntegerNumberField, EditDecimalNumberField, EditDatePickerField, EditCheckbox } from '../Share/EditComponents';

class FormVehicle extends React.Component{
    handleClose = () => {
        this.props.closeDialogVehicle();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}> 
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='partnerID'
                                        component ={this.props.EditSelectField}
                                        label = 'Partner'
                                        dataSource={this.props.partnersSelectBox?.filter(el => this.props.loanPartners.filter(el => el.id > 0).map(m => parseInt(m.partnerID)).indexOf(el.value) != -1)}
                                        >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='description'
                                        component={this.props.EditTextField}
                                        label='Description'
                                    />
                                </div>
                            </ComponentInLine>
                            
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='brand'
                                        component={this.props.EditTextField}
                                        label='Brand'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='vehicleModel'
                                        component={this.props.EditTextField}
                                        label='VehicleModel'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='typeOfVehicle'
                                        component={this.props.EditTextField}
                                        label='TypeOfVehicle'
                                    />
                                </div>   
                            </ComponentInLine>

                            
                            {/* <ComponentInLine>
                                <div>
                                    <Field
                                        name='volume'
                                        component={this.props.EditIntegerNumberField}
                                        label='Volume'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='powerKW'
                                        component={this.props.EditIntegerNumberField}
                                        label='PowerKW'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='carryingCapacityKg'
                                        component={this.props.EditIntegerNumberField}
                                        label='CarryingCapacityKg'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='allowedMassKg'
                                        component={this.props.EditIntegerNumberField}
                                        label='AllowedMassKg'
                                    />
                                </div>
                            </ComponentInLine>

                            
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='registrationNumber'
                                        component={this.props.EditTextField}
                                        label='RegistrationNumber'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='registrationExpiresOn'
                                        component={this.props.EditDatePickerField}
                                        label='RegistrationExpiresOn'
                                    />
                                </div>  
                            </ComponentInLine> */}
                            
                            
                            <ComponentInLine>                                                           
                                <div>
                                    <Field
                                        name='chassisNumber'
                                        component={this.props.EditTextField}
                                        label='ChassisNumber'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='estimatedValue'
                                        component={this.props.EditDecimalNumberField}
                                        label='EstimatedValue'
                                    />
                                </div>                            
                                <div>
                                    <Field
                                        name='titleCertificateNo'
                                        component={this.props.EditTextField}
                                        label='TitleCertificateNo'
                                    />
                                </div>
                            </ComponentInLine>
                            
                            
                            <div>
                                <Field
                                    name='valid'
                                    component={this.props.EditCheckbox}
                                    label='Valid'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.partnerID){
        errors.partnerID = 'Polje je obavezno!';
    }
    if(!formValues.description){
        errors.description = 'Polje je obavezno!';
    }
    if(!formValues.brand){
        errors.brand = 'Polje je obavezno!';
    }
    if(!formValues.vehicleModel){
        errors.vehicleModel = 'Polje je obavezno!';
    }
    if(!formValues.chassisNumber){
        errors.chassisNumber = 'Polje je obavezno!';
    }
    if(!formValues.typeOfVehicle){
        errors.typeOfVehicle = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        loansSelectBox: Object.values(state.loansSelectBox), 
        partnersSelectBox: Object.values(state.partnersSelectBox),  
        loanPartners: Object.values(state.loanPartners)
      }
}
const formWrapper = reduxForm({
    form: 'formVehicle',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormVehicle);

export default connect(mapStateToProps, { closeDialogVehicle, EditSelectField, EditTextField, EditIntegerNumberField, EditDecimalNumberField, EditDatePickerField, EditCheckbox})(formWrapper);