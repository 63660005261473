import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import i18next from '../../language/i18n';
import DateProvider from '../../share/dateProvider';
import FormLegalService_Iskup from './FormLegalService_Iskup';
import { updateLegalService_Iskup, getAllLegalService_Iskup, createLegalService_Execution } from "../../actions/legalService_WarningAction";
import { getSelectBoxLegalService_Courts } from "../../actions/legalService_CourtAction";


class TableLegalService_Iskup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, IsOpenFormDialog: false, item: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxLegalService_Courts();
    this.props.getAllLegalService_Iskup();
  }
  columns = [ 
    {
      field: 'loanNumber',
      headerName: i18next.t('LoanNumber'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link href={"#/Loan/" + params.row.loanID}
          >
            {params.row.loanNumber ?? i18next.t('Loan')}
          </Link>
        </strong>
      ),
    },
    { field: 'referenceNumber', headerName: i18next.t('ReferenceNumber2'), minWidth: 150 },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 2 },
    { field: 'latePaymentDays', headerName: i18next.t('LatePaymentDays'), minWidth: 50 },
    { field: 'amountOfDebt', headerName: i18next.t('Amount'), minWidth: 50 },
    { field: 'iskupDate', headerName: i18next.t('IskupDate'), minWidth: 50, flex: 1, type:'date', valueGetter: DateProvider.GetDate },
    { field: 'iskupReceivingDate', headerName: i18next.t('IskupReceivingDate'), minWidth: 50, flex: 1, type:'date', valueGetter: DateProvider.GetDate },
    {
      field: 'edit',
      headerName: ' ',
      minWidth: 100,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Receiving')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'execution',
      headerName: ' ',
      minWidth: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => {this.setState({ IsOpenConfirmDialog: true, item: params.row });}}
          >
            {i18next.t('Execution')}
          </Button>
        </strong>
      ),
    }
  ];
  onSubmit = (formValues) => {
    formValues.id = this.state.item.id;
    this.props.updateLegalService_Iskup(formValues);
    this.onEditClose();
  }
  edit = (params) => {
    this.setState({IsOpenFormDialog : true, item: params.row});
  }
  onEditClose = () => {
    this.setState({IsOpenFormDialog : false, item: null});
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.legalService_Iskups}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
        <FormLegalService_Iskup
          IsOpen={this.state.IsOpenFormDialog}
          onSubmit={this.onSubmit}
          onClose={this.onEditClose}
          initialValues={this.props.item}
        ></FormLegalService_Iskup> 
        
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, item: null })}
          confirm={() => {
            this.props.createLegalService_Execution({id: this.state.item.id});
            this.setState({ IsOpenConfirmDialog: false, item: null });
          }}
          message={'Da li ste sigurni da želite opomenu proslijediti na izvršenje?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    legalService_Iskups: Object.values(state.legalService_Iskups)
  };
}

export default connect(mapStateToProps, {
  updateLegalService_Iskup,
  getAllLegalService_Iskup,
  createLegalService_Execution,
  getSelectBoxLegalService_Courts
})(TableLegalService_Iskup);