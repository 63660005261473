import { 
    CREATE_DOCUMENT,
    GET_ALL_DOCUMENT,
    GET_DOCUMENT,
    DELETE_DOCUMENT,
    UPDATE_DOCUMENT
} from '../../actions/types';

const documentReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_DOCUMENT:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_DOCUMENT:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_DOCUMENT:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_DOCUMENT: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_DOCUMENT:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default documentReducer;