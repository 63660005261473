import { 
    CREATE_LOANHISTORY,
    GET_ALL_LOANHISTORY,
    GET_LOANHISTORY,
    DELETE_LOANHISTORY,
    UPDATE_LOANHISTORY
} from '../../actions/types';

const loanHistoryReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANHISTORY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANHISTORY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanHistoryReducer;