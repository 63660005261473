//Mojo-AG
import { 
    CREATE_FILEUPLOADTYPE,
    GET_ALL_FILEUPLOADTYPE,
    GET_FILEUPLOADTYPE,
    DELETE_FILEUPLOADTYPE,
    UPDATE_FILEUPLOADTYPE
} from '../../actions/types';

const fileUploadTypeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_FILEUPLOADTYPE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_FILEUPLOADTYPE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_FILEUPLOADTYPE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_FILEUPLOADTYPE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_FILEUPLOADTYPE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default fileUploadTypeReducer;