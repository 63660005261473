import { 
    GET_LOANS_BY_PARTNER
} from '../../actions/types';

 const loansByPartnerReducer = (state = {}, action) => {
    switch(action.type){
        case GET_LOANS_BY_PARTNER:
            return [...action.payload];
        default:
            return state;
    }
}
export default loansByPartnerReducer;