import React from 'react';
import { connect } from 'react-redux';
import { createLoan } from '../../actions/loanAction';
import { clearLoanPartners } from '../../actions/loanPartnerAction';
import FormLoan from './FormLoan';

class CreateLoan extends React.Component{
    componentDidMount = () => {
        this.props.clearLoanPartners();
    } 
    onSubmit  = (formValues) => {
        formValues.parnters = this.props.loanPartners;
        this.props.createLoan(formValues);
    }
    initialValues = () =>  {
        return {
            loanStatusID: 0,
            requestedAmount: 0,
            loanTermMonths: 0,
            totalMonthRepayment: 0,
            approvalCost: 0,
            costOfTheBill: 0,
            lifeInsuranceAmount: 0,
            costOfVerification: 0,
            theCostOfNotarHypothec: 0,
            insurancePolicyAmount: 0,
            interestRate: 0,
            effectiveInterestRate: 0,
            penaltyRate: 0,
            gracePeriodMonths: 0,
            riskLevelID: 0,
            employeeUserID: this.props.userID            
        };
    }
    render(){
        return (
            <FormLoan 
                onSubmit={this.onSubmit}
                initialValues={this.initialValues()}
                title="Create"
                isNew={true}>
            </FormLoan>
        );
    }
}

const mapStateToProps = state => {
    return{        
        loanPartners: Object.values(state.loanPartners),
        userID: state.authUser?.userID
    };
  //return state.dialog.LoanDialog === undefined || state.dialog.LoanDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.LoanDialog};
}


export default connect(mapStateToProps, { createLoan, clearLoanPartners })(CreateLoan);
