//Mojo-AG
import { 
    CREATE_BOARDMEMBER,
    GET_ALL_BOARDMEMBER,
    GET_BOARDMEMBER,
    DELETE_BOARDMEMBER,
    UPDATE_BOARDMEMBER
} from '../../actions/types';

const boardMemberReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_BOARDMEMBER:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_BOARDMEMBER:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_BOARDMEMBER:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_BOARDMEMBER: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_BOARDMEMBER:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default boardMemberReducer;