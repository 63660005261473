//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createCommissionSettings } from '../../actions/commissionSettingsAction';
import FormCommissionSettings from './FormCommissionSettings';

class CreateCommissionSettings extends React.Component{
    onSubmit  = (formValues) => {
        formValues.partnerID = this.props.partnerSelected.partnerData.id;
        this.props.createCommissionSettings(formValues);
    }
    
    render(){
        return (
            <FormCommissionSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormCommissionSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CommissionSettingsDialog === undefined || state.dialog.CommissionSettingsDialog.type !== 'add'? 
  {
    IsOpen:false,
    partnerSelected: state.partnerSelected
} : {
    ...state.dialog.CommissionSettingsDialog,
    partnerSelected: state.partnerSelected
};
}


export default connect(mapStateToProps, { createCommissionSettings })(CreateCommissionSettings);
