import api from "../api/myApi";
import { 
    CREATE_REALESTATES,
    GET_ALL_REALESTATES,
    GET_REALESTATES,
    GET_ALL_SELECTBOX_REALESTATES,
    DELETE_REALESTATES,
    UPDATE_REALESTATES,
    ADD_DIALOG_REALESTATES,
    EDIT_DIALOG_REALESTATES,
    CLOSE_DIALOG_REALESTATES,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createRealEstates = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/RealEstates/Create', formValues);    
    dispatch(responseHandler(response, CREATE_REALESTATES));
    if(response.data?.succeeded){
        dispatch(reset('formRealEstates'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllRealEstates = () => async (dispatch) => {
    const response = await api.get('/api/v1/RealEstates/GetAll');
    dispatch(responseHandler(response, GET_ALL_REALESTATES));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getRealEstates = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/RealEstates/Get/' + id);
    dispatch(responseHandler(response, GET_REALESTATES));
}

export const getSelectBoxRealEstates = () => async (dispatch) => {
    const response = await api.get('/api/v1/RealEstates/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_REALESTATES));
}

export const updateRealEstates = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/RealEstates/Update', formValues);
    dispatch(responseHandler(response, UPDATE_REALESTATES));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteRealEstates = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/RealEstates/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_REALESTATES));
}

export const addDialogRealEstates = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_REALESTATES,
        payload: null
    });
}

export const editDialogRealEstates = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_REALESTATES,
        payload: item
    });
}

export const closeDialogRealEstates = () => async (dispatch) => {
    dispatch(reset('formRealEstates'));
    dispatch({
        type: CLOSE_DIALOG_REALESTATES,
        payload: undefined
    });
}