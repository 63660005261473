//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TableLoanApprovalToolbar from './TableLoanApprovalToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateLoanApproval from './CreateLoanApproval';
import EditLoanApproval from './EditLoanApproval';
import i18next from '../../language/i18n';
import { addDialogLoanApproval, editDialogLoanApproval, deleteLoanApproval, getAllLoanApprovals } from "../../actions/loanApprovalAction";
import { getSelectBoxBoardMembers } from '../../actions/boardMemberAction';
import DeleteButton from '../Share/Button/DeleteButton';


class TableLoanApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxBoardMembers();
  }

  // loanName = (props) => {
  //   return this.props.loans.find(el => el.value == props.row.loanID)?.text;
  // }
  boardMemberName = (props) => {
    return this.props.boardMembers.find(el => el.value == props.row.boardMemberID)?.text;
  }
  columns = [
    {
      field: 'BoardMemberID',
      headerName: i18next.t('BoardMember'),
      minWidth: 160,
      flex:1,
      valueGetter: this.boardMemberName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.boardMemberName(cellParams1).localeCompare(this.boardMemberName(cellParams2))
    },
    //{ field: 'note', headerName: 'Note', width: 150 },
    { field: 'amount', headerName: i18next.t('Amount'), width: 150 },
    { field: 'loanTermMonths', headerName: i18next.t('LoanTermMonths'), width: 150 },
    { field: 'interestRate', headerName: i18next.t('InterestRate'), width: 150 },
    { field: 'approvalCost', headerName: i18next.t('ApprovalCost'), width: 150 },
    //{ field: 'createDate', headerName: 'CreateDate', width: 150 },
    //{ field: 'editDate', headerName: 'EditDate', width: 150 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
            disabled={params.row.createUserID !== this.props.userID}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',      
      renderCell: (params) => (
        <strong>
          <DeleteButton 
          onClick={() => this.delete(params)}  
          disabled={params.row.createUserID !== this.props.userID}
          />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogLoanApproval();
  }
  edit = (params) => {
    let item = params.row;
    item.approvalCostPercent = (item.approvalCost / item.amount * 100).toFixed(2);
    this.props.editDialogLoanApproval(item);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={this.props.loanApprovals}
          columns={this.columns}
          components={{
            Toolbar: TableLoanApprovalToolbar
          }}
          componentsProps={{ toolbar: { approveNewConditions: this.create, approve: this.props.approve } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateLoanApproval loanID={this.props.loanID}></CreateLoanApproval>
        <EditLoanApproval loanID={this.props.loanID}></EditLoanApproval>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLoanApproval(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { 
    loanApprovals: Object.values(state.loanApprovals), 
    loans: Object.values(state.loansSelectBox), 
    boardMembers: Object.values(state.boardMembersSelectBox),
    userID: state.authUser?.userID
  };
}

export default connect(mapStateToProps, {
  addDialogLoanApproval,
  editDialogLoanApproval,
  deleteLoanApproval,
  getAllLoanApprovals,
  getSelectBoxBoardMembers
})(TableLoanApproval);