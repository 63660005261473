import api from "../api/myApi";
import { 
    CREATE_BANK,
    GET_ALL_BANK,
    GET_BANK,
    GET_ALL_SELECTBOX_BANK,
    DELETE_BANK,
    UPDATE_BANK,
    ADD_DIALOG_BANK,
    EDIT_DIALOG_BANK,
    CLOSE_DIALOG_BANK,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBank = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Bank/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BANK));
    if(response.data?.succeeded){
        dispatch(reset('formBank'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllBanks = () => async (dispatch) => {
    const response = await api.get('/api/v1/Bank/GetAll');
    dispatch(responseHandler(response, GET_ALL_BANK));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

// export const getBank = (id) => async (dispatch) => {
//     const response = await api.get('/api/v1/Bank/Get/' + id);
//     dispatch(responseHandler(response, GET_BANK));
// }

export const getSelectBoxBanks = () => async (dispatch) => {
    const response = await api.get('/api/v1/Bank/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BANK));
}

export const updateBank = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Bank/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BANK));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteBank = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Bank/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BANK));
}

export const addDialogBank = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BANK,
        payload: null
    });
}

export const editDialogBank = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BANK,
        payload: item
    });
}

export const closeDialogBank = () => async (dispatch) => {
    dispatch(reset('formBank'));
    dispatch({
        type: CLOSE_DIALOG_BANK,
        payload: undefined
    });
}