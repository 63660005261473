import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableUserBranch from './TableUserBranch';
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';

class TableUserBranchDialog extends React.Component {
    render() {
        return (<div>
            <Dialog
                open={this.props.isOpen}

                maxWidth={'md'}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    korisničko ime: {this.props.selectedRowInput?.email}<br/>
                    Ime: {this.props.selectedRowInput?.firstName} {this.props.selectedRowInput?.lastName}<br/>
                    </DialogTitle>
                <DialogContent>
                    <TableUserBranch
                        userID={this.props.selectedRowInput?.id}
                    ></TableUserBranch>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="secondary" variant="outlined">
                            {i18next.t('Close')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>)
    }
}

export default TableUserBranchDialog;