import { 
    CREATE_REGION,
    GET_ALL_REGION,
    GET_REGION,
    DELETE_REGION,
    UPDATE_REGION
} from '../../actions/types';

const regionReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_REGION:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_REGION:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_REGION:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_REGION: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_REGION:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default regionReducer;