import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLoanTakenOut } from '../../actions/loanTakenOutAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditCheckbox, EditIntegerNumberField } from '../Share/EditComponents';

class FormLoanTakenOut extends React.Component {
    handleClose = () => {
        this.props.closeDialogLoanTakenOut();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>

                            <div>
                                <Field
                                    name='partnerID'
                                    component={this.props.EditSelectField}
                                    label='Partner'
                                    dataSource={this.props.partnersSelectBox?.filter(el => this.props.loanPartners.filter(el => el.id > 0).map(m => parseInt(m.partnerID)).indexOf(el.value) != -1)}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='loanPartnerTypeID'
                                    component={this.props.EditSelectField}
                                    label='LoanPartnerType'
                                    dataSource={this.props.loanPartnerTypesSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='bankID'
                                    component={this.props.EditSelectField}
                                    label='Bank'
                                    dataSource={this.props.banksSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='description'
                                    component={this.props.EditTextField}
                                    label='Description'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='LoanAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='anuity'
                                    component={this.props.EditDecimalNumberField}
                                    label='Anuity'
                                />
                            </div>
                            <div>
                                <Field
                                    name='totalOverDue'
                                    component={this.props.EditDecimalNumberField}
                                    label='TotalOverDue'
                                />
                            </div>
                            <div>
                                <Field
                                    name='totalUnRepaid'
                                    component={this.props.EditDecimalNumberField}
                                    label='TotalUnRepaid'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanTermMonths'
                                    component={this.props.EditTextField}
                                    label='LoanTermMonths'
                                />
                            </div>
                            <div>
                                <Field
                                    name='billNumberCount'
                                    component={this.props.EditIntegerNumberField}
                                    label='BillNumberCount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanQualityStatus'
                                    component={this.props.EditTextField}
                                    label='LoanQualityStatus'
                                />
                            </div>
                            <div>
                                <Field
                                    name='isReprogram'
                                    component={this.props.EditCheckbox}
                                    label='IsReprogram'
                                />
                            </div>
                            <div>
                                <Field
                                    name='valid'
                                    component={this.props.EditCheckbox}
                                    label='Valid'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if (!formValues.partnerID) {
        errors.partnerID = 'Polje je obavezno!';
    }
    if (!formValues.bankID) {
        errors.bankID = 'Polje je obavezno!';
    }
    if (!formValues.description) {
        errors.description = 'Polje je obavezno!';
    }
    if (!(formValues.loanAmount > 0)) {
        errors.loanAmount = 'Polje je obavezno!';
    }
    if (!(formValues.anuity >= 0)) {
        errors.anuity = 'Polje je obavezno!';
    }
    if (!(formValues.totalOverDue >= 0)) {
        errors.totalOverDue = 'Polje je obavezno!';
    }
    if (!(formValues.totalUnRepaid >= 0)) {
        errors.totalUnRepaid = 'Polje je obavezno!';
    }
    if (!formValues.loanQualityStatus) {
        errors.loanQualityStatus = 'Polje je obavezno!';
    }
    if (formValues.loanPartnerTypeID === null) {
        errors.loanPartnerTypeID = 'Polje je obavezno!';
    }
    if (!(formValues.billNumberCount >= 0)) {
        errors.billNumberCount = 'Polje je obavezno!';
    }
    
    return errors;
}


const mapStateToProps = state => {
    return {
        partnersSelectBox: Object.values(state.partnersSelectBox),
        banksSelectBox: Object.values(state.banksSelectBox),
        loanPartners: Object.values(state.loanPartners),
        loanPartnerTypesSelectBox: Object.values(state.loanPartnerTypesSelectBox),
    }
}
const formWrapper = reduxForm({
    form: 'formLoanTakenOut',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormLoanTakenOut);

export default connect(mapStateToProps, { closeDialogLoanTakenOut, EditSelectField, EditTextField, EditDecimalNumberField, EditCheckbox, EditIntegerNumberField })(formWrapper);