import { 
    CREATE_COMPANYDETAILS,
    GET_ALL_COMPANYDETAILS,
    GET_COMPANYDETAILS,
    DELETE_COMPANYDETAILS,
    UPDATE_COMPANYDETAILS
} from '../../actions/types';

const companyDetailsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_COMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_COMPANYDETAILS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_COMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_COMPANYDETAILS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_COMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default companyDetailsReducer;