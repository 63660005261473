import React from "react";
import Button from '@mui/material/Button';
import ConfirmDialog from '../../components/Share/ConfirmDialog';
import TableLoan from '../../components/Loan/TableLoan';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { returnLoanToDraft, refuseLoan, realizeLoan } from '../../actions/loanAction';
import { showError } from '../../actions/messageAction';

class LoanForRealizationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenConfirmDialog: false,
            loanData: null,
            message: null,
            action: null
        }
    } 
    returnToDraft = (params) => {
        this.setState({
            isOpenConfirmDialog: true,
            loanData: params.row,
            action: this.returnToDraftAction,
            message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite vratiti u nacrt?`
        });
    }
    returnToDraftAction = () => {
        this.props.returnLoanToDraft(this.state.loanData.id);
    }
    refuse = (params) => {
        this.setState({
            isOpenConfirmDialog: true,
            loanData: params.row,
            action: this.refuseAction,
            message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite odbiti?`
        });
    }
    refuseAction = () => {
        this.props.refuseLoan(this.state.loanData.id);
    }
    realizeLoan = (params) => {
        this.setState({
            isOpenConfirmDialog: true,
            loanData: params.row,
            action: this.realizeLoanAction,
            message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite poslati na realizaciju?`
        });
    }
    realizeLoanAction = () => {
        this.props.realizeLoan(this.state.loanData.id);
    }
    columns = () => {
        return [
            {
                field: 'returnToDraft',
                headerName: ' ',
                flex: 1,
                renderCell: (params) => (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 0 }}
                            onClick={() => this.returnToDraft(params)}
                        >
                            {i18next.t('ReturnToDraft')}
                        </Button>
                    </strong>
                ),
            },
            {
                field: 'refuse',
                headerName: ' ',
                hide: !this.hasRefusePermission(),
                renderCell: (params) => (
                    <strong>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            style={{ marginLeft: 0 }}
                            onClick={() => this.refuse(params)}
                        >
                            {i18next.t('Refuse')}
                        </Button>
                    </strong>
                ),
            },
            {
                field: 'realizeLoan',
                headerName: ' ',
                hide: !this.hasRealizePermission(),
                renderCell: (params) => (
                    <strong>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 0 }}
                            onClick={() => this.realizeLoan(params)}
                        >
                            {i18next.t('Realize')}
                        </Button>
                    </strong>
                ),
            },
        ];
    }
    hasRealizePermission = () => {
        let roles = ['Admin', 'ClientAdmin', 'CompanyAdmin', 'Accounting'];
        for (let i = 0; i < roles?.length; i++) {
            if (this.props.authUser.roles?.indexOf(roles[i]) > -1) {
                return true;
            }
        }
        return false;
    }
    hasRefusePermission = () => {
        let roles = ['Admin', 'ClientAdmin', 'CompanyAdmin'];
        for (let i = 0; i < roles?.length; i++) {
            if (this.props.authUser.roles?.indexOf(roles[i]) > -1) {
                return true;
            }
        }
        return false;
    }
    render() {
        return (
            <>
                <TableLoan
                    Status={4}
                    columns={this.columns()}
                />
                <ConfirmDialog
                    IsOpen={this.state.isOpenConfirmDialog}
                    close={() => this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null })}
                    confirm={() => {
                        this.state.action();
                        this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null });
                    }}
                    message={this.state.message}
                />
            </>);
    }
}

const mapStateToProps = state => {
    return {
        authUser: state.authUser
    }
}

export default connect(mapStateToProps, { returnLoanToDraft, refuseLoan, realizeLoan, showError })(LoanForRealizationPage);