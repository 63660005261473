import { format } from 'date-fns';

export const dateFormat = (data) => {
  try {

    if (data?.value === null) {
      return '';
    }
    else {
      return format(new Date(data.value), 'dd.MM.yyyy');
    }
  }
  catch (err) {
    return '';
  }
  //return data.value;
}


export const dateTimeFormat = (data) => {
  try {
    if (data.value === null) {
      return '';
    }
    else {
      return format(new Date(data.value), 'dd.MM.yyyy HH:mm');
    }
  } catch (err) {
    return '';
  }
}