import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateUser from './CreateUser';
import EditUser from './EditUser';
import { addDialogUser, editDialogUser, deleteUser, getAllUsers } from "../../actions/userAction";
import { getSelectBoxRoles } from "../../actions/roleAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import TableUserBranchDialog from '../UserBranch/TableUserBranchDialog';
import TableUserCompanyDetailsDialog from '../UserCompanyDetails/TableUserCompanyDetailsDialog';

class TableUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      IsOpenConfirmDialog: false, 
      ID: null,
      isUserBranchOpen: false,
      isUserCompanyDetailsOpen:false,
      selectedRow: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getAllUsers();
    this.props.getSelectBoxRoles();
  }
  columns = () => {
    return [

      { field: 'email', headerName: i18next.t('Email'), minWidth: 150, flex: 1 },
      { field: 'firstName', headerName: i18next.t('FirstName'), minWidth: 150, flex: 1 },
      { field: 'lastName', headerName: i18next.t('LastName'), minWidth: 150, flex: 1 },
      { field: 'phoneNumber', headerName: i18next.t('Phone'), minWidth: 150, flex: 1 },
      { field: 'roles', headerName: i18next.t('RoleName'), minWidth: 150, flex: 1 },
      {
        field: 'add',
        headerName: ' ',
        renderCell: (params) => {
          return this.canAdd(params, ['CompanyAdmin', 'Broker', 'Seller']) ? (
            <strong>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={() => this.add(params)}
              >
                {i18next.t('Add')}
              </Button>
            </strong>
          ) : null
        },
      },
      {
        field: 'edit',
        headerName: ' ',
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => this.edit(params)}
            >
              {i18next.t('Edit')}
            </Button>
          </strong>
        ),
      },
      {
        field: 'delete',
        headerName: ' ',
        renderCell: (params) => (
          <strong>
            <DeleteButton
              onClick={() => this.delete(params)}
            ></DeleteButton>
          </strong>
        )
      }
    ];
  }

  canAdd = (params, roles) => {
    for (let i = 0; i < roles?.length; i++) {
      if (params.row?.roles?.indexOf(roles[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  add = (params) => {    
    if(this.canAdd(params, ['CompanyAdmin'])){
      this.setState({
        selectedRow: params.row,
        isUserCompanyDetailsOpen: true
      });
    }
    else {
      this.setState({
        selectedRow: params.row,
        isUserBranchOpen: true
      });
    }
  }
  create = () => {
    this.props.addDialogUser();
  }
  edit = (params) => {
    this.props.editDialogUser(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.users}
          columns={this.columns()}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateUser></CreateUser>
        <EditUser></EditUser>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteUser(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
        
        <TableUserBranchDialog
          isOpen={this.state.isUserBranchOpen}
          selectedRowInput={this.state.selectedRow}
          onClose={() => this.setState({ isUserBranchOpen: false })}>
        </TableUserBranchDialog>
        
        <TableUserCompanyDetailsDialog
          isOpen={this.state.isUserCompanyDetailsOpen}
          selectedRowInput={this.state.selectedRow}
          onClose={() => this.setState({ isUserCompanyDetailsOpen: false })}>
        </TableUserCompanyDetailsDialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: Object.values(state.users),
    roles: Object.values(state.rolesSelectBox),
    authUser: state.authUser
  };
}

export default connect(mapStateToProps, {
  addDialogUser,
  editDialogUser,
  deleteUser,
  getAllUsers,
  getSelectBoxRoles,
  showLoadingDataInProgress
})(TableUser);