//Mojo-AG
import { 
    CREATE_BANKSTATEMENTIMPORTSPLIT,
    GET_ALL_BANKSTATEMENTIMPORTSPLIT,
    GET_BANKSTATEMENTIMPORTSPLIT,
    DELETE_BANKSTATEMENTIMPORTSPLIT,
    UPDATE_BANKSTATEMENTIMPORTSPLIT
} from '../../actions/types';

const bankStatementImportSplitReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_BANKSTATEMENTIMPORTSPLIT:
            return {...action.payload};
        case GET_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_BANKSTATEMENTIMPORTSPLIT: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default bankStatementImportSplitReducer;