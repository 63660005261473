import api from "../api/myApi";
import { 
    GET_ALL_SELECTBOX_ROLE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const getSelectBoxRoles = () => async (dispatch) => {
    const response = await api.get('/api/v1/Role/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_ROLE));
}