import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createCashier } from '../../actions/cashierAction';
import FormCashier from './FormCashier';

class CreateCashier extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createCashier(formValues);
    }
    
    render(){
        return (
            <FormCashier 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormCashier>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CashierDialog === undefined || state.dialog.CashierDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CashierDialog};
}


export default connect(mapStateToProps, { createCashier })(CreateCashier);
