import { format } from 'date-fns'

class DateProvider {
    static GetDate = (data) => {
        if(data?.value === null){
            return '';
        }
        return format(new Date(data?.value ?? data), 'dd.MM.yyyy.');
    }
    static GetDateTime = (data) => {
        if(data?.value === null){
            return '';
        }
        return format(new Date(data?.value ?? data), 'dd.MM.yyyy. HH:mm');
    }
    static GetDateJSON = (data) => {
        if(data === null){
            return '';
        }
        return format(new Date(data), 'yyyy-MM-dd');
    }
  }
  export default DateProvider;