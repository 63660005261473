import { 
    //CREATE_LOANREPAYMENTSCHEDULE,
    //GET_ALL_LOANREPAYMENTSCHEDULE,
    //GET_LOANREPAYMENTSCHEDULE,
    //DELETE_LOANREPAYMENTSCHEDULE,
    //UPDATE_LOANREPAYMENTSCHEDULE,
    GET_LOAN,
    UPDATE_LOAN,
    UPDATE_LOAN_START_DATE
} from '../../actions/types';

const loanRepaymentScheduleReducer = (state = {}, action) => {
    switch(action.type){
        // case CREATE_LOANREPAYMENTSCHEDULE:
        //     return { ...state, [action.payload.id]: action.payload };
        // case GET_ALL_LOANREPAYMENTSCHEDULE:
        //     let result = { ...state };
        //     for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
        //     return result;
        // case GET_LOANREPAYMENTSCHEDULE:
        //     return { ...state, [action.payload.id]: action.payload };
        // case DELETE_LOANREPAYMENTSCHEDULE: {
        //     let result = {...state};
        //     delete result[action.payload];
        //     return result;
        // }
        // case UPDATE_LOANREPAYMENTSCHEDULE:
        //     return { ...state, [action.payload.id]: action.payload };        
        case GET_LOAN:
        case UPDATE_LOAN:
        case UPDATE_LOAN_START_DATE:
            let result = { };
             for(let i=0; i<action.payload.loanRepaymentSchedule.length;i++) {result[action.payload.loanRepaymentSchedule[i].id]=action.payload.loanRepaymentSchedule[i];}
             return result;
        default:
            return state;
    }
}

export default loanRepaymentScheduleReducer;