import i18next from '../language/i18n';

export const responseHandler = (response, action, defaultValue) => {
    if(response.status === 200){
        if(response.data.succeeded) {
            if(response.data.data === true || action === 'success'){
                return {
                    type:action,
                    payload: defaultValue ?? "Operacija uspešno izvršena!"
                }
            }
            else{
                return {
                    type: action,
                    payload: defaultValue ?? response.data.data ?? response.data.message ?? response.data.Message
                }
            }
        }
        else if(response.data?.Status === 401 && response.data?.Message == 'You are not Authorized')
        {
            localStorage.clear();
            window.location.reload(true);
            return {
                type: "error",
                payload: response.statusText
            }
        }
        else{
            return {
                type: "error",
                payload: response.data.message ?? response.data.Message
            }
        }
    }
    else{
        return {
            type: "error",
            payload: response.statusText
        }
    }
}



export const responseHandlerWithTranslate = (response, action) => {
    if(response.status === 200){
        if(response.data.succeeded) {
            if(response.data.data === true || action === 'success'){
                return {
                    type:action,
                    payload: "Operacija uspešno izvršena!"
                }
            }
            else{
                for(let i = 0;i<response.data.data.length;i++){
                    response.data.data[i].text = i18next.t(response.data.data[i].text);
                }
                return {
                    type: action,
                    payload: response.data.data ?? response.data.message ?? response.data.Message
                }
            }
        }
        else{
            return {
                type: "error",
                payload: response.data.message ?? response.data.Message
            }
        }
    }
    else{
        return {
            type: "error",
            payload: response.statusText
        }
    }
}
