//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createLoanWriteOff } from '../../actions/loanWriteOffAction';
import FormLoanWriteOff from './FormLoanWriteOff';

class CreateLoanWriteOff extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loan.loan.id;
        this.props.createLoanWriteOff(formValues);
    }
    
    render(){
        return (
            <FormLoanWriteOff 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormLoanWriteOff>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanWriteOffDialog === undefined || state.dialog.LoanWriteOffDialog.type !== 'add'? 
  {
    IsOpen:false,
    loan: state.loanSelected
} : 
{
    ...state.dialog.LoanWriteOffDialog,
    loan: state.loanSelected
};
}


export default connect(mapStateToProps, { createLoanWriteOff })(CreateLoanWriteOff);
