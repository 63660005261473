import React from "react";
import Button from '@mui/material/Button';
import i18next from '../../../language/i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';

class DeleteButton extends React.Component {
    theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#dc3545',
            },
            secondary: {
                // This is green.A700 as hex.
                main: '#218838',
            },
            neutral: {
                main: '#FF0000',
                contrastText: '#fff',
            }
        },
    });

    render() {
        return (<ThemeProvider theme={this.theme}>
            <Button
                variant="contained"
                color="primary"
                size="small"
                style={{ marginLeft: 16 }}
                onClick={(params) => this.props.onClick(params)}
                disabled={this.props.disabled}
            >
                {i18next.t('Delete')}
            </Button>
        </ThemeProvider>);
    }
}

export default DeleteButton;