import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createKUF } from '../../actions/kUFAction';
import FormKUF from './FormKUF';

class CreateKUF extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createKUF(formValues);
    }
    
    render(){
        return (
            <FormKUF 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormKUF>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.KUFDialog === undefined || state.dialog.KUFDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.KUFDialog};
}


export default connect(mapStateToProps, { createKUF })(CreateKUF);
