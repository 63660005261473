import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogPartner } from '../../actions/partnerAction';
import { EditSelectFieldClear } from '../Share/EditComponents';
import FormPartnerPerson from './FormPartnerPerson';
import FormPartnerLegalEntity from './FormPartnerLegalEntity';
import UserPartnerForm from './UserPartnerForm';
import i18next from '../../language/i18n';

class FormPartner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerType: null,
            currentValue: null
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.initialValues?.partnerTypeID !== undefined && this.state.partnerType == null) {
            this.setState({ partnerType: this.props.initialValues?.partnerTypeID })
        }
        else if (prevProps.initialValues?.partnerTypeID !== this.props.initialValues?.partnerTypeID) {
            this.setState({ partnerType: this.props.initialValues?.partnerTypeID })
        }
    }

    handleClose = () => {
        this.setState({
            partnerType: null,
            currentValue: null
        });
        this.props.closeDialogPartner();
    }
    onSubmit = (formValues) => {        
        formValues.partnerTypeID = this.state.partnerType;
        this.props.onSubmit(formValues);
    }
    renderElement = () => {
        if (this.state.partnerType === 3 || this.state.partnerType === 5) {
            return (
                <FormPartnerPerson onSubmit={
                    this.onSubmit
                }
                    initialValues={this.props.initialValues}
                    onlyAddress={this.state.partnerType === 5}
                    useJmbgChecker={this.state.partnerType !== 5}
                    handleClose={this.handleClose}></FormPartnerPerson>
            )
        }
        else if (this.state.partnerType === 1 || this.state.partnerType === 2 || this.state.partnerType === 4 || this.state.partnerType === 6) {
            return (
                <FormPartnerLegalEntity onSubmit={
                    this.onSubmit
                }
                    onlyAddress={this.state.partnerType === 4}
                    initialValues={this.props.initialValues}
                    handleClose={this.handleClose}
                    buttonText={i18next.t('Submit')}></FormPartnerLegalEntity>
            )
        } else {
            return (
                <div>
                    <EditSelectFieldClear name='partnerTypeID'
                        label='PartnerType'
                        onChange={
                            (event) => {
                                this.setState({ currentValue: parseInt(event.target.value) })
                            }
                        }
                        dataSource={this.props.partnerTypesSelectBox}
                        ></EditSelectFieldClear>

                    <DialogActions>
                        <CancelButton onClick={this.handleClose}></CancelButton>
                        <Button color="primary" variant="contained"
                            onClick={
                                () => { this.setState({ partnerType: this.state.currentValue }); }
                            }>
                            {i18next.t('Next')}
                        </Button>
                    </DialogActions>
                </div>
            );
        }
    }
    render() {
        return (
            <div>
                <Dialog open={this.props.IsOpen}
                    maxWidth={'md'}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {
                            this.props.title
                        }
                    </DialogTitle>
                    <DialogContent> {
                        this.renderElement()
                    } </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.companyID) {
        errors.companyID = 'Polje je obavezno!';
    }

    return errors;
}


const mapStateToProps = state => {
    return {
        companiesSelectBox: Object.values(state.companiesSelectBox),
        citiesSelectBox: Object.values(state.citiesSelectBox),
        groupsSelectBox: Object.values(state.groupsSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        gendersSelectBox: Object.values(state.gendersSelectBox),
        maritalStatusSelectBox: Object.values(state.maritalStatusSelectBox),
        partnerTypesSelectBox: Object.values(state.partnerTypesSelectBox),
        entitiesSelectBox: Object.values(state.entitiesSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        employmentTypesSelectBox: Object.values(state.employmentTypesSelectBox)
    }
}
const formWrapper = reduxForm({ form: 'formPartner', validate: validate, destroyOnUnmount: false, enableReinitialize: true })(FormPartner);

export default connect(mapStateToProps, {
    closeDialogPartner,
    EditSelectFieldClear
})(formWrapper);
