import { 
    CREATE_LEGALSERVICE_DONE,
    GET_ALL_DONE_LEGALSERVICE
} from '../../actions/types';

const legalService_DoneReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LEGALSERVICE_DONE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_DONE_LEGALSERVICE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        default:
            return state;
    }
}

export default legalService_DoneReducer;