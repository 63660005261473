import { 
    CREATE_WAYOFKNOWING,
    GET_ALL_WAYOFKNOWING,
    GET_WAYOFKNOWING,
    DELETE_WAYOFKNOWING,
    UPDATE_WAYOFKNOWING
} from '../../actions/types';

const wayOfKnowingReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_WAYOFKNOWING:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_WAYOFKNOWING:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_WAYOFKNOWING:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_WAYOFKNOWING: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_WAYOFKNOWING:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default wayOfKnowingReducer;