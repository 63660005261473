//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogUserCompanyDetails, updateUserCompanyDetails } from '../../actions/userCompanyDetailsAction';
import FormUserCompanyDetails from './FormUserCompanyDetails';

class EditUserCompanyDetails extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateUserCompanyDetails(formValues);
    }
    
    render(){
        return (
            <FormUserCompanyDetails 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormUserCompanyDetails>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserCompanyDetailsDialog === undefined || state.dialog.UserCompanyDetailsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.UserCompanyDetailsDialog};
}


export default connect(mapStateToProps, { closeDialogUserCompanyDetails, updateUserCompanyDetails })(EditUserCompanyDetails);
