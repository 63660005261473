import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCompany } from '../../actions/companyAction';
import { EditTextField } from '../Share/EditComponents';

class FormCompany extends React.Component{
    handleClose = () => {
        this.props.closeDialogCompany();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            <div>
                                <Field
                                    name='web'
                                    component={this.props.EditTextField}
                                    label='Web'
                                />
                            </div>
                            <div>
                                <Field
                                    name='email'
                                    component={this.props.EditTextField}
                                    label='Email'
                                />
                            </div>
                            <div>
                                <Field
                                    name='director'
                                    component={this.props.EditTextField}
                                    label='Director'
                                />
                            </div>
                            <div>
                                <Field
                                    name='cRKCode'
                                    component={this.props.EditTextField}
                                    label='CRKCode'
                                />
                            </div>
                            <div>
                                <Field
                                    name='logoURL'
                                    component={this.props.EditTextField}
                                    label='LogoURL'
                                />
                            </div>
                            <DialogActions>
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.name){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.web){
        errors.web = 'Polje je obavezno!';
    }
    if(!formValues.email){
        errors.email = 'Polje je obavezno!';
    }
    if(!formValues.director){
        errors.director = 'Polje je obavezno!';
    }
    if(!formValues.cRKCode){
        errors.cRKCode = 'Polje je obavezno!';
    }
    if(!formValues.logoURL){
        errors.logoURL = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {  }
}
const formWrapper = reduxForm({
    form: 'formCompany',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormCompany);

export default connect(mapStateToProps, { closeDialogCompany, EditTextField})(formWrapper);