import { 
    GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS
} from '../../actions/types';

 const debtCollectionCostDetailsSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS:
            return [...action.payload];
        default:
            return state;
    }
}
export default debtCollectionCostDetailsSelectBoxReducer;