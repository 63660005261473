import { 
    GET_ALL_BASE_LOAN_DATA
} from '../../actions/types';

const loanBaseDataReducer = (state = {}, action) => {
    switch(action.type){
        //case CREATE_LOAN:
        //    return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_BASE_LOAN_DATA:
            let result = {  };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        //case GET_LOAN:
        //    return { ...state, [action.payload.id]: action.payload };
        //case DELETE_LOAN: {
        //    let result = {...state};
        //    delete result[action.payload];
        //    return result;
        //}
        //case UPDATE_LOAN:
        //    return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanBaseDataReducer;