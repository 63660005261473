//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_LOANAPPROVAL,
    GET_ALL_LOANAPPROVAL,
    GET_LOANAPPROVAL,
    GET_ALL_SELECTBOX_LOANAPPROVAL,
    DELETE_LOANAPPROVAL,
    UPDATE_LOANAPPROVAL,
    ADD_DIALOG_LOANAPPROVAL,
    EDIT_DIALOG_LOANAPPROVAL,
    CLOSE_DIALOG_LOANAPPROVAL
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanApproval = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApproval/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANAPPROVAL));
    if(response.data?.succeeded){
        dispatch(reset('formLoanApproval'));

        if(response.data?.loan?.loanStatusID === 3){
            dispatch({
                type:'success',
                payload: "Operacija uspešno izvršena!"
            });
        }
    }
}

export const getAllLoanApprovals = (loanID) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApproval/GetAll?loanID='+loanID);
    dispatch(responseHandler(response, GET_ALL_LOANAPPROVAL));
}

export const getLoanApproval = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApproval/Get/' + id);
    dispatch(responseHandler(response, GET_LOANAPPROVAL));
}

export const getSelectBoxLoanApprovals = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApproval/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANAPPROVAL));
}

export const updateLoanApproval = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApproval/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANAPPROVAL));

    if(response.data?.loan?.loanStatusID === 3){
        dispatch({
            type:'success',
            payload: "Operacija uspešno izvršena!"
        });
    }
}

export const deleteLoanApproval = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApproval/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANAPPROVAL));
}

export const addDialogLoanApproval = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANAPPROVAL,
        payload: null
    });
}

export const editDialogLoanApproval = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANAPPROVAL,
        payload: item
    });
}

export const closeDialogLoanApproval = () => async (dispatch) => {
    dispatch(reset('formLoanApproval'));
    dispatch({
        type: CLOSE_DIALOG_LOANAPPROVAL,
        payload: undefined
    });
}