import {
    // ADD_DIALOG_ACTIONLOG,
    // EDIT_DIALOG_ACTIONLOG,
    // CREATE_ACTIONLOG,
    // UPDATE_ACTIONLOG,
    // CLOSE_DIALOG_ACTIONLOG,

    // ADD_DIALOG_ACTIONLOGTYPE,
    // EDIT_DIALOG_ACTIONLOGTYPE,
    // CREATE_ACTIONLOGTYPE,
    // UPDATE_ACTIONLOGTYPE,
    // CLOSE_DIALOG_ACTIONLOGTYPE,

    ADD_DIALOG_ASSIGNEE,
    EDIT_DIALOG_ASSIGNEE,
    CREATE_ASSIGNEE,
    UPDATE_ASSIGNEE,
    CLOSE_DIALOG_ASSIGNEE,

    ADD_DIALOG_BANK,
    EDIT_DIALOG_BANK,
    CREATE_BANK,
    UPDATE_BANK,
    CLOSE_DIALOG_BANK,

    ADD_DIALOG_BANKSTATEMENTIMPORT,
    EDIT_DIALOG_BANKSTATEMENTIMPORT,
    CREATE_BANKSTATEMENTIMPORT,
    UPDATE_BANKSTATEMENTIMPORT,
    CLOSE_DIALOG_BANKSTATEMENTIMPORT,

    ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT,
    EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT,
    CREATE_BANKSTATEMENTIMPORTSPLIT,
    UPDATE_BANKSTATEMENTIMPORTSPLIT,
    CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT,

    ADD_DIALOG_BLTURNOVER,
    EDIT_DIALOG_BLTURNOVER,
    CREATE_BLTURNOVER,
    UPDATE_BLTURNOVER,
    CLOSE_DIALOG_BLTURNOVER,

    ADD_DIALOG_BOARDMEMBER,
    EDIT_DIALOG_BOARDMEMBER,
    CREATE_BOARDMEMBER,
    UPDATE_BOARDMEMBER,
    CLOSE_DIALOG_BOARDMEMBER,

    ADD_DIALOG_BOARDMEMBERTYPE,
    EDIT_DIALOG_BOARDMEMBERTYPE,
    CREATE_BOARDMEMBERTYPE,
    UPDATE_BOARDMEMBERTYPE,
    CLOSE_DIALOG_BOARDMEMBERTYPE,

    ADD_DIALOG_BRANCH,
    EDIT_DIALOG_BRANCH,
    CREATE_BRANCH,
    UPDATE_BRANCH,
    CLOSE_DIALOG_BRANCH,

    ADD_DIALOG_BROKER,
    EDIT_DIALOG_BROKER,
    CREATE_BROKER,
    UPDATE_BROKER,
    CLOSE_DIALOG_BROKER,

    ADD_DIALOG_CASHBALANCE,
    EDIT_DIALOG_CASHBALANCE,
    CREATE_CASHBALANCE,
    UPDATE_CASHBALANCE,
    CLOSE_DIALOG_CASHBALANCE,

    ADD_DIALOG_CASHIER,
    EDIT_DIALOG_CASHIER,
    CREATE_CASHIER,
    UPDATE_CASHIER,
    CLOSE_DIALOG_CASHIER,

    ADD_DIALOG_CITY,
    EDIT_DIALOG_CITY,
    CREATE_CITY,
    UPDATE_CITY,
    CLOSE_DIALOG_CITY,

    ADD_DIALOG_COMPANY,
    EDIT_DIALOG_COMPANY,
    CREATE_COMPANY,
    UPDATE_COMPANY,
    CLOSE_DIALOG_COMPANY,

    ADD_DIALOG_COMPANYDETAILS,
    EDIT_DIALOG_COMPANYDETAILS,
    CREATE_COMPANYDETAILS,
    UPDATE_COMPANYDETAILS,
    CLOSE_DIALOG_COMPANYDETAILS,

    ADD_DIALOG_CURRENCYEXCHANGERATE,
    EDIT_DIALOG_CURRENCYEXCHANGERATE,
    CREATE_CURRENCYEXCHANGERATE,
    UPDATE_CURRENCYEXCHANGERATE,
    CLOSE_DIALOG_CURRENCYEXCHANGERATE,

    ADD_DIALOG_DEBTCOLLECTIONCOST,
    EDIT_DIALOG_DEBTCOLLECTIONCOST,
    CREATE_DEBTCOLLECTIONCOST,
    UPDATE_DEBTCOLLECTIONCOST,
    CLOSE_DIALOG_DEBTCOLLECTIONCOST,

    ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
    EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
    CREATE_DEBTCOLLECTIONCOSTDETAILS,
    UPDATE_DEBTCOLLECTIONCOSTDETAILS,
    CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS,

    ADD_DIALOG_DOCUMENT,
    EDIT_DIALOG_DOCUMENT,
    CREATE_DOCUMENT,
    UPDATE_DOCUMENT,
    CLOSE_DIALOG_DOCUMENT,

    ADD_DIALOG_DOCUMENTTURNOVERTYPE,
    EDIT_DIALOG_DOCUMENTTURNOVERTYPE,
    CREATE_DOCUMENTTURNOVERTYPE,
    UPDATE_DOCUMENTTURNOVERTYPE,
    CLOSE_DIALOG_DOCUMENTTURNOVERTYPE,

    ADD_DIALOG_DOCUMENTTYPE,
    EDIT_DIALOG_DOCUMENTTYPE,
    CREATE_DOCUMENTTYPE,
    UPDATE_DOCUMENTTYPE,
    CLOSE_DIALOG_DOCUMENTTYPE,

    ADD_DIALOG_EMPLOYMENTTYPE,
    EDIT_DIALOG_EMPLOYMENTTYPE,
    CREATE_EMPLOYMENTTYPE,
    UPDATE_EMPLOYMENTTYPE,
    CLOSE_DIALOG_EMPLOYMENTTYPE,

    ADD_DIALOG_ENTITY,
    EDIT_DIALOG_ENTITY,
    CREATE_ENTITY,
    UPDATE_ENTITY,
    CLOSE_DIALOG_ENTITY,

    ADD_DIALOG_FILEUPLOAD,
    EDIT_DIALOG_FILEUPLOAD,
    CREATE_FILEUPLOAD,
    UPDATE_FILEUPLOAD,
    CLOSE_DIALOG_FILEUPLOAD,

    ADD_DIALOG_FILEUPLOADTYPE,
    EDIT_DIALOG_FILEUPLOADTYPE,
    CREATE_FILEUPLOADTYPE,
    UPDATE_FILEUPLOADTYPE,
    CLOSE_DIALOG_FILEUPLOADTYPE,

    ADD_DIALOG_LOANPARTNER,
    EDIT_DIALOG_LOANPARTNER,
    CREATE_LOANPARTNER,
    UPDATE_LOANPARTNER,
    CLOSE_DIALOG_LOANPARTNER,

    ADD_DIALOG_GENDER,
    EDIT_DIALOG_GENDER,
    CREATE_GENDER,
    UPDATE_GENDER,
    CLOSE_DIALOG_GENDER,

    ADD_DIALOG_GROUP,
    EDIT_DIALOG_GROUP,
    CREATE_GROUP,
    UPDATE_GROUP,
    CLOSE_DIALOG_GROUP,

    ADD_DIALOG_INCOMESOURCE,
    EDIT_DIALOG_INCOMESOURCE,
    CREATE_INCOMESOURCE,
    UPDATE_INCOMESOURCE,
    CLOSE_DIALOG_INCOMESOURCE,

    ADD_DIALOG_INCOMESOURCETYPE,
    EDIT_DIALOG_INCOMESOURCETYPE,
    CREATE_INCOMESOURCETYPE,
    UPDATE_INCOMESOURCETYPE,
    CLOSE_DIALOG_INCOMESOURCETYPE,

    ADD_DIALOG_JOURNAL,
    EDIT_DIALOG_JOURNAL,
    CREATE_JOURNAL,
    UPDATE_JOURNAL,
    CLOSE_DIALOG_JOURNAL,

    ADD_DIALOG_JOURNALHISTORY,
    EDIT_DIALOG_JOURNALHISTORY,
    CREATE_JOURNALHISTORY,
    UPDATE_JOURNALHISTORY,
    CLOSE_DIALOG_JOURNALHISTORY,

    ADD_DIALOG_KUF,
    EDIT_DIALOG_KUF,
    CREATE_KUF,
    UPDATE_KUF,
    CLOSE_DIALOG_KUF,

    ADD_DIALOG_LEGALSERVICE_ACTIVITY,
    EDIT_DIALOG_LEGALSERVICE_ACTIVITY,
    CREATE_LEGALSERVICE_ACTIVITY,
    UPDATE_LEGALSERVICE_ACTIVITY,
    CLOSE_DIALOG_LEGALSERVICE_ACTIVITY,

    ADD_DIALOG_LEGALSERVICE_COURT,
    EDIT_DIALOG_LEGALSERVICE_COURT,
    CREATE_LEGALSERVICE_COURT,
    UPDATE_LEGALSERVICE_COURT,
    CLOSE_DIALOG_LEGALSERVICE_COURT,

    ADD_DIALOG_LEGALSERVICE_DOCUMENT,
    EDIT_DIALOG_LEGALSERVICE_DOCUMENT,
    CREATE_LEGALSERVICE_DOCUMENT,
    UPDATE_LEGALSERVICE_DOCUMENT,
    CLOSE_DIALOG_LEGALSERVICE_DOCUMENT,

    ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
    EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
    CREATE_LEGALSERVICE_EXECUTIONTYPE,
    UPDATE_LEGALSERVICE_EXECUTIONTYPE,
    CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE,

    ADD_DIALOG_LEGALSERVICE_WARNING,
    EDIT_DIALOG_LEGALSERVICE_WARNING,
    CREATE_LEGALSERVICE_WARNING,
    UPDATE_LEGALSERVICE_WARNING,
    CLOSE_DIALOG_LEGALSERVICE_WARNING,

    ADD_DIALOG_LOAN,
    EDIT_DIALOG_LOAN,
    CREATE_LOAN,
    UPDATE_LOAN,
    CLOSE_DIALOG_LOAN,

    ADD_DIALOG_LOANAPPROVAL,
    EDIT_DIALOG_LOANAPPROVAL,
    CREATE_LOANAPPROVAL,
    UPDATE_LOANAPPROVAL,
    CLOSE_DIALOG_LOANAPPROVAL,

    ADD_DIALOG_LOANAPPROVALSETTINGS,
    EDIT_DIALOG_LOANAPPROVALSETTINGS,
    CREATE_LOANAPPROVALSETTINGS,
    UPDATE_LOANAPPROVALSETTINGS,
    CLOSE_DIALOG_LOANAPPROVALSETTINGS,

    ADD_DIALOG_LOANHISTORY,
    EDIT_DIALOG_LOANHISTORY,
    CREATE_LOANHISTORY,
    UPDATE_LOANHISTORY,
    CLOSE_DIALOG_LOANHISTORY,

    ADD_DIALOG_LOANMORATORIUM,
    EDIT_DIALOG_LOANMORATORIUM,
    CREATE_LOANMORATORIUM,
    UPDATE_LOANMORATORIUM,
    CLOSE_DIALOG_LOANMORATORIUM,

    ADD_DIALOG_LOANQUALITY,
    EDIT_DIALOG_LOANQUALITY,
    CREATE_LOANQUALITY,
    UPDATE_LOANQUALITY,
    CLOSE_DIALOG_LOANQUALITY,

    ADD_DIALOG_LOANQUALITYSTATUS,
    EDIT_DIALOG_LOANQUALITYSTATUS,
    CREATE_LOANQUALITYSTATUS,
    UPDATE_LOANQUALITYSTATUS,
    CLOSE_DIALOG_LOANQUALITYSTATUS,

    ADD_DIALOG_LOANREPAYMENTSCHEDULE,
    EDIT_DIALOG_LOANREPAYMENTSCHEDULE,
    CREATE_LOANREPAYMENTSCHEDULE,
    UPDATE_LOANREPAYMENTSCHEDULE,
    CLOSE_DIALOG_LOANREPAYMENTSCHEDULE,

    ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE,
    EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE,
    CREATE_LOANREPAYMENTSCHEDULETYPE,
    UPDATE_LOANREPAYMENTSCHEDULETYPE,
    CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE,

    ADD_DIALOG_LOANSTATUS,
    EDIT_DIALOG_LOANSTATUS,
    CREATE_LOANSTATUS,
    UPDATE_LOANSTATUS,
    CLOSE_DIALOG_LOANSTATUS,

    ADD_DIALOG_LOANTAKENOUT,
    EDIT_DIALOG_LOANTAKENOUT,
    CREATE_LOANTAKENOUT,
    UPDATE_LOANTAKENOUT,
    CLOSE_DIALOG_LOANTAKENOUT,

    ADD_DIALOG_LOANTYPE,
    EDIT_DIALOG_LOANTYPE,
    CREATE_LOANTYPE,
    UPDATE_LOANTYPE,
    CLOSE_DIALOG_LOANTYPE,

    ADD_DIALOG_MARITALSTATUS,
    EDIT_DIALOG_MARITALSTATUS,
    CREATE_MARITALSTATUS,
    UPDATE_MARITALSTATUS,
    CLOSE_DIALOG_MARITALSTATUS,

    ADD_DIALOG_OPINIONOFTHEREFERENT,
    EDIT_DIALOG_OPINIONOFTHEREFERENT,
    CREATE_OPINIONOFTHEREFERENT,
    UPDATE_OPINIONOFTHEREFERENT,
    CLOSE_DIALOG_OPINIONOFTHEREFERENT,

    ADD_DIALOG_ORDER,
    EDIT_DIALOG_ORDER,
    CREATE_ORDER,
    UPDATE_ORDER,
    CLOSE_DIALOG_ORDER,

    ADD_DIALOG_PARTNER,
    EDIT_DIALOG_PARTNER,
    CREATE_PARTNER,
    UPDATE_PARTNER,
    CLOSE_DIALOG_PARTNER,

    ADD_DIALOG_PARTNERNOTE,
    EDIT_DIALOG_PARTNERNOTE,
    CREATE_PARTNERNOTE,
    UPDATE_PARTNERNOTE,
    CLOSE_DIALOG_PARTNERNOTE,

    ADD_DIALOG_PARTNERTYPE,
    EDIT_DIALOG_PARTNERTYPE,
    CREATE_PARTNERTYPE,
    UPDATE_PARTNERTYPE,
    CLOSE_DIALOG_PARTNERTYPE,

    ADD_DIALOG_PAYMENTNOTETYPE,
    EDIT_DIALOG_PAYMENTNOTETYPE,
    CREATE_PAYMENTNOTETYPE,
    UPDATE_PAYMENTNOTETYPE,
    CLOSE_DIALOG_PAYMENTNOTETYPE,

    ADD_DIALOG_PAYMENTWAY,
    EDIT_DIALOG_PAYMENTWAY,
    CREATE_PAYMENTWAY,
    UPDATE_PAYMENTWAY,
    CLOSE_DIALOG_PAYMENTWAY,

    ADD_DIALOG_PAYWARNING,
    EDIT_DIALOG_PAYWARNING,
    CREATE_PAYWARNING,
    UPDATE_PAYWARNING,
    CLOSE_DIALOG_PAYWARNING,

    ADD_DIALOG_PAYWARNINGTYPE,
    EDIT_DIALOG_PAYWARNINGTYPE,
    CREATE_PAYWARNINGTYPE,
    UPDATE_PAYWARNINGTYPE,
    CLOSE_DIALOG_PAYWARNINGTYPE,

    ADD_DIALOG_PENALTYINTEREST,
    EDIT_DIALOG_PENALTYINTEREST,
    CREATE_PENALTYINTEREST,
    UPDATE_PENALTYINTEREST,
    CLOSE_DIALOG_PENALTYINTEREST,

    ADD_DIALOG_PENALTYINTERESTDETAILS,
    EDIT_DIALOG_PENALTYINTERESTDETAILS,
    CREATE_PENALTYINTERESTDETAILS,
    UPDATE_PENALTYINTERESTDETAILS,
    CLOSE_DIALOG_PENALTYINTERESTDETAILS,

    ADD_DIALOG_PLAN,
    EDIT_DIALOG_PLAN,
    CREATE_PLAN,
    UPDATE_PLAN,
    CLOSE_DIALOG_PLAN,

    ADD_DIALOG_QUALIFICATIONS,
    EDIT_DIALOG_QUALIFICATIONS,
    CREATE_QUALIFICATIONS,
    UPDATE_QUALIFICATIONS,
    CLOSE_DIALOG_QUALIFICATIONS,

    ADD_DIALOG_REALESTATES,
    EDIT_DIALOG_REALESTATES,
    CREATE_REALESTATES,
    UPDATE_REALESTATES,
    CLOSE_DIALOG_REALESTATES,

    ADD_DIALOG_REFINANCING,
    EDIT_DIALOG_REFINANCING,
    CREATE_REFINANCING,
    UPDATE_REFINANCING,
    CLOSE_DIALOG_REFINANCING,

    ADD_DIALOG_REGION,
    EDIT_DIALOG_REGION,
    CREATE_REGION,
    UPDATE_REGION,
    CLOSE_DIALOG_REGION,

    ADD_DIALOG_SETTINGS,
    EDIT_DIALOG_SETTINGS,
    CREATE_SETTINGS,
    UPDATE_SETTINGS,
    CLOSE_DIALOG_SETTINGS,

    ADD_DIALOG_SMS,
    EDIT_DIALOG_SMS,
    CREATE_SMS,
    UPDATE_SMS,
    CLOSE_DIALOG_SMS,

    ADD_DIALOG_TAXGROUPS,
    EDIT_DIALOG_TAXGROUPS,
    CREATE_TAXGROUPS,
    UPDATE_TAXGROUPS,
    CLOSE_DIALOG_TAXGROUPS,

    ADD_DIALOG_TURNOVER,
    EDIT_DIALOG_TURNOVER,
    CREATE_TURNOVER,
    UPDATE_TURNOVER,
    CLOSE_DIALOG_TURNOVER,

    ADD_DIALOG_TYPEOFLOANCHANGE,
    EDIT_DIALOG_TYPEOFLOANCHANGE,
    CREATE_TYPEOFLOANCHANGE,
    UPDATE_TYPEOFLOANCHANGE,
    CLOSE_DIALOG_TYPEOFLOANCHANGE,

    ADD_DIALOG_USER,
    EDIT_DIALOG_USER,
    CREATE_USER,
    UPDATE_USER,
    CLOSE_DIALOG_USER,

    ADD_DIALOG_USERACTION,
    EDIT_DIALOG_USERACTION,
    CREATE_USERACTION,
    UPDATE_USERACTION,
    CLOSE_DIALOG_USERACTION,

    ADD_DIALOG_USERBRANCH,
    EDIT_DIALOG_USERBRANCH,
    CREATE_USERBRANCH,
    UPDATE_USERBRANCH,
    CLOSE_DIALOG_USERBRANCH,

    ADD_DIALOG_USERCOMPANYDETAILS,
    EDIT_DIALOG_USERCOMPANYDETAILS,
    CREATE_USERCOMPANYDETAILS,
    UPDATE_USERCOMPANYDETAILS,
    CLOSE_DIALOG_USERCOMPANYDETAILS,

    ADD_DIALOG_VEHICLE,
    EDIT_DIALOG_VEHICLE,
    CREATE_VEHICLE,
    UPDATE_VEHICLE,
    CLOSE_DIALOG_VEHICLE,

    ADD_DIALOG_WAYOFKNOWING,
    EDIT_DIALOG_WAYOFKNOWING,
    CREATE_WAYOFKNOWING,
    UPDATE_WAYOFKNOWING,
    CLOSE_DIALOG_WAYOFKNOWING,

    ADD_DIALOG_WORKER,
    EDIT_DIALOG_WORKER,
    CREATE_WORKER,
    UPDATE_WORKER,
    CLOSE_DIALOG_WORKER,

    ADD_DIALOG_WORKSPACE,
    EDIT_DIALOG_WORKSPACE,
    CREATE_WORKSPACE,
    UPDATE_WORKSPACE,
    CLOSE_DIALOG_WORKSPACE,

    ADD_DIALOG_RELATEDPERSON,
    EDIT_DIALOG_RELATEDPERSON,
    CREATE_RELATEDPERSON,
    UPDATE_RELATEDPERSON,
    CLOSE_DIALOG_RELATEDPERSON,

    ADD_DIALOG_CRKREQUEST,
    EDIT_DIALOG_CRKREQUEST,
    CREATE_CRKREQUEST,
    UPDATE_CRKREQUEST,
    CLOSE_DIALOG_CRKREQUEST,

    ADD_DIALOG_CRKREQUESTTYPE,
    EDIT_DIALOG_CRKREQUESTTYPE,
    CREATE_CRKREQUESTTYPE,
    UPDATE_CRKREQUESTTYPE,
    CLOSE_DIALOG_CRKREQUESTTYPE,

    ADD_DIALOG_CRKRESPONSE,
    EDIT_DIALOG_CRKRESPONSE,
    CREATE_CRKRESPONSE,
    UPDATE_CRKRESPONSE,
    CLOSE_DIALOG_CRKRESPONSE,

    ADD_DIALOG_COMPANYACTIVITYCODE,
    EDIT_DIALOG_COMPANYACTIVITYCODE,
    CREATE_COMPANYACTIVITYCODE,
    UPDATE_COMPANYACTIVITYCODE,
    CLOSE_DIALOG_COMPANYACTIVITYCODE,

    ADD_DIALOG_EARLYREPAYMENTSETTINGS,
    EDIT_DIALOG_EARLYREPAYMENTSETTINGS,
    CREATE_EARLYREPAYMENTSETTINGS,
    UPDATE_EARLYREPAYMENTSETTINGS,
    CLOSE_DIALOG_EARLYREPAYMENTSETTINGS,

    ADD_DIALOG_CRKARCHIVE,
    EDIT_DIALOG_CRKARCHIVE,
    CREATE_CRKARCHIVE,
    UPDATE_CRKARCHIVE,
    CLOSE_DIALOG_CRKARCHIVE,

    ADD_DIALOG_LOANWRITEOFF,
    EDIT_DIALOG_LOANWRITEOFF,
    CREATE_LOANWRITEOFF,
    UPDATE_LOANWRITEOFF,
    CLOSE_DIALOG_LOANWRITEOFF,

    ADD_DIALOG_REVENUERECOGNITION,
    EDIT_DIALOG_REVENUERECOGNITION,
    CREATE_REVENUERECOGNITION,
    UPDATE_REVENUERECOGNITION,
    CLOSE_DIALOG_REVENUERECOGNITION,

    ADD_DIALOG_UNPARTNERDETAILS,
    EDIT_DIALOG_UNPARTNERDETAILS,
    CREATE_UNPARTNERDETAILS,
    UPDATE_UNPARTNERDETAILS,
    CLOSE_DIALOG_UNPARTNERDETAILS,

    ADD_DIALOG_ORDERTYPE,
    EDIT_DIALOG_ORDERTYPE,
    CREATE_ORDERTYPE,
    UPDATE_ORDERTYPE,
    CLOSE_DIALOG_ORDERTYPE,

    ADD_DIALOG_COMMISSIONSETTINGS,
    EDIT_DIALOG_COMMISSIONSETTINGS,
    CREATE_COMMISSIONSETTINGS,
    UPDATE_COMMISSIONSETTINGS,
    CLOSE_DIALOG_COMMISSIONSETTINGS,

    ADD_DIALOG_SOURCEOFFINANCING,
    EDIT_DIALOG_SOURCEOFFINANCING,
    CREATE_SOURCEOFFINANCING,
    UPDATE_SOURCEOFFINANCING,
    CLOSE_DIALOG_SOURCEOFFINANCING,
} from '../actions/types';

const dialogReducer = (state = {}, action) => {
    switch (action.type) {
        // case ADD_DIALOG_ACTIONLOG:
        //     return { ...state, "ActionLogDialog": { IsOpen: true, type: 'add', item: action.payload } };
        // case EDIT_DIALOG_ACTIONLOG:
        //     return { ...state, "ActionLogDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        // case CREATE_ACTIONLOG:
        //     return { ...state, "ActionLogDialog": { IsOpen: false, type: 'none', item: null } };
        // case UPDATE_ACTIONLOG:
        //     return { ...state, "ActionLogDialog": { IsOpen: false, type: 'none', item: null } };
        // case CLOSE_DIALOG_ACTIONLOG:
        //     return { ...state, "ActionLogDialog": { IsOpen: false, type: 'none', item: null } };

        // case ADD_DIALOG_ACTIONLOGTYPE:
        //     return { ...state, "ActionLogTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        // case EDIT_DIALOG_ACTIONLOGTYPE:
        //     return { ...state, "ActionLogTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        // case CREATE_ACTIONLOGTYPE:
        //     return { ...state, "ActionLogTypeDialog": { IsOpen: false, type: 'none', item: null } };
        // case UPDATE_ACTIONLOGTYPE:
        //     return { ...state, "ActionLogTypeDialog": { IsOpen: false, type: 'none', item: null } };
        // case CLOSE_DIALOG_ACTIONLOGTYPE:
        //     return { ...state, "ActionLogTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_ASSIGNEE:
            return { ...state, "AssigneeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_ASSIGNEE:
            return { ...state, "AssigneeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_ASSIGNEE:
            return { ...state, "AssigneeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_ASSIGNEE:
            return { ...state, "AssigneeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_ASSIGNEE:
            return { ...state, "AssigneeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BANK:
            return { ...state, "BankDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BANK:
            return { ...state, "BankDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BANK:
            return { ...state, "BankDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BANK:
            return { ...state, "BankDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BANK:
            return { ...state, "BankDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BANKSTATEMENTIMPORT:
            return { ...state, "BankStatementImportDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BANKSTATEMENTIMPORT:
            return { ...state, "BankStatementImportDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BANKSTATEMENTIMPORT:
            return { ...state, "BankStatementImportDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BANKSTATEMENTIMPORT:
            return { ...state, "BankStatementImportDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BANKSTATEMENTIMPORT:
            return { ...state, "BankStatementImportDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, "BankStatementImportSplitDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, "BankStatementImportSplitDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, "BankStatementImportSplitDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, "BankStatementImportSplitDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT:
            return { ...state, "BankStatementImportSplitDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BLTURNOVER:
            return { ...state, "BLTurnoverDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BLTURNOVER:
            return { ...state, "BLTurnoverDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BLTURNOVER:
            return { ...state, "BLTurnoverDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BLTURNOVER:
            return { ...state, "BLTurnoverDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BLTURNOVER:
            return { ...state, "BLTurnoverDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BOARDMEMBER:
            return { ...state, "BoardMemberDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BOARDMEMBER:
            return { ...state, "BoardMemberDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BOARDMEMBER:
            return { ...state, "BoardMemberDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BOARDMEMBER:
            return { ...state, "BoardMemberDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BOARDMEMBER:
            return { ...state, "BoardMemberDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BOARDMEMBERTYPE:
            return { ...state, "BoardMemberTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BOARDMEMBERTYPE:
            return { ...state, "BoardMemberTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BOARDMEMBERTYPE:
            return { ...state, "BoardMemberTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BOARDMEMBERTYPE:
            return { ...state, "BoardMemberTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BOARDMEMBERTYPE:
            return { ...state, "BoardMemberTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BRANCH:
            return { ...state, "BranchDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BRANCH:
            return { ...state, "BranchDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BRANCH:
            return { ...state, "BranchDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BRANCH:
            return { ...state, "BranchDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BRANCH:
            return { ...state, "BranchDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_BROKER:
            return { ...state, "BrokerDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_BROKER:
            return { ...state, "BrokerDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_BROKER:
            return { ...state, "BrokerDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_BROKER:
            return { ...state, "BrokerDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_BROKER:
            return { ...state, "BrokerDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CASHBALANCE:
            return { ...state, "CashBalanceDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CASHBALANCE:
            return { ...state, "CashBalanceDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CASHBALANCE:
            return { ...state, "CashBalanceDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CASHBALANCE:
            return { ...state, "CashBalanceDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CASHBALANCE:
            return { ...state, "CashBalanceDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CASHIER:
            return { ...state, "CashierDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CASHIER:
            return { ...state, "CashierDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CASHIER:
            return { ...state, "CashierDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CASHIER:
            return { ...state, "CashierDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CASHIER:
            return { ...state, "CashierDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CITY:
            return { ...state, "CityDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CITY:
            return { ...state, "CityDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CITY:
            return { ...state, "CityDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CITY:
            return { ...state, "CityDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CITY:
            return { ...state, "CityDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_COMPANY:
            return { ...state, "CompanyDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_COMPANY:
            return { ...state, "CompanyDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_COMPANY:
            return { ...state, "CompanyDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_COMPANY:
            return { ...state, "CompanyDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_COMPANY:
            return { ...state, "CompanyDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_COMPANYDETAILS:
            return { ...state, "CompanyDetailsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_COMPANYDETAILS:
            return { ...state, "CompanyDetailsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_COMPANYDETAILS:
            return { ...state, "CompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_COMPANYDETAILS:
            return { ...state, "CompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_COMPANYDETAILS:
            return { ...state, "CompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CURRENCYEXCHANGERATE:
            return { ...state, "CurrencyExchangeRateDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CURRENCYEXCHANGERATE:
            return { ...state, "CurrencyExchangeRateDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CURRENCYEXCHANGERATE:
            return { ...state, "CurrencyExchangeRateDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CURRENCYEXCHANGERATE:
            return { ...state, "CurrencyExchangeRateDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CURRENCYEXCHANGERATE:
            return { ...state, "CurrencyExchangeRateDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_DEBTCOLLECTIONCOST:
            return { ...state, "DebtCollectionCostDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_DEBTCOLLECTIONCOST:
            return { ...state, "DebtCollectionCostDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_DEBTCOLLECTIONCOST:
            return { ...state, "DebtCollectionCostDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_DEBTCOLLECTIONCOST:
            return { ...state, "DebtCollectionCostDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_DEBTCOLLECTIONCOST:
            return { ...state, "DebtCollectionCostDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, "DebtCollectionCostDetailsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, "DebtCollectionCostDetailsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, "DebtCollectionCostDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, "DebtCollectionCostDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS:
            return { ...state, "DebtCollectionCostDetailsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_DOCUMENT:
            return { ...state, "DocumentDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_DOCUMENT:
            return { ...state, "DocumentDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_DOCUMENT:
            return { ...state, "DocumentDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_DOCUMENT:
            return { ...state, "DocumentDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_DOCUMENT:
            return { ...state, "DocumentDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_DOCUMENTTURNOVERTYPE:
            return { ...state, "DocumentTurnoverTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_DOCUMENTTURNOVERTYPE:
            return { ...state, "DocumentTurnoverTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_DOCUMENTTURNOVERTYPE:
            return { ...state, "DocumentTurnoverTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_DOCUMENTTURNOVERTYPE:
            return { ...state, "DocumentTurnoverTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_DOCUMENTTURNOVERTYPE:
            return { ...state, "DocumentTurnoverTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_DOCUMENTTYPE:
            return { ...state, "DocumentTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_DOCUMENTTYPE:
            return { ...state, "DocumentTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_DOCUMENTTYPE:
            return { ...state, "DocumentTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_DOCUMENTTYPE:
            return { ...state, "DocumentTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_DOCUMENTTYPE:
            return { ...state, "DocumentTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_EMPLOYMENTTYPE:
            return { ...state, "EmploymentTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_EMPLOYMENTTYPE:
            return { ...state, "EmploymentTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_EMPLOYMENTTYPE:
            return { ...state, "EmploymentTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_EMPLOYMENTTYPE:
            return { ...state, "EmploymentTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_EMPLOYMENTTYPE:
            return { ...state, "EmploymentTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_ENTITY:
            return { ...state, "EntityDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_ENTITY:
            return { ...state, "EntityDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_ENTITY:
            return { ...state, "EntityDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_ENTITY:
            return { ...state, "EntityDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_ENTITY:
            return { ...state, "EntityDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_FILEUPLOAD:
            return { ...state, "FileUploadDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_FILEUPLOAD:
            return { ...state, "FileUploadDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_FILEUPLOAD:
            return { ...state, "FileUploadDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_FILEUPLOAD:
            return { ...state, "FileUploadDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_FILEUPLOAD:
            return { ...state, "FileUploadDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_FILEUPLOADTYPE:
            return { ...state, "FileUploadTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_FILEUPLOADTYPE:
            return { ...state, "FileUploadTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_FILEUPLOADTYPE:
            return { ...state, "FileUploadTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_FILEUPLOADTYPE:
            return { ...state, "FileUploadTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_FILEUPLOADTYPE:
            return { ...state, "FileUploadTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANPARTNER:
            return { ...state, "LoanPartnerDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANPARTNER:
            return { ...state, "LoanPartnerDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANPARTNER:
            return { ...state, "LoanPartnerDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANPARTNER:
            return { ...state, "LoanPartnerDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANPARTNER:
            return { ...state, "LoanPartnerDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_GENDER:
            return { ...state, "GenderDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_GENDER:
            return { ...state, "GenderDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_GENDER:
            return { ...state, "GenderDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_GENDER:
            return { ...state, "GenderDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_GENDER:
            return { ...state, "GenderDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_GROUP:
            return { ...state, "GroupDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_GROUP:
            return { ...state, "GroupDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_GROUP:
            return { ...state, "GroupDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_GROUP:
            return { ...state, "GroupDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_GROUP:
            return { ...state, "GroupDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_INCOMESOURCE:
            return { ...state, "IncomeSourceDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_INCOMESOURCE:
            return { ...state, "IncomeSourceDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_INCOMESOURCE:
            return { ...state, "IncomeSourceDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_INCOMESOURCE:
            return { ...state, "IncomeSourceDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_INCOMESOURCE:
            return { ...state, "IncomeSourceDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_INCOMESOURCETYPE:
            return { ...state, "IncomeSourceTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_INCOMESOURCETYPE:
            return { ...state, "IncomeSourceTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_INCOMESOURCETYPE:
            return { ...state, "IncomeSourceTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_INCOMESOURCETYPE:
            return { ...state, "IncomeSourceTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_INCOMESOURCETYPE:
            return { ...state, "IncomeSourceTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_JOURNAL:
            return { ...state, "JournalDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_JOURNAL:
            return { ...state, "JournalDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_JOURNAL:
            return { ...state, "JournalDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_JOURNAL:
            return { ...state, "JournalDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_JOURNAL:
            return { ...state, "JournalDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_JOURNALHISTORY:
            return { ...state, "JournalHistoryDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_JOURNALHISTORY:
            return { ...state, "JournalHistoryDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_JOURNALHISTORY:
            return { ...state, "JournalHistoryDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_JOURNALHISTORY:
            return { ...state, "JournalHistoryDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_JOURNALHISTORY:
            return { ...state, "JournalHistoryDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_KUF:
            return { ...state, "KUFDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_KUF:
            return { ...state, "KUFDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_KUF:
            return { ...state, "KUFDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_KUF:
            return { ...state, "KUFDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_KUF:
            return { ...state, "KUFDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LEGALSERVICE_ACTIVITY:
            return { ...state, "LegalService_ActivityDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LEGALSERVICE_ACTIVITY:
            return { ...state, "LegalService_ActivityDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LEGALSERVICE_ACTIVITY:
            return { ...state, "LegalService_ActivityDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LEGALSERVICE_ACTIVITY:
            return { ...state, "LegalService_ActivityDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LEGALSERVICE_ACTIVITY:
            return { ...state, "LegalService_ActivityDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LEGALSERVICE_COURT:
            return { ...state, "LegalService_CourtDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LEGALSERVICE_COURT:
            return { ...state, "LegalService_CourtDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LEGALSERVICE_COURT:
            return { ...state, "LegalService_CourtDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LEGALSERVICE_COURT:
            return { ...state, "LegalService_CourtDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LEGALSERVICE_COURT:
            return { ...state, "LegalService_CourtDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LEGALSERVICE_DOCUMENT:
            return { ...state, "LegalService_DocumentDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LEGALSERVICE_DOCUMENT:
            return { ...state, "LegalService_DocumentDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LEGALSERVICE_DOCUMENT:
            return { ...state, "LegalService_DocumentDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LEGALSERVICE_DOCUMENT:
            return { ...state, "LegalService_DocumentDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LEGALSERVICE_DOCUMENT:
            return { ...state, "LegalService_DocumentDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE:
            return { ...state, "LegalService_ExecutionTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE:
            return { ...state, "LegalService_ExecutionTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LEGALSERVICE_EXECUTIONTYPE:
            return { ...state, "LegalService_ExecutionTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LEGALSERVICE_EXECUTIONTYPE:
            return { ...state, "LegalService_ExecutionTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE:
            return { ...state, "LegalService_ExecutionTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LEGALSERVICE_WARNING:
            return { ...state, "LegalService_WarningDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LEGALSERVICE_WARNING:
            return { ...state, "LegalService_WarningDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LEGALSERVICE_WARNING:
            return { ...state, "LegalService_WarningDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LEGALSERVICE_WARNING:
            return { ...state, "LegalService_WarningDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LEGALSERVICE_WARNING:
            return { ...state, "LegalService_WarningDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOAN:
            return { ...state, "LoanDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOAN:
            return { ...state, "LoanDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOAN:
            return { ...state, "LoanDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOAN:
            return { ...state, "LoanDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOAN:
            return { ...state, "LoanDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANAPPROVAL:
            return { ...state, "LoanApprovalDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANAPPROVAL:
            return { ...state, "LoanApprovalDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANAPPROVAL:
            return { ...state, "LoanApprovalDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANAPPROVAL:
            return { ...state, "LoanApprovalDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANAPPROVAL:
            return { ...state, "LoanApprovalDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANAPPROVALSETTINGS:
            return { ...state, "LoanApprovalSettingsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANAPPROVALSETTINGS:
            return { ...state, "LoanApprovalSettingsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANAPPROVALSETTINGS:
            return { ...state, "LoanApprovalSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANAPPROVALSETTINGS:
            return { ...state, "LoanApprovalSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANAPPROVALSETTINGS:
            return { ...state, "LoanApprovalSettingsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANHISTORY:
            return { ...state, "LoanHistoryDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANHISTORY:
            return { ...state, "LoanHistoryDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANHISTORY:
            return { ...state, "LoanHistoryDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANHISTORY:
            return { ...state, "LoanHistoryDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANHISTORY:
            return { ...state, "LoanHistoryDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANMORATORIUM:
            return { ...state, "LoanMoratoriumDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANMORATORIUM:
            return { ...state, "LoanMoratoriumDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANMORATORIUM:
            return { ...state, "LoanMoratoriumDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANMORATORIUM:
            return { ...state, "LoanMoratoriumDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANMORATORIUM:
            return { ...state, "LoanMoratoriumDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANQUALITY:
            return { ...state, "LoanQualityDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANQUALITY:
            return { ...state, "LoanQualityDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANQUALITY:
            return { ...state, "LoanQualityDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANQUALITY:
            return { ...state, "LoanQualityDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANQUALITY:
            return { ...state, "LoanQualityDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANQUALITYSTATUS:
            return { ...state, "LoanQualityStatusDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANQUALITYSTATUS:
            return { ...state, "LoanQualityStatusDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANQUALITYSTATUS:
            return { ...state, "LoanQualityStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANQUALITYSTATUS:
            return { ...state, "LoanQualityStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANQUALITYSTATUS:
            return { ...state, "LoanQualityStatusDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANREPAYMENTSCHEDULE:
            return { ...state, "LoanRepaymentScheduleDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANREPAYMENTSCHEDULE:
            return { ...state, "LoanRepaymentScheduleDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANREPAYMENTSCHEDULE:
            return { ...state, "LoanRepaymentScheduleDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANREPAYMENTSCHEDULE:
            return { ...state, "LoanRepaymentScheduleDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANREPAYMENTSCHEDULE:
            return { ...state, "LoanRepaymentScheduleDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, "LoanRepaymentScheduleTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, "LoanRepaymentScheduleTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, "LoanRepaymentScheduleTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, "LoanRepaymentScheduleTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, "LoanRepaymentScheduleTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANSTATUS:
            return { ...state, "LoanStatusDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANSTATUS:
            return { ...state, "LoanStatusDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANSTATUS:
            return { ...state, "LoanStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANSTATUS:
            return { ...state, "LoanStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANSTATUS:
            return { ...state, "LoanStatusDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANTAKENOUT:
            return { ...state, "LoanTakenOutDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANTAKENOUT:
            return { ...state, "LoanTakenOutDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANTAKENOUT:
            return { ...state, "LoanTakenOutDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANTAKENOUT:
            return { ...state, "LoanTakenOutDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANTAKENOUT:
            return { ...state, "LoanTakenOutDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANTYPE:
            return { ...state, "LoanTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANTYPE:
            return { ...state, "LoanTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANTYPE:
            return { ...state, "LoanTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANTYPE:
            return { ...state, "LoanTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANTYPE:
            return { ...state, "LoanTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_MARITALSTATUS:
            return { ...state, "MaritalStatusDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_MARITALSTATUS:
            return { ...state, "MaritalStatusDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_MARITALSTATUS:
            return { ...state, "MaritalStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_MARITALSTATUS:
            return { ...state, "MaritalStatusDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_MARITALSTATUS:
            return { ...state, "MaritalStatusDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_OPINIONOFTHEREFERENT:
            return { ...state, "OpinionOfTheReferentDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_OPINIONOFTHEREFERENT:
            return { ...state, "OpinionOfTheReferentDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_OPINIONOFTHEREFERENT:
            return { ...state, "OpinionOfTheReferentDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_OPINIONOFTHEREFERENT:
            return { ...state, "OpinionOfTheReferentDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_OPINIONOFTHEREFERENT:
            return { ...state, "OpinionOfTheReferentDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_ORDER:
            return { ...state, "OrderDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_ORDER:
            return { ...state, "OrderDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_ORDER:
            return { ...state, "OrderDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_ORDER:
            return { ...state, "OrderDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_ORDER:
            return { ...state, "OrderDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PARTNER:
            return { ...state, "PartnerDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PARTNER:
            return { ...state, "PartnerDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PARTNER:
            return { ...state, "PartnerDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PARTNER:
            return { ...state, "PartnerDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PARTNER:
            return { ...state, "PartnerDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PARTNERNOTE:
            return { ...state, "PartnerNoteDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PARTNERNOTE:
            return { ...state, "PartnerNoteDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PARTNERNOTE:
            return { ...state, "PartnerNoteDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PARTNERNOTE:
            return { ...state, "PartnerNoteDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PARTNERNOTE:
            return { ...state, "PartnerNoteDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PARTNERTYPE:
            return { ...state, "PartnerTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PARTNERTYPE:
            return { ...state, "PartnerTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PARTNERTYPE:
            return { ...state, "PartnerTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PARTNERTYPE:
            return { ...state, "PartnerTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PARTNERTYPE:
            return { ...state, "PartnerTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PAYMENTNOTETYPE:
            return { ...state, "PaymentNoteTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PAYMENTNOTETYPE:
            return { ...state, "PaymentNoteTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PAYMENTNOTETYPE:
            return { ...state, "PaymentNoteTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PAYMENTNOTETYPE:
            return { ...state, "PaymentNoteTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PAYMENTNOTETYPE:
            return { ...state, "PaymentNoteTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PAYMENTWAY:
            return { ...state, "PaymentWayDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PAYMENTWAY:
            return { ...state, "PaymentWayDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PAYMENTWAY:
            return { ...state, "PaymentWayDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PAYMENTWAY:
            return { ...state, "PaymentWayDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PAYMENTWAY:
            return { ...state, "PaymentWayDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PAYWARNING:
            return { ...state, "PayWarningDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PAYWARNING:
            return { ...state, "PayWarningDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PAYWARNING:
            return { ...state, "PayWarningDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PAYWARNING:
            return { ...state, "PayWarningDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PAYWARNING:
            return { ...state, "PayWarningDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PAYWARNINGTYPE:
            return { ...state, "PayWarningTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PAYWARNINGTYPE:
            return { ...state, "PayWarningTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PAYWARNINGTYPE:
            return { ...state, "PayWarningTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PAYWARNINGTYPE:
            return { ...state, "PayWarningTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PAYWARNINGTYPE:
            return { ...state, "PayWarningTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PENALTYINTEREST:
            return { ...state, "PenaltyInterestSettingsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PENALTYINTEREST:
            return { ...state, "PenaltyInterestSettingsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PENALTYINTEREST:
            return { ...state, "PenaltyInterestSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PENALTYINTEREST:
            return { ...state, "PenaltyInterestSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PENALTYINTEREST:
            return { ...state, "PenaltyInterestSettingsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PENALTYINTERESTDETAILS:
            return { ...state, "PenaltyInterestDetailsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PENALTYINTERESTDETAILS:
            return { ...state, "PenaltyInterestDetailsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PENALTYINTERESTDETAILS:
            return { ...state, "PenaltyInterestDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PENALTYINTERESTDETAILS:
            return { ...state, "PenaltyInterestDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PENALTYINTERESTDETAILS:
            return { ...state, "PenaltyInterestDetailsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_PLAN:
            return { ...state, "PlanDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_PLAN:
            return { ...state, "PlanDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_PLAN:
            return { ...state, "PlanDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_PLAN:
            return { ...state, "PlanDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_PLAN:
            return { ...state, "PlanDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_QUALIFICATIONS:
            return { ...state, "QualificationsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_QUALIFICATIONS:
            return { ...state, "QualificationsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_QUALIFICATIONS:
            return { ...state, "QualificationsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_QUALIFICATIONS:
            return { ...state, "QualificationsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_QUALIFICATIONS:
            return { ...state, "QualificationsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_REALESTATES:
            return { ...state, "RealEstatesDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_REALESTATES:
            return { ...state, "RealEstatesDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_REALESTATES:
            return { ...state, "RealEstatesDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_REALESTATES:
            return { ...state, "RealEstatesDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_REALESTATES:
            return { ...state, "RealEstatesDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_REFINANCING:
            return { ...state, "RefinancingDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_REFINANCING:
            return { ...state, "RefinancingDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_REFINANCING:
            return { ...state, "RefinancingDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_REFINANCING:
            return { ...state, "RefinancingDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_REFINANCING:
            return { ...state, "RefinancingDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_REGION:
            return { ...state, "RegionDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_REGION:
            return { ...state, "RegionDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_REGION:
            return { ...state, "RegionDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_REGION:
            return { ...state, "RegionDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_REGION:
            return { ...state, "RegionDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_SETTINGS:
            return { ...state, "SettingsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_SETTINGS:
            return { ...state, "SettingsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_SETTINGS:
            return { ...state, "SettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_SETTINGS:
            return { ...state, "SettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_SETTINGS:
            return { ...state, "SettingsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_SMS:
            return { ...state, "SMSDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_SMS:
            return { ...state, "SMSDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_SMS:
            return { ...state, "SMSDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_SMS:
            return { ...state, "SMSDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_SMS:
            return { ...state, "SMSDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_TAXGROUPS:
            return { ...state, "TaxGroupsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_TAXGROUPS:
            return { ...state, "TaxGroupsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_TAXGROUPS:
            return { ...state, "TaxGroupsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_TAXGROUPS:
            return { ...state, "TaxGroupsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_TAXGROUPS:
            return { ...state, "TaxGroupsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_TURNOVER:
            return { ...state, "TurnoverDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_TURNOVER:
            return { ...state, "TurnoverDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_TURNOVER:
            return { ...state, "TurnoverDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_TURNOVER:
            return { ...state, "TurnoverDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_TURNOVER:
            return { ...state, "TurnoverDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_TYPEOFLOANCHANGE:
            return { ...state, "TypeOfLoanChangeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_TYPEOFLOANCHANGE:
            return { ...state, "TypeOfLoanChangeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_TYPEOFLOANCHANGE:
            return { ...state, "TypeOfLoanChangeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_TYPEOFLOANCHANGE:
            return { ...state, "TypeOfLoanChangeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_TYPEOFLOANCHANGE:
            return { ...state, "TypeOfLoanChangeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_USER:
            return { ...state, "UserDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_USER:
            return { ...state, "UserDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_USER:
            return { ...state, "UserDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_USER:
            return { ...state, "UserDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_USER:
            return { ...state, "UserDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_USERACTION:
            return { ...state, "UserActionDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_USERACTION:
            return { ...state, "UserActionDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_USERACTION:
            return { ...state, "UserActionDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_USERACTION:
            return { ...state, "UserActionDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_USERACTION:
            return { ...state, "UserActionDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_USERBRANCH:
            return { ...state, "UserBranchDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_USERBRANCH:
            return { ...state, "UserBranchDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_USERBRANCH:
            return { ...state, "UserBranchDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_USERBRANCH:
            return { ...state, "UserBranchDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_USERBRANCH:
            return { ...state, "UserBranchDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_USERCOMPANYDETAILS:
            return { ...state, "UserCompanyDetailsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_USERCOMPANYDETAILS:
            return { ...state, "UserCompanyDetailsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_USERCOMPANYDETAILS:
            return { ...state, "UserCompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_USERCOMPANYDETAILS:
            return { ...state, "UserCompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_USERCOMPANYDETAILS:
            return { ...state, "UserCompanyDetailsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_VEHICLE:
            return { ...state, "VehicleDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_VEHICLE:
            return { ...state, "VehicleDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_VEHICLE:
            return { ...state, "VehicleDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_VEHICLE:
            return { ...state, "VehicleDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_VEHICLE:
            return { ...state, "VehicleDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_WAYOFKNOWING:
            return { ...state, "WayOfKnowingDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_WAYOFKNOWING:
            return { ...state, "WayOfKnowingDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_WAYOFKNOWING:
            return { ...state, "WayOfKnowingDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_WAYOFKNOWING:
            return { ...state, "WayOfKnowingDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_WAYOFKNOWING:
            return { ...state, "WayOfKnowingDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_WORKER:
            return { ...state, "WorkerDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_WORKER:
            return { ...state, "WorkerDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_WORKER:
            return { ...state, "WorkerDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_WORKER:
            return { ...state, "WorkerDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_WORKER:
            return { ...state, "WorkerDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_WORKSPACE:
            return { ...state, "WorkspaceDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_WORKSPACE:
            return { ...state, "WorkspaceDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_WORKSPACE:
            return { ...state, "WorkspaceDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_WORKSPACE:
            return { ...state, "WorkspaceDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_WORKSPACE:
            return { ...state, "WorkspaceDialog": { IsOpen: false, type: 'none', item: null } };


        case ADD_DIALOG_RELATEDPERSON:
            return { ...state, "RelatedPersonDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_RELATEDPERSON:
            return { ...state, "RelatedPersonDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_RELATEDPERSON:
            return { ...state, "RelatedPersonDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_RELATEDPERSON:
            return { ...state, "RelatedPersonDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_RELATEDPERSON:
            return { ...state, "RelatedPersonDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CRKREQUEST:
            return { ...state, "CRKRequestDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CRKREQUEST:
            return { ...state, "CRKRequestDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CRKREQUEST:
            return { ...state, "CRKRequestDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CRKREQUEST:
            return { ...state, "CRKRequestDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CRKREQUEST:
            return { ...state, "CRKRequestDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CRKREQUESTTYPE:
            return { ...state, "CRKRequestTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CRKREQUESTTYPE:
            return { ...state, "CRKRequestTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CRKREQUESTTYPE:
            return { ...state, "CRKRequestTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CRKREQUESTTYPE:
            return { ...state, "CRKRequestTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CRKREQUESTTYPE:
            return { ...state, "CRKRequestTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CRKRESPONSE:
            return { ...state, "CRKResponseDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CRKRESPONSE:
            return { ...state, "CRKResponseDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CRKRESPONSE:
            return { ...state, "CRKResponseDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CRKRESPONSE:
            return { ...state, "CRKResponseDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CRKRESPONSE:
            return { ...state, "CRKResponseDialog": { IsOpen: false, type: 'none', item: null } };


        case ADD_DIALOG_COMPANYACTIVITYCODE:
            return { ...state, "CompanyActivityCodeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_COMPANYACTIVITYCODE:
            return { ...state, "CompanyActivityCodeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_COMPANYACTIVITYCODE:
            return { ...state, "CompanyActivityCodeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_COMPANYACTIVITYCODE:
            return { ...state, "CompanyActivityCodeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_COMPANYACTIVITYCODE:
            return { ...state, "CompanyActivityCodeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_EARLYREPAYMENTSETTINGS:
            return { ...state, "EarlyRepaymentSettingsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_EARLYREPAYMENTSETTINGS:
            return { ...state, "EarlyRepaymentSettingsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_EARLYREPAYMENTSETTINGS:
            return { ...state, "EarlyRepaymentSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_EARLYREPAYMENTSETTINGS:
            return { ...state, "EarlyRepaymentSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_EARLYREPAYMENTSETTINGS:
            return { ...state, "EarlyRepaymentSettingsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_CRKARCHIVE:
            return { ...state, "CRKArchiveDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_CRKARCHIVE:
            return { ...state, "CRKArchiveDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_CRKARCHIVE:
            return { ...state, "CRKArchiveDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_CRKARCHIVE:
            return { ...state, "CRKArchiveDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_CRKARCHIVE:
            return { ...state, "CRKArchiveDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_LOANWRITEOFF:
            return { ...state, "LoanWriteOffDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_LOANWRITEOFF:
            return { ...state, "LoanWriteOffDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_LOANWRITEOFF:
            return { ...state, "LoanWriteOffDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_LOANWRITEOFF:
            return { ...state, "LoanWriteOffDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_LOANWRITEOFF:
            return { ...state, "LoanWriteOffDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_REVENUERECOGNITION:
            return { ...state, "RevenueRecognitionDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_REVENUERECOGNITION:
            return { ...state, "RevenueRecognitionDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_REVENUERECOGNITION:
            return { ...state, "RevenueRecognitionDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_REVENUERECOGNITION:
            return { ...state, "RevenueRecognitionDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_REVENUERECOGNITION:
            return { ...state, "RevenueRecognitionDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_UNPARTNERDETAILS:
            return { ...state, "UNPartnerDetailsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_UNPARTNERDETAILS:
            return { ...state, "UNPartnerDetailsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_UNPARTNERDETAILS:
            return { ...state, "UNPartnerDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_UNPARTNERDETAILS:
            return { ...state, "UNPartnerDetailsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_UNPARTNERDETAILS:
            return { ...state, "UNPartnerDetailsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_ORDERTYPE:
            return { ...state, "OrderTypeDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_ORDERTYPE:
            return { ...state, "OrderTypeDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_ORDERTYPE:
            return { ...state, "OrderTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_ORDERTYPE:
            return { ...state, "OrderTypeDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_ORDERTYPE:
            return { ...state, "OrderTypeDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_COMMISSIONSETTINGS:
            return { ...state, "CommissionSettingsDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_COMMISSIONSETTINGS:
            return { ...state, "CommissionSettingsDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_COMMISSIONSETTINGS:
            return { ...state, "CommissionSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_COMMISSIONSETTINGS:
            return { ...state, "CommissionSettingsDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_COMMISSIONSETTINGS:
            return { ...state, "CommissionSettingsDialog": { IsOpen: false, type: 'none', item: null } };

        case ADD_DIALOG_SOURCEOFFINANCING:
            return { ...state, "SourceOfFinancingDialog": { IsOpen: true, type: 'add', item: action.payload } };
        case EDIT_DIALOG_SOURCEOFFINANCING:
            return { ...state, "SourceOfFinancingDialog": { IsOpen: true, type: 'edit', item: action.payload } };
        case CREATE_SOURCEOFFINANCING:
            return { ...state, "SourceOfFinancingDialog": { IsOpen: false, type: 'none', item: null } };
        case UPDATE_SOURCEOFFINANCING:
            return { ...state, "SourceOfFinancingDialog": { IsOpen: false, type: 'none', item: null } };
        case CLOSE_DIALOG_SOURCEOFFINANCING:
            return { ...state, "SourceOfFinancingDialog": { IsOpen: false, type: 'none', item: null } };
        default:
            return state;
    }
}
export default dialogReducer;