import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { closeDialogLoanPartner } from '../../actions/loanPartnerAction';
import { EditSelectField, EditTextField } from '../Share/EditComponents';
import ComponentInLine from '../Share/ComponentInLine';
import CreatePartner from '../Partner/CreatePartner';
import EditPartner from '../Partner/EditPartner';
import { addDialogPartner, editDialogPartner, getPartner } from "../../actions/partnerAction";
import Validation from '../../share/validation';

import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCities } from '../../actions/cityAction';
import { getSelectBoxGenders } from '../../actions/genderAction';
import { getSelectBoxMaritalStatus } from '../../actions/maritalStatusAction';
import { getSelectBoxPartnerTypes } from '../../actions/partnerTypeAction';
import { getSelectBoxEntities } from '../../actions/entityAction';
import { getSelectBoxEmploymentTypes } from '../../actions/employmentTypeAction';
import i18next from '../../language/i18n';

class FormLoanPartner extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.initialValues);
        let data = props.initialValues?.billNumber?.split(';');
        this.state = { billNumberAdditional: data?.length > 0 ? data.length -1 : 0 }
    }
    componentDidMount = () => {
        if (!this.props.isSBLoaded) {
            this.props.getSelectBoxCompanies();
            this.props.getSelectBoxCities();
            this.props.getSelectBoxGenders();
            this.props.getSelectBoxMaritalStatus();
            this.props.getSelectBoxPartnerTypes();
            this.props.getSelectBoxEntities();
            this.props.getSelectBoxEmploymentTypes();
        }
    }
    
    // shouldComponentUpdate = (nextProps, nextState) => {
    //     console.log(this.props.title, nextProps.initialValues, this.props.initialValues);
    //     if (nextProps.initialValues?.billNumber !== this.props.initialValues?.billNumber) {
    //         let data = nextProps.initialValues?.billNumber?.split(';');
    //         this.setState({ billNumberAdditional: data?.length > 0 ? data.length -1 : 0 });
    //     }
    //     return true;
    // }
    handleClose = () => {
        this.props.closeDialogLoanPartner();
    }
    editParnter = async () => {
        let partnerInfo = this.props.partners.find(el => el.id === this.props.formValues.partnerID);
        if (partnerInfo === undefined) {
            partnerInfo = await this.props.getPartner(this.props.formValues.partnerID);
        }
        this.props.editDialogPartner(partnerInfo.partnerData);
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.lastPartnerID !== this.props.lastPartnerID) {
            this.props.change('partnerID', this.props.lastPartnerID)
        }
    }
    billNumberAdditionalFields = () => {
        let result = []
        for (let i = 0; i < this.state.billNumberAdditional; i++) {
            result.push(
                <ComponentInLine key={i} xs={[11, 1]}>
                    <div>
                        <Field
                            name={'billNumber' + (i + 1)}
                            component={this.props.EditTextField}
                            label='BillNumber'
                        />
                    </div>
                    <div className={"add-buttons-plus"}>
                        <Button onClick={() => { this.setState({ billNumberAdditional: this.state.billNumberAdditional + 1 }) }} color="primary" variant="contained" className={"add-button-plus-round"}>
                            +
                        </Button>
                    </div>
                </ComponentInLine>
            );
        }
        return result;
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    maxWidth={'md'}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>

                            <div>
                                <Field
                                    name='loanPartnerTypeID'
                                    component={this.props.EditSelectField}
                                    label='LoanPartnerType'
                                    dataSource={this.props.loanPartnerTypesSelectBox}
                                >
                                </Field>
                            </div>
                            <ComponentInLine xs={[9, 3]}>
                                <div>
                                    <Field
                                        name='partnerID'
                                        component={this.props.EditSelectField}
                                        label='Partner'
                                        dataSource={this.props.partnersSelectBox}
                                    >
                                    </Field>
                                </div>
                                <ComponentInLine className={"add-edit-buttons-group"}>
                                    <Button onClick={this.editParnter} color="primary" variant="contained">
                                        {i18next.t('Edit')}
                                    </Button>
                                    <Button onClick={this.props.addDialogPartner} color="primary" variant="contained">
                                        {i18next.t('Add')}
                                    </Button>
                                </ComponentInLine>
                            </ComponentInLine>

                            <ComponentInLine xs={[11, 1]}>
                                <div>
                                    <Field
                                        name='billNumber0'
                                        component={this.props.EditTextField}
                                        label='BillNumber'
                                    />
                                </div>
                                <div className={"add-buttons-plus"}>

                                    <Button onClick={() => { this.setState({ billNumberAdditional: this.state.billNumberAdditional + 1 }) }} color="primary" variant="contained" className={"add-button-plus-round"}>
                                        +
                                    </Button>
                                </div>
                            </ComponentInLine>
                            {
                                this.billNumberAdditionalFields()
                            }
                            <div>
                                <Field
                                    name='riskLevelID'
                                    component={this.props.EditSelectField}
                                    label='RiskLevel'
                                    dataSource={this.props.riskLevelSelectBox}
                                >
                                </Field>
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>

                <CreatePartner></CreatePartner>
                <EditPartner></EditPartner>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if(!Validation.isSelected(formValues.partnerID)){
        errors.partnerID = 'Polje je obavezno!';
    }
    if (!Validation.isSelected(formValues.loanPartnerTypeID)) {
        errors.loanPartnerTypeID = 'Polje je obavezno!';
    }
    if (!formValues.billNumber0) {
        errors.billNumber = 'Polje je obavezno!';
    }
    if (!Validation.isSelected(formValues.riskLevelID)) {
        errors.riskLevelID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        loansSelectBox: Object.values(state.loansSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        partners: Object.values(state.partners),
        loanPartnerTypesSelectBox: Object.values(state.loanPartnerTypesSelectBox),
        isSBLoaded: Object.values(state.maritalStatusSelectBox).length > 0,
        lastPartnerID: state.lastPartnerID,
        riskLevelSelectBox: Object.values(state.riskLevelSelectBox),
        formValues: getFormValues('formLoanPartner')(state)
    }
}
const formWrapper = reduxForm({
    form: 'formLoanPartner',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormLoanPartner);

export default connect(mapStateToProps, {
    closeDialogLoanPartner,
    EditSelectField,
    EditTextField,
    addDialogPartner,
    editDialogPartner,
    getPartner,
    getSelectBoxCompanies,
    getSelectBoxCities,
    getSelectBoxGenders,
    getSelectBoxMaritalStatus,
    getSelectBoxPartnerTypes,
    getSelectBoxEntities,
    getSelectBoxEmploymentTypes,
    change
})(formWrapper);