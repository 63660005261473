import api from "../api/myApi";
import { responseHandler } from "../api/responseHandler";
import { reset } from "redux-form";

export const login = (formValues) => async (dispatch) => {
    const response = await api.post('/api/Account/authenticate', formValues);
    if(response.status===200){
        if(response.data.succeeded){
            let userInfo = {
                jwt: response.data.data.jwToken,
                email: response.data.data.email,
                userName: response.data.data.userName,
                roles : response.data.data.roles,
                isAuth: true,
                userID: response.data.data.id,
            }
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            localStorage.setItem('token', response.data.data.jwToken);
            
            dispatch({
                type: "login",
                payload: userInfo
            });
        }
        else{
            dispatch({
                type: "error",
                payload: response.data.Message
            });
        }
    }
}
export const changePassword = (formValues) => async(dispatch) =>{
    const response = await api.post('/api/Account/changePassword', formValues);
    dispatch(responseHandler(response, 'success'));
    if(response.data?.succeeded){
        dispatch(reset('formChangePassword'));
    }
}
export const getUserInfo = () => (dispatch) => {    
    let item = localStorage.getItem('userInfo');
    let userInfo = null;
    if(item !== null){
        userInfo = JSON.parse(item);
    }
    dispatch({
        type: "login",
        payload: userInfo
    });
}
export const logout = () => async (dispatch) => {
    localStorage.clear();
    dispatch({
        type: "logout",
        payload: null
    });
}