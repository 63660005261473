//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createRevenueRecognition } from '../../actions/revenueRecognitionAction';
import FormRevenueRecognition from './FormRevenueRecognition';

class CreateRevenueRecognition extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loan.loan.id;
        this.props.createRevenueRecognition(formValues);
    }
    
    render(){
        return (
            <FormRevenueRecognition 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormRevenueRecognition>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RevenueRecognitionDialog === undefined || state.dialog.RevenueRecognitionDialog.type !== 'add'? 
  {
    IsOpen:false,
    loan: state.loanSelected
} : 
{
    ...state.dialog.RevenueRecognitionDialog,
    loan: state.loanSelected
};
}


export default connect(mapStateToProps, { createRevenueRecognition })(CreateRevenueRecognition);
