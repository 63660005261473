//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createEarlyRepaymentSettings } from '../../actions/earlyRepaymentSettingsAction';
import FormEarlyRepaymentSettings from './FormEarlyRepaymentSettings';

class CreateEarlyRepaymentSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createEarlyRepaymentSettings(formValues);
    }
    
    render(){
        return (
            <FormEarlyRepaymentSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormEarlyRepaymentSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.EarlyRepaymentSettingsDialog === undefined || state.dialog.EarlyRepaymentSettingsDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.EarlyRepaymentSettingsDialog};
}


export default connect(mapStateToProps, { createEarlyRepaymentSettings })(CreateEarlyRepaymentSettings);
