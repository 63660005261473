import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createBranch } from '../../actions/branchAction';
import FormBranch from './FormBranch';

class CreateBranch extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createBranch(formValues);
    }
    
    render(){
        return (
            <FormBranch 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormBranch>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BranchDialog === undefined || state.dialog.BranchDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BranchDialog};
}


export default connect(mapStateToProps, { createBranch })(CreateBranch);
