import React, { useState, useMemo, useEffect } from "react";
import {
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel, 
  ListSubheader,
  TextField,
  InputAdornment,
  FormHelperText
} from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { makeStyles } from '@mui/styles';
import styles from '../../assets/jss/material-dashboard-react/components/mainStyle';
import i18next from '../../language/i18n';
import SearchIcon from "@mui/icons-material/Search";
import { CompressOutlined } from "@mui/icons-material";
import { FixedSizeList as List } from 'react-window';

export const EditTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <TextField
      InputProps={{
        classes: {
          input: classes?.textBoxInput
        }
      }}
      margin="normal"
      padding="normal"
      label={i18next.t(label)}
      placeholder={i18next.t(label)}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      fullWidth
    />
  )
}

export const EditDecimalNumberField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <TextField
      InputProps={{
        classes: {
          input: classes?.textBoxInput
        }
      }}
      label={i18next.t(label)}
      placeholder={i18next.t(label)}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      fullWidth
      type="number"
      margin="normal"
      padding="normal"
    />)
}

export const EditIntegerNumberField = ({
  label,
  input,
  meta: { touched, invalid, error }, InputProps,
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <TextField
      InputProps={{
        classes: {
          input: classes?.textBoxInput
        },
        pattern: '[0-9]*'
      }}
      label={i18next.t(label)}
      placeholder={i18next.t(label)}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      fullWidth
      type="number"
      margin="normal"
      padding="normal"
    />
  );
}

export const EditIntegerNumberField2 = ({
  label,
  input,
  InputProps,
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <TextField
      InputProps={{
        classes: {
          input: classes?.textBoxInput
        },
        pattern: '[0-9]*'
      }}
      label={i18next.t(label)}
      placeholder={i18next.t(label)}
      {...input}
      {...custom}
      fullWidth
      type="number"
      margin="normal"
      padding="normal"
    />
  );
}

export const EditDatePickerField = ({
  label,
  input,
  isHighlighted,
  meta: { touched, invalid, error },
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        
        inputFormat={'dd.MM.yyyy'}
        mask={'__.__.____'}
        label={i18next.t(label)}
        InputProps={{
          classes: {
            input: isHighlighted ? classes?.textBoxInputHighlighted : classes?.textBoxInput
          }
        }}
        placeholder={i18next.t(label)}
        {...input}
        {...custom}
        fullWidth
        renderInput={(params) => {
          params.error = touched && invalid;
          return (<TextField
            margin="normal"
            padding="normal"
            fullWidth
            helperText={touched && error}
            {...params}
          />)
        }}
      />
    </LocalizationProvider>
  )
}
export const EditDatePickerField2 = ({
  label,
  input,
  ...custom
}) => {
  const classes = makeStyles(styles)();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        InputProps={{
          classes: {
            input: classes?.textBoxInput
          }
        }}
        inputFormat={'dd.MM.yyyy'}
        mask={'__.__.____'}
        label={i18next.t(label)}
        placeholder={i18next.t(label)}
        {...input}
        {...custom}
        fullWidth
        renderInput={(params) => {
          return (<TextField
            margin="normal"
            padding="normal"
            fullWidth
            {...params}
          />)
        }}
      />
    </LocalizationProvider>
  )
}

export const EditCheckbox = ({ input, label, ...custom }) => {
  const classes = makeStyles(styles)();
  return (
    <div className={classes?.checkBoxInput}>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            {...custom}
          />
        }
        label={i18next.t(label)}
      />
    </div>
  );
}

export const EditCheckbox2 = ({ value, onChange, label, ...custom }) => {
  const classes = makeStyles(styles)();
  return (
    <div className={classes?.checkBoxInput}>
      <FormControlLabel
        control={
          <Checkbox
            checked={value ? true : false}
            onChange={onChange}
            {...custom}
          />
        }
        label={i18next.t(label)}
      />
    </div>
  );
}

/* export const EditSelectField = ({
   input,
   label,
   meta: { touched, error },
   children,
   ...custom
 }) => (
   <Select
   nativ
     //value={{null}}
     fullWidth
     label={i18next.t(label)}
     error={touched && error}
     {...input}
     onChange={(event, index, value) => input.onChange(value)}
     children={children}
     {...custom}
   />
 )*/
/*
export const EditSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <FormControl error={touched && error} fullWidth>
    <InputLabel htmlFor={input.name}>{i18next.t(label)}</InputLabel>
    <NativeSelect defaultValue={{null}}
    inputProps={{
      name: 'age',
      id: input.name,
    }}
      {...input}
      {...custom}
    >
      {children}
    </NativeSelect>    
  </FormControl>
)*/
//EditTextFieldSelect
//EditSelectField //
export const EditSelectField = ({
  input,
  label,
  meta: { touched, error },
  dataSource,
  hasEmpty,
  children,
  onChange,
  ...custom
}) => {
  //const classes = makeStyles(styles)();
  if (hasEmpty == undefined) { hasEmpty = true; }
  const [searchText, setSearchText] = useState("");
  const displayedOptions = dataSource?.filter((option) => option.text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
  return (
    <FormControl
      fullWidth
      error={touched && error}
      margin="normal"
      padding="normal">
        
      <InputLabel id={input.name+'-label'}>{i18next.t(label)}</InputLabel>
      <Select

        //classes={{ select: classes?.selectInput }}
        fullWidth
        label={i18next.t(label)}
        labelId={input.name+'-label'}
        {...input}
        {...custom}
        inputProps={{
          name: label,
          id: input.name
        }}
        onClose={() => setSearchText("")}
      //searchable={true}
      >
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          placeholder="Type to search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
        {children}
        {hasEmpty ? <MenuItem key={-1} value={null} >--Select--</MenuItem> : null}
        {displayedOptions.map(el => (
          <MenuItem key={el.value} value={el.value}>
            {el.text}
          </MenuItem>
        ))}
      </Select>
      {renderFromHelper({ touched, error })}
    </FormControl>
  );
}

export const EditSelectFieldVirtual = ({
  input,
  label,
  meta: { touched, error },
  dataSource,
  hasEmpty,
  children,
  onChange,
  ...custom
}) => {
  if (hasEmpty == undefined) { hasEmpty = true; }
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [displayedOptions, setDisplayedOptions] = useState(dataSource);

  useEffect(() => {
    setDisplayedOptions(dataSource.filter((option) => option.text.toLowerCase().indexOf(searchText.toLowerCase()) > -1));
  }, [searchText, dataSource]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    input.onChange(value);
    if (onChange) {
      onChange(value);
    }
    setOpen(false); // Close the dropdown
  };

  const Row = ({ index, style }) => {
    const option = displayedOptions[index];
    return (
      <MenuItem
        style={style}
        key={option.value}
        value={option.value}
        onClick={() => handleSelectChange({ target: { value: option.value } })}
      >
        {option.text}
      </MenuItem>
    );
  };

  return (
    <FormControl
      fullWidth
      error={touched && error}
      margin="normal"
    >
      <InputLabel id={input.name + '-label'}>{label}</InputLabel>
      <Select
        fullWidth
        label={label}
        labelId={input.name + '-label'}
        value={input.value}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleSelectChange}
        {...custom}
        inputProps={{
          name: label,
          id: input.name
        }}
        renderValue={(selected) => {
          if (!selected) {
            return "--Select--";
          }
          const selectedOption = dataSource.find(option => option.value === selected);
          return selectedOption ? selectedOption.text : "--Select--";
        }}
        
      >
        <TextField
          size="small"
          autoFocus
          placeholder="Type to search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
        {children}
        {hasEmpty && <MenuItem key={-1} value={null}>--Select--</MenuItem>}
        <List
          width={500} // Adjust as needed
          height={(displayedOptions.length * 36 > 350 ? 350 : displayedOptions.length * 36)} // Adjust as needed
          itemCount={displayedOptions.length}
          itemSize={35} // Adjust as needed
        >
          {Row}
        </List>
      </Select>
      {touched && error && <div>{error}</div>}
    </FormControl>
  );
};

export const EditAutocomplete = ({
  input,
  label,
  options,
  meta: { touched, error },
  children,
  ...custom
}) => {

  const classes = makeStyles(styles)();
  let localError = (error?.length ?? 0) > 0;
  console.log(options);
  return (
    <FormControl

      fullWidth
      error={touched && localError}
      margin="normal"
      padding="normal">
      <Autocomplete
        freeSolo
        options={options ?? []}
        {...input}
        {...custom}
        getOptionLabel={(option) => option ?? "" }
        renderInput={(params) => <TextField {...params} label={i18next.t(label)} />}

      >
      </Autocomplete>
      {renderFromHelper({ touched, localError })}
    </FormControl>
  );
}

const renderFromHelper = ({ touched, error }) => {
  if (!(touched && error)) {
    return
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

export const EditTextFieldSelect = ({
  label,
  input,
  meta: { touched, invalid, error },
  children,
  dataSource,
  hasEmpty,
  ...custom
}) => {
  if (hasEmpty == undefined) { hasEmpty = true; }
  //const classes = makeStyles(styles)();
  return (
    <TextField
      select

      SelectProps={{
        native: true,
      }}
      margin="normal"
      padding="normal"
      label={i18next.t(label)}
      placeholder={i18next.t(label)}
      error={touched && invalid}
      helperText={touched && error}
      {...input}
      {...custom}
      fullWidth
    >
      {children}
      {hasEmpty ? <option value={null} /> : null}
      {dataSource?.map(el => {
        return (
          <option key={el.value} value={el.value}>
            {el.text ?? el.value}
          </ option >
        );
      })}
    </TextField>
  )
}

// export const EditSelectFieldClear = ({
//   label,
//   input,
//   children,
//   dataSource,
//   hasEmpty,
//   ...custom
// }) => {
//   if (hasEmpty == undefined) { hasEmpty = true; }
//   const classes = makeStyles(styles)();
//   return (
//     <TextField
//       select
//       InputProps={{
//         classes: {
//           input: classes?.textBoxInput
//         }
//       }}
//       SelectProps={{
//         native: true,
//       }}
//       margin="normal"
//       padding="normal"
//       label={i18next.t(label)}
//       placeholder={i18next.t(label)}
//       {...input}
//       {...custom}
//       fullWidth
//     >
//       {children}
//       {hasEmpty ? <option value={null} /> : null}
//       {dataSource?.map(el => {
//         return (
//           <option key={el.value} value={el.value}>
//             {el.text ?? el.value}
//           </ option >
//         );
//       })}
//     </TextField>
//   )
// }

export const EditSelectFieldClear = ({
  label,
  input,
  children,
  dataSource,
  hasEmpty,
  ...custom
}) => {
  if (hasEmpty == undefined) { hasEmpty = true; }
  const [searchText, setSearchText] = useState("");
  /*const displayedOptions =
    dataSource?.filter((option) => 
    option.data?.jib !== undefined ? option.text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || option.data?.jib.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    : option.text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1);    */

    const displayedOptions = dataSource?.filter((option) => option.text?.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
  const classes = makeStyles(styles)();
  return (
    <FormControl
      fullWidth
      margin="normal"
      padding="normal">
        
      <InputLabel id={label+'-label'}>{i18next.t(label)}</InputLabel>
      <Select

        //classes={{ select: classes?.selectInput }}
        fullWidth
        label={i18next.t(label)}
        labelId={label+'-label'}
        {...input}
        {...custom}
        inputProps={{
          name: label,
          id: label
        }}
        onClose={() => setSearchText("")}
      //searchable={true}
      >
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          placeholder="Type to search..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
        {children}
        {hasEmpty ? <MenuItem key={-1} value={null} >--Select--</MenuItem> : null}
        {displayedOptions.map(el => (
          <MenuItem key={el.value} value={el.value}>
            {el.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
