import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TablePayWarningToolbar from './TablePayWarningToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
//import CreatePayWarning from './CreatePayWarning';
//import EditPayWarning from './EditPayWarning';
import { getAllPayWarnings, createPayWarning, deletePayWarning, postingPayWarning } from "../../actions/payWarningAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxPayWarningTypes } from '../../actions/payWarningTypeAction';
import i18next from '../../language/i18n';
import { EditDatePickerField2 } from '../../components/Share/EditComponents';
import DateProvider from '../../share/dateProvider';
import DeleteButton from '../Share/Button/DeleteButton';


class TablePayWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      date: new Date()
    }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxPartners();
    this.props.getSelectBoxPayWarningTypes();
    this.props.getAllPayWarnings(this.state.date);
  }

  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerToSendID)?.text;
  }
  warningTypeName = (props) => {
    return this.props.warningTypes.find(el => el.value === props.row.partnerToSendID)?.text;
  }

  columns = [
    { field: 'loanDate', headerName: i18next.t('LoanDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate },

    {
      field: 'PartnerToSendID',
      headerName: i18next.t('Partner'),
      width: 160, flex: 2,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },

    /* {
         field: 'PayWarningTypeID',
         headerName: i18next.t('Partner'),
         width: 160,
         valueGetter: this.partnerName,
         sortComparator: (v1, v2, cellParams1, cellParams2) =>
         this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
       },*/
    { field: 'debtAmount', headerName: i18next.t('DebtAmount'), minWidth: 150, flex: 1 },
    { field: 'isGarantor', headerName: i18next.t('IsGarantor'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'warningCost', headerName: i18next.t('WarningCost'), minWidth: 150, flex: 1 },
    {
      field: 'PayWarningTypeID',
      headerName: i18next.t('WarningType'),
      width: 160, flex: 2,
      valueGetter: this.warningTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.warningTypeName(cellParams1).localeCompare(this.warningTypeName(cellParams2))
    },

    { field: 'isPrinted', headerName: i18next.t('IsPrinted'), minWidth: 150, flex: 1, type: 'boolean' },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    //this.props.addDialogPayWarning();
    this.props.createPayWarning({ date: this.state.date });
  }
  posting = () => {
    this.props.postingPayWarning({ date: this.state.date });
  }
  print = () => {
  }

  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <EditDatePickerField2
          date={this.state.date}
          onChange={(e) => {
            this.props.showLoadingDataInProgress();
            this.setState({ date: e });
            this.props.getAllPayWarnings(e);
          }}
          value={this.state.date}
        >
        </EditDatePickerField2>
        <DataGrid
          rows={this.props.payWarnings}
          columns={this.columns}
          components={{
            Toolbar: TablePayWarningToolbar
          }}
          componentsProps={{
            toolbar:
            {
              create: this.create,
              posting: this.posting,
              print: this.print
            }
          }}
          checkboxSelection
          disableSelectionOnClick
        />

        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deletePayWarning(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payWarnings: Object.values(state.payWarnings),
    loans: Object.values(state.loansSelectBox),
    partners: Object.values(state.partnersSelectBox),
    warningTypes: Object.values(state.payWarningTypesSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    EditDatePickerField2,
    getAllPayWarnings,
    createPayWarning,
    deletePayWarning,
    postingPayWarning,
    getSelectBoxPayWarningTypes,
    getSelectBoxPartners,
    showLoadingDataInProgress
  })(TablePayWarning);