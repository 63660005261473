import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createPartner } from '../../actions/partnerAction';
import FormPartner from './FormPartner';


class CreatePartner extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createPartner(formValues);
    }
    
    render(){
        return (
            <FormPartner 
                IsOpen = {this.props.IsOpen}
                IsNew = { true }
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormPartner>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PartnerDialog === undefined || state.dialog.PartnerDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.PartnerDialog};
}


export default connect(mapStateToProps, { createPartner })(CreatePartner);
