//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_EARLYREPAYMENTSETTINGS,
    GET_ALL_EARLYREPAYMENTSETTINGS,
    GET_EARLYREPAYMENTSETTINGS,
    GET_ALL_SELECTBOX_EARLYREPAYMENTSETTINGS,
    DELETE_EARLYREPAYMENTSETTINGS,
    UPDATE_EARLYREPAYMENTSETTINGS,
    ADD_DIALOG_EARLYREPAYMENTSETTINGS,
    EDIT_DIALOG_EARLYREPAYMENTSETTINGS,
    CLOSE_DIALOG_EARLYREPAYMENTSETTINGS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createEarlyRepaymentSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/EarlyRepaymentSettings/Create', formValues);    
    dispatch(responseHandler(response, CREATE_EARLYREPAYMENTSETTINGS));
    if(response.data?.succeeded){
        dispatch(reset('formEarlyRepaymentSettings'));
    }
}

export const getAllEarlyRepaymentSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/EarlyRepaymentSettings/GetAll');
    dispatch(responseHandler(response, GET_ALL_EARLYREPAYMENTSETTINGS));
}

export const getEarlyRepaymentSettings = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/EarlyRepaymentSettings/Get/' + id);
    dispatch(responseHandler(response, GET_EARLYREPAYMENTSETTINGS));
}

export const getSelectBoxEarlyRepaymentSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/EarlyRepaymentSettings/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_EARLYREPAYMENTSETTINGS));
}

export const updateEarlyRepaymentSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/EarlyRepaymentSettings/Update', formValues);
    dispatch(responseHandler(response, UPDATE_EARLYREPAYMENTSETTINGS));
}

export const deleteEarlyRepaymentSettings = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/EarlyRepaymentSettings/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_EARLYREPAYMENTSETTINGS));
}

export const addDialogEarlyRepaymentSettings = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_EARLYREPAYMENTSETTINGS,
        payload: null
    });
}

export const editDialogEarlyRepaymentSettings = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_EARLYREPAYMENTSETTINGS,
        payload: item
    });
}

export const closeDialogEarlyRepaymentSettings = () => async (dispatch) => {
    dispatch(reset('formEarlyRepaymentSettings'));
    dispatch({
        type: CLOSE_DIALOG_EARLYREPAYMENTSETTINGS,
        payload: undefined
    });
}