import api from "../api/myApi";
import { 
    CREATE_RELATEDPERSON,
    GET_ALL_RELATEDPERSON,
    GET_RELATEDPERSON,
    GET_ALL_SELECTBOX_RELATEDPERSON,
    DELETE_RELATEDPERSON,
    UPDATE_RELATEDPERSON,
    ADD_DIALOG_RELATEDPERSON,
    EDIT_DIALOG_RELATEDPERSON,
    CLOSE_DIALOG_RELATEDPERSON,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createRelatedPerson = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/RelatedPerson/Create', formValues);    
    dispatch(responseHandler(response, CREATE_RELATEDPERSON));
    if(response.data?.succeeded){
        dispatch(reset('formRelatedPerson'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllRelatedPersons = () => async (dispatch) => {
    const response = await api.get('/api/v1/RelatedPerson/GetAll');
    dispatch(responseHandler(response, GET_ALL_RELATEDPERSON));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

// export const getRelatedPerson = (id) => async (dispatch) => {
//     const response = await api.get('/api/v1/RelatedPerson/Get/' + id);
//     dispatch(responseHandler(response, GET_RELATEDPERSON));
// }

// export const getSelectBoxRelatedPersons = () => async (dispatch) => {
//     const response = await api.get('/api/v1/RelatedPerson/GetSelectBox');
//     dispatch(responseHandler(response, GET_ALL_SELECTBOX_RELATEDPERSON));
// }

export const updateRelatedPerson = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/RelatedPerson/Update', formValues);
    dispatch(responseHandler(response, UPDATE_RELATEDPERSON));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteRelatedPerson = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/RelatedPerson/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_RELATEDPERSON));
}

export const addDialogRelatedPerson = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_RELATEDPERSON,
        payload: null
    });
}

export const editDialogRelatedPerson = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_RELATEDPERSON,
        payload: item
    });
}

export const closeDialogRelatedPerson = () => async (dispatch) => {
    dispatch(reset('formRelatedPerson'));
    dispatch({
        type: CLOSE_DIALOG_RELATEDPERSON,
        payload: undefined
    });
}