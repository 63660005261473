import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { addDialogCity, editDialogCity, deleteCity, getAllCities } from "../../actions/cityAction";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DateProvider from '../../share/dateProvider';


class TableIOS extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
  }
  columns = [
    { field: 'planCode', headerName: i18next.t('PlanCode'), minWidth: 150, flex: 1 },
    { field: 'link', headerName: i18next.t('Link'), minWidth: 150, flex: 1 },
    { field: 'owes', headerName: i18next.t('Owes'), minWidth: 150, flex: 1 },
    { field: 'claim', headerName: i18next.t('Claim'), minWidth: 150, flex: 1 },
    { field: 'deadline', headerName: i18next.t('Deadline'), minWidth: 150, flex: 1, type: 'date', valueGetter: DateProvider.GetDate }

  ];
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.partnerSelected?.iosData}
          columns={this.columns}
          getRowId={(row) => row.planCode + row.link}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partnerSelected: state.partnerSelected
  };
}

export default connect(mapStateToProps, { addDialogCity, editDialogCity, deleteCity, getAllCities, createTheme })(TableIOS);