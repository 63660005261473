//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogUNPartnerDetails, updateUNPartnerDetails } from '../../actions/uNPartnerDetailsAction';
import FormUNPartnerDetails from './FormUNPartnerDetails';

class EditUNPartnerDetails extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateUNPartnerDetails(formValues);
    }
    
    render(){
        return (
            <FormUNPartnerDetails 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormUNPartnerDetails>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UNPartnerDetailsDialog === undefined || state.dialog.UNPartnerDetailsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.UNPartnerDetailsDialog};
}


export default connect(mapStateToProps, { closeDialogUNPartnerDetails, updateUNPartnerDetails })(EditUNPartnerDetails);
