import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import FormLoanPartner from './FormLoanPartner';
import { closeDialogLoanPartner, createLoanPartner } from '../../actions/loanPartnerAction';

class CreateLoanPartner extends React.Component {
    onSubmit = (formValues) => {
        console.log(formValues);
        let keys = Object.keys(formValues).filter(el => el.indexOf('billNumber') > -1 && el != 'billNumber').sort();
        let billNumber = [];
        for (let i = 0; i < keys.length; i++) {
            if (formValues[keys[i]] != null && formValues[keys[i]] != '') {
                billNumber.push(formValues[keys[i]]);
            }
        }
        formValues.billNumber = billNumber.join(';');
        console.log(formValues);
        this.props.createLoanPartner(formValues);
    }
    render() {
        return ( this.props.IsOpen ? 
            <FormLoanPartner
                IsOpen={this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormLoanPartner> : null
        );
    }
}

const mapStateToProps = state => {
    return state.dialog.LoanPartnerDialog === undefined || state.dialog.LoanPartnerDialog.type !== 'add' ? 
    { IsOpen: false } : 
    { ...state.dialog.LoanPartnerDialog };
}


export default connect(mapStateToProps, { createLoanPartner, closeDialogLoanPartner })(CreateLoanPartner);
