//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_CRKREQUEST,
    GET_ALL_CRKREQUEST,
    GET_CRKREQUEST,
    GET_ALL_SELECTBOX_CRKREQUEST,
    DELETE_CRKREQUEST,
    UPDATE_CRKREQUEST,
    ADD_DIALOG_CRKREQUEST,
    EDIT_DIALOG_CRKREQUEST,
    CLOSE_DIALOG_CRKREQUEST
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCRKRequest = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequest/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CRKREQUEST));
    if(response.data?.succeeded){
        dispatch(reset('formCRKRequest'));
    }
}

export const getAllCRKRequests = (data) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequest/GetAll', data);
    dispatch(responseHandler(response, GET_ALL_CRKREQUEST));
}

export const getCRKRequest = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CRKRequest/Get/' + id);
    dispatch(responseHandler(response, GET_CRKREQUEST));
}

export const getSelectBoxCRKRequests = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKRequest/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CRKREQUEST));
}

export const updateCRKRequest = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequest/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CRKREQUEST));
}

export const deleteCRKRequest = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKRequest/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CRKREQUEST));
}

export const addDialogCRKRequest = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CRKREQUEST,
        payload: null
    });
}

export const editDialogCRKRequest = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CRKREQUEST,
        payload: item
    });
}

export const closeDialogCRKRequest = () => async (dispatch) => {
    dispatch(reset('formCRKRequest'));
    dispatch({
        type: CLOSE_DIALOG_CRKREQUEST,
        payload: undefined
    });
}