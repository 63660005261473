import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogUser } from '../../actions/userAction';
import { EditSelectField, EditTextField, EditDatePickerField } from '../Share/EditComponents';

class FormUser extends React.Component{
    handleClose = () => {
        this.props.closeDialogUser();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='email'
                                    component={this.props.EditTextField}
                                    label='Email'
                                    disabled={!this.props.IsNew}
                                />
                            </div>
                            {
                                this.props.IsNew ? (<>
                                <div>
                                    <Field
                                        name='password'
                                        component={this.props.EditTextField}
                                        label='Password'
                                        type="password"
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='confirmPassword'
                                        component={this.props.EditTextField}
                                        label='ConfirmPassword'
                                        type="password"
                                    />
                                </div></>)
                                : null
                            }
                            <div>
                                <Field
                                    name='roles'
                                    component ={this.props.EditSelectField}
                                    label = 'RoleName'
                                    dataSource={this.props.roles?.filter(el => el.text !== 'Broker')}
                                    >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='firstName'
                                    component={this.props.EditTextField}
                                    label='FirstName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastName'
                                    component={this.props.EditTextField}
                                    label='LastName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='phoneNumber'
                                    component={this.props.EditTextField}
                                    label='Phone'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.userName){
        errors.userName = 'Polje je obavezno!';
    }
    if(!formValues.email){
        errors.email = 'Polje je obavezno!';
    }
    if(!formValues.firstName){
        errors.firstName = 'Polje je obavezno!';
    }
    
    if(!formValues.lastName){
        errors.lastName = 'Polje je obavezno!';
    }
    if(formValues.password != formValues.confirmPassword){
        errors.confirmPassword = 'Potvrda se ne poklapa sa lozinkom';
    }
    return errors;
}

const mapStateToProps = state => {
    return {
      roles: Object.values(state.rolesSelectBox), 
    };
}

const formWrapper = reduxForm({
    form: 'formUser',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormUser);

export default connect(mapStateToProps, { closeDialogUser, EditSelectField, EditTextField, EditDatePickerField})(formWrapper);