import {
    GET_LOAN,
    UPDATE_LOAN,
    UPDATE_LOAN_MADE_ACTION
} from '../../actions/types';

const loanSelectedReducer = (state = {}, action) => {
    switch(action.type){
        case GET_LOAN:
            return { ...action.payload };
        case UPDATE_LOAN:
            return { ...state, loan:action.payload.loan};
        case UPDATE_LOAN_MADE_ACTION:
            return { ...state, loan:action.payload};
        default:
            return state;
    }
}

export default loanSelectedReducer;