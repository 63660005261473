import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateLegalService_Court from './CreateLegalService_Court';
import EditLegalService_Court from './EditLegalService_Court';
import { addDialogLegalService_Court, editDialogLegalService_Court, deleteLegalService_Court, getAllLegalService_Courts } from "../../actions/legalService_CourtAction";
import { getSelectBoxCities } from '../../actions/cityAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableLegalService_Court extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxCities();
    this.props.getAllLegalService_Courts();
  }

  cityName = (props) => {
    return this.props.cities.find(el => el.value === props.row.cityID)?.text;
  }
  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    { field: 'address', headerName: i18next.t('Address'), minWidth: 150, flex: 1 },

    {
      field: 'CityID',
      headerName: i18next.t('City'),
      width: 160,
      valueGetter: this.cityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cityName(cellParams1).localeCompare(this.cityName(cellParams2))
    },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogLegalService_Court();
  }
  edit = (params) => {
    this.props.editDialogLegalService_Court(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.legalService_Courts}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateLegalService_Court></CreateLegalService_Court>
        <EditLegalService_Court></EditLegalService_Court>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLegalService_Court(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    legalService_Courts: Object.values(state.legalService_Courts),
    cities: Object.values(state.citiesSelectBox)
  };
}

export default connect(mapStateToProps, { addDialogLegalService_Court, editDialogLegalService_Court, deleteLegalService_Court, getAllLegalService_Courts, getSelectBoxCities })(TableLegalService_Court);