import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  IconButton,
  Typography,
} from '@mui/material';
import { Close } from '@material-ui/icons';
import React from 'react';
import i18next from '../../language/i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';

class ConfirmDialog extends React.Component {
  theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#dc3545',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#218838',
      },
      neutral: {
        main: '#FF0000',
        contrastText: '#fff',
      }
    },
  });
  render() {
    return (
      <ThemeProvider theme={this.theme}>
        <Dialog open={this.props.IsOpen} maxWidth="sm" fullWidth>
          <DialogTitle>{i18next.t('Confirm')}</DialogTitle>
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={this.props.close}>
              <Close />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography>{this.props.message}</Typography>
            {this.props.children}
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="contained" onClick={this.props.close}>
              {i18next.t('Cancel')}
            </Button>
            <Button color="secondary" variant="contained" onClick={this.props.confirm}>
              {i18next.t('Confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
};

export default ConfirmDialog;