import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateLoanType from './CreateLoanType';
import EditLoanType from './EditLoanType';
import i18next from '../../language/i18n';
import { addDialogLoanType, editDialogLoanType, deleteLoanType, getAllLoanTypes } from "../../actions/loanTypeAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxCompanies } from '../../actions/companyAction';
import DeleteButton from '../Share/Button/DeleteButton';


class TableLoanType extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxCompanies();

    this.props.getAllLoanTypes();
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  columns = [

    {
      field: 'CompanyID',
      headerName: i18next.t('Company'),
      width: 160,
      valueGetter: this.companyName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
    },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    { field: 'loanPurpose', headerName: i18next.t('LoanPurpose'), minWidth: 150, flex: 1 },
    { field: 'amountMin', headerName: i18next.t('AmountMin'), minWidth: 150, flex: 1 },
    { field: 'amountMax', headerName: i18next.t('AmountMax'), minWidth: 150, flex: 1 },
    { field: 'commissionPercent', headerName: i18next.t('CommissionPercent'), minWidth: 150, flex: 1 },
    { field: 'approvalCostPercent', headerName: i18next.t('ApprovalCostPercent'), minWidth: 150, flex: 1 },
    { field: 'approvalCostAmount', headerName: i18next.t('ApprovalCostAmount'), minWidth: 150, flex: 1 },
    { field: 'interestRate', headerName: i18next.t('InterestRate'), minWidth: 150, flex: 1 },
    { field: 'legalPenaltyRate', headerName: i18next.t('LegalPenaltyRate'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'indexedToCurrency', headerName: i18next.t('IndexedToCurrency'), minWidth: 150, flex: 1 },
    { field: 'alternativeIR', headerName: i18next.t('AlternativeIR'), minWidth: 150, flex: 1 },
    { field: 'approvalCostMin', headerName: i18next.t('ApprovalCostMin'), minWidth: 150, flex: 1 },
    { field: 'monthlyCost', headerName: i18next.t('MonthlyCost'), minWidth: 150, flex: 1 },
    { field: 'approvalCostFBIH', headerName: i18next.t('ApprovalCostFBIH'), minWidth: 150, flex: 1 },
    { field: 'commisionPercent13Plus', headerName: 'CommisionPercent13Plus', minWidth: 150, flex: 1 },
    { field: 'commisionPercent10Plus', headerName: 'CommisionPercent10Plus', minWidth: 150, flex: 1 },
    { field: 'defaultPaymentWay', headerName: i18next.t('DefaultPaymentWay'), minWidth: 150, flex: 1 },
    { field: 'defaultAssignee', headerName: i18next.t('DefaultAssignee'), minWidth: 150, flex: 1 },
    { field: 'isNonPurposeLoan', headerName: i18next.t('IsNonPurposeLoan'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'expensesOnAccountAmount', headerName: i18next.t('ExpensesOnAccountAmount'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'commissiontoDeduct', headerName: i18next.t('CommissiontoDeduct'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'hasLifeInsurance', headerName: i18next.t('HasLifeInsurance'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'hasPawnOnTheVehicle', headerName: i18next.t('HasPawnOnTheVehicle'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'hasPolicy', headerName: i18next.t('HasPolicy'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'hasLBO', headerName: i18next.t('HasLBO'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'firstMonthAdd', headerName: i18next.t('FirstMonthAdd'), minWidth: 150, flex: 1 },
    //{ field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogLoanType();
  }
  edit = (params) => {
    this.props.editDialogLoanType(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.loanTypes}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateLoanType></CreateLoanType>
        <EditLoanType></EditLoanType>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteLoanType(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loanTypes: Object.values(state.loanTypes),
    companies: Object.values(state.companiesSelectBox)
  };
}

export default connect(mapStateToProps, { addDialogLoanType, editDialogLoanType, deleteLoanType, getAllLoanTypes, getSelectBoxCompanies, showLoadingDataInProgress })(TableLoanType);