import { 
    CREATE_TYPEOFLOANCHANGE,
    GET_ALL_TYPEOFLOANCHANGE,
    GET_TYPEOFLOANCHANGE,
    DELETE_TYPEOFLOANCHANGE,
    UPDATE_TYPEOFLOANCHANGE
} from '../../actions/types';

const typeOfLoanChangeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_TYPEOFLOANCHANGE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_TYPEOFLOANCHANGE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_TYPEOFLOANCHANGE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_TYPEOFLOANCHANGE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_TYPEOFLOANCHANGE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default typeOfLoanChangeReducer;