import api from "../api/myApi";
import { 
    CREATE_LEGALSERVICE_WARNING,
    GET_ALL_LEGALSERVICE_WARNING,
    UPDATE_LEGALSERVICE_WARNING,
    ADD_DIALOG_LEGALSERVICE_WARNING,
    EDIT_DIALOG_LEGALSERVICE_WARNING,
    CLOSE_DIALOG_LEGALSERVICE_WARNING,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS,

    GET_ALL_DEBS_LEGALSERVICE,
    GET_ALL_ISKUP_LEGALSERVICE,
    GET_ALL_EXECUTION_LEGALSERVICE,
    GET_ALL_DONE_LEGALSERVICE,

    CREATE_LEGALSERVICE_ISKUP,
    CREATE_LEGALSERVICE_EXECUTION,
    CREATE_LEGALSERVICE_DONE,
    
    UPDATE_LEGALSERVICE_ISKUP,
    UPDATE_LEGALSERVICE_EXECUTION
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const getAllLegalService_Debs = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_Warning/GetAllDebts');
    dispatch(responseHandler(response, GET_ALL_DEBS_LEGALSERVICE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getAllLegalService_Warnings = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_Warning/GetAllWarnings');
    dispatch(responseHandler(response, GET_ALL_LEGALSERVICE_WARNING));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}


export const getAllLegalService_Iskup = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_Warning/GetAllIskup');
    dispatch(responseHandler(response, GET_ALL_ISKUP_LEGALSERVICE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getAllLegalService_Execution = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_Warning/GetAllExecution');
    dispatch(responseHandler(response, GET_ALL_EXECUTION_LEGALSERVICE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getAllLegalService_Done = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_Warning/GetAllDone');
    dispatch(responseHandler(response, GET_ALL_DONE_LEGALSERVICE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const createLegalService_Warning = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/CreateWarning', formValues);    
    dispatch(responseHandler(response, CREATE_LEGALSERVICE_WARNING));
    if(response.data?.succeeded){
        dispatch(reset('formLegalService_Warning'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const createLegalService_Iskup = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/CreateIskup', formValues);    
    dispatch(responseHandler(response, CREATE_LEGALSERVICE_ISKUP));
    if(response.data?.succeeded){
        dispatch(reset('formLegalService_Warning'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const createLegalService_Execution = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/CreateExecution', formValues);    
    dispatch(responseHandler(response, CREATE_LEGALSERVICE_EXECUTION));
    if(response.data?.succeeded){
        dispatch(reset('formLegalService_Warning'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const createLegalService_Done = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/CreateDone', formValues);    
    dispatch(responseHandler(response, CREATE_LEGALSERVICE_DONE));
    if(response.data?.succeeded){
        dispatch(reset('formLegalService_Warning'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const updateLegalService_Warning = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/UpdateWarning', formValues);
    dispatch(responseHandler(response, UPDATE_LEGALSERVICE_WARNING));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
    dispatch(reset('formLegalService_Warning'));    
}

export const updateLegalService_Iskup = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/UpdateIskup', formValues);
    dispatch(responseHandler(response, UPDATE_LEGALSERVICE_ISKUP));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const updateLegalService_Execution = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_Warning/UpdateExecution', formValues);
    dispatch(responseHandler(response, UPDATE_LEGALSERVICE_EXECUTION));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const addDialogLegalService_Warning = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LEGALSERVICE_WARNING,
        payload: null
    });
}

export const editDialogLegalService_Warning = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LEGALSERVICE_WARNING,
        payload: item
    });
}

export const closeDialogLegalService_Warning = () => async (dispatch) => {
    dispatch(reset('formLegalService_Warning'));
    dispatch({
        type: CLOSE_DIALOG_LEGALSERVICE_WARNING,
        payload: undefined
    });
}