import React from "react";
import { getMyCompanyData } from "../../actions/companyAction";
import { yearLock } from "../../actions/journalAction";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';

class YearLockPage extends React.Component {
    componentDidMount = () => {
        this.props.getMyCompanyData();
    }

    render() {
        return (<div>
            <div>{i18next.t('CurrentYear')} : {this.props.myCompanyData.currentYear}</div>
            

            <div>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ marginTop: 20 }}
                    onClick={() => this.props.yearLock()}
                >
                    {i18next.t('LockCurrentYear')}
                </Button>
            </div>
        </div>);
    }
}

const mapStateToProps = state => {
    return { myCompanyData: state.myCompanyData };
}

export default connect(mapStateToProps, { getMyCompanyData, yearLock })(YearLockPage);