import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import ComponentInLine from '../Share/ComponentInLine';
import { connect } from 'react-redux';
import Validation from '../../share/validation';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { closeDialogIncomeSource } from '../../actions/incomeSourceAction';
import { EditSelectField, EditTextField, EditDecimalNumberField, EditCheckbox } from '../Share/EditComponents';

class FormIncomeSource extends React.Component {
    handleClose = () => {
        this.props.closeDialogIncomeSource();
    }
    calculateNetIncome = (income, householdCost, suspensionsLastMonth) => {
        if (income >= 0 && householdCost >= 0)
            this.props.change("netIncome", income - householdCost - suspensionsLastMonth);

    }
    render() {
        let partnerType = this.props.partnersSelectBox?.find(el => el.value == this.props.formValues?.partnerID)?.data.partnerTypeID;
        return (
            <div>
                <Dialog
                    maxWidth={'lg'}
                    fullWidth={true}
                    open={this.props.IsOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"

                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>

                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='partnerID'
                                        component={this.props.EditSelectField}
                                        label='Partner'
                                        dataSource={this.props.partnersSelectBox?.filter(el => this.props.loanPartners.filter(el => el.id > 0).map(m => parseInt(m.partnerID)).indexOf(el.value) != -1)}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='incomeSourceTypeID'
                                        component={this.props.EditSelectField}
                                        label='IncomeSourceType'
                                        dataSource={this.props.incomeSourceTypesSelectBox?.filter(el => (partnerType == 1 && el.value >= 100) || (partnerType != 1 && el.value < 100))}
                                    >
                                    </Field>
                                </div>
                            </ComponentInLine>
                            {this.props.formValues?.incomeSourceTypeID == 2 ?
                                <ComponentInLine>
                                    <div>
                                        <Field
                                            name='typeOfContractWithTheEmployer'
                                            component={this.props.EditSelectField}
                                            label='TypeOfContractWithTheEmployer'
                                            dataSource={this.props.typeOfContractWithTheEmployerSelectBox}
                                        >
                                        </Field>
                                    </div>
                                    <div>
                                        <Field
                                            name='employmentTypeID'
                                            component={this.props.EditSelectField}
                                            label='EmploymentType'
                                            dataSource={this.props.employmentTypesSelectBox}
                                        >
                                        </Field>
                                    </div>
                                    <div>
                                        <Field
                                            name='employerID'
                                            component={this.props.EditSelectField}
                                            label='Employer'
                                            dataSource={this.props.partnersSelectBox?.filter(el => el.data.partnerTypeID == 1)}
                                        >
                                        </Field>
                                    </div>
                                    <div style={{marginTop:'20px'}}>
                                        <Field
                                            name='administrativeBan'
                                            component={this.props.EditCheckbox}
                                            label='AdministrativeBan'
                                        />
                                    </div>
                                </ComponentInLine> : null}
                            {this.props.formValues?.incomeSourceTypeID == 3 ?
                                <ComponentInLine>
                                    <div>
                                        <Field
                                            name='lbo'
                                            component={this.props.EditTextField}
                                            label='LBO'
                                        />
                                    </div>
                                    <div style={{marginTop:'20px'}}>
                                        <Field
                                            name='lboPension'
                                            component={this.props.EditCheckbox}
                                            label='LBOPension'
                                        />
                                    </div>
                                </ComponentInLine> : null}
                            {this.props.partnersSelectBox?.find(el => el.value == this.props.formValues?.partnerID)?.data.partnerTypeID === 1 ?

                                <ComponentInLine>
                                    <div>
                                        <Field
                                            name='annualIncome'
                                            component={this.props.EditDecimalNumberField}
                                            label='AnnualIncome'
                                        />
                                    </div>
                                    <div>
                                        <Field
                                            name='annualExpenses'
                                            component={this.props.EditDecimalNumberField}
                                            label='AnnualExpenses'
                                        />
                                    </div>
                                </ComponentInLine> :
                                <>
                                    <ComponentInLine>
                                        <div>
                                            <Field
                                                name='income'
                                                component={this.props.EditDecimalNumberField}
                                                onChange={(e) => this.calculateNetIncome(e.target.value, this.props.formValues?.householdCost, this.props.formValues?.suspensionsLastMonth)}
                                                label='Income'
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name='lastThreeMonthsIncome'
                                                component={this.props.EditDecimalNumberField}
                                                label='LastThreeMonthsIncome'
                                            />
                                        </div>
                                    </ComponentInLine>
                                    <ComponentInLine>

                                        <div>
                                            <Field
                                                name='householdCost'
                                                component={this.props.EditDecimalNumberField}
                                                onChange={(e) => this.calculateNetIncome(this.props.formValues?.income, e.target.value, this.props.formValues?.suspensionsLastMonth)}
                                                label='HouseholdCost'
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name='suspensionsLastMonth'
                                                component={this.props.EditDecimalNumberField}
                                                onChange={(e) => this.calculateNetIncome(this.props.formValues?.income, this.props.formValues?.householdCost, e.target.value)}
                                                label='SuspensionsLastMonth'
                                            />
                                        </div>
                                        <div>
                                            <Field
                                                name='netIncome'
                                                component={this.props.EditDecimalNumberField}
                                                label='NetIncome'
                                                disabled={true}
                                            />
                                        </div>
                                    </ComponentInLine>
                                </>}




                            <div>
                                <Field
                                    name='isValid'
                                    component={this.props.EditCheckbox}
                                    label='IsValid'
                                />
                            </div>
                            {/* <div>
                                <Field
                                    name='source'
                                    component={this.props.EditTextField}
                                    label='Source'
                                />
                            </div> */}
                            <div>
                                <Field
                                    name='description'
                                    component={this.props.EditTextField}
                                    label='Description'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    console.log(formValues);
    if (!formValues.partnerID) {
        errors.partnerID = 'Polje je obavezno!';
    }
    // if (!formValues.source) {
    //     errors.source = 'Polje je obavezno!';
    // }
    // if(!formValues.description){
    //     errors.description = 'Polje je obavezno!';
    // }
    if (!Validation.hasNonNegativeValue(formValues.income)) {
        errors.income = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.householdCost)) {
        errors.householdCost = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.netIncome)) {
        errors.netIncome = 'Polje je obavezno!';
    }
    if (!Validation.isSelected(formValues.incomeSourceTypeID)) {
        errors.incomeSourceTypeID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        partnersSelectBox: Object.values(state.partnersSelectBox),
        incomeSourceTypesSelectBox: Object.values(state.incomeSourceTypesSelectBox),
        employmentTypesSelectBox: Object.values(state.employmentTypesSelectBox),
        typeOfContractWithTheEmployerSelectBox: Object.values(state.typeOfContractWithTheEmployerSelectBox),
        loanPartners: Object.values(state.loanPartners),
        formValues: getFormValues('formIncomeSource')(state),
    }
}
const formWrapper = reduxForm({
    form: 'formIncomeSource',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormIncomeSource);

export default connect(mapStateToProps, {
    getFormValues,
    change,
    closeDialogIncomeSource,
    EditSelectField,
    EditTextField,
    EditDecimalNumberField,
    EditCheckbox
})(formWrapper);