//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_FILEUPLOAD,
    GET_ALL_FILEUPLOAD,
    GET_FILEUPLOAD,
    GET_ALL_SELECTBOX_FILEUPLOAD,
    DELETE_FILEUPLOAD,
    UPDATE_FILEUPLOAD,
    ADD_DIALOG_FILEUPLOAD,
    EDIT_DIALOG_FILEUPLOAD,
    CLOSE_DIALOG_FILEUPLOAD
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import { showFile } from "../share/fileHandler";


export const createFileUpload = (loanID, fileUploadTypeID, file) => async (dispatch) => {
    let formData = new FormData();
    formData.append("loanID", loanID);
    formData.append("fileUploadTypeID", fileUploadTypeID);
    formData.append("File", file);

    const response = await api.post("/api/v1/FileUpload/Create/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    dispatch(responseHandler(response, CREATE_FILEUPLOAD));
    return response;
    // const response = await api.post('/api/v1/FileUpload/Create', formValues);    
    // dispatch(responseHandler(response, CREATE_FILEUPLOAD));
    // if(response.data?.succeeded){
    //     dispatch(reset('formFileUpload'));
    // }
}

export const getAllFileUploads = () => async (dispatch) => {
    const response = await api.get('/api/v1/FileUpload/GetAll');
    dispatch(responseHandler(response, GET_ALL_FILEUPLOAD));
}

export const getFileUpload = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/FileUpload/Get/' + id);
    dispatch(responseHandler(response, GET_FILEUPLOAD));
}
export const getFile = (id, fileName) => async (dispatch) => {
    const response = await api.get('/api/v1/FileUpload/GetFile/' + id, {responseType: 'blob'});
    showFile(response, fileName);
}

export const getSelectBoxFileUploads = () => async (dispatch) => {
    const response = await api.get('/api/v1/FileUpload/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_FILEUPLOAD));
}

export const updateFileUpload = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/FileUpload/Update', formValues);
    dispatch(responseHandler(response, UPDATE_FILEUPLOAD));
}

export const deleteFileUpload = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/FileUpload/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_FILEUPLOAD));
}

export const addDialogFileUpload = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_FILEUPLOAD,
        payload: null
    });
}

export const editDialogFileUpload = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_FILEUPLOAD,
        payload: item
    });
}

export const closeDialogFileUpload = () => async (dispatch) => {
    dispatch(reset('formFileUpload'));
    dispatch({
        type: CLOSE_DIALOG_FILEUPLOAD,
        payload: undefined
    });
}