import { 
    GET_ALL_SELECTBOX_KUF
} from '../../actions/types';

 const kUFSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_KUF:
            return [...action.payload];
        default:
            return state;
    }
}
export default kUFSelectBoxReducer;