import { 
    GET_ALL_SELECTBOX_LEGALSERVICE_ACTIVITY
} from '../../actions/types';

 const legalService_ActivitySelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_LEGALSERVICE_ACTIVITY:
            return [...action.payload];
        default:
            return state;
    }
}
export default legalService_ActivitySelectBoxReducer;