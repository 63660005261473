import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogIncomeSource, updateIncomeSource } from '../../actions/incomeSourceAction';
import FormIncomeSource from './FormIncomeSource';

class EditIncomeSource extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateIncomeSource(formValues);
    }
    
    render(){
        return (
            <FormIncomeSource 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormIncomeSource>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.IncomeSourceDialog === undefined || state.dialog.IncomeSourceDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.IncomeSourceDialog};
}


export default connect(mapStateToProps, { closeDialogIncomeSource, updateIncomeSource })(EditIncomeSource);
