//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createOrderType } from '../../actions/orderTypeAction';
import FormOrderType from './FormOrderType';

class CreateOrderType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createOrderType(formValues);
    }
    
    render(){
        return (
            <FormOrderType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormOrderType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.OrderTypeDialog === undefined || state.dialog.OrderTypeDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.OrderTypeDialog};
}


export default connect(mapStateToProps, { createOrderType })(CreateOrderType);
