import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreatePartner from './CreatePartner';
import EditPartner from './EditPartner';
import { addDialogPartner, editDialogPartner, deletePartner, getAllPartners } from "../../actions/partnerAction";
//import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCities } from '../../actions/cityAction';
//import { getSelectBoxGroups } from '../../actions/groupAction';
import { getSelectBoxGenders } from '../../actions/genderAction';
import { getSelectBoxMaritalStatus } from '../../actions/maritalStatusAction';
import { getSelectBoxPartnerTypes } from '../../actions/partnerTypeAction';
import { getSelectBoxEntities } from '../../actions/entityAction';
import { getSelectBoxQualifications } from '../../actions/qualificationsAction';
//import { getSelectBoxPartners } from '../../actions/partnerAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxCompanyActivityCodes } from '../../actions/companyActivityCodeAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TablePartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    //this.props.getSelectBoxCompanies();
    this.props.getSelectBoxCities();
    //this.props.getSelectBoxGroups();
    //this.props.getSelectBoxPartners();
    this.props.getSelectBoxGenders();
    this.props.getSelectBoxMaritalStatus();
    this.props.getSelectBoxPartnerTypes();
    this.props.getSelectBoxEntities();
    this.props.getSelectBoxQualifications();
    this.props.getSelectBoxCompanyActivityCodes();
    this.props.getAllPartners();
  }

  // companyName = (props) => {
  //   return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  // }
  cityName = (props) => {
    return this.props.cities.find(el => el.value === props.row.cityID)?.text;
  }
  groupName = (props) => {
    return this.props.groups.find(el => el.value === props.row.groupID)?.text;
  }
  partnerName = (props) => {
    return this.props.partnersSB.find(el => el.value === props.row.ownerID)?.text;
  }
  genderName = (props) => {
    return this.props.genders.find(el => el.value === props.row.genderID)?.text;
  }
  maritalStatusName = (props) => {
    return this.props.maritalStatus.find(el => el.value === props.row.maritalStatusID)?.text;
  }
  partnerTypeName = (props) => {
    return this.props.partnerTypes.find(el => el.value === props.row.partnerTypeID)?.text;
  }
  entityName = (props) => {
    return this.props.entities.find(el => el.value === props.row.entityID)?.text;
  }
  qualificationsName = (props) => {
    return this.props.qualifications.find(el => el.value === props.row.qualificationsID)?.text;
  }
  companyActivityCodeName = (props) => {
    return this.props.companyActivityCodes.find(el => el.value === props.row.companyActivityCodeID)?.text;
  }
  columns = [

    // {
    //     field: 'CompanyID',
    //     headerName: i18next.t('Company'),
    //     width: 160,
    //     valueGetter: this.companyName,
    //     sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
    //   },
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    { field: 'address', headerName: i18next.t('Address'), minWidth: 150, flex: 1 },

    {
      field: 'CityID',
      headerName: i18next.t('City'),
      width: 160,
      valueGetter: this.cityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cityName(cellParams1).localeCompare(this.cityName(cellParams2))
    },
    { field: 'phone', headerName: i18next.t('Phone'), minWidth: 150, flex: 1 },
    { field: 'bankAccount', headerName: i18next.t('BankAccount'), minWidth: 150, flex: 1 },
    { field: 'contactPerson', headerName: i18next.t('ContactPerson'), minWidth: 150, flex: 1 },
    { field: 'jIB', headerName: i18next.t('JIB'), minWidth: 150, flex: 1 },
    { field: 'pIB', headerName: i18next.t('PIB'), minWidth: 150, flex: 1 },
    { field: 'fax', headerName: i18next.t('Fax'), minWidth: 150, flex: 1 },
    { field: 'mobilePhone', headerName: i18next.t('MobilePhone'), minWidth: 150, flex: 1 },
    { field: 'phone2', headerName: 'Phone2', minWidth: 150, flex: 1 },
    { field: 'email', headerName: i18next.t('Email'), minWidth: 150, flex: 1 },
    { field: 'note', headerName: i18next.t('Note'), minWidth: 150, flex: 1 },
    //{ field: 'bankAccount2', headerName: 'BankAccount2', minWidth: 150, flex: 1 },
    { field: 'foreignCurrencyAccount', headerName: i18next.t('ForeignCurrencyAccount'), minWidth: 150, flex: 1 },
    { field: 'isPaymentAccount', headerName: i18next.t('IsPaymentAccount'), minWidth: 150, flex: 1, type: 'boolean' },

    {
      field: 'GroupID',
      headerName: i18next.t('Group'),
      width: 160,
      valueGetter: this.groupName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.groupName(cellParams1).localeCompare(this.groupName(cellParams2))
    },

    {
      field: 'OwnerID',
      headerName: i18next.t('Partner'),
      width: 160,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    { field: 'birthDate', headerName: i18next.t('BirthDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'birthCity', headerName: i18next.t('BirthCity'), minWidth: 150, flex: 1 },
    { field: 'identityCardNo', headerName: i18next.t('IdentityCardNo'), minWidth: 150, flex: 1 },

    {
      field: 'GenderID',
      headerName: i18next.t('Gender'),
      width: 160,
      valueGetter: this.genderName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.genderName(cellParams1).localeCompare(this.genderName(cellParams2))
    },

    {
      field: 'MaritalStatusID',
      headerName: i18next.t('MaritalStatus'),
      width: 160,
      valueGetter: this.maritalStatusName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.maritalStatusName(cellParams1).localeCompare(this.maritalStatusName(cellParams2))
    },

    {
      field: 'PartnerTypeID',
      headerName: i18next.t('PartnerType'),
      width: 160,
      valueGetter: this.partnerTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerTypeName(cellParams1).localeCompare(this.partnerTypeName(cellParams2))
    },

    {
      field: 'EntityID',
      headerName: i18next.t('Entity'),
      width: 160,
      valueGetter: this.entityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.entityName(cellParams1).localeCompare(this.entityName(cellParams2))
    },

    {
      field: 'CEOID',
      headerName: i18next.t('Partner'),
      width: 160,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    {
      field: 'companyActivityCodeID',
      headerName: i18next.t('ActivityCode'),
      width: 160,
      valueGetter: this.companyActivityCodeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyActivityCodeName(cellParams1).localeCompare(this.companyActivityCodeName(cellParams2))
    },
    { field: 'firstName', headerName: i18next.t('FirstName'), minWidth: 150, flex: 1 },
    { field: 'parentsName', headerName: i18next.t('ParentsName'), minWidth: 150, flex: 1 },
    { field: 'lastName', headerName: i18next.t('LastName'), minWidth: 150, flex: 1 },
    { field: 'subAccount', headerName: i18next.t('SubAccount'), minWidth: 150, flex: 1 },

    
    { field: 'profession', headerName: i18next.t('Profession'), minWidth: 150, flex: 1 },
    {
      field: 'qualificationsID',
      headerName: i18next.t('Qualifications'),
      width: 160,
      valueGetter: this.qualificationsName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.qualificationsName(cellParams1).localeCompare(this.qualificationsName(cellParams2))
    },

    { field: 'isOnUNSanctionList', headerName: i18next.t('IsOnUNSanctionList'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'idExpireDate', headerName: i18next.t('IDExpireDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'isPoliticallyExposed', headerName: i18next.t('IsPoliticallyExposed'), minWidth: 150, flex: 1, type: 'boolean' },

    //{ field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogPartner();
  }
  edit = (params) => {
    this.props.editDialogPartner(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.partners}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreatePartner></CreatePartner>
        <EditPartner></EditPartner>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deletePartner(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partners: Object.values(state.partners),
    companies: Object.values(state.companiesSelectBox),
    cities: Object.values(state.citiesSelectBox),
    groups: Object.values(state.groupsSelectBox),
    genders: Object.values(state.gendersSelectBox),
    maritalStatus: Object.values(state.maritalStatusSelectBox),
    partnerTypes: Object.values(state.partnerTypesSelectBox),
    entities: Object.values(state.entitiesSelectBox),
    partnersSB: Object.values(state.partnersSelectBox),
    qualifications: Object.values(state.qualificationsSelectBox),
    companyActivityCodes: Object.values(state.companyActivityCodesSelectBox)
  };
}
export default connect(mapStateToProps, {
  addDialogPartner,
  editDialogPartner,
  deletePartner,
  getAllPartners,
  //getSelectBoxCompanies,
  getSelectBoxCities,
  //getSelectBoxGroups,
  getSelectBoxGenders,
  getSelectBoxMaritalStatus,
  getSelectBoxPartnerTypes,
  getSelectBoxEntities,
  getSelectBoxQualifications,
  //getSelectBoxPartners,
  showLoadingDataInProgress,
  getSelectBoxCompanyActivityCodes
})(TablePartner);