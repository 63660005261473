import React from 'react';
import { connect } from 'react-redux';
import { createLegalService_ExecutionType } from '../../actions/legalService_ExecutionTypeAction';
import FormLegalService_ExecutionType from './FormLegalService_ExecutionType';
import i18next from '../../language/i18n';

class CreateLegalService_ExecutionType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createLegalService_ExecutionType(formValues);
    }
    
    render(){
        return (
            <FormLegalService_ExecutionType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormLegalService_ExecutionType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LegalService_ExecutionTypeDialog === undefined || state.dialog.LegalService_ExecutionTypeDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.LegalService_ExecutionTypeDialog};
}


export default connect(mapStateToProps, { createLegalService_ExecutionType })(CreateLegalService_ExecutionType);
