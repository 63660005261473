//Mojo-AG
import { 
    GET_ALL_SELECTBOX_FILEUPLOADTYPE
} from '../../actions/types';

 const fileUploadTypeSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_FILEUPLOADTYPE:
            return [...action.payload];
        default:
            return state;
    }
}
export default fileUploadTypeSelectBoxReducer;