//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_CRKARCHIVE,
    GET_ALL_CRKARCHIVE,
    GET_CRKARCHIVE,
    GET_ALL_SELECTBOX_CRKARCHIVE,
    DELETE_CRKARCHIVE,
    UPDATE_CRKARCHIVE,
    ADD_DIALOG_CRKARCHIVE,
    EDIT_DIALOG_CRKARCHIVE,
    CLOSE_DIALOG_CRKARCHIVE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import { showFile } from "../share/fileHandler";

export const createCRKArchive = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKArchive/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CRKARCHIVE));
    if(response.data?.succeeded){
        dispatch(reset('formCRKArchive'));
    }
}

export const getAllCRKArchives = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKArchive/GetAll');
    dispatch(responseHandler(response, GET_ALL_CRKARCHIVE));
}

export const getCRKArchive = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CRKArchive/Get/' + id);
    dispatch(responseHandler(response, GET_CRKARCHIVE));
}

export const getSelectBoxCRKArchives = () => async (dispatch) => {
    const response = await api.get('/api/v1/CRKArchive/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CRKARCHIVE));
}

export const updateCRKArchive = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKArchive/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CRKARCHIVE));
}

export const deleteCRKArchive = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CRKArchive/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CRKARCHIVE));
}

export const addDialogCRKArchive = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CRKARCHIVE,
        payload: null
    });
}

export const editDialogCRKArchive = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CRKARCHIVE,
        payload: item
    });
}
export const getFile = (id, fileName) => async (dispatch) => {
    const response = await api.get('/api/v1/CRKArchive/GetFile/' + id, {responseType: 'blob'});
    showFile(response, fileName);
}


export const closeDialogCRKArchive = () => async (dispatch) => {
    dispatch(reset('formCRKArchive'));
    dispatch({
        type: CLOSE_DIALOG_CRKARCHIVE,
        payload: undefined
    });
}