import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogPartner } from '../../actions/partnerAction';
import { EditSelectField, EditTextField, EditCheckbox, EditDatePickerField } from '../Share/EditComponents';
import ComponentInLine from '../Share/ComponentInLine';
import i18next from '../../language/i18n';

class FormPartnerLegalEntity extends React.Component {

    isDisabled = () => {
        return this.props.disabled === true;
    }

    render() {
        return (

            <form onSubmit={
                this.props.handleSubmit(this.props.onSubmit)
            }>
                {/*
                            <div>
                                <Field name='companyID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Company'
                                    dataSource={this.props.companiesSelectBox}>
                                } </Field>
                            </div>
                            */}
                <ComponentInLine>
                    <div>
                        <Field name='name'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Name' />
                    </div>
                    <div>
                        <Field name='jib'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='JIB' />
                    </div>
                </ComponentInLine>

                <ComponentInLine>
                    <div>
                        <Field name='pib'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='PIB' />
                    </div>
                    <div>
                        <Field name='bankAccount'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='BankAccount' />
                    </div>
                    <div>
                        <Field name='companyActivityCodeID'
                            component={
                                this.props.EditSelectField
                            }
                            disabled={this.isDisabled()}
                            label='ActivityCode'
                            dataSource={this.props.companyActivityCodesSelectBox} />
                    </div>
                </ComponentInLine>


                <ComponentInLine>
                    <div>
                        <Field name='ceo'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='CEO' />
                    </div>
                    <div>
                        <Field name='owner'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Owner' />
                    </div>
                </ComponentInLine>
                {
                    this.props.onlyAddress === false ? (
                        <ComponentInLine>

                            <div>
                                <Field name='entityID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    disabled={this.isDisabled()}
                                    label='Entity'
                                    dataSource={this.props.entitiesSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field name='cityID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    disabled={this.isDisabled()}
                                    label='City'
                                    dataSource={this.props.citiesSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field name='address'
                                    component={
                                        this.props.EditTextField
                                    }
                                    disabled={this.isDisabled()}
                                    label='Address' />
                            </div>
                        </ComponentInLine>) :
                        (
                            <div>
                                <Field name='address'
                                    component={
                                        this.props.EditTextField
                                    }
                                    disabled={this.isDisabled()}
                                    label='Address' />
                            </div>
                        )
                }


                <ComponentInLine>
                    <div>
                        <Field name='email'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Email' />
                    </div>
                    <div>
                        <Field name='mobilePhone'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='MobilePhone' />
                    </div>

                </ComponentInLine>

                <ComponentInLine>
                    <div>
                        <Field name='phone'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Phone' />
                    </div>
                    <div>
                        <Field name='fax'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Fax' />
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <Field name='isAssignee'
                            component={
                                this.props.EditCheckbox
                            }
                            disabled={this.isDisabled()}
                            label='CommodityAssociate' />
                    </div>
                </ComponentInLine>

                <ComponentInLine></ComponentInLine>


                <div>
                    <Field name='note'
                        component={
                            this.props.EditTextField
                        }
                        disabled={this.isDisabled()}
                        label='Note' />
                </div>
                {/*
                            <div>
                                <Field name='contactPerson'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ContactPerson'/>
                            </div>


                            <div>
                                <Field name='bankAccount2'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='BankAccount2'/>
                            </div>
                            <div>
                                <Field name='foreignCurrencyAccount'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ForeignCurrencyAccount'/>
                            </div>
                            <div>
                                <Field name='isPaymentAccount'
                                    component={
                                        this.props.EditCheckbox
                                    }
                                    label='IsPaymentAccount'/>
                            </div>
                            <div>
                                <Field name='groupID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Group'
                                    dataSource={this.props.groupsSelectBox}
                                    > </Field>
                            </div>

                            <div>
                                <Field name='birthDate'
                                    component={
                                        this.props.EditDatePickerField
                                    }
                                    label='BirthDate'/>
                            </div>
                            <div>
                                <Field name='birthCity'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='BirthCity'/>
                            </div>
                            <div>
                                <Field name='identityCardNo'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='IdentityCardNo'/>
                            </div>
                            <div>
                                <Field name='genderID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Gender'
                                    dataSource={this.props.gendersSelectBox}
                                    > </Field>
                            </div>

                            <div>
                                <Field name='maritalStatusID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='MaritalStatus'
                                    dataSource={this.props.maritalStatusSelectBox}
                                    ></Field>
                            </div>

                            <div>
                                <Field name='partnerTypeID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='PartnerType'
                                    dataSource={this.props.partnerTypesSelectBox}
                                    ></Field>
                            </div>


                            <div>
                                <Field name='firstName'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='FirstName'/>
                            </div>
                            <div>
                                <Field name='parentsName'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ParentsName'/>
                            </div>
                            <div>
                                <Field name='lastName'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='LastName'/>
                            </div>
                            <div>
                                <Field name='subAccount'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='SubAccount'/>
                            </div>

                            <div>
                                <Field name='isOnUNSanctionList'
                                    component={
                                        this.props.EditCheckbox
                                    }
                                    label='IsOnUNSanctionList'/>
                            </div>
                            <div>
                                <Field name='idExpireDate'
                                    component={
                                        this.props.EditDatePickerField
                                    }
                                    label='IDExpireDate'/>
                            </div>
   */}
                {this.isDisabled() ? null : (<DialogActions>
                    <CancelButton onClick={this.props.handleClose}></CancelButton>
                    <Button color="primary" variant="contained" type="Submit">
                        {this.props.buttonText}
                    </Button>
                </DialogActions>)}

            </form>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    // if (!formValues.companyID) {
    //     errors.companyID = 'Polje je obavezno!';
    // }
    if (!formValues.name) {
        errors.name = 'Polje je obavezno!';
    }
    if (!formValues.address) {
        errors.address = 'Polje je obavezno!';
    }
    // if (!formValues.entityID) {
    //     errors.entityID = 'Polje je obavezno!';
    // }
    // if (!formValues.cityID) {
    //     errors.cityID = 'Polje je obavezno!';
    // }
    if (!formValues.phone) {
        errors.phone = 'Polje je obavezno!';
    }
    // if (!formValues.bankAccount) {
    //     errors.bankAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.contactPerson) {
    //     errors.contactPerson = 'Polje je obavezno!';
    // }
    if (!formValues.jIB) {
        errors.jIB = 'Polje je obavezno!';
    }
    // if (!formValues.pIB) {
    //     errors.pIB = 'Polje je obavezno!';
    // }
    //if (!formValues.fax) {
    //    errors.fax = 'Polje je obavezno!';
    //}
    //if (!formValues.mobilePhone) {
    //    errors.mobilePhone = 'Polje je obavezno!';
    //}
    // if (!formValues.phone2) {
    //     errors.phone2 = 'Polje je obavezno!';
    // }
    if (!formValues.email) {
        errors.email = 'Polje je obavezno!';
    }
    // if (!formValues.bankAccount2) {
    //     errors.bankAccount2 = 'Polje je obavezno!';
    // }
    // if (!formValues.foreignCurrencyAccount) {
    //     errors.foreignCurrencyAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.isPaymentAccount) {
    //     errors.isPaymentAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.groupID) {
    //     errors.groupID = 'Polje je obavezno!';
    // }
    // if (!formValues.birthCity) {
    //     errors.birthCity = 'Polje je obavezno!';
    // }
    // if (!formValues.identityCardNo) {
    //     errors.identityCardNo = 'Polje je obavezno!';
    // }
    // if (!formValues.genderID) {
    //     errors.genderID = 'Polje je obavezno!';
    // }
    // if (!formValues.partnerTypeID) {
    //     errors.partnerTypeID = 'Polje je obavezno!';
    // }
    if (!formValues.companyActivityCodeID) {
        errors.companyActivityCodeID = 'Polje je obavezno!';
    }
    // if (!formValues.firstName) {
    //     errors.firstName = 'Polje je obavezno!';
    // }
    // if (!formValues.parentsName) {
    //     errors.parentsName = 'Polje je obavezno!';
    // }
    // if (!formValues.lastName) {
    //     errors.lastName = 'Polje je obavezno!';
    // }
    // if (!formValues.subAccount) {
    //     errors.subAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.isOnUNSanctionList) {
    //     errors.isOnUNSanctionList = 'Polje je obavezno!';
    // }
    return errors;
}


const mapStateToProps = state => {
    return {
        companiesSelectBox: Object.values(state.companiesSelectBox),
        citiesSelectBox: Object.values(state.citiesSelectBox),
        groupsSelectBox: Object.values(state.groupsSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        //gendersSelectBox: Object.values(state.gendersSelectBox),
        maritalStatusSelectBox: Object.values(state.maritalStatusSelectBox),
        partnerTypesSelectBox: Object.values(state.partnerTypesSelectBox),
        entitiesSelectBox: Object.values(state.entitiesSelectBox),
        employmentTypesSelectBox: Object.values(state.employmentTypesSelectBox),
        companyActivityCodesSelectBox: Object.values(state.companyActivityCodesSelectBox)
    }
}
const formWrapper = reduxForm(
    {
        form: 'formPartnerLegalEntity',
        validate: validate,
        destroyOnUnmount: true,
        enableReinitialize: true
    })(FormPartnerLegalEntity);

export default connect(mapStateToProps, {
    closeDialogPartner,
    EditSelectField,
    EditTextField,
    EditCheckbox,
    EditDatePickerField
})(formWrapper);
