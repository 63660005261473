import api from "../api/myApi";
import { 
    CREATE_SMS,
    GET_ALL_SMS,
    GET_SMS,
    GET_ALL_SELECTBOX_SMS,
    DELETE_SMS,
    UPDATE_SMS,
    ADD_DIALOG_SMS,
    EDIT_DIALOG_SMS,
    CLOSE_DIALOG_SMS,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import DateProvider from '../share/dateProvider';
import { showTXTFile } from "../share/fileHandler";

export const createSMS = (formValues) => async (dispatch) => {
    /*const response = await api.post('/api/v1/SMS/Create', formValues);    
    dispatch(responseHandler(response, CREATE_SMS));
    if(response.data?.succeeded){
        dispatch(reset('formSMS'));
    }*/
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Warning/CreateSMSWarning', formValues);  
    dispatch(responseHandler(response, GET_ALL_SMS));  
    dispatch(responseHandler(response, 'success', 'OK'));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}


export const getAllSMS = (date) => async (dispatch) => {
    const response = await api.get(`/api/v1/SMS/GetAll?date=${DateProvider.GetDateJSON(date)}`); 
    dispatch(responseHandler(response, GET_ALL_SMS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const exportSMSToCSV = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Warning/exportSMSToCSV', formValues); 
    showTXTFile(response)
}


export const getSMS = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/SMS/Get/' + id);
    dispatch(responseHandler(response, GET_SMS));
}

export const getSelectBoxSMS = () => async (dispatch) => {
    const response = await api.get('/api/v1/SMS/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_SMS));
}

export const updateSMS = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/SMS/Update', formValues);
    dispatch(responseHandler(response, UPDATE_SMS));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteSMS = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/SMS/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_SMS));
}

export const addDialogSMS = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_SMS,
        payload: null
    });
}

export const editDialogSMS = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_SMS,
        payload: item
    });
}

export const closeDialogSMS = () => async (dispatch) => {
    dispatch(reset('formSMS'));
    dispatch({
        type: CLOSE_DIALOG_SMS,
        payload: undefined
    });
}