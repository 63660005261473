import { 
    GET_POSTING_ACCOUNT_CONNECTIONS
} from '../../actions/types';

 const postingAccountConnectionsReducer = (state = {}, action) => {
    switch(action.type){
        case GET_POSTING_ACCOUNT_CONNECTIONS:
            return [...action.payload];
        default:
            return state;
    }
}
export default postingAccountConnectionsReducer;