//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_QUALIFICATIONS,
    GET_ALL_QUALIFICATIONS,
    GET_QUALIFICATIONS,
    GET_ALL_SELECTBOX_QUALIFICATIONS,
    DELETE_QUALIFICATIONS,
    UPDATE_QUALIFICATIONS,
    ADD_DIALOG_QUALIFICATIONS,
    EDIT_DIALOG_QUALIFICATIONS,
    CLOSE_DIALOG_QUALIFICATIONS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createQualifications = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Qualifications/Create', formValues);    
    dispatch(responseHandler(response, CREATE_QUALIFICATIONS));
    if(response.data?.succeeded){
        dispatch(reset('formQualifications'));
    }
}

export const getAllQualifications = () => async (dispatch) => {
    const response = await api.get('/api/v1/Qualifications/GetAll');
    dispatch(responseHandler(response, GET_ALL_QUALIFICATIONS));
}

export const getQualifications = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Qualifications/Get/' + id);
    dispatch(responseHandler(response, GET_QUALIFICATIONS));
}

export const getSelectBoxQualifications = () => async (dispatch) => {
    const response = await api.get('/api/v1/Qualifications/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_QUALIFICATIONS));
}

export const updateQualifications = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Qualifications/Update', formValues);
    dispatch(responseHandler(response, UPDATE_QUALIFICATIONS));
}

export const deleteQualifications = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Qualifications/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_QUALIFICATIONS));
}

export const addDialogQualifications = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_QUALIFICATIONS,
        payload: null
    });
}

export const editDialogQualifications = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_QUALIFICATIONS,
        payload: item
    });
}

export const closeDialogQualifications = () => async (dispatch) => {
    dispatch(reset('formQualifications'));
    dispatch({
        type: CLOSE_DIALOG_QUALIFICATIONS,
        payload: undefined
    });
}