import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { addDialogBankStatementImport, editDialogBankStatementImport, deleteBankStatementImport, getAllBankStatementImports, fileBankStatementImport } from "../../actions/bankStatementImportAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { Field, reduxForm, change } from "redux-form";
import { EditSelectField, EditDatePickerField, EditTextField, EditIntegerNumberField, EditDecimalNumberField } from '../Share/EditComponents';
import i18next from '../../language/i18n';
import ComponentInLine from '../Share/ComponentInLine';

class TableBankStatementImportLoanFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {},
      selectedRow: []
    }
  }
  componentDidMount = () => {
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initialValues?.branchID !== prevProps.initialValues?.branchID ||
      this.props.initialValues?.loanAppID !== prevProps.initialValues?.loanAppID) {
      this.setState({
        filterData: {
          branchID: this.props.initialValues?.branchID,
          loanAppID: this.props.initialValues?.loanAppID,
        }
      })
    }
  }
  branchName = (props) => {
    return this.props.branchesSelectBox.find(el => el.value === props.row.branchID)?.text;
  }
  onSubmit = (formValues) => {
    this.setState({ filterData: formValues });
  }
  loansBaseDataRows = () => {
    let data = this.props.loansBaseData;
    if (this.state.filterData.branchID !== undefined && this.state.filterData.branchID !== '' && this.state.filterData.branchID !== null) {
      data = data.filter(el => el.branchID == this.state.filterData.branchID);
    }
    if (this.state.filterData.loanAppID !== undefined && this.state.filterData.loanAppID !== '' && this.state.filterData.loanAppID !== null) {
      data = data.filter(el => el.loanNumber.indexOf(this.state.filterData.loanAppID) != -1);
    }
    if (this.state.filterData.revisionID !== undefined && this.state.filterData.revisionID !== '' && this.state.filterData.revisionID !== null) {
      data = data.filter(el => el.revisionID == this.state.filterData.revisionID);
    }
    if (this.state.filterData.firstName !== undefined && this.state.filterData.firstName !== '' && this.state.filterData.firstName !== null) {
      data = data.filter(el => el.partnerName.toLowerCase().includes(this.state.filterData.firstName.toLowerCase()));
    }
    if (this.state.filterData.lastName !== undefined && this.state.filterData.lastName !== '' && this.state.filterData.lastName !== null) {
      data = data.filter(el => el.partnerName.toLowerCase().includes(this.state.filterData.lastName.toLowerCase()));
    }
    if (this.state.filterData.jib !== undefined && this.state.filterData.jib !== '' && this.state.filterData.jib !== null) {
      data = data.filter(el => el.partnerJib.includes(this.state.filterData.jib));
    }

    return data;
  }
  columns = [{
    field: 'branchID',
    headerName: i18next.t('Branch'),
    minWidth: 150, flex: 1,
    valueGetter: this.branchName,
    sortComparator: (v1, v2, cellParams1, cellParams2) =>
      this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
  },
    //{ field: 'loanAppID', headerName: i18next.t('LoanApp'), minWidth: 150, flex: 1 },
    { field: 'loanNumber', headerName: i18next.t('LoanNumber'), minWidth: 150, flex: 1 },
    //{ field: 'revisionID', headerName: i18next.t('Revision'), minWidth: 150, flex: 1 },

    { field: 'partnerName', headerName: i18next.t('Name'), minWidth: 150, flex: 4 },
    { field: 'partnerJib', headerName: i18next.t('JIB'), minWidth: 150, flex: 1 },
    { field: 'totalMonthRepayment', headerName: i18next.t('TotalMonthRepayment'), flex: 1 }
  ];
  getSelectedData = () => {
    return this.loansBaseDataRows().find((row) =>
      this.state.selectedRow.includes(row.id)
    );
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.isOpen}

          maxWidth={'xl'}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <form //onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
              onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <ComponentInLine>
                <div>
                  <Field
                    name='firstName'
                    component={this.props.EditTextField}
                    label='Name'
                  />
                </div>
                <div>
                  <Field
                    name='lastName'
                    component={this.props.EditTextField}
                    label='LastName'
                  />
                </div>
                <div>
                  <Field
                    name='jib'
                    component={this.props.EditTextField}
                    label='JIB'
                  />
                </div>
              </ComponentInLine>
              <ComponentInLine>
                <div>
                  <Field
                    name='branchID'
                    component={this.props.EditSelectField}
                    label='Branch'
                    dataSource={this.props.branchesSelectBox}
                  >
                  </Field>
                </div>
                <div>
                  <Field
                    name='loanAppID'
                    component={this.props.EditTextField}
                    label='LoanAppID'
                  />
                </div>
                <div>
                  <DialogActions style={{ justifyContent: "center", paddingTop: "24px" }}>
                    <Button color="primary" variant="contained" type="Submit">
                      Find
                    </Button>
                  </DialogActions>
                </div>
              </ComponentInLine>

            </form>
            <DataGrid
              rows={this.loansBaseDataRows()}
              columns={this.columns}
              autoHeight
              onSelectionModelChange={(e) => {
                let newValue = e;
                if (e.length === 1 && this.state.selectedRow.length === 1 && e[0] === this.state.selectedRow[0]) {
                  newValue = [];
                }
                this.setState({ selectedRow: newValue });
              }}
              selectionModel={this.state.selectedRow}
            />
            <DialogActions>
              <Button onClick={this.props.onClose} color="secondary" variant="outlined">
                {i18next.t('Cancel')}
              </Button>
              <Button onClick={() => this.props.onSubmit(this.getSelectedData())} color="primary" variant="contained">
                {i18next.t('Submit')}
              </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const formWrapper = reduxForm({
  form: 'formBankImportSearchLoanFilter',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TableBankStatementImportLoanFilter);

const mapStateToProps = state => {
  return {
    partners: Object.values(state.partnersSelectBox),
    branchesSelectBox: Object.values(state.branchesSelectBox),
    loansBaseData: Object.values(state.loansBaseData)
  };
}

export default connect(mapStateToProps,
  {
    addDialogBankStatementImport,
    editDialogBankStatementImport,
    deleteBankStatementImport,
    getAllBankStatementImports,
    fileBankStatementImport,
    //getSelectBoxCompanies, 
    //getSelectBoxCashiers, 
    getSelectBoxPartners,
    //getSelectBoxBranches,
    change,
    EditSelectField,
    EditDatePickerField,
    EditTextField,
    EditIntegerNumberField,
    EditDecimalNumberField
  })(formWrapper);
