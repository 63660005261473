import { 
    GET_LOAN_HALCOM,
} from '../../actions/types';

const loanHalcomReducer = (state = {}, action) => {
    switch(action.type){
        case GET_LOAN_HALCOM:
            return {...action.payload};
        default:
            return state;
    }
}

export default loanHalcomReducer;