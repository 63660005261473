//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateUserCompanyDetails from './CreateUserCompanyDetails';
import EditUserCompanyDetails from './EditUserCompanyDetails';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogUserCompanyDetails, editDialogUserCompanyDetails, deleteUserCompanyDetails, getAllUserCompanyDetails } from "../../actions/userCompanyDetailsAction";
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';


class TableUserCompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      IsOpenConfirmDialog: false,
      ID: null,
      userID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxCompanyDetails();

    //this.props.getAllUserCompanyDetails();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.userID !== this.props.userID && this.props.userID !== undefined && this.props.userID != null) {
      this.setState({userID: this.props.userID});
      this.props.getAllUserCompanyDetails(this.props.userID);
    }
  }

  companyDetailsName = (props) => {
    return this.props.companyDetails.find(el => el.value == props.row.companyDetailsID)?.text;
  }
  columns = [ 

    {
      field: 'CompanyDetailsID',
      headerName: i18next.t('CompanyDetails'),
      minWidth: 160,
      flex:1,
      valueGetter: this.companyDetailsName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyDetailsName(cellParams1).localeCompare(this.companyDetailsName(cellParams2))
    },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogUserCompanyDetails();
  }
  edit = (params) => {
    this.props.editDialogUserCompanyDetails(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.userCompanyDetails.filter(el => el.userID === this.props.userID)}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateUserCompanyDetails userID={this.props.userID}></CreateUserCompanyDetails>
        <EditUserCompanyDetails></EditUserCompanyDetails>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteUserCompanyDetails(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userCompanyDetails: Object.values(state.userCompanyDetails),
    companyDetails: Object.values(state.companyDetailsSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogUserCompanyDetails,
    editDialogUserCompanyDetails,
    deleteUserCompanyDetails,
    getAllUserCompanyDetails,
    getSelectBoxCompanyDetails
  })(TableUserCompanyDetails);