import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogCashier, updateCashier } from '../../actions/cashierAction';
import FormCashier from './FormCashier';

class EditCashier extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCashier(formValues);
    }
    
    render(){
        return (
            <FormCashier 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormCashier>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CashierDialog === undefined || state.dialog.CashierDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CashierDialog};
}


export default connect(mapStateToProps, { closeDialogCashier, updateCashier })(EditCashier);
