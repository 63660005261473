//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createFileUploadType } from '../../actions/fileUploadTypeAction';
import FormFileUploadType from './FormFileUploadType';

class CreateFileUploadType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createFileUploadType(formValues);
    }
    
    render(){
        return (
            <FormFileUploadType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormFileUploadType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.FileUploadTypeDialog === undefined || state.dialog.FileUploadTypeDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.FileUploadTypeDialog};
}


export default connect(mapStateToProps, { createFileUploadType })(CreateFileUploadType);
