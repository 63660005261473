import { 
    CREATE_LOANREPAYMENTSCHEDULETYPE,
    GET_ALL_LOANREPAYMENTSCHEDULETYPE,
    GET_LOANREPAYMENTSCHEDULETYPE,
    DELETE_LOANREPAYMENTSCHEDULETYPE,
    UPDATE_LOANREPAYMENTSCHEDULETYPE
} from '../../actions/types';

const loanRepaymentScheduleTypeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANREPAYMENTSCHEDULETYPE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANREPAYMENTSCHEDULETYPE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANREPAYMENTSCHEDULETYPE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanRepaymentScheduleTypeReducer;