//Mojo-AG
import { 
    CREATE_CRKREQUEST,
    GET_ALL_CRKREQUEST,
    GET_CRKREQUEST,
    DELETE_CRKREQUEST,
    UPDATE_CRKREQUEST
} from '../../actions/types';

const cRKRequestReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_CRKREQUEST:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_CRKREQUEST:
            console.log("R:", action.payload)
            return {...action.payload}
        case GET_CRKREQUEST:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_CRKREQUEST: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_CRKREQUEST:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default cRKRequestReducer;