//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_USERCOMPANYDETAILS,
    GET_ALL_USERCOMPANYDETAILS,
    GET_USERCOMPANYDETAILS,
    GET_ALL_SELECTBOX_USERCOMPANYDETAILS,
    DELETE_USERCOMPANYDETAILS,
    UPDATE_USERCOMPANYDETAILS,
    ADD_DIALOG_USERCOMPANYDETAILS,
    EDIT_DIALOG_USERCOMPANYDETAILS,
    CLOSE_DIALOG_USERCOMPANYDETAILS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createUserCompanyDetails = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/UserCompanyDetails/Create', formValues);    
    dispatch(responseHandler(response, CREATE_USERCOMPANYDETAILS));
    if(response.data?.succeeded){
        dispatch(reset('formUserCompanyDetails'));
    }
}

export const getAllUserCompanyDetails = (userID) => async (dispatch) => {
    const response = await api.get('/api/v1/UserCompanyDetails/GetAll?userID='+userID);
    dispatch(responseHandler(response, GET_ALL_USERCOMPANYDETAILS));
}

export const getUserCompanyDetails = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/UserCompanyDetails/Get/' + id);
    dispatch(responseHandler(response, GET_USERCOMPANYDETAILS));
}

export const getSelectBoxUserCompanyDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/UserCompanyDetails/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_USERCOMPANYDETAILS));
}

export const updateUserCompanyDetails = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/UserCompanyDetails/Update', formValues);
    dispatch(responseHandler(response, UPDATE_USERCOMPANYDETAILS));
}

export const deleteUserCompanyDetails = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/UserCompanyDetails/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_USERCOMPANYDETAILS));
}

export const addDialogUserCompanyDetails = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_USERCOMPANYDETAILS,
        payload: null
    });
}

export const editDialogUserCompanyDetails = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_USERCOMPANYDETAILS,
        payload: item
    });
}

export const closeDialogUserCompanyDetails = () => async (dispatch) => {
    dispatch(reset('formUserCompanyDetails'));
    dispatch({
        type: CLOSE_DIALOG_USERCOMPANYDETAILS,
        payload: undefined
    });
}