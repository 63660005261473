import { 
    GET_ALL_SELECTBOX_PARTNER,
    CREATE_PARTNER,
    UPDATE_PARTNER
} from '../../actions/types';

 const partnerSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_PARTNER:
            return [...action.payload];
        case CREATE_PARTNER:
            if(Array.isArray(state)){
                return [...state, {value:action.payload.id, text: action.payload.name}];
            }
            else{
                return [{value:action.payload.id, text: action.payload.name}];
            }
        case UPDATE_PARTNER:
            if(Array.isArray(state)){
                let item = state.find(el => el.value === action.payload.id);
                if(item === undefined){
                    return [...state, {value:action.payload.id, text: action.payload.name}];
                }
                else{
                    item.text = action.payload.name;
                    return [...state];
                }
            }
            else{
                return [{value:action.payload.id, text: action.payload.name}];
            }
        default:
            return state;
    }
}
export default partnerSelectBoxReducer;