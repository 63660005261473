export const CREATE_ACTIONLOG = 'CREATE_ACTIONLOG';
export const GET_ALL_ACTIONLOG = 'GET_ALL_ACTIONLOG';
export const GET_ACTIONLOG = 'GET_ACTIONLOG';
export const GET_ALL_SELECTBOX_ACTIONLOG = 'GET_ALL_SELECTBOX_ACTIONLOG';
export const DELETE_ACTIONLOG = 'DELETE_ACTIONLOG';
export const UPDATE_ACTIONLOG = 'UPDATE_ACTIONLOG';
export const ADD_DIALOG_ACTIONLOG = 'ADD_DIALOG_ACTIONLOG';
export const EDIT_DIALOG_ACTIONLOG = 'EDIT_DIALOG_ACTIONLOG';
export const CLOSE_DIALOG_ACTIONLOG = 'CLOSE_DIALOG_ACTIONLOG';

export const CREATE_ACTIONLOGTYPE = 'CREATE_ACTIONLOGTYPE';
export const GET_ALL_ACTIONLOGTYPE = 'GET_ALL_ACTIONLOGTYPE';
export const GET_ACTIONLOGTYPE = 'GET_ACTIONLOGTYPE';
export const GET_ALL_SELECTBOX_ACTIONLOGTYPE = 'GET_ALL_SELECTBOX_ACTIONLOGTYPE';
export const DELETE_ACTIONLOGTYPE = 'DELETE_ACTIONLOGTYPE';
export const UPDATE_ACTIONLOGTYPE = 'UPDATE_ACTIONLOGTYPE';
export const ADD_DIALOG_ACTIONLOGTYPE = 'ADD_DIALOG_ACTIONLOGTYPE';
export const EDIT_DIALOG_ACTIONLOGTYPE = 'EDIT_DIALOG_ACTIONLOGTYPE';
export const CLOSE_DIALOG_ACTIONLOGTYPE = 'CLOSE_DIALOG_ACTIONLOGTYPE';

export const CREATE_ASSIGNEE = 'CREATE_ASSIGNEE';
export const GET_ALL_ASSIGNEE = 'GET_ALL_ASSIGNEE';
export const GET_ASSIGNEE = 'GET_ASSIGNEE';
export const GET_ALL_SELECTBOX_ASSIGNEE = 'GET_ALL_SELECTBOX_ASSIGNEE';
export const DELETE_ASSIGNEE = 'DELETE_ASSIGNEE';
export const UPDATE_ASSIGNEE = 'UPDATE_ASSIGNEE';
export const ADD_DIALOG_ASSIGNEE = 'ADD_DIALOG_ASSIGNEE';
export const EDIT_DIALOG_ASSIGNEE = 'EDIT_DIALOG_ASSIGNEE';
export const CLOSE_DIALOG_ASSIGNEE = 'CLOSE_DIALOG_ASSIGNEE';

export const CREATE_BANK = 'CREATE_BANK';
export const GET_ALL_BANK = 'GET_ALL_BANK';
export const GET_BANK = 'GET_BANK';
export const GET_ALL_SELECTBOX_BANK = 'GET_ALL_SELECTBOX_BANK';
export const DELETE_BANK = 'DELETE_BANK';
export const UPDATE_BANK = 'UPDATE_BANK';
export const ADD_DIALOG_BANK = 'ADD_DIALOG_BANK';
export const EDIT_DIALOG_BANK = 'EDIT_DIALOG_BANK';
export const CLOSE_DIALOG_BANK = 'CLOSE_DIALOG_BANK';

export const CREATE_BANKSTATEMENTIMPORT = 'CREATE_BANKSTATEMENTIMPORT';
export const GET_ALL_BANKSTATEMENTIMPORT = 'GET_ALL_BANKSTATEMENTIMPORT';
export const GET_BANKSTATEMENTIMPORT = 'GET_BANKSTATEMENTIMPORT';
export const GET_ALL_SELECTBOX_BANKSTATEMENTIMPORT = 'GET_ALL_SELECTBOX_BANKSTATEMENTIMPORT';
export const DELETE_BANKSTATEMENTIMPORT = 'DELETE_BANKSTATEMENTIMPORT';
export const UPDATE_BANKSTATEMENTIMPORT = 'UPDATE_BANKSTATEMENTIMPORT';
export const ADD_DIALOG_BANKSTATEMENTIMPORT = 'ADD_DIALOG_BANKSTATEMENTIMPORT';
export const EDIT_DIALOG_BANKSTATEMENTIMPORT = 'EDIT_DIALOG_BANKSTATEMENTIMPORT';
export const CLOSE_DIALOG_BANKSTATEMENTIMPORT = 'CLOSE_DIALOG_BANKSTATEMENTIMPORT';
export const UPLOAD_BANKSTATEMENTIMPORT = 'UPLOAD_BANKSTATEMENTIMPORT';

export const CREATE_BANKSTATEMENTIMPORTSPLIT = 'CREATE_BANKSTATEMENTIMPORTSPLIT';
export const GET_ALL_BANKSTATEMENTIMPORTSPLIT = 'GET_ALL_BANKSTATEMENTIMPORTSPLIT';
export const GET_BANKSTATEMENTIMPORTSPLIT = 'GET_BANKSTATEMENTIMPORTSPLIT';
export const GET_ALL_SELECTBOX_BANKSTATEMENTIMPORTSPLIT = 'GET_ALL_SELECTBOX_BANKSTATEMENTIMPORTSPLIT';
export const DELETE_BANKSTATEMENTIMPORTSPLIT = 'DELETE_BANKSTATEMENTIMPORTSPLIT';
export const UPDATE_BANKSTATEMENTIMPORTSPLIT = 'UPDATE_BANKSTATEMENTIMPORTSPLIT';
export const ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT = 'ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT';
export const EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT = 'EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT';
export const CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT = 'CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT';

export const CREATE_BLTURNOVER = 'CREATE_BLTURNOVER';
export const GET_ALL_BLTURNOVER = 'GET_ALL_BLTURNOVER';
export const GET_BLTURNOVER = 'GET_BLTURNOVER';
export const GET_ALL_SELECTBOX_BLTURNOVER = 'GET_ALL_SELECTBOX_BLTURNOVER';
export const DELETE_BLTURNOVER = 'DELETE_BLTURNOVER';
export const UPDATE_BLTURNOVER = 'UPDATE_BLTURNOVER';
export const ADD_DIALOG_BLTURNOVER = 'ADD_DIALOG_BLTURNOVER';
export const EDIT_DIALOG_BLTURNOVER = 'EDIT_DIALOG_BLTURNOVER';
export const CLOSE_DIALOG_BLTURNOVER = 'CLOSE_DIALOG_BLTURNOVER';
export const CLEAR_BLTURNOVER = 'CLEAR_BLTURNOVER';

export const CREATE_BOARDMEMBER = 'CREATE_BOARDMEMBER';
export const GET_ALL_BOARDMEMBER = 'GET_ALL_BOARDMEMBER';
export const GET_BOARDMEMBER = 'GET_BOARDMEMBER';
export const GET_ALL_SELECTBOX_BOARDMEMBER = 'GET_ALL_SELECTBOX_BOARDMEMBER';
export const DELETE_BOARDMEMBER = 'DELETE_BOARDMEMBER';
export const UPDATE_BOARDMEMBER = 'UPDATE_BOARDMEMBER';
export const ADD_DIALOG_BOARDMEMBER = 'ADD_DIALOG_BOARDMEMBER';
export const EDIT_DIALOG_BOARDMEMBER = 'EDIT_DIALOG_BOARDMEMBER';
export const CLOSE_DIALOG_BOARDMEMBER = 'CLOSE_DIALOG_BOARDMEMBER';

export const CREATE_BOARDMEMBERTYPE = 'CREATE_BOARDMEMBERTYPE';
export const GET_ALL_BOARDMEMBERTYPE = 'GET_ALL_BOARDMEMBERTYPE';
export const GET_BOARDMEMBERTYPE = 'GET_BOARDMEMBERTYPE';
export const GET_ALL_SELECTBOX_BOARDMEMBERTYPE = 'GET_ALL_SELECTBOX_BOARDMEMBERTYPE';
export const DELETE_BOARDMEMBERTYPE = 'DELETE_BOARDMEMBERTYPE';
export const UPDATE_BOARDMEMBERTYPE = 'UPDATE_BOARDMEMBERTYPE';
export const ADD_DIALOG_BOARDMEMBERTYPE = 'ADD_DIALOG_BOARDMEMBERTYPE';
export const EDIT_DIALOG_BOARDMEMBERTYPE = 'EDIT_DIALOG_BOARDMEMBERTYPE';
export const CLOSE_DIALOG_BOARDMEMBERTYPE = 'CLOSE_DIALOG_BOARDMEMBERTYPE';

export const CREATE_BRANCH = 'CREATE_BRANCH';
export const GET_ALL_BRANCH = 'GET_ALL_BRANCH';
export const GET_BRANCH = 'GET_BRANCH';
export const GET_ALL_SELECTBOX_BRANCH = 'GET_ALL_SELECTBOX_BRANCH';
export const DELETE_BRANCH = 'DELETE_BRANCH';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const ADD_DIALOG_BRANCH = 'ADD_DIALOG_BRANCH';
export const EDIT_DIALOG_BRANCH = 'EDIT_DIALOG_BRANCH';
export const CLOSE_DIALOG_BRANCH = 'CLOSE_DIALOG_BRANCH';

export const CREATE_BROKER = 'CREATE_BROKER';
export const GET_ALL_BROKER = 'GET_ALL_BROKER';
export const GET_BROKER = 'GET_BROKER';
export const GET_ALL_SELECTBOX_BROKER = 'GET_ALL_SELECTBOX_BROKER';
export const DELETE_BROKER = 'DELETE_BROKER';
export const UPDATE_BROKER = 'UPDATE_BROKER';
export const ADD_DIALOG_BROKER = 'ADD_DIALOG_BROKER';
export const EDIT_DIALOG_BROKER = 'EDIT_DIALOG_BROKER';
export const CLOSE_DIALOG_BROKER = 'CLOSE_DIALOG_BROKER';

export const CREATE_CASHBALANCE = 'CREATE_CASHBALANCE';
export const GET_ALL_CASHBALANCE = 'GET_ALL_CASHBALANCE';
export const GET_CASHBALANCE = 'GET_CASHBALANCE';
export const GET_ALL_SELECTBOX_CASHBALANCE = 'GET_ALL_SELECTBOX_CASHBALANCE';
export const DELETE_CASHBALANCE = 'DELETE_CASHBALANCE';
export const UPDATE_CASHBALANCE = 'UPDATE_CASHBALANCE';
export const ADD_DIALOG_CASHBALANCE = 'ADD_DIALOG_CASHBALANCE';
export const EDIT_DIALOG_CASHBALANCE = 'EDIT_DIALOG_CASHBALANCE';
export const CLOSE_DIALOG_CASHBALANCE = 'CLOSE_DIALOG_CASHBALANCE';

export const CREATE_CASHIER = 'CREATE_CASHIER';
export const GET_ALL_CASHIER = 'GET_ALL_CASHIER';
export const GET_CASHIER = 'GET_CASHIER';
export const GET_ALL_SELECTBOX_CASHIER = 'GET_ALL_SELECTBOX_CASHIER';
export const DELETE_CASHIER = 'DELETE_CASHIER';
export const UPDATE_CASHIER = 'UPDATE_CASHIER';
export const ADD_DIALOG_CASHIER = 'ADD_DIALOG_CASHIER';
export const EDIT_DIALOG_CASHIER = 'EDIT_DIALOG_CASHIER';
export const CLOSE_DIALOG_CASHIER = 'CLOSE_DIALOG_CASHIER';

export const CREATE_CITY = 'CREATE_CITY';
export const GET_ALL_CITY = 'GET_ALL_CITY';
export const GET_CITY = 'GET_CITY';
export const GET_ALL_SELECTBOX_CITY = 'GET_ALL_SELECTBOX_CITY';
export const DELETE_CITY = 'DELETE_CITY';
export const UPDATE_CITY = 'UPDATE_CITY';
export const ADD_DIALOG_CITY = 'ADD_DIALOG_CITY';
export const EDIT_DIALOG_CITY = 'EDIT_DIALOG_CITY';
export const CLOSE_DIALOG_CITY = 'CLOSE_DIALOG_CITY';

export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_ALL_SELECTBOX_COMPANY = 'GET_ALL_SELECTBOX_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const ADD_DIALOG_COMPANY = 'ADD_DIALOG_COMPANY';
export const EDIT_DIALOG_COMPANY = 'EDIT_DIALOG_COMPANY';
export const CLOSE_DIALOG_COMPANY = 'CLOSE_DIALOG_COMPANY';

export const GET_MYCOMPANY_DATA = 'GET_MYCOMPANY_DATA';
export const GET_MYCOMPANY_DATA_YEAR = 'GET_MYCOMPANY_DATA_YEAR';

export const CREATE_COMPANYDETAILS = 'CREATE_COMPANYDETAILS';
export const GET_ALL_COMPANYDETAILS = 'GET_ALL_COMPANYDETAILS';
export const GET_COMPANYDETAILS = 'GET_COMPANYDETAILS';
export const GET_ALL_SELECTBOX_COMPANYDETAILS = 'GET_ALL_SELECTBOX_COMPANYDETAILS';
export const DELETE_COMPANYDETAILS = 'DELETE_COMPANYDETAILS';
export const UPDATE_COMPANYDETAILS = 'UPDATE_COMPANYDETAILS';
export const ADD_DIALOG_COMPANYDETAILS = 'ADD_DIALOG_COMPANYDETAILS';
export const EDIT_DIALOG_COMPANYDETAILS = 'EDIT_DIALOG_COMPANYDETAILS';
export const CLOSE_DIALOG_COMPANYDETAILS = 'CLOSE_DIALOG_COMPANYDETAILS';

export const CREATE_CURRENCYEXCHANGERATE = 'CREATE_CURRENCYEXCHANGERATE';
export const GET_ALL_CURRENCYEXCHANGERATE = 'GET_ALL_CURRENCYEXCHANGERATE';
export const GET_CURRENCYEXCHANGERATE = 'GET_CURRENCYEXCHANGERATE';
export const GET_ALL_SELECTBOX_CURRENCYEXCHANGERATE = 'GET_ALL_SELECTBOX_CURRENCYEXCHANGERATE';
export const DELETE_CURRENCYEXCHANGERATE = 'DELETE_CURRENCYEXCHANGERATE';
export const UPDATE_CURRENCYEXCHANGERATE = 'UPDATE_CURRENCYEXCHANGERATE';
export const ADD_DIALOG_CURRENCYEXCHANGERATE = 'ADD_DIALOG_CURRENCYEXCHANGERATE';
export const EDIT_DIALOG_CURRENCYEXCHANGERATE = 'EDIT_DIALOG_CURRENCYEXCHANGERATE';
export const CLOSE_DIALOG_CURRENCYEXCHANGERATE = 'CLOSE_DIALOG_CURRENCYEXCHANGERATE';

export const CREATE_DEBTCOLLECTIONCOST = 'CREATE_DEBTCOLLECTIONCOST';
export const GET_ALL_DEBTCOLLECTIONCOST = 'GET_ALL_DEBTCOLLECTIONCOST';
export const GET_DEBTCOLLECTIONCOST = 'GET_DEBTCOLLECTIONCOST';
export const GET_ALL_SELECTBOX_DEBTCOLLECTIONCOST = 'GET_ALL_SELECTBOX_DEBTCOLLECTIONCOST';
export const DELETE_DEBTCOLLECTIONCOST = 'DELETE_DEBTCOLLECTIONCOST';
export const UPDATE_DEBTCOLLECTIONCOST = 'UPDATE_DEBTCOLLECTIONCOST';
export const ADD_DIALOG_DEBTCOLLECTIONCOST = 'ADD_DIALOG_DEBTCOLLECTIONCOST';
export const EDIT_DIALOG_DEBTCOLLECTIONCOST = 'EDIT_DIALOG_DEBTCOLLECTIONCOST';
export const CLOSE_DIALOG_DEBTCOLLECTIONCOST = 'CLOSE_DIALOG_DEBTCOLLECTIONCOST';

export const CREATE_DEBTCOLLECTIONCOSTDETAILS = 'CREATE_DEBTCOLLECTIONCOSTDETAILS';
export const GET_ALL_DEBTCOLLECTIONCOSTDETAILS = 'GET_ALL_DEBTCOLLECTIONCOSTDETAILS';
export const GET_DEBTCOLLECTIONCOSTDETAILS = 'GET_DEBTCOLLECTIONCOSTDETAILS';
export const GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS = 'GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS';
export const DELETE_DEBTCOLLECTIONCOSTDETAILS = 'DELETE_DEBTCOLLECTIONCOSTDETAILS';
export const UPDATE_DEBTCOLLECTIONCOSTDETAILS = 'UPDATE_DEBTCOLLECTIONCOSTDETAILS';
export const ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS = 'ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS';
export const EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS = 'EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS';
export const CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS = 'CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS';

export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const GET_ALL_DOCUMENT = 'GET_ALL_DOCUMENT';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const GET_ALL_SELECTBOX_DOCUMENT = 'GET_ALL_SELECTBOX_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const ADD_DIALOG_DOCUMENT = 'ADD_DIALOG_DOCUMENT';
export const EDIT_DIALOG_DOCUMENT = 'EDIT_DIALOG_DOCUMENT';
export const CLOSE_DIALOG_DOCUMENT = 'CLOSE_DIALOG_DOCUMENT';

export const CREATE_DOCUMENTTURNOVERTYPE = 'CREATE_DOCUMENTTURNOVERTYPE';
export const GET_ALL_DOCUMENTTURNOVERTYPE = 'GET_ALL_DOCUMENTTURNOVERTYPE';
export const GET_DOCUMENTTURNOVERTYPE = 'GET_DOCUMENTTURNOVERTYPE';
export const GET_ALL_SELECTBOX_DOCUMENTTURNOVERTYPE = 'GET_ALL_SELECTBOX_DOCUMENTTURNOVERTYPE';
export const DELETE_DOCUMENTTURNOVERTYPE = 'DELETE_DOCUMENTTURNOVERTYPE';
export const UPDATE_DOCUMENTTURNOVERTYPE = 'UPDATE_DOCUMENTTURNOVERTYPE';
export const ADD_DIALOG_DOCUMENTTURNOVERTYPE = 'ADD_DIALOG_DOCUMENTTURNOVERTYPE';
export const EDIT_DIALOG_DOCUMENTTURNOVERTYPE = 'EDIT_DIALOG_DOCUMENTTURNOVERTYPE';
export const CLOSE_DIALOG_DOCUMENTTURNOVERTYPE = 'CLOSE_DIALOG_DOCUMENTTURNOVERTYPE';

export const CREATE_DOCUMENTTYPE = 'CREATE_DOCUMENTTYPE';
export const GET_ALL_DOCUMENTTYPE = 'GET_ALL_DOCUMENTTYPE';
export const GET_DOCUMENTTYPE = 'GET_DOCUMENTTYPE';
export const GET_ALL_SELECTBOX_DOCUMENTTYPE = 'GET_ALL_SELECTBOX_DOCUMENTTYPE';
export const DELETE_DOCUMENTTYPE = 'DELETE_DOCUMENTTYPE';
export const UPDATE_DOCUMENTTYPE = 'UPDATE_DOCUMENTTYPE';
export const ADD_DIALOG_DOCUMENTTYPE = 'ADD_DIALOG_DOCUMENTTYPE';
export const EDIT_DIALOG_DOCUMENTTYPE = 'EDIT_DIALOG_DOCUMENTTYPE';
export const CLOSE_DIALOG_DOCUMENTTYPE = 'CLOSE_DIALOG_DOCUMENTTYPE';

export const CREATE_EMPLOYMENTTYPE = 'CREATE_EMPLOYMENTTYPE';
export const GET_ALL_EMPLOYMENTTYPE = 'GET_ALL_EMPLOYMENTTYPE';
export const GET_EMPLOYMENTTYPE = 'GET_EMPLOYMENTTYPE';
export const GET_ALL_SELECTBOX_EMPLOYMENTTYPE = 'GET_ALL_SELECTBOX_EMPLOYMENTTYPE';
export const DELETE_EMPLOYMENTTYPE = 'DELETE_EMPLOYMENTTYPE';
export const UPDATE_EMPLOYMENTTYPE = 'UPDATE_EMPLOYMENTTYPE';
export const ADD_DIALOG_EMPLOYMENTTYPE = 'ADD_DIALOG_EMPLOYMENTTYPE';
export const EDIT_DIALOG_EMPLOYMENTTYPE = 'EDIT_DIALOG_EMPLOYMENTTYPE';
export const CLOSE_DIALOG_EMPLOYMENTTYPE = 'CLOSE_DIALOG_EMPLOYMENTTYPE';

export const CREATE_ENTITY = 'CREATE_ENTITY';
export const GET_ALL_ENTITY = 'GET_ALL_ENTITY';
export const GET_ENTITY = 'GET_ENTITY';
export const GET_ALL_SELECTBOX_ENTITY = 'GET_ALL_SELECTBOX_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const ADD_DIALOG_ENTITY = 'ADD_DIALOG_ENTITY';
export const EDIT_DIALOG_ENTITY = 'EDIT_DIALOG_ENTITY';
export const CLOSE_DIALOG_ENTITY = 'CLOSE_DIALOG_ENTITY';

export const CREATE_FILEUPLOAD = 'CREATE_FILEUPLOAD';
export const GET_ALL_FILEUPLOAD = 'GET_ALL_FILEUPLOAD';
export const GET_FILEUPLOAD = 'GET_FILEUPLOAD';
export const GET_ALL_SELECTBOX_FILEUPLOAD = 'GET_ALL_SELECTBOX_FILEUPLOAD';
export const DELETE_FILEUPLOAD = 'DELETE_FILEUPLOAD';
export const UPDATE_FILEUPLOAD = 'UPDATE_FILEUPLOAD';
export const ADD_DIALOG_FILEUPLOAD = 'ADD_DIALOG_FILEUPLOAD';
export const EDIT_DIALOG_FILEUPLOAD = 'EDIT_DIALOG_FILEUPLOAD';
export const CLOSE_DIALOG_FILEUPLOAD = 'CLOSE_DIALOG_FILEUPLOAD';

export const CREATE_FILEUPLOADTYPE = 'CREATE_FILEUPLOADTYPE';
export const GET_ALL_FILEUPLOADTYPE = 'GET_ALL_FILEUPLOADTYPE';
export const GET_FILEUPLOADTYPE = 'GET_FILEUPLOADTYPE';
export const GET_ALL_SELECTBOX_FILEUPLOADTYPE = 'GET_ALL_SELECTBOX_FILEUPLOADTYPE';
export const DELETE_FILEUPLOADTYPE = 'DELETE_FILEUPLOADTYPE';
export const UPDATE_FILEUPLOADTYPE = 'UPDATE_FILEUPLOADTYPE';
export const ADD_DIALOG_FILEUPLOADTYPE = 'ADD_DIALOG_FILEUPLOADTYPE';
export const EDIT_DIALOG_FILEUPLOADTYPE = 'EDIT_DIALOG_FILEUPLOADTYPE';
export const CLOSE_DIALOG_FILEUPLOADTYPE = 'CLOSE_DIALOG_FILEUPLOADTYPE';

export const CREATE_LOANPARTNER = 'CREATE_LOANPARTNER';
export const GET_ALL_LOANPARTNER = 'GET_ALL_LOANPARTNER';
export const GET_ALL_LOANPARTNER_BY_LOAN = 'GET_ALL_LOANPARTNER_BY_LOAN';
export const GET_LOANPARTNER = 'GET_LOANPARTNER';
export const GET_ALL_SELECTBOX_LOANPARTNER = 'GET_ALL_SELECTBOX_LOANPARTNER';
export const DELETE_LOANPARTNER = 'DELETE_LOANPARTNER';
export const UPDATE_LOANPARTNER = 'UPDATE_LOANPARTNER';
export const CLEAR_LOANPARTNER = 'CLEAR_LOANPARTNER';
export const ADD_DIALOG_LOANPARTNER = 'ADD_DIALOG_LOANPARTNER';
export const EDIT_DIALOG_LOANPARTNER = 'EDIT_DIALOG_LOANPARTNER';
export const CLOSE_DIALOG_LOANPARTNER = 'CLOSE_DIALOG_LOANPARTNER';

export const GET_ALL_SELECTBOX_LOANPARTNERTYPE = 'GET_ALL_SELECTBOX_LOANPARTNERTYPE';

export const CREATE_GENDER = 'CREATE_GENDER';
export const GET_ALL_GENDER = 'GET_ALL_GENDER';
export const GET_GENDER = 'GET_GENDER';
export const GET_ALL_SELECTBOX_GENDER = 'GET_ALL_SELECTBOX_GENDER';
export const DELETE_GENDER = 'DELETE_GENDER';
export const UPDATE_GENDER = 'UPDATE_GENDER';
export const ADD_DIALOG_GENDER = 'ADD_DIALOG_GENDER';
export const EDIT_DIALOG_GENDER = 'EDIT_DIALOG_GENDER';
export const CLOSE_DIALOG_GENDER = 'CLOSE_DIALOG_GENDER';

export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_ALL_GROUP = 'GET_ALL_GROUP';
export const GET_GROUP = 'GET_GROUP';
export const GET_ALL_SELECTBOX_GROUP = 'GET_ALL_SELECTBOX_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const ADD_DIALOG_GROUP = 'ADD_DIALOG_GROUP';
export const EDIT_DIALOG_GROUP = 'EDIT_DIALOG_GROUP';
export const CLOSE_DIALOG_GROUP = 'CLOSE_DIALOG_GROUP';

export const CREATE_INCOMESOURCE = 'CREATE_INCOMESOURCE';
export const GET_ALL_INCOMESOURCE = 'GET_ALL_INCOMESOURCE';
export const GET_INCOMESOURCE = 'GET_INCOMESOURCE';
export const GET_ALL_SELECTBOX_INCOMESOURCE = 'GET_ALL_SELECTBOX_INCOMESOURCE';
export const DELETE_INCOMESOURCE = 'DELETE_INCOMESOURCE';
export const UPDATE_INCOMESOURCE = 'UPDATE_INCOMESOURCE';
export const ADD_DIALOG_INCOMESOURCE = 'ADD_DIALOG_INCOMESOURCE';
export const EDIT_DIALOG_INCOMESOURCE = 'EDIT_DIALOG_INCOMESOURCE';
export const CLOSE_DIALOG_INCOMESOURCE = 'CLOSE_DIALOG_INCOMESOURCE';

export const CREATE_INCOMESOURCETYPE = 'CREATE_INCOMESOURCETYPE';
export const GET_ALL_INCOMESOURCETYPE = 'GET_ALL_INCOMESOURCETYPE';
export const GET_INCOMESOURCETYPE = 'GET_INCOMESOURCETYPE';
export const GET_ALL_SELECTBOX_INCOMESOURCETYPE = 'GET_ALL_SELECTBOX_INCOMESOURCETYPE';
export const DELETE_INCOMESOURCETYPE = 'DELETE_INCOMESOURCETYPE';
export const UPDATE_INCOMESOURCETYPE = 'UPDATE_INCOMESOURCETYPE';
export const ADD_DIALOG_INCOMESOURCETYPE = 'ADD_DIALOG_INCOMESOURCETYPE';
export const EDIT_DIALOG_INCOMESOURCETYPE = 'EDIT_DIALOG_INCOMESOURCETYPE';
export const CLOSE_DIALOG_INCOMESOURCETYPE = 'CLOSE_DIALOG_INCOMESOURCETYPE';

export const CREATE_JOURNAL = 'CREATE_JOURNAL';
export const GET_ALL_JOURNAL = 'GET_ALL_JOURNAL';
export const GET_ALL_JOURNAL_BY_ORDER = 'GET_ALL_JOURNAL_BY_ORDER';
export const GET_JOURNAL = 'GET_JOURNAL';
export const GET_ALL_SELECTBOX_JOURNAL = 'GET_ALL_SELECTBOX_JOURNAL';
export const DELETE_JOURNAL = 'DELETE_JOURNAL';
export const UPDATE_JOURNAL = 'UPDATE_JOURNAL';
export const ADD_DIALOG_JOURNAL = 'ADD_DIALOG_JOURNAL';
export const EDIT_DIALOG_JOURNAL = 'EDIT_DIALOG_JOURNAL';
export const CLOSE_DIALOG_JOURNAL = 'CLOSE_DIALOG_JOURNAL';

export const CREATE_JOURNALHISTORY = 'CREATE_JOURNALHISTORY';
export const GET_ALL_JOURNALHISTORY = 'GET_ALL_JOURNALHISTORY';
export const GET_JOURNALHISTORY = 'GET_JOURNALHISTORY';
export const GET_ALL_SELECTBOX_JOURNALHISTORY = 'GET_ALL_SELECTBOX_JOURNALHISTORY';
export const DELETE_JOURNALHISTORY = 'DELETE_JOURNALHISTORY';
export const UPDATE_JOURNALHISTORY = 'UPDATE_JOURNALHISTORY';
export const ADD_DIALOG_JOURNALHISTORY = 'ADD_DIALOG_JOURNALHISTORY';
export const EDIT_DIALOG_JOURNALHISTORY = 'EDIT_DIALOG_JOURNALHISTORY';
export const CLOSE_DIALOG_JOURNALHISTORY = 'CLOSE_DIALOG_JOURNALHISTORY';

export const CREATE_KUF = 'CREATE_KUF';
export const GET_ALL_KUF = 'GET_ALL_KUF';
export const GET_KUF = 'GET_KUF';
export const GET_ALL_SELECTBOX_KUF = 'GET_ALL_SELECTBOX_KUF';
export const DELETE_KUF = 'DELETE_KUF';
export const UPDATE_KUF = 'UPDATE_KUF';
export const ADD_DIALOG_KUF = 'ADD_DIALOG_KUF';
export const EDIT_DIALOG_KUF = 'EDIT_DIALOG_KUF';
export const CLOSE_DIALOG_KUF = 'CLOSE_DIALOG_KUF';

export const CREATE_LEGALSERVICE_ACTIVITY = 'CREATE_LEGALSERVICE_ACTIVITY';
export const GET_ALL_LEGALSERVICE_ACTIVITY = 'GET_ALL_LEGALSERVICE_ACTIVITY';
export const GET_LEGALSERVICE_ACTIVITY = 'GET_LEGALSERVICE_ACTIVITY';
export const GET_ALL_SELECTBOX_LEGALSERVICE_ACTIVITY = 'GET_ALL_SELECTBOX_LEGALSERVICE_ACTIVITY';
export const DELETE_LEGALSERVICE_ACTIVITY = 'DELETE_LEGALSERVICE_ACTIVITY';
export const UPDATE_LEGALSERVICE_ACTIVITY = 'UPDATE_LEGALSERVICE_ACTIVITY';
export const ADD_DIALOG_LEGALSERVICE_ACTIVITY = 'ADD_DIALOG_LEGALSERVICE_ACTIVITY';
export const EDIT_DIALOG_LEGALSERVICE_ACTIVITY = 'EDIT_DIALOG_LEGALSERVICE_ACTIVITY';
export const CLOSE_DIALOG_LEGALSERVICE_ACTIVITY = 'CLOSE_DIALOG_LEGALSERVICE_ACTIVITY';

export const CREATE_LEGALSERVICE_COURT = 'CREATE_LEGALSERVICE_COURT';
export const GET_ALL_LEGALSERVICE_COURT = 'GET_ALL_LEGALSERVICE_COURT';
export const GET_LEGALSERVICE_COURT = 'GET_LEGALSERVICE_COURT';
export const GET_ALL_SELECTBOX_LEGALSERVICE_COURT = 'GET_ALL_SELECTBOX_LEGALSERVICE_COURT';
export const DELETE_LEGALSERVICE_COURT = 'DELETE_LEGALSERVICE_COURT';
export const UPDATE_LEGALSERVICE_COURT = 'UPDATE_LEGALSERVICE_COURT';
export const ADD_DIALOG_LEGALSERVICE_COURT = 'ADD_DIALOG_LEGALSERVICE_COURT';
export const EDIT_DIALOG_LEGALSERVICE_COURT = 'EDIT_DIALOG_LEGALSERVICE_COURT';
export const CLOSE_DIALOG_LEGALSERVICE_COURT = 'CLOSE_DIALOG_LEGALSERVICE_COURT';

export const CREATE_LEGALSERVICE_DOCUMENT = 'CREATE_LEGALSERVICE_DOCUMENT';
export const GET_ALL_LEGALSERVICE_DOCUMENT = 'GET_ALL_LEGALSERVICE_DOCUMENT';
export const GET_LEGALSERVICE_DOCUMENT = 'GET_LEGALSERVICE_DOCUMENT';
export const GET_ALL_SELECTBOX_LEGALSERVICE_DOCUMENT = 'GET_ALL_SELECTBOX_LEGALSERVICE_DOCUMENT';
export const DELETE_LEGALSERVICE_DOCUMENT = 'DELETE_LEGALSERVICE_DOCUMENT';
export const UPDATE_LEGALSERVICE_DOCUMENT = 'UPDATE_LEGALSERVICE_DOCUMENT';
export const ADD_DIALOG_LEGALSERVICE_DOCUMENT = 'ADD_DIALOG_LEGALSERVICE_DOCUMENT';
export const EDIT_DIALOG_LEGALSERVICE_DOCUMENT = 'EDIT_DIALOG_LEGALSERVICE_DOCUMENT';
export const CLOSE_DIALOG_LEGALSERVICE_DOCUMENT = 'CLOSE_DIALOG_LEGALSERVICE_DOCUMENT';

export const CREATE_LEGALSERVICE_EXECUTIONTYPE = 'CREATE_LEGALSERVICE_EXECUTIONTYPE';
export const GET_ALL_LEGALSERVICE_EXECUTIONTYPE = 'GET_ALL_LEGALSERVICE_EXECUTIONTYPE';
export const GET_LEGALSERVICE_EXECUTIONTYPE = 'GET_LEGALSERVICE_EXECUTIONTYPE';
export const GET_ALL_SELECTBOX_LEGALSERVICE_EXECUTIONTYPE = 'GET_ALL_SELECTBOX_LEGALSERVICE_EXECUTIONTYPE';
export const DELETE_LEGALSERVICE_EXECUTIONTYPE = 'DELETE_LEGALSERVICE_EXECUTIONTYPE';
export const UPDATE_LEGALSERVICE_EXECUTIONTYPE = 'UPDATE_LEGALSERVICE_EXECUTIONTYPE';
export const ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE = 'ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE';
export const EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE = 'EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE';
export const CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE = 'CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE';

export const CREATE_LEGALSERVICE_WARNING = 'CREATE_LEGALSERVICE_WARNING';
export const GET_ALL_LEGALSERVICE_WARNING = 'GET_ALL_LEGALSERVICE_WARNING';
export const GET_LEGALSERVICE_WARNING = 'GET_LEGALSERVICE_WARNING';
export const GET_ALL_SELECTBOX_LEGALSERVICE_WARNING = 'GET_ALL_SELECTBOX_LEGALSERVICE_WARNING';
export const DELETE_LEGALSERVICE_WARNING = 'DELETE_LEGALSERVICE_WARNING';
export const UPDATE_LEGALSERVICE_WARNING = 'UPDATE_LEGALSERVICE_WARNING';
export const ADD_DIALOG_LEGALSERVICE_WARNING = 'ADD_DIALOG_LEGALSERVICE_WARNING';
export const EDIT_DIALOG_LEGALSERVICE_WARNING = 'EDIT_DIALOG_LEGALSERVICE_WARNING';
export const CLOSE_DIALOG_LEGALSERVICE_WARNING = 'CLOSE_DIALOG_LEGALSERVICE_WARNING';

export const GET_ALL_DEBS_LEGALSERVICE = 'GET_ALL_DEBS_LEGALSERVICE';
export const GET_ALL_ISKUP_LEGALSERVICE = 'GET_ALL_ISKUP_LEGALSERVICE';
export const GET_ALL_EXECUTION_LEGALSERVICE = 'GET_ALL_EXECUTION_LEGALSERVICE';
export const GET_ALL_DONE_LEGALSERVICE = 'GET_ALL_DONE_LEGALSERVICE';

export const CREATE_LEGALSERVICE_ISKUP = 'CREATE_LEGALSERVICE_ISKUP';
export const CREATE_LEGALSERVICE_EXECUTION = 'CREATE_LEGALSERVICE_EXECUTION';
export const CREATE_LEGALSERVICE_DONE = 'CREATE_LEGALSERVICE_DONE';

export const UPDATE_LEGALSERVICE_ISKUP = 'UPDATE_LEGALSERVICE_ISKUP';
export const UPDATE_LEGALSERVICE_EXECUTION = 'UPDATE_LEGALSERVICE_EXECUTION';

export const CREATE_LOAN = 'CREATE_LOAN';
export const GET_ALL_LOAN = 'GET_ALL_LOAN';
export const GET_ALL_BASE_LOAN_DATA = 'GET_ALL_BASE_LOAN_DATA';
export const GET_LOAN = 'GET_LOAN';
export const GET_LOANS_BY_PARTNER = 'GET_LOANS_BY_PARTNER';
export const GET_LOANS_BY_SEARCH = 'GET_LOANS_BY_SEARCH';
export const GET_ALL_SELECTBOX_LOAN = 'GET_ALL_SELECTBOX_LOAN';
export const DELETE_LOAN = 'DELETE_LOAN';
export const UPDATE_LOAN = 'UPDATE_LOAN';
export const UPDATE_LOAN_START_DATE = 'UPDATE_LOAN_START_DATE';
export const UPDATE_LOAN_MADE_ACTION = 'UPDATE_LOAN_MADE_ACTION';
export const ADD_DIALOG_LOAN = 'ADD_DIALOG_LOAN';
export const EDIT_DIALOG_LOAN = 'EDIT_DIALOG_LOAN';
export const CLOSE_DIALOG_LOAN = 'CLOSE_DIALOG_LOAN';
export const GET_LOAN_HALCOM = 'GET_LOAN_HALCOM';

export const CREATE_LOANAPPROVAL = 'CREATE_LOANAPPROVAL';
export const GET_ALL_LOANAPPROVAL = 'GET_ALL_LOANAPPROVAL';
export const GET_LOANAPPROVAL = 'GET_LOANAPPROVAL';
export const GET_ALL_SELECTBOX_LOANAPPROVAL = 'GET_ALL_SELECTBOX_LOANAPPROVAL';
export const DELETE_LOANAPPROVAL = 'DELETE_LOANAPPROVAL';
export const UPDATE_LOANAPPROVAL = 'UPDATE_LOANAPPROVAL';
export const ADD_DIALOG_LOANAPPROVAL = 'ADD_DIALOG_LOANAPPROVAL';
export const EDIT_DIALOG_LOANAPPROVAL = 'EDIT_DIALOG_LOANAPPROVAL';
export const CLOSE_DIALOG_LOANAPPROVAL = 'CLOSE_DIALOG_LOANAPPROVAL';

export const CREATE_LOANAPPROVALSETTINGS = 'CREATE_LOANAPPROVALSETTINGS';
export const GET_ALL_LOANAPPROVALSETTINGS = 'GET_ALL_LOANAPPROVALSETTINGS';
export const GET_LOANAPPROVALSETTINGS = 'GET_LOANAPPROVALSETTINGS';
export const GET_ALL_SELECTBOX_LOANAPPROVALSETTINGS = 'GET_ALL_SELECTBOX_LOANAPPROVALSETTINGS';
export const DELETE_LOANAPPROVALSETTINGS = 'DELETE_LOANAPPROVALSETTINGS';
export const UPDATE_LOANAPPROVALSETTINGS = 'UPDATE_LOANAPPROVALSETTINGS';
export const ADD_DIALOG_LOANAPPROVALSETTINGS = 'ADD_DIALOG_LOANAPPROVALSETTINGS';
export const EDIT_DIALOG_LOANAPPROVALSETTINGS = 'EDIT_DIALOG_LOANAPPROVALSETTINGS';
export const CLOSE_DIALOG_LOANAPPROVALSETTINGS = 'CLOSE_DIALOG_LOANAPPROVALSETTINGS';

export const CREATE_LOANHISTORY = 'CREATE_LOANHISTORY';
export const GET_ALL_LOANHISTORY = 'GET_ALL_LOANHISTORY';
export const GET_LOANHISTORY = 'GET_LOANHISTORY';
export const GET_ALL_SELECTBOX_LOANHISTORY = 'GET_ALL_SELECTBOX_LOANHISTORY';
export const DELETE_LOANHISTORY = 'DELETE_LOANHISTORY';
export const UPDATE_LOANHISTORY = 'UPDATE_LOANHISTORY';
export const ADD_DIALOG_LOANHISTORY = 'ADD_DIALOG_LOANHISTORY';
export const EDIT_DIALOG_LOANHISTORY = 'EDIT_DIALOG_LOANHISTORY';
export const CLOSE_DIALOG_LOANHISTORY = 'CLOSE_DIALOG_LOANHISTORY';

export const CREATE_LOANMORATORIUM = 'CREATE_LOANMORATORIUM';
export const GET_ALL_LOANMORATORIUM = 'GET_ALL_LOANMORATORIUM';
export const GET_LOANMORATORIUM = 'GET_LOANMORATORIUM';
export const GET_ALL_SELECTBOX_LOANMORATORIUM = 'GET_ALL_SELECTBOX_LOANMORATORIUM';
export const DELETE_LOANMORATORIUM = 'DELETE_LOANMORATORIUM';
export const UPDATE_LOANMORATORIUM = 'UPDATE_LOANMORATORIUM';
export const ADD_DIALOG_LOANMORATORIUM = 'ADD_DIALOG_LOANMORATORIUM';
export const EDIT_DIALOG_LOANMORATORIUM = 'EDIT_DIALOG_LOANMORATORIUM';
export const CLOSE_DIALOG_LOANMORATORIUM = 'CLOSE_DIALOG_LOANMORATORIUM';

export const CREATE_LOANQUALITY = 'CREATE_LOANQUALITY';
export const GET_ALL_LOANQUALITY = 'GET_ALL_LOANQUALITY';
export const GET_LOANQUALITY = 'GET_LOANQUALITY';
export const GET_ALL_SELECTBOX_LOANQUALITY = 'GET_ALL_SELECTBOX_LOANQUALITY';
export const DELETE_LOANQUALITY = 'DELETE_LOANQUALITY';
export const UPDATE_LOANQUALITY = 'UPDATE_LOANQUALITY';
export const ADD_DIALOG_LOANQUALITY = 'ADD_DIALOG_LOANQUALITY';
export const EDIT_DIALOG_LOANQUALITY = 'EDIT_DIALOG_LOANQUALITY';
export const CLOSE_DIALOG_LOANQUALITY = 'CLOSE_DIALOG_LOANQUALITY';

export const CREATE_LOANQUALITYSTATUS = 'CREATE_LOANQUALITYSTATUS';
export const GET_ALL_LOANQUALITYSTATUS = 'GET_ALL_LOANQUALITYSTATUS';
export const GET_LOANQUALITYSTATUS = 'GET_LOANQUALITYSTATUS';
export const GET_ALL_SELECTBOX_LOANQUALITYSTATUS = 'GET_ALL_SELECTBOX_LOANQUALITYSTATUS';
export const DELETE_LOANQUALITYSTATUS = 'DELETE_LOANQUALITYSTATUS';
export const UPDATE_LOANQUALITYSTATUS = 'UPDATE_LOANQUALITYSTATUS';
export const ADD_DIALOG_LOANQUALITYSTATUS = 'ADD_DIALOG_LOANQUALITYSTATUS';
export const EDIT_DIALOG_LOANQUALITYSTATUS = 'EDIT_DIALOG_LOANQUALITYSTATUS';
export const CLOSE_DIALOG_LOANQUALITYSTATUS = 'CLOSE_DIALOG_LOANQUALITYSTATUS';

export const CREATE_LOANREPAYMENTSCHEDULE = 'CREATE_LOANREPAYMENTSCHEDULE';
export const GET_ALL_LOANREPAYMENTSCHEDULE = 'GET_ALL_LOANREPAYMENTSCHEDULE';
export const GET_LOANREPAYMENTSCHEDULE = 'GET_LOANREPAYMENTSCHEDULE';
export const GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULE = 'GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULE';
export const DELETE_LOANREPAYMENTSCHEDULE = 'DELETE_LOANREPAYMENTSCHEDULE';
export const UPDATE_LOANREPAYMENTSCHEDULE = 'UPDATE_LOANREPAYMENTSCHEDULE';
export const ADD_DIALOG_LOANREPAYMENTSCHEDULE = 'ADD_DIALOG_LOANREPAYMENTSCHEDULE';
export const EDIT_DIALOG_LOANREPAYMENTSCHEDULE = 'EDIT_DIALOG_LOANREPAYMENTSCHEDULE';
export const CLOSE_DIALOG_LOANREPAYMENTSCHEDULE = 'CLOSE_DIALOG_LOANREPAYMENTSCHEDULE';

export const CREATE_LOANREPAYMENTSCHEDULETYPE = 'CREATE_LOANREPAYMENTSCHEDULETYPE';
export const GET_ALL_LOANREPAYMENTSCHEDULETYPE = 'GET_ALL_LOANREPAYMENTSCHEDULETYPE';
export const GET_LOANREPAYMENTSCHEDULETYPE = 'GET_LOANREPAYMENTSCHEDULETYPE';
export const GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULETYPE = 'GET_ALL_SELECTBOX_LOANREPAYMENTSCHEDULETYPE';
export const DELETE_LOANREPAYMENTSCHEDULETYPE = 'DELETE_LOANREPAYMENTSCHEDULETYPE';
export const UPDATE_LOANREPAYMENTSCHEDULETYPE = 'UPDATE_LOANREPAYMENTSCHEDULETYPE';
export const ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE = 'ADD_DIALOG_LOANREPAYMENTSCHEDULETYPE';
export const EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE = 'EDIT_DIALOG_LOANREPAYMENTSCHEDULETYPE';
export const CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE = 'CLOSE_DIALOG_LOANREPAYMENTSCHEDULETYPE';

export const CREATE_LOANREQUESTHISTORY = 'CREATE_LOANREQUESTHISTORY';
export const GET_ALL_LOANREQUESTHISTORY = 'GET_ALL_LOANREQUESTHISTORY';
export const GET_LOANREQUESTHISTORY = 'GET_LOANREQUESTHISTORY';
export const GET_ALL_SELECTBOX_LOANREQUESTHISTORY = 'GET_ALL_SELECTBOX_LOANREQUESTHISTORY';
export const DELETE_LOANREQUESTHISTORY = 'DELETE_LOANREQUESTHISTORY';
export const UPDATE_LOANREQUESTHISTORY = 'UPDATE_LOANREQUESTHISTORY';
export const ADD_DIALOG_LOANREQUESTHISTORY = 'ADD_DIALOG_LOANREQUESTHISTORY';
export const EDIT_DIALOG_LOANREQUESTHISTORY = 'EDIT_DIALOG_LOANREQUESTHISTORY';
export const CLOSE_DIALOG_LOANREQUESTHISTORY = 'CLOSE_DIALOG_LOANREQUESTHISTORY';

export const CREATE_LOANSTATUS = 'CREATE_LOANSTATUS';
export const GET_ALL_LOANSTATUS = 'GET_ALL_LOANSTATUS';
export const GET_LOANSTATUS = 'GET_LOANSTATUS';
export const GET_ALL_SELECTBOX_LOANSTATUS = 'GET_ALL_SELECTBOX_LOANSTATUS';
export const DELETE_LOANSTATUS = 'DELETE_LOANSTATUS';
export const UPDATE_LOANSTATUS = 'UPDATE_LOANSTATUS';
export const ADD_DIALOG_LOANSTATUS = 'ADD_DIALOG_LOANSTATUS';
export const EDIT_DIALOG_LOANSTATUS = 'EDIT_DIALOG_LOANSTATUS';
export const CLOSE_DIALOG_LOANSTATUS = 'CLOSE_DIALOG_LOANSTATUS';

export const GET_ALL_SELECTBOX_LOANPURPOSE = 'GET_ALL_SELECTBOX_LOANPURPOSE';

export const CREATE_LOANTAKENOUT = 'CREATE_LOANTAKENOUT';
export const GET_ALL_LOANTAKENOUT = 'GET_ALL_LOANTAKENOUT';
export const GET_IMPORT_LOANTAKENOUT = 'GET_IMPORT_LOANTAKENOUT';
export const GET_LOANTAKENOUT = 'GET_LOANTAKENOUT';
export const GET_ALL_SELECTBOX_LOANTAKENOUT = 'GET_ALL_SELECTBOX_LOANTAKENOUT';
export const DELETE_LOANTAKENOUT = 'DELETE_LOANTAKENOUT';
export const UPDATE_LOANTAKENOUT = 'UPDATE_LOANTAKENOUT';
export const ADD_DIALOG_LOANTAKENOUT = 'ADD_DIALOG_LOANTAKENOUT';
export const EDIT_DIALOG_LOANTAKENOUT = 'EDIT_DIALOG_LOANTAKENOUT';
export const CLOSE_DIALOG_LOANTAKENOUT = 'CLOSE_DIALOG_LOANTAKENOUT';

export const CREATE_LOANTYPE = 'CREATE_LOANTYPE';
export const GET_ALL_LOANTYPE = 'GET_ALL_LOANTYPE';
export const GET_LOANTYPE = 'GET_LOANTYPE';
export const GET_ALL_SELECTBOX_LOANTYPE = 'GET_ALL_SELECTBOX_LOANTYPE';
export const GET_ALL_SELECTBOX_EXTENDED_LOANTYPE = 'GET_ALL_SELECTBOX_EXTENDED_LOANTYPE'; 
export const DELETE_LOANTYPE = 'DELETE_LOANTYPE';
export const UPDATE_LOANTYPE = 'UPDATE_LOANTYPE';
export const ADD_DIALOG_LOANTYPE = 'ADD_DIALOG_LOANTYPE';
export const EDIT_DIALOG_LOANTYPE = 'EDIT_DIALOG_LOANTYPE';
export const CLOSE_DIALOG_LOANTYPE = 'CLOSE_DIALOG_LOANTYPE';

export const CREATE_MARITALSTATUS = 'CREATE_MARITALSTATUS';
export const GET_ALL_MARITALSTATUS = 'GET_ALL_MARITALSTATUS';
export const GET_MARITALSTATUS = 'GET_MARITALSTATUS';
export const GET_ALL_SELECTBOX_MARITALSTATUS = 'GET_ALL_SELECTBOX_MARITALSTATUS';
export const DELETE_MARITALSTATUS = 'DELETE_MARITALSTATUS';
export const UPDATE_MARITALSTATUS = 'UPDATE_MARITALSTATUS';
export const ADD_DIALOG_MARITALSTATUS = 'ADD_DIALOG_MARITALSTATUS';
export const EDIT_DIALOG_MARITALSTATUS = 'EDIT_DIALOG_MARITALSTATUS';
export const CLOSE_DIALOG_MARITALSTATUS = 'CLOSE_DIALOG_MARITALSTATUS';

export const CREATE_OPINIONOFTHEREFERENT = 'CREATE_OPINIONOFTHEREFERENT';
export const GET_ALL_OPINIONOFTHEREFERENT = 'GET_ALL_OPINIONOFTHEREFERENT';
export const GET_OPINIONOFTHEREFERENT = 'GET_OPINIONOFTHEREFERENT';
export const GET_ALL_SELECTBOX_OPINIONOFTHEREFERENT = 'GET_ALL_SELECTBOX_OPINIONOFTHEREFERENT';
export const DELETE_OPINIONOFTHEREFERENT = 'DELETE_OPINIONOFTHEREFERENT';
export const UPDATE_OPINIONOFTHEREFERENT = 'UPDATE_OPINIONOFTHEREFERENT';
export const ADD_DIALOG_OPINIONOFTHEREFERENT = 'ADD_DIALOG_OPINIONOFTHEREFERENT';
export const EDIT_DIALOG_OPINIONOFTHEREFERENT = 'EDIT_DIALOG_OPINIONOFTHEREFERENT';
export const CLOSE_DIALOG_OPINIONOFTHEREFERENT = 'CLOSE_DIALOG_OPINIONOFTHEREFERENT';

export const CREATE_ORDER = 'CREATE_ORDER';
export const GET_ALL_ORDER = 'GET_ALL_ORDER';
export const GET_ORDER = 'GET_ORDER';
export const GET_ALL_SELECTBOX_ORDER = 'GET_ALL_SELECTBOX_ORDER';
export const DELETE_ORDER = 'DELETE_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const ADD_DIALOG_ORDER = 'ADD_DIALOG_ORDER';
export const EDIT_DIALOG_ORDER = 'EDIT_DIALOG_ORDER';
export const CLOSE_DIALOG_ORDER = 'CLOSE_DIALOG_ORDER';
export const GET_ALL_ORDER_IN_IMBALANCE = 'GET_ALL_ORDER_IN_IMBALANCE';

export const CREATE_PARTNER = 'CREATE_PARTNER';
export const GET_ALL_PARTNER = 'GET_ALL_PARTNER';
export const GET_PARTNER = 'GET_PARTNER';
export const GET_ALL_SELECTBOX_PARTNER = 'GET_ALL_SELECTBOX_PARTNER';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const UPDATE_PARTNER = 'UPDATE_PARTNER';
export const ADD_DIALOG_PARTNER = 'ADD_DIALOG_PARTNER';
export const EDIT_DIALOG_PARTNER = 'EDIT_DIALOG_PARTNER';
export const CLOSE_DIALOG_PARTNER = 'CLOSE_DIALOG_PARTNER';

export const CREATE_PARTNERNOTE = 'CREATE_PARTNERNOTE';
export const GET_ALL_PARTNERNOTE = 'GET_ALL_PARTNERNOTE';
export const GET_PARTNERNOTE = 'GET_PARTNERNOTE';
export const GET_ALL_SELECTBOX_PARTNERNOTE = 'GET_ALL_SELECTBOX_PARTNERNOTE';
export const DELETE_PARTNERNOTE = 'DELETE_PARTNERNOTE';
export const UPDATE_PARTNERNOTE = 'UPDATE_PARTNERNOTE';
export const ADD_DIALOG_PARTNERNOTE = 'ADD_DIALOG_PARTNERNOTE';
export const EDIT_DIALOG_PARTNERNOTE = 'EDIT_DIALOG_PARTNERNOTE';
export const CLOSE_DIALOG_PARTNERNOTE = 'CLOSE_DIALOG_PARTNERNOTE';

export const CREATE_PARTNERTYPE = 'CREATE_PARTNERTYPE';
export const GET_ALL_PARTNERTYPE = 'GET_ALL_PARTNERTYPE';
export const GET_PARTNERTYPE = 'GET_PARTNERTYPE';
export const GET_ALL_SELECTBOX_PARTNERTYPE = 'GET_ALL_SELECTBOX_PARTNERTYPE';
export const DELETE_PARTNERTYPE = 'DELETE_PARTNERTYPE';
export const UPDATE_PARTNERTYPE = 'UPDATE_PARTNERTYPE';
export const ADD_DIALOG_PARTNERTYPE = 'ADD_DIALOG_PARTNERTYPE';
export const EDIT_DIALOG_PARTNERTYPE = 'EDIT_DIALOG_PARTNERTYPE';
export const CLOSE_DIALOG_PARTNERTYPE = 'CLOSE_DIALOG_PARTNERTYPE';

export const CREATE_PAYMENTNOTETYPE = 'CREATE_PAYMENTNOTETYPE';
export const GET_ALL_PAYMENTNOTETYPE = 'GET_ALL_PAYMENTNOTETYPE';
export const GET_PAYMENTNOTETYPE = 'GET_PAYMENTNOTETYPE';
export const GET_ALL_SELECTBOX_PAYMENTNOTETYPE = 'GET_ALL_SELECTBOX_PAYMENTNOTETYPE';
export const DELETE_PAYMENTNOTETYPE = 'DELETE_PAYMENTNOTETYPE';
export const UPDATE_PAYMENTNOTETYPE = 'UPDATE_PAYMENTNOTETYPE';
export const ADD_DIALOG_PAYMENTNOTETYPE = 'ADD_DIALOG_PAYMENTNOTETYPE';
export const EDIT_DIALOG_PAYMENTNOTETYPE = 'EDIT_DIALOG_PAYMENTNOTETYPE';
export const CLOSE_DIALOG_PAYMENTNOTETYPE = 'CLOSE_DIALOG_PAYMENTNOTETYPE';

export const CREATE_PAYMENTWAY = 'CREATE_PAYMENTWAY';
export const GET_ALL_PAYMENTWAY = 'GET_ALL_PAYMENTWAY';
export const GET_PAYMENTWAY = 'GET_PAYMENTWAY';
export const GET_ALL_SELECTBOX_PAYMENTWAY = 'GET_ALL_SELECTBOX_PAYMENTWAY';
export const DELETE_PAYMENTWAY = 'DELETE_PAYMENTWAY';
export const UPDATE_PAYMENTWAY = 'UPDATE_PAYMENTWAY';
export const ADD_DIALOG_PAYMENTWAY = 'ADD_DIALOG_PAYMENTWAY';
export const EDIT_DIALOG_PAYMENTWAY = 'EDIT_DIALOG_PAYMENTWAY';
export const CLOSE_DIALOG_PAYMENTWAY = 'CLOSE_DIALOG_PAYMENTWAY';

export const CREATE_PAYWARNING = 'CREATE_PAYWARNING';
export const GET_ALL_PAYWARNING = 'GET_ALL_PAYWARNING';
export const GET_PAYWARNING = 'GET_PAYWARNING';
export const GET_ALL_SELECTBOX_PAYWARNING = 'GET_ALL_SELECTBOX_PAYWARNING';
export const DELETE_PAYWARNING = 'DELETE_PAYWARNING';
export const UPDATE_PAYWARNING = 'UPDATE_PAYWARNING';
export const ADD_DIALOG_PAYWARNING = 'ADD_DIALOG_PAYWARNING';
export const EDIT_DIALOG_PAYWARNING = 'EDIT_DIALOG_PAYWARNING';
export const CLOSE_DIALOG_PAYWARNING = 'CLOSE_DIALOG_PAYWARNING';

export const CREATE_PAYWARNINGTYPE = 'CREATE_PAYWARNINGTYPE';
export const GET_ALL_PAYWARNINGTYPE = 'GET_ALL_PAYWARNINGTYPE';
export const GET_PAYWARNINGTYPE = 'GET_PAYWARNINGTYPE';
export const GET_ALL_SELECTBOX_PAYWARNINGTYPE = 'GET_ALL_SELECTBOX_PAYWARNINGTYPE';
export const DELETE_PAYWARNINGTYPE = 'DELETE_PAYWARNINGTYPE';
export const UPDATE_PAYWARNINGTYPE = 'UPDATE_PAYWARNINGTYPE';
export const ADD_DIALOG_PAYWARNINGTYPE = 'ADD_DIALOG_PAYWARNINGTYPE';
export const EDIT_DIALOG_PAYWARNINGTYPE = 'EDIT_DIALOG_PAYWARNINGTYPE';
export const CLOSE_DIALOG_PAYWARNINGTYPE = 'CLOSE_DIALOG_PAYWARNINGTYPE';

export const CREATE_PENALTYINTEREST = 'CREATE_PENALTYINTEREST';
export const GET_ALL_PENALTYINTEREST = 'GET_ALL_PENALTYINTEREST';
export const GET_PENALTYINTEREST = 'GET_PENALTYINTEREST';
export const GET_ALL_SELECTBOX_PENALTYINTEREST = 'GET_ALL_SELECTBOX_PENALTYINTEREST';
export const DELETE_PENALTYINTEREST = 'DELETE_PENALTYINTEREST';
export const UPDATE_PENALTYINTEREST = 'UPDATE_PENALTYINTEREST';
export const ADD_DIALOG_PENALTYINTEREST = 'ADD_DIALOG_PENALTYINTEREST';
export const EDIT_DIALOG_PENALTYINTEREST = 'EDIT_DIALOG_PENALTYINTEREST';
export const CLOSE_DIALOG_PENALTYINTEREST = 'CLOSE_DIALOG_PENALTYINTEREST';

export const CREATE_PENALTYINTERESTDETAILS = 'CREATE_PENALTYINTERESTDETAILS';
export const GET_ALL_PENALTYINTERESTDETAILS = 'GET_ALL_PENALTYINTERESTDETAILS';
export const GET_PENALTYINTERESTDETAILS = 'GET_PENALTYINTERESTDETAILS';
export const GET_ALL_SELECTBOX_PENALTYINTERESTDETAILS = 'GET_ALL_SELECTBOX_PENALTYINTERESTDETAILS';
export const DELETE_PENALTYINTERESTDETAILS = 'DELETE_PENALTYINTERESTDETAILS';
export const UPDATE_PENALTYINTERESTDETAILS = 'UPDATE_PENALTYINTERESTDETAILS';
export const ADD_DIALOG_PENALTYINTERESTDETAILS = 'ADD_DIALOG_PENALTYINTERESTDETAILS';
export const EDIT_DIALOG_PENALTYINTERESTDETAILS = 'EDIT_DIALOG_PENALTYINTERESTDETAILS';
export const CLOSE_DIALOG_PENALTYINTERESTDETAILS = 'CLOSE_DIALOG_PENALTYINTERESTDETAILS';

export const CREATE_PLAN = 'CREATE_PLAN';
export const GET_ALL_PLAN = 'GET_ALL_PLAN';
export const GET_PLAN = 'GET_PLAN';
export const GET_ALL_SELECTBOX_PLAN = 'GET_ALL_SELECTBOX_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const ADD_DIALOG_PLAN = 'ADD_DIALOG_PLAN';
export const EDIT_DIALOG_PLAN = 'EDIT_DIALOG_PLAN';
export const CLOSE_DIALOG_PLAN = 'CLOSE_DIALOG_PLAN';

export const CREATE_QUALIFICATIONS = 'CREATE_QUALIFICATIONS';
export const GET_ALL_QUALIFICATIONS = 'GET_ALL_QUALIFICATIONS';
export const GET_QUALIFICATIONS = 'GET_QUALIFICATIONS';
export const GET_ALL_SELECTBOX_QUALIFICATIONS = 'GET_ALL_SELECTBOX_QUALIFICATIONS';
export const DELETE_QUALIFICATIONS = 'DELETE_QUALIFICATIONS';
export const UPDATE_QUALIFICATIONS = 'UPDATE_QUALIFICATIONS';
export const ADD_DIALOG_QUALIFICATIONS = 'ADD_DIALOG_QUALIFICATIONS';
export const EDIT_DIALOG_QUALIFICATIONS = 'EDIT_DIALOG_QUALIFICATIONS';
export const CLOSE_DIALOG_QUALIFICATIONS = 'CLOSE_DIALOG_QUALIFICATIONS';

export const CREATE_REALESTATES = 'CREATE_REALESTATES';
export const GET_ALL_REALESTATES = 'GET_ALL_REALESTATES';
export const GET_REALESTATES = 'GET_REALESTATES';
export const GET_ALL_SELECTBOX_REALESTATES = 'GET_ALL_SELECTBOX_REALESTATES';
export const DELETE_REALESTATES = 'DELETE_REALESTATES';
export const UPDATE_REALESTATES = 'UPDATE_REALESTATES';
export const ADD_DIALOG_REALESTATES = 'ADD_DIALOG_REALESTATES';
export const EDIT_DIALOG_REALESTATES = 'EDIT_DIALOG_REALESTATES';
export const CLOSE_DIALOG_REALESTATES = 'CLOSE_DIALOG_REALESTATES';

export const CREATE_REFINANCING = 'CREATE_REFINANCING';
export const GET_ALL_REFINANCING = 'GET_ALL_REFINANCING';
export const GET_REFINANCING = 'GET_REFINANCING';
export const GET_ALL_SELECTBOX_REFINANCING = 'GET_ALL_SELECTBOX_REFINANCING';
export const DELETE_REFINANCING = 'DELETE_REFINANCING';
export const UPDATE_REFINANCING = 'UPDATE_REFINANCING';
export const ADD_DIALOG_REFINANCING = 'ADD_DIALOG_REFINANCING';
export const EDIT_DIALOG_REFINANCING = 'EDIT_DIALOG_REFINANCING';
export const CLOSE_DIALOG_REFINANCING = 'CLOSE_DIALOG_REFINANCING';

export const CREATE_REGION = 'CREATE_REGION';
export const GET_ALL_REGION = 'GET_ALL_REGION';
export const GET_REGION = 'GET_REGION';
export const GET_ALL_SELECTBOX_REGION = 'GET_ALL_SELECTBOX_REGION';
export const DELETE_REGION = 'DELETE_REGION';
export const UPDATE_REGION = 'UPDATE_REGION';
export const ADD_DIALOG_REGION = 'ADD_DIALOG_REGION';
export const EDIT_DIALOG_REGION = 'EDIT_DIALOG_REGION';
export const CLOSE_DIALOG_REGION = 'CLOSE_DIALOG_REGION';

export const GET_ALL_SELECTBOX_RISKLEVEL = 'GET_ALL_SELECTBOX_RISKLEVEL';

export const CREATE_SETTINGS = 'CREATE_SETTINGS';
export const GET_ALL_SETTINGS = 'GET_ALL_SETTINGS';
export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_ALL_SELECTBOX_SETTINGS = 'GET_ALL_SELECTBOX_SETTINGS';
export const DELETE_SETTINGS = 'DELETE_SETTINGS';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const ADD_DIALOG_SETTINGS = 'ADD_DIALOG_SETTINGS';
export const EDIT_DIALOG_SETTINGS = 'EDIT_DIALOG_SETTINGS';
export const CLOSE_DIALOG_SETTINGS = 'CLOSE_DIALOG_SETTINGS';

export const CREATE_SMS = 'CREATE_SMS';
export const GET_ALL_SMS = 'GET_ALL_SMS';
export const GET_SMS = 'GET_SMS';
export const GET_ALL_SELECTBOX_SMS = 'GET_ALL_SELECTBOX_SMS';
export const DELETE_SMS = 'DELETE_SMS';
export const UPDATE_SMS = 'UPDATE_SMS';
export const ADD_DIALOG_SMS = 'ADD_DIALOG_SMS';
export const EDIT_DIALOG_SMS = 'EDIT_DIALOG_SMS';
export const CLOSE_DIALOG_SMS = 'CLOSE_DIALOG_SMS';

export const CREATE_TAXGROUPS = 'CREATE_TAXGROUPS';
export const GET_ALL_TAXGROUPS = 'GET_ALL_TAXGROUPS';
export const GET_TAXGROUPS = 'GET_TAXGROUPS';
export const GET_ALL_SELECTBOX_TAXGROUPS = 'GET_ALL_SELECTBOX_TAXGROUPS';
export const DELETE_TAXGROUPS = 'DELETE_TAXGROUPS';
export const UPDATE_TAXGROUPS = 'UPDATE_TAXGROUPS';
export const ADD_DIALOG_TAXGROUPS = 'ADD_DIALOG_TAXGROUPS';
export const EDIT_DIALOG_TAXGROUPS = 'EDIT_DIALOG_TAXGROUPS';
export const CLOSE_DIALOG_TAXGROUPS = 'CLOSE_DIALOG_TAXGROUPS';

export const CREATE_TURNOVER = 'CREATE_TURNOVER';
export const GET_ALL_TURNOVER = 'GET_ALL_TURNOVER';
export const GET_TURNOVER = 'GET_TURNOVER';
export const GET_ALL_SELECTBOX_TURNOVER = 'GET_ALL_SELECTBOX_TURNOVER';
export const DELETE_TURNOVER = 'DELETE_TURNOVER';
export const UPDATE_TURNOVER = 'UPDATE_TURNOVER';
export const ADD_DIALOG_TURNOVER = 'ADD_DIALOG_TURNOVER';
export const EDIT_DIALOG_TURNOVER = 'EDIT_DIALOG_TURNOVER';
export const CLOSE_DIALOG_TURNOVER = 'CLOSE_DIALOG_TURNOVER';

export const CREATE_TYPEOFLOANCHANGE = 'CREATE_TYPEOFLOANCHANGE';
export const GET_ALL_TYPEOFLOANCHANGE = 'GET_ALL_TYPEOFLOANCHANGE';
export const GET_TYPEOFLOANCHANGE = 'GET_TYPEOFLOANCHANGE';
export const GET_ALL_SELECTBOX_TYPEOFLOANCHANGE = 'GET_ALL_SELECTBOX_TYPEOFLOANCHANGE';
export const DELETE_TYPEOFLOANCHANGE = 'DELETE_TYPEOFLOANCHANGE';
export const UPDATE_TYPEOFLOANCHANGE = 'UPDATE_TYPEOFLOANCHANGE';
export const ADD_DIALOG_TYPEOFLOANCHANGE = 'ADD_DIALOG_TYPEOFLOANCHANGE';
export const EDIT_DIALOG_TYPEOFLOANCHANGE = 'EDIT_DIALOG_TYPEOFLOANCHANGE';
export const CLOSE_DIALOG_TYPEOFLOANCHANGE = 'CLOSE_DIALOG_TYPEOFLOANCHANGE';

export const CREATE_USER = 'CREATE_USER';
export const GET_ALL_USER = 'GET_ALL_USER';
export const GET_USER = 'GET_USER';
export const GET_ALL_SELECTBOX_USER = 'GET_ALL_SELECTBOX_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const ADD_DIALOG_USER = 'ADD_DIALOG_USER';
export const EDIT_DIALOG_USER = 'EDIT_DIALOG_USER';
export const CLOSE_DIALOG_USER = 'CLOSE_DIALOG_USER';

export const GET_ALL_SELECTBOX_ROLE = 'GET_ALL_SELECTBOX_ROLE';

export const CREATE_USERACTION = 'CREATE_USERACTION';
export const GET_ALL_USERACTION = 'GET_ALL_USERACTION';
export const GET_USERACTION = 'GET_USERACTION';
export const GET_ALL_SELECTBOX_USERACTION = 'GET_ALL_SELECTBOX_USERACTION';
export const DELETE_USERACTION = 'DELETE_USERACTION';
export const UPDATE_USERACTION = 'UPDATE_USERACTION';
export const ADD_DIALOG_USERACTION = 'ADD_DIALOG_USERACTION';
export const EDIT_DIALOG_USERACTION = 'EDIT_DIALOG_USERACTION';
export const CLOSE_DIALOG_USERACTION = 'CLOSE_DIALOG_USERACTION';

export const CREATE_USERBRANCH = 'CREATE_USERBRANCH';
export const GET_ALL_USERBRANCH = 'GET_ALL_USERBRANCH';
export const GET_USERBRANCH = 'GET_USERBRANCH';
export const GET_ALL_SELECTBOX_USERBRANCH = 'GET_ALL_SELECTBOX_USERBRANCH';
export const DELETE_USERBRANCH = 'DELETE_USERBRANCH';
export const UPDATE_USERBRANCH = 'UPDATE_USERBRANCH';
export const ADD_DIALOG_USERBRANCH = 'ADD_DIALOG_USERBRANCH';
export const EDIT_DIALOG_USERBRANCH = 'EDIT_DIALOG_USERBRANCH';
export const CLOSE_DIALOG_USERBRANCH = 'CLOSE_DIALOG_USERBRANCH';

export const CREATE_USERCOMPANYDETAILS = 'CREATE_USERCOMPANYDETAILS';
export const GET_ALL_USERCOMPANYDETAILS = 'GET_ALL_USERCOMPANYDETAILS';
export const GET_USERCOMPANYDETAILS = 'GET_USERCOMPANYDETAILS';
export const GET_ALL_SELECTBOX_USERCOMPANYDETAILS = 'GET_ALL_SELECTBOX_USERCOMPANYDETAILS';
export const DELETE_USERCOMPANYDETAILS = 'DELETE_USERCOMPANYDETAILS';
export const UPDATE_USERCOMPANYDETAILS = 'UPDATE_USERCOMPANYDETAILS';
export const ADD_DIALOG_USERCOMPANYDETAILS = 'ADD_DIALOG_USERCOMPANYDETAILS';
export const EDIT_DIALOG_USERCOMPANYDETAILS = 'EDIT_DIALOG_USERCOMPANYDETAILS';
export const CLOSE_DIALOG_USERCOMPANYDETAILS = 'CLOSE_DIALOG_USERCOMPANYDETAILS';

export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const GET_ALL_VEHICLE = 'GET_ALL_VEHICLE';
export const GET_VEHICLE = 'GET_VEHICLE';
export const GET_ALL_SELECTBOX_VEHICLE = 'GET_ALL_SELECTBOX_VEHICLE';
export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const ADD_DIALOG_VEHICLE = 'ADD_DIALOG_VEHICLE';
export const EDIT_DIALOG_VEHICLE = 'EDIT_DIALOG_VEHICLE';
export const CLOSE_DIALOG_VEHICLE = 'CLOSE_DIALOG_VEHICLE';

export const CREATE_WAYOFKNOWING = 'CREATE_WAYOFKNOWING';
export const GET_ALL_WAYOFKNOWING = 'GET_ALL_WAYOFKNOWING';
export const GET_WAYOFKNOWING = 'GET_WAYOFKNOWING';
export const GET_ALL_SELECTBOX_WAYOFKNOWING = 'GET_ALL_SELECTBOX_WAYOFKNOWING';
export const DELETE_WAYOFKNOWING = 'DELETE_WAYOFKNOWING';
export const UPDATE_WAYOFKNOWING = 'UPDATE_WAYOFKNOWING';
export const ADD_DIALOG_WAYOFKNOWING = 'ADD_DIALOG_WAYOFKNOWING';
export const EDIT_DIALOG_WAYOFKNOWING = 'EDIT_DIALOG_WAYOFKNOWING';
export const CLOSE_DIALOG_WAYOFKNOWING = 'CLOSE_DIALOG_WAYOFKNOWING';

export const CREATE_WORKER = 'CREATE_WORKER';
export const GET_ALL_WORKER = 'GET_ALL_WORKER';
export const GET_WORKER = 'GET_WORKER';
export const GET_ALL_SELECTBOX_WORKER = 'GET_ALL_SELECTBOX_WORKER';
export const DELETE_WORKER = 'DELETE_WORKER';
export const UPDATE_WORKER = 'UPDATE_WORKER';
export const ADD_DIALOG_WORKER = 'ADD_DIALOG_WORKER';
export const EDIT_DIALOG_WORKER = 'EDIT_DIALOG_WORKER';
export const CLOSE_DIALOG_WORKER = 'CLOSE_DIALOG_WORKER';

export const CREATE_WORKSPACE = 'CREATE_WORKSPACE';
export const GET_ALL_WORKSPACE = 'GET_ALL_WORKSPACE';
export const GET_WORKSPACE = 'GET_WORKSPACE';
export const GET_ALL_SELECTBOX_WORKSPACE = 'GET_ALL_SELECTBOX_WORKSPACE';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';
export const UPDATE_WORKSPACE = 'UPDATE_WORKSPACE';
export const ADD_DIALOG_WORKSPACE = 'ADD_DIALOG_WORKSPACE';
export const EDIT_DIALOG_WORKSPACE = 'EDIT_DIALOG_WORKSPACE';
export const CLOSE_DIALOG_WORKSPACE = 'CLOSE_DIALOG_WORKSPACE';

export const GET_ALL_SELECTBOX_PENALTYINTERESTPERIOD = 'GET_ALL_SELECTBOX_PENALTYINTERESTPERIOD';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const LOANMAINTENANCE_LOAN_POSTING = 'LOANMAINTENANCE_LOAN_POSTING';
export const LOANMAINTENANCE_CLOSING_AVANS = 'LOANMAINTENANCE_CLOSING_AVANS';
export const LOANMAINTENANCE_RECLASSIFICATION = 'LOANMAINTENANCE_RECLASSIFICATION';
export const LOANMAINTENANCE_MONTHLY_RESERVATIONS = 'LOANMAINTENANCE_MONTHLY_RESERVATIONS';
export const LOANMAINTENANCE_CRK_EXPORT = 'LOANMAINTENANCE_CRK_EXPORT';
export const LOANMAINTENANCE_GET_INFO = 'LOANMAINTENANCE_GET_INFO';


export const SHOW_SUBMIT_BUTTON_PROGRESS = 'SHOW_SUBMIT_BUTTON_PROGRESS';
export const HIDE_SUBMIT_BUTTON_PROGRESS = 'HIDE_SUBMIT_BUTTON_PROGRESS';
export const SHOW_LOADING_DATA_PROGRESS = 'SHOW_LOADING_DATA_PROGRESS';
export const HIDE_LOADING_DATA_PROGRESS = 'HIDE_LOADING_DATA_PROGRESS';

export const GET_POSTING_ACCOUNT_CONNECTIONS = 'GET_POSTING_ACCOUNT_CONNECTIONS';

export const GET_ALL_SELECTBOX_RELATEDPERSONTYPE = 'GET_ALL_SELECTBOX_RELATEDPERSONTYPE';

export const CREATE_RELATEDPERSON = 'CREATE_RELATEDPERSON';
export const GET_ALL_RELATEDPERSON = 'GET_ALL_RELATEDPERSON';
export const GET_RELATEDPERSON = 'GET_RELATEDPERSON';
export const GET_ALL_SELECTBOX_RELATEDPERSON = 'GET_ALL_SELECTBOX_RELATEDPERSON';
export const DELETE_RELATEDPERSON = 'DELETE_RELATEDPERSON';
export const UPDATE_RELATEDPERSON = 'UPDATE_RELATEDPERSON';
export const ADD_DIALOG_RELATEDPERSON = 'ADD_DIALOG_RELATEDPERSON';
export const EDIT_DIALOG_RELATEDPERSON = 'EDIT_DIALOG_RELATEDPERSON';
export const CLOSE_DIALOG_RELATEDPERSON = 'CLOSE_DIALOG_RELATEDPERSON';

export const CREATE_CRKREQUEST = 'CREATE_CRKREQUEST';
export const GET_ALL_CRKREQUEST = 'GET_ALL_CRKREQUEST';
export const GET_CRKREQUEST = 'GET_CRKREQUEST';
export const GET_ALL_SELECTBOX_CRKREQUEST = 'GET_ALL_SELECTBOX_CRKREQUEST';
export const DELETE_CRKREQUEST = 'DELETE_CRKREQUEST';
export const UPDATE_CRKREQUEST = 'UPDATE_CRKREQUEST';
export const ADD_DIALOG_CRKREQUEST = 'ADD_DIALOG_CRKREQUEST';
export const EDIT_DIALOG_CRKREQUEST = 'EDIT_DIALOG_CRKREQUEST';
export const CLOSE_DIALOG_CRKREQUEST = 'CLOSE_DIALOG_CRKREQUEST';

export const CREATE_CRKREQUESTTYPE = 'CREATE_CRKREQUESTTYPE';
export const GET_ALL_CRKREQUESTTYPE = 'GET_ALL_CRKREQUESTTYPE';
export const GET_CRKREQUESTTYPE = 'GET_CRKREQUESTTYPE';
export const GET_ALL_SELECTBOX_CRKREQUESTTYPE = 'GET_ALL_SELECTBOX_CRKREQUESTTYPE';
export const DELETE_CRKREQUESTTYPE = 'DELETE_CRKREQUESTTYPE';
export const UPDATE_CRKREQUESTTYPE = 'UPDATE_CRKREQUESTTYPE';
export const ADD_DIALOG_CRKREQUESTTYPE = 'ADD_DIALOG_CRKREQUESTTYPE';
export const EDIT_DIALOG_CRKREQUESTTYPE = 'EDIT_DIALOG_CRKREQUESTTYPE';
export const CLOSE_DIALOG_CRKREQUESTTYPE = 'CLOSE_DIALOG_CRKREQUESTTYPE';

export const CREATE_CRKRESPONSE = 'CREATE_CRKRESPONSE';
export const GET_ALL_CRKRESPONSE = 'GET_ALL_CRKRESPONSE';
export const GET_CRKRESPONSE = 'GET_CRKRESPONSE';
export const GET_ALL_SELECTBOX_CRKRESPONSE = 'GET_ALL_SELECTBOX_CRKRESPONSE';
export const DELETE_CRKRESPONSE = 'DELETE_CRKRESPONSE';
export const UPDATE_CRKRESPONSE = 'UPDATE_CRKRESPONSE';
export const ADD_DIALOG_CRKRESPONSE = 'ADD_DIALOG_CRKRESPONSE';
export const EDIT_DIALOG_CRKRESPONSE = 'EDIT_DIALOG_CRKRESPONSE';
export const CLOSE_DIALOG_CRKRESPONSE = 'CLOSE_DIALOG_CRKRESPONSE';


export const CREATE_COMPANYACTIVITYCODE = 'CREATE_COMPANYACTIVITYCODE';
export const GET_ALL_COMPANYACTIVITYCODE = 'GET_ALL_COMPANYACTIVITYCODE';
export const GET_COMPANYACTIVITYCODE = 'GET_COMPANYACTIVITYCODE';
export const GET_ALL_SELECTBOX_COMPANYACTIVITYCODE = 'GET_ALL_SELECTBOX_COMPANYACTIVITYCODE';
export const DELETE_COMPANYACTIVITYCODE = 'DELETE_COMPANYACTIVITYCODE';
export const UPDATE_COMPANYACTIVITYCODE = 'UPDATE_COMPANYACTIVITYCODE';
export const ADD_DIALOG_COMPANYACTIVITYCODE = 'ADD_DIALOG_COMPANYACTIVITYCODE';
export const EDIT_DIALOG_COMPANYACTIVITYCODE = 'EDIT_DIALOG_COMPANYACTIVITYCODE';
export const CLOSE_DIALOG_COMPANYACTIVITYCODE = 'CLOSE_DIALOG_COMPANYACTIVITYCODE';

export const CREATE_EARLYREPAYMENTSETTINGS = 'CREATE_EARLYREPAYMENTSETTINGS';
export const GET_ALL_EARLYREPAYMENTSETTINGS = 'GET_ALL_EARLYREPAYMENTSETTINGS';
export const GET_EARLYREPAYMENTSETTINGS = 'GET_EARLYREPAYMENTSETTINGS';
export const GET_ALL_SELECTBOX_EARLYREPAYMENTSETTINGS = 'GET_ALL_SELECTBOX_EARLYREPAYMENTSETTINGS';
export const DELETE_EARLYREPAYMENTSETTINGS = 'DELETE_EARLYREPAYMENTSETTINGS';
export const UPDATE_EARLYREPAYMENTSETTINGS = 'UPDATE_EARLYREPAYMENTSETTINGS';
export const ADD_DIALOG_EARLYREPAYMENTSETTINGS = 'ADD_DIALOG_EARLYREPAYMENTSETTINGS';
export const EDIT_DIALOG_EARLYREPAYMENTSETTINGS = 'EDIT_DIALOG_EARLYREPAYMENTSETTINGS';
export const CLOSE_DIALOG_EARLYREPAYMENTSETTINGS = 'CLOSE_DIALOG_EARLYREPAYMENTSETTINGS';

export const GET_ALL_NOTIFICATION = 'GET_ALL_NOTIFICATION';
export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const READ_ALL_NOTIFICATION = 'READ_ALL_NOTIFICATION';

export const STATISTIC_LOAN_STATUS_STATISTIC = 'STATISTIC_LOAN_STATUS_STATISTIC';
export const STATISTIC_REALIZED_LOANS = 'STATISTIC_REALIZED_LOANS';
export const STATISTIC_LOAN_CLASSIFICATION = 'STATISTIC_LOAN_CLASSIFICATION';

export const GET_ALL_SELECTBOX_TYPEOFCONTRACTWITHEMPLOYER = 'GET_ALL_SELECTBOX_TYPEOFCONTRACTWITHEMPLOYER';

export const CREATE_CRKARCHIVE = 'CREATE_CRKARCHIVE';
export const GET_ALL_CRKARCHIVE = 'GET_ALL_CRKARCHIVE';
export const GET_CRKARCHIVE = 'GET_CRKARCHIVE';
export const GET_ALL_SELECTBOX_CRKARCHIVE = 'GET_ALL_SELECTBOX_CRKARCHIVE';
export const DELETE_CRKARCHIVE = 'DELETE_CRKARCHIVE';
export const UPDATE_CRKARCHIVE = 'UPDATE_CRKARCHIVE';
export const ADD_DIALOG_CRKARCHIVE = 'ADD_DIALOG_CRKARCHIVE';
export const EDIT_DIALOG_CRKARCHIVE = 'EDIT_DIALOG_CRKARCHIVE';
export const CLOSE_DIALOG_CRKARCHIVE = 'CLOSE_DIALOG_CRKARCHIVE';

export const CREATE_LOANWRITEOFF = 'CREATE_LOANWRITEOFF';
export const GET_ALL_LOANWRITEOFF = 'GET_ALL_LOANWRITEOFF';
export const GET_LOANWRITEOFF = 'GET_LOANWRITEOFF';
export const GET_ALL_SELECTBOX_LOANWRITEOFF = 'GET_ALL_SELECTBOX_LOANWRITEOFF';
export const DELETE_LOANWRITEOFF = 'DELETE_LOANWRITEOFF';
export const UPDATE_LOANWRITEOFF = 'UPDATE_LOANWRITEOFF';
export const ADD_DIALOG_LOANWRITEOFF = 'ADD_DIALOG_LOANWRITEOFF';
export const EDIT_DIALOG_LOANWRITEOFF = 'EDIT_DIALOG_LOANWRITEOFF';
export const CLOSE_DIALOG_LOANWRITEOFF = 'CLOSE_DIALOG_LOANWRITEOFF';

export const CREATE_REVENUERECOGNITION = 'CREATE_REVENUERECOGNITION';
export const GET_ALL_REVENUERECOGNITION = 'GET_ALL_REVENUERECOGNITION';
export const GET_REVENUERECOGNITION = 'GET_REVENUERECOGNITION';
export const GET_ALL_SELECTBOX_REVENUERECOGNITION = 'GET_ALL_SELECTBOX_REVENUERECOGNITION';
export const DELETE_REVENUERECOGNITION = 'DELETE_REVENUERECOGNITION';
export const UPDATE_REVENUERECOGNITION = 'UPDATE_REVENUERECOGNITION';
export const ADD_DIALOG_REVENUERECOGNITION = 'ADD_DIALOG_REVENUERECOGNITION';
export const EDIT_DIALOG_REVENUERECOGNITION = 'EDIT_DIALOG_REVENUERECOGNITION';
export const CLOSE_DIALOG_REVENUERECOGNITION = 'CLOSE_DIALOG_REVENUERECOGNITION';

export const CREATE_UNPARTNERDETAILS = 'CREATE_UNPARTNERDETAILS';
export const GET_ALL_UNPARTNERDETAILS = 'GET_ALL_UNPARTNERDETAILS';
export const GET_UNPARTNERDETAILS = 'GET_UNPARTNERDETAILS';
export const GET_ALL_SELECTBOX_UNPARTNERDETAILS = 'GET_ALL_SELECTBOX_UNPARTNERDETAILS';
export const DELETE_UNPARTNERDETAILS = 'DELETE_UNPARTNERDETAILS';
export const UPDATE_UNPARTNERDETAILS = 'UPDATE_UNPARTNERDETAILS';
export const ADD_DIALOG_UNPARTNERDETAILS = 'ADD_DIALOG_UNPARTNERDETAILS';
export const EDIT_DIALOG_UNPARTNERDETAILS = 'EDIT_DIALOG_UNPARTNERDETAILS';
export const CLOSE_DIALOG_UNPARTNERDETAILS = 'CLOSE_DIALOG_UNPARTNERDETAILS';

export const CREATE_ORDERTYPE = 'CREATE_ORDERTYPE';
export const GET_ALL_ORDERTYPE = 'GET_ALL_ORDERTYPE';
export const GET_ORDERTYPE = 'GET_ORDERTYPE';
export const GET_ALL_SELECTBOX_ORDERTYPE = 'GET_ALL_SELECTBOX_ORDERTYPE';
export const DELETE_ORDERTYPE = 'DELETE_ORDERTYPE';
export const UPDATE_ORDERTYPE = 'UPDATE_ORDERTYPE';
export const ADD_DIALOG_ORDERTYPE = 'ADD_DIALOG_ORDERTYPE';
export const EDIT_DIALOG_ORDERTYPE = 'EDIT_DIALOG_ORDERTYPE';
export const CLOSE_DIALOG_ORDERTYPE = 'CLOSE_DIALOG_ORDERTYPE';

export const CREATE_COMMISSIONSETTINGS = 'CREATE_COMMISSIONSETTINGS';
export const GET_ALL_COMMISSIONSETTINGS = 'GET_ALL_COMMISSIONSETTINGS';
export const GET_COMMISSIONSETTINGS = 'GET_COMMISSIONSETTINGS';
export const GET_ALL_SELECTBOX_COMMISSIONSETTINGS = 'GET_ALL_SELECTBOX_COMMISSIONSETTINGS';
export const DELETE_COMMISSIONSETTINGS = 'DELETE_COMMISSIONSETTINGS';
export const UPDATE_COMMISSIONSETTINGS = 'UPDATE_COMMISSIONSETTINGS';
export const ADD_DIALOG_COMMISSIONSETTINGS = 'ADD_DIALOG_COMMISSIONSETTINGS';
export const EDIT_DIALOG_COMMISSIONSETTINGS = 'EDIT_DIALOG_COMMISSIONSETTINGS';
export const CLOSE_DIALOG_COMMISSIONSETTINGS = 'CLOSE_DIALOG_COMMISSIONSETTINGS';

export const CREATE_SOURCEOFFINANCING = 'CREATE_SOURCEOFFINANCING';
export const GET_ALL_SOURCEOFFINANCING = 'GET_ALL_SOURCEOFFINANCING';
export const GET_SOURCEOFFINANCING = 'GET_SOURCEOFFINANCING';
export const GET_ALL_SELECTBOX_SOURCEOFFINANCING = 'GET_ALL_SELECTBOX_SOURCEOFFINANCING';
export const DELETE_SOURCEOFFINANCING = 'DELETE_SOURCEOFFINANCING';
export const UPDATE_SOURCEOFFINANCING = 'UPDATE_SOURCEOFFINANCING';
export const ADD_DIALOG_SOURCEOFFINANCING = 'ADD_DIALOG_SOURCEOFFINANCING';
export const EDIT_DIALOG_SOURCEOFFINANCING = 'EDIT_DIALOG_SOURCEOFFINANCING';
export const CLOSE_DIALOG_SOURCEOFFINANCING = 'CLOSE_DIALOG_SOURCEOFFINANCING';