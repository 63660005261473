import api from "../api/myApi";
import { 
    CREATE_PARTNER,
    GET_ALL_PARTNER,
    GET_PARTNER,
    GET_ALL_SELECTBOX_PARTNER,
    DELETE_PARTNER,
    UPDATE_PARTNER,
    ADD_DIALOG_PARTNER,
    EDIT_DIALOG_PARTNER,
    CLOSE_DIALOG_PARTNER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createPartner = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Partner/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PARTNER));
    if(response.data?.succeeded){
        dispatch(reset('formPartner'));
    } 
    if(response.data.message != null && response.data.message != undefined)
    {
        dispatch( {
            type: "error",
            payload: response.data.message
        });
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPartners = () => async (dispatch) => {
    const response = await api.get('/api/v1/Partner/GetAll');
    dispatch(responseHandler(response, GET_ALL_PARTNER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPartner = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Partner/Get/' + id);
    let data = responseHandler(response, GET_PARTNER);
    dispatch(data);
    return data.payload;
}

export const getSelectBoxPartners = () => async (dispatch) => {
    const response = await api.get('/api/v1/Partner/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PARTNER));
}

export const updatePartner = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Partner/Update', formValues);
    if(response.data.message != null && response.data.message != undefined)
    {
        dispatch( {
            type: "error",
            payload: response.data.message
        });
    }
    dispatch(responseHandler(response, UPDATE_PARTNER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePartner = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Partner/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PARTNER));
}

export const addDialogPartner = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PARTNER,
        payload: null
    });
}

export const editDialogPartner = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PARTNER,
        payload: item
    });
}

export const closeDialogPartner = () => async (dispatch) => {
    dispatch(reset('formPartnerPerson'));
    dispatch(reset('formPartnerLegalEntity'));
    dispatch(reset('formPartner'));
    dispatch({
        type: CLOSE_DIALOG_PARTNER,
        payload: undefined
    });
}