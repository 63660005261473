//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_BOARDMEMBERTYPE,
    GET_ALL_BOARDMEMBERTYPE,
    GET_BOARDMEMBERTYPE,
    GET_ALL_SELECTBOX_BOARDMEMBERTYPE,
    DELETE_BOARDMEMBERTYPE,
    UPDATE_BOARDMEMBERTYPE,
    ADD_DIALOG_BOARDMEMBERTYPE,
    EDIT_DIALOG_BOARDMEMBERTYPE,
    CLOSE_DIALOG_BOARDMEMBERTYPE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBoardMemberType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMemberType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BOARDMEMBERTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formBoardMemberType'));
    }
}

export const getAllBoardMemberTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMemberType/GetAll');
    dispatch(responseHandler(response, GET_ALL_BOARDMEMBERTYPE));
}

export const getBoardMemberType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMemberType/Get/' + id);
    dispatch(responseHandler(response, GET_BOARDMEMBERTYPE));
}

export const getSelectBoxBoardMemberTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMemberType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BOARDMEMBERTYPE));
}

export const updateBoardMemberType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMemberType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BOARDMEMBERTYPE));
}

export const deleteBoardMemberType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMemberType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BOARDMEMBERTYPE));
}

export const addDialogBoardMemberType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BOARDMEMBERTYPE,
        payload: null
    });
}

export const editDialogBoardMemberType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BOARDMEMBERTYPE,
        payload: item
    });
}

export const closeDialogBoardMemberType = () => async (dispatch) => {
    dispatch(reset('formBoardMemberType'));
    dispatch({
        type: CLOSE_DIALOG_BOARDMEMBERTYPE,
        payload: undefined
    });
}