import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createCompany } from '../../actions/companyAction';
import FormCompany from './FormCompany';

class CreateCompany extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createCompany(formValues);
    }
    
    render(){
        return (
            <FormCompany 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormCompany>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CompanyDialog === undefined || state.dialog.CompanyDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CompanyDialog};
}


export default connect(mapStateToProps, { createCompany })(CreateCompany);
