import React from 'react';
import { connect } from 'react-redux';
import { createJournal } from '../../actions/journalAction';
import FormJournal from './FormJournal';
import i18next from '../../language/i18n';

class CreateJournal extends React.Component{
    onSubmit  = (formValues) => {
        formValues.year = this.props.year;
        formValues.number = this.props.number;
        this.props.createJournal(formValues);
    }
    initialValues = {
        planID: null,
        partnerID: null,
        link: null,
        id: 0,
        owes: 0,
        claim: 0
    }
    render(){
        return (
            <FormJournal 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.initialValues}
                title={i18next.t('CreateNew')}>
            </FormJournal>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.JournalDialog === undefined || state.dialog.JournalDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.JournalDialog};
}


export default connect(mapStateToProps, { createJournal })(CreateJournal);
