import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogLoanQualityStatus, updateLoanQualityStatus } from '../../actions/loanQualityStatusAction';
import FormLoanQualityStatus from './FormLoanQualityStatus';

class EditLoanQualityStatus extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateLoanQualityStatus(formValues);
    }
    
    render(){
        return (
            <FormLoanQualityStatus 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormLoanQualityStatus>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanQualityStatusDialog === undefined || state.dialog.LoanQualityStatusDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.LoanQualityStatusDialog};
}


export default connect(mapStateToProps, { closeDialogLoanQualityStatus, updateLoanQualityStatus })(EditLoanQualityStatus);
