import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { closeDialogJournal, getPostingAccountConnections } from '../../actions/journalAction';
import { getLoansByPartner } from '../../actions/loanAction';
import { EditSelectField, EditSelectFieldVirtual, EditTextField, EditDatePickerField, EditDecimalNumberField, EditCheckbox, EditAutocomplete } from '../Share/EditComponents';
import i18next from '../../language/i18n';
import ComponentInLine from '../Share/ComponentInLine';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

class FormJournal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            link: this.props.formValues?.link ?? ''
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formValues !== this.props.formValues && prevProps.formValues?.id !== this.props.formValues?.id) {
            if (this.props.formValues?.id > 0) {
                this.props.getLoansByPartner(this.props.formValues.partnerID);
                this.getLinks(this.props.formValues.planID, this.props.formValues.partnerID, this.props.formValues.link, this.props.formValues.id);
            }
            this.setState({link: this.props.formValues?.link ?? ''});
        }
    }

    handleClose = () => {
        this.props.closeDialogJournal();
    }
    onPartnerChange = (e, child) => {
        this.props.getLoansByPartner(child);
        this.getLinks(this.props.formValues?.planID, child, this.props.formValues?.link, this.props.formValues?.id);
    }
    onLoanChange = (e, child) => {
        let value = parseInt(child);
        let item = this.props.loansByPartner.find(el => el.id == value);
        if(item != undefined && item != null){
            this.props.change('brvd', item.loanNumber);
            this.props.change('branchID', item.branchID);            
        }
    }
    getLinks = (planID, partnerID, link, id) => {
        if (planID !== undefined && planID !== null && partnerID !== undefined && partnerID !== null)
            this.props.getPostingAccountConnections({
                id: id,
                partnerID: partnerID,
                planID: planID,
                link: link
            });
    }
    onLinkChange = (value) => {
        this.setState({ link: value });
        this.props.change('link', value);
        let linkObject = this.props.postingAccountConnections.find(el => el.link === value);
        if (linkObject !== undefined) {
            if (linkObject.owes > linkObject.claim) {
                this.props.change('owes', 0);
                this.props.change('claim', linkObject.owes - linkObject.claim);
            }
            else {
                this.props.change('owes', linkObject.claim - linkObject.owes);
                this.props.change('claim', 0);
            }
        }
        else {
            this.props.change('owes', 0);
            this.props.change('claim', 0);
        }
    }
    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    maxWidth={'md'}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='partnerID'
                                        component={this.props.EditSelectFieldVirtual}
                                        label='Partner'
                                        onChange={this.onPartnerChange}
                                        dataSource={this.props.partnersSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='loanID'
                                        component={this.props.EditSelectField}
                                        label='Loan'
                                        onChange={this.onLoanChange}
                                        dataSource={this.props.loansByPartner?.map(el => ({value: el.id, text: el.loanNumber}))}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field
                                        name='branchID'
                                        component={this.props.EditSelectField}
                                        label='Branch'
                                        dataSource={this.props.branchesSelectBox}
                                    >
                                    </Field>
                                </div>
                            </ComponentInLine>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='planID'
                                        component={this.props.EditSelectField}
                                        label='Plan'
                                        onChange={(e, child) => {
                                            this.getLinks(child, this.props.formValues.partnerID, this.props.formValues.link, this.props.formValues.id);
                                        }
                                        }
                                        dataSource={this.props.plansSelectBox}
                                    >
                                    </Field>
                                </div>

                                <div>
                                    {/* <Field
                                        name='link'
                                        component={this.props.EditAutocomplete} //
                                        label='Link'
                                        options={this.props.postingAccountConnections?.map(el => el.link)}
                                        onChange={this.onLinkChange}
                                    /> */}
                                    <div>
                                        <Autocomplete
                                            name='link'
                                            label="Link"
                                            freeSolo
                                            value={this.state.link}
                                            onChange={(e) => { this.onLinkChange(e.target.textContent) }}
                                            options={this.props.postingAccountConnections?.map(el => el.link ?? "")}
                                            renderInput={(params) => <TextField {...params} label="Link" onChange={(e) => { this.onLinkChange(e.target.value) }} />}
                                            style={{ marginTop: '16px' }}
                                        />
                                    </div>
                                </div>

                                {/* <div>
                                    <Field
                                        name='documentTypeID'
                                        component={this.props.EditSelectField}
                                        label='DocumentType'
                                        dataSource={this.props.documentTypesSelectBox}
                                    >
                                    </Field>
                                </div> */}
                            </ComponentInLine>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='date'
                                        component={this.props.EditDatePickerField}
                                        label='Date'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='deadline'
                                        component={this.props.EditDatePickerField}
                                        label='Deadline'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='dateDPO'
                                        component={this.props.EditDatePickerField}
                                        label='DateDPO'
                                    />
                                </div>
                            </ComponentInLine>
                            <ComponentInLine>
                                <div>
                                    <Field
                                        name='owes'
                                        component={this.props.EditDecimalNumberField}
                                        label='Owes'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='claim'
                                        component={this.props.EditDecimalNumberField}
                                        label='Claim'
                                    />
                                </div>
                            </ComponentInLine>
                            <ComponentInLine>

                                <div>
                                    <Field
                                        name='brvd'
                                        component={this.props.EditTextField}
                                        label='brvd'
                                    />
                                </div>
                                <div>
                                    <Field
                                        name='description'
                                        component={this.props.EditTextField}
                                        label='Description'
                                    />
                                </div>
                            </ComponentInLine>
                            <div>
                                <Field
                                    name='locked'
                                    component={this.props.EditCheckbox}
                                    label='Locked'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if (!formValues.planID) {
        errors.planID = 'Polje je obavezno!';
    }
    if (!formValues.partnerID) {
        errors.partnerID = 'Polje je obavezno!';
    }
    // if (!formValues.documentTypeID) {
    //     errors.documentTypeID = 'Polje je obavezno!';
    // }
    // if (!formValues.description) {
    //     errors.description = 'Polje je obavezno!';
    // }
    if (!formValues.brvd) {
        errors.brvd = 'Polje je obavezno!';
    }
    if (!formValues.date) {
        errors.date = 'Polje je obavezno!';
    }
    if (formValues.owes === undefined || formValues.owes === null) {
        errors.owes = 'Polje je obavezno!';
    }
    if (formValues.claim === undefined || formValues.claim === null) {
        errors.claim = 'Polje je obavezno!';
    }
    // if (!formValues.locked) {
    //     errors.locked = 'Polje je obavezno!';
    // }
    if (!formValues.link) {
        errors.link = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        formValues: getFormValues('formJournal')(state),
        plansSelectBox: Object.values(state.plansSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        //documentTypesSelectBox: Object.values(state.documentTypesSelectBox),
        branchesSelectBox: Object.values(state.branchesSelectBox),
        loansByPartner: Array.isArray(state.loansByPartner) ? state.loansByPartner : Object.values(state.loansByPartner),
        postingAccountConnections: Array.isArray(state.postingAccountConnections) ? state.postingAccountConnections : Object.values(state.postingAccountConnections),
    }
}
const formWrapper = reduxForm({
    form: 'formJournal',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormJournal);

export default connect(mapStateToProps, {
    closeDialogJournal,
    EditSelectField,
    EditSelectFieldVirtual,
    EditTextField,
    EditDatePickerField,
    EditDecimalNumberField,
    EditCheckbox,
    EditAutocomplete,
    getLoansByPartner,
    change,
    getPostingAccountConnections,
    getFormValues
})(formWrapper);