import api from "../api/myApi";
import { 
    CREATE_MARITALSTATUS,
    GET_ALL_MARITALSTATUS,
    GET_MARITALSTATUS,
    GET_ALL_SELECTBOX_MARITALSTATUS,
    DELETE_MARITALSTATUS,
    UPDATE_MARITALSTATUS,
    ADD_DIALOG_MARITALSTATUS,
    EDIT_DIALOG_MARITALSTATUS,
    CLOSE_DIALOG_MARITALSTATUS,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler, responseHandlerWithTranslate } from "../api/responseHandler";

export const createMaritalStatus = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/MaritalStatus/Create', formValues);    
    dispatch(responseHandler(response, CREATE_MARITALSTATUS));
    if(response.data?.succeeded){
        dispatch(reset('formMaritalStatus'));
    }
}

export const getAllMaritalStatus = () => async (dispatch) => {
    const response = await api.get('/api/v1/MaritalStatus/GetAll');
    dispatch(responseHandler(response, GET_ALL_MARITALSTATUS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getMaritalStatus = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/MaritalStatus/Get/' + id);
    dispatch(responseHandlerWithTranslate(response, GET_MARITALSTATUS));
}

export const getSelectBoxMaritalStatus = () => async (dispatch) => {
    const response = await api.get('/api/v1/MaritalStatus/GetSelectBox');
    dispatch(responseHandlerWithTranslate(response, GET_ALL_SELECTBOX_MARITALSTATUS));
}

export const updateMaritalStatus = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/MaritalStatus/Update', formValues);
    dispatch(responseHandler(response, UPDATE_MARITALSTATUS));
}

export const deleteMaritalStatus = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/MaritalStatus/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_MARITALSTATUS));
}

export const addDialogMaritalStatus = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_MARITALSTATUS,
        payload: null
    });
}

export const editDialogMaritalStatus = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_MARITALSTATUS,
        payload: item
    });
}

export const closeDialogMaritalStatus = () => async (dispatch) => {
    dispatch(reset('formMaritalStatus'));
    dispatch({
        type: CLOSE_DIALOG_MARITALSTATUS,
        payload: undefined
    });
}