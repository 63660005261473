import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton,  GridToolbarDensitySelector } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@material-ui/icons/Add';
import i18next from '../../../language/i18n';

class CustomToolbar extends React.Component{
renderAddButton = () => {
    if(this.props.createNew != null && this.props.createNew != undefined){
        return(<Button color="primary" startIcon={<AddIcon />} onClick={this.props.createNew}>
        {i18next.t('AddNewRecord')}
    </Button>);
    }
}
//<GridToolbarExport />
    render(){
        return(
            <GridToolbarContainer>
                {this.renderAddButton()}
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }
}

export default CustomToolbar;