import { 
    GET_ALL_SELECTBOX_EMPLOYMENTTYPE
} from '../../actions/types';

 const employmentTypeSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_EMPLOYMENTTYPE:
            return [...action.payload];
        default:
            return state;
    }
}
export default employmentTypeSelectBoxReducer;