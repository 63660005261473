//Mojo-AG
import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogRelatedPerson, updateRelatedPerson } from '../../actions/relatedPersonAction';
import FormRelatedPerson from './FormRelatedPerson';

class EditRelatedPerson extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateRelatedPerson(formValues);
    }
    
    render(){
        return (
            <FormRelatedPerson 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormRelatedPerson>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RelatedPersonDialog === undefined || state.dialog.RelatedPersonDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.RelatedPersonDialog};
}


export default connect(mapStateToProps, { closeDialogRelatedPerson, updateRelatedPerson })(EditRelatedPerson);
