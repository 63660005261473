//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogBankStatementImportSplit, updateBankStatementImportSplit } from '../../actions/bankStatementImportSplitAction';
import FormBankStatementImportSplit from './FormBankStatementImportSplit';

class EditBankStatementImportSplit extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBankStatementImportSplit(formValues);
    }
    
    render(){
        return (
            <FormBankStatementImportSplit 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormBankStatementImportSplit>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BankStatementImportSplitDialog === undefined || state.dialog.BankStatementImportSplitDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BankStatementImportSplitDialog};
}


export default connect(mapStateToProps, { closeDialogBankStatementImportSplit, updateBankStatementImportSplit })(EditBankStatementImportSplit);
