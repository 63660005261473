import api from "../api/myApi";
import { 
    CREATE_DOCUMENTTURNOVERTYPE,
    GET_ALL_DOCUMENTTURNOVERTYPE,
    GET_DOCUMENTTURNOVERTYPE,
    GET_ALL_SELECTBOX_DOCUMENTTURNOVERTYPE,
    DELETE_DOCUMENTTURNOVERTYPE,
    UPDATE_DOCUMENTTURNOVERTYPE,
    ADD_DIALOG_DOCUMENTTURNOVERTYPE,
    EDIT_DIALOG_DOCUMENTTURNOVERTYPE,
    CLOSE_DIALOG_DOCUMENTTURNOVERTYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createDocumentTurnoverType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DocumentTurnoverType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_DOCUMENTTURNOVERTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formDocumentTurnoverType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllDocumentTurnoverTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/DocumentTurnoverType/GetAll');
    dispatch(responseHandler(response, GET_ALL_DOCUMENTTURNOVERTYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getDocumentTurnoverType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/DocumentTurnoverType/Get/' + id);
    dispatch(responseHandler(response, GET_DOCUMENTTURNOVERTYPE));
}

export const getSelectBoxDocumentTurnoverTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/DocumentTurnoverType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_DOCUMENTTURNOVERTYPE));
}

export const updateDocumentTurnoverType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DocumentTurnoverType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_DOCUMENTTURNOVERTYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteDocumentTurnoverType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/DocumentTurnoverType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_DOCUMENTTURNOVERTYPE));
}

export const addDialogDocumentTurnoverType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_DOCUMENTTURNOVERTYPE,
        payload: null
    });
}

export const editDialogDocumentTurnoverType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_DOCUMENTTURNOVERTYPE,
        payload: item
    });
}

export const closeDialogDocumentTurnoverType = () => async (dispatch) => {
    dispatch(reset('formDocumentTurnoverType'));
    dispatch({
        type: CLOSE_DIALOG_DOCUMENTTURNOVERTYPE,
        payload: undefined
    });
}