import api from "../api/myApi";
import { 
    CREATE_PAYWARNINGTYPE,
    GET_ALL_PAYWARNINGTYPE,
    GET_PAYWARNINGTYPE,
    GET_ALL_SELECTBOX_PAYWARNINGTYPE,
    DELETE_PAYWARNINGTYPE,
    UPDATE_PAYWARNINGTYPE,
    ADD_DIALOG_PAYWARNINGTYPE,
    EDIT_DIALOG_PAYWARNINGTYPE,
    CLOSE_DIALOG_PAYWARNINGTYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createPayWarningType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PayWarningType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PAYWARNINGTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formPayWarningType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPayWarningTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PayWarningType/GetAll');
    dispatch(responseHandler(response, GET_ALL_PAYWARNINGTYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPayWarningType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/PayWarningType/Get/' + id);
    dispatch(responseHandler(response, GET_PAYWARNINGTYPE));
}

export const getSelectBoxPayWarningTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PayWarningType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PAYWARNINGTYPE));
}

export const updatePayWarningType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PayWarningType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PAYWARNINGTYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePayWarningType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/PayWarningType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PAYWARNINGTYPE));
}

export const addDialogPayWarningType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PAYWARNINGTYPE,
        payload: null
    });
}

export const editDialogPayWarningType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PAYWARNINGTYPE,
        payload: item
    });
}

export const closeDialogPayWarningType = () => async (dispatch) => {
    dispatch(reset('formPayWarningType'));
    dispatch({
        type: CLOSE_DIALOG_PAYWARNINGTYPE,
        payload: undefined
    });
}