import { 
    CREATE_LOANQUALITY,
    GET_ALL_LOANQUALITY,
    GET_LOANQUALITY,
    DELETE_LOANQUALITY,
    UPDATE_LOANQUALITY
} from '../../actions/types';

const loanQualityReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANQUALITY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANQUALITY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANQUALITY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANQUALITY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANQUALITY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanQualityReducer;