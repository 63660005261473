import { 
    GET_ALL_SELECTBOX_VEHICLE
} from '../../actions/types';

 const vehicleSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_VEHICLE:
            return [...action.payload];
        default:
            return state;
    }
}
export default vehicleSelectBoxReducer;