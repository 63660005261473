import React from "react";
import {EditIntegerNumberField2} from '../../components/Share/EditComponents';
import ComponentInLine from "../../components/Share/ComponentInLine";
import i18next from '../../language/i18n';
import { DataGrid } from '@mui/x-data-grid';

class LifeInsurancePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            maxYear: 75,
            maxDuration: 5
        }
    }
    columns = () => {
        let result = [
            { field: 'year', headerName: i18next.t('Year'), minWidth: 150, flex: 1 },
            { field: 'maxAmount', headerName: i18next.t('MaxInsuranceAmount'), minWidth: 150, flex: 1 },
        ];
        for(let i=0; i<this.state.maxDuration; i++)
        {
            result.push({ field: ('duration' + i), headerName: (i + 1).toString(), minWidth: 50, flex: 1 })
        }
        return result;
    }
    rows = () => {
        let result = [];
        let startYear = this.state.maxYear - this.state.maxDuration;
        for(let i = 0; i<this.state.maxDuration; i++){
            let data = {id: startYear + i, year:i == 0 ? `Do ${startYear} godina`: startYear + i, maxAmount: 5000};
            for(let j=0; j<this.state.maxDuration;j++){
                data['duration' + j] = 1;
            }
            result.push(data);
        }
        return result;
    }
    render() {
        return (
            <div style={{ height: 700, width: '100%' }}>
            <ComponentInLine>
                <EditIntegerNumberField2 name='maxYear' label='Year' value={this.state.maxYear} onChange={(e) => { this.setState({ maxYear: parseInt(e.target.value) }) }}></EditIntegerNumberField2>
                <EditIntegerNumberField2 name='maxDuration' label='Year' value={this.state.maxDuration} onChange={(e) => { this.setState({ maxDuration: parseInt(e.target.value) }) }}></EditIntegerNumberField2>
            </ComponentInLine>

            <DataGrid
          rows={this.rows()}
          columns={this.columns()}
        //   components={{
        //     Toolbar: CustomToolbar
        //   }}
          disableSelectionOnClick
        />

            {/* <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                toolbar: EditToolbar,
                }}
                slotProps={{
                toolbar: { setRows, setRowModesModel },
                }} */}
        </div>);
    }
}

export default LifeInsurancePage;