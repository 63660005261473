import { 
    CREATE_PENALTYINTERESTDETAILS,
    GET_ALL_PENALTYINTERESTDETAILS,
    GET_PENALTYINTERESTDETAILS,
    DELETE_PENALTYINTERESTDETAILS,
    UPDATE_PENALTYINTERESTDETAILS
} from '../../actions/types';

const penaltyInterestDetailsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_PENALTYINTERESTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_PENALTYINTERESTDETAILS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_PENALTYINTERESTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_PENALTYINTERESTDETAILS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_PENALTYINTERESTDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default penaltyInterestDetailsReducer;