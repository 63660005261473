import React from "react";
import { EditSelectField, EditTextField, EditDatePickerField, EditCheckbox, EditIntegerNumberField } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import i18next from '../../language/i18n';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Validation from '../../share/validation';
import {
    getGrossBalance,
    getExtendedReview,
    getClasses,
    getGeneralLedger,
    getOpenItemsByPartnerAndAccount,
    getCashFlow
} from '../../actions/reportAction';
import {
    Button,
    MenuItem,
    Box
  } from "@mui/material";
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { getSelectBoxBranches } from '../../actions/branchAction';

class GeneralLedgerPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentValue: 1, selectedCompany: null }
    }
    componentDidMount = () => {
        this.props.getSelectBoxCompanyDetails();
        this.props.getSelectBoxBranches();
    }
    reports = [
        { value: 1, text: 'Bruto Bilans' },
        { value: 2, text: 'Prošireni pregled' },
        { value: 3, text: 'Prošireni pregled bez ispravki' },
        { value: 4, text: 'Prošireni pregled sa ispravkama' },
        { value: 5, text: 'Klase' },
        { value: 6, text: 'Glavna knjiga' },
        { value: 7, text: 'Otvorene stavke po partnerima i kontima' },
        { value: 8, text: 'Protok novca' }
    ];
    onSubmit = (formValues) => {
        let reportType = parseInt(formValues.reportType);

        if(formValues.companyDetailsID === 'Sve'){
            formValues.companyDetailsID = null;
        }
        if(formValues.branchID === 'Sve'){
            formValues.branchID = null;
        }
        let data = reportType == 7 ? {
            fromAccount: formValues.fromAccount,
            toAccount: formValues.toAccount,
            companyDetailsID: formValues.companyDetailsID,
            branchID: formValues.branchID
        } : {
            fromDate: formValues.fromDate,
            toDate: formValues.toDate,
            fromAccount: formValues.fromAccount,
            toAccount: formValues.toAccount,
            companyDetailsID: formValues.companyDetailsID,
            branchID: formValues.branchID
        }
        if (reportType === 1) {
            this.props.getGrossBalance(data);
        }
        else if (reportType === 2) {
            this.props.getExtendedReview(data);
        }
        else if (reportType === 3) {
            data.withCorrection = false;
            this.props.getExtendedReview(data);
        }
        else if (reportType === 4) {
            data.withCorrection = true;
            this.props.getExtendedReview(data);
        }
        else if (reportType === 5) {
            this.props.getClasses(data);
        }
        else if (reportType === 6) {
            this.props.getGeneralLedger(data);
        }
        else if (reportType === 7) {
            this.props.getOpenItemsByPartnerAndAccount(data);
        }
        else if (reportType === 8) {
            this.props.getCashFlow(data);
        }
    }
    render() {
        return (<div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                    name='reportType'
                    component={this.props.EditSelectField}
                    label='Reports'
                    onChange={
                        (e, child) => {
                            this.setState({ currentValue: parseInt(child) })
                        }
                    }
                    dataSource={this.reports}
                    hasEmpty={false}
                    >
                </Field>
                <Field
                    name='companyDetailsID'
                    component={this.props.EditSelectField}
                    onChange={(e, child) => {this.setState({selectedCompany: parseInt(child)})}}
                    label='Company'
                    dataSource={this.props.companyDetails}
                    hasEmpty={false}
                >
                    <MenuItem key={-1} value={null}>
                        Sve
                    </MenuItem>
                </Field>
                <Field
                    name='branchID'
                    component={this.props.EditSelectField}
                    label='Branch'
                    dataSource={(this.state.selectedCompany == null ? this.props.branches : this.props.branches?.filter(el => el.data == this.state.selectedCompany))}
                    hasEmpty={false}
                >
                    <MenuItem key={-1} value={null}>
                        Sve
                    </MenuItem>
                </Field>
                {
                    this.state.currentValue !== 7 ?
                        (
                            <>
                                <Field
                                    name='fromDate'
                                    component={this.props.EditDatePickerField}
                                    label='FromDate'
                                />
                                <Field
                                    name='toDate'
                                    component={this.props.EditDatePickerField}
                                    label='ToDate'
                                />
                            </>
                        ) : null
                }
                <Field
                    name='fromAccount'
                    component={this.props.EditIntegerNumberField}
                    label='FromAccount'
                />
                <Field
                    name='toAccount'
                    component={this.props.EditIntegerNumberField}
                    label='ToAccount'
                />
                {/*
                    [1].indexOf(this.state.currentValue) !== -1 ? (
                        <>
                            <Field
                                name='companyDetailsID'
                                component={this.props.EditSelectField}
                                label='Company'
                            dataSource={this.props.companyDetails}
                            >
                            </Field>
                        </>
                    ) : null*/
                }
                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                        {i18next.t('Report')}
                    </Button>
                </Box>
            </form>
        </div>);
    }
}


const validate = (formValues) => {
    const errors = {};
    if (!formValues.reportType) {
        errors.reportType = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.fromAccount)) {
        errors.fromAccount = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.toAccount)) {
        errors.toAccount = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formGeneralLedgerReport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportType: '1',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
        fromAccount: 0,
        toAccount: 9
    }
})(GeneralLedgerPage);
const mapStateToProps = state => {
    return {
        companyDetails: Object.values(state.companyDetailsSelectBox),
        branches: Object.values(state.branchesSelectBox)
    }
}
export default connect(mapStateToProps, {
    EditSelectField,
    EditTextField,
    EditDatePickerField,
    EditCheckbox,
    EditIntegerNumberField,
    getSelectBoxCompanyDetails,
    getSelectBoxBranches,
    getGrossBalance,
    getExtendedReview,
    getClasses,
    getGeneralLedger,
    getOpenItemsByPartnerAndAccount,
    getCashFlow
})(formWrapper);
