//Mojo-AG
import { 
    CREATE_USERCOMPANYDETAILS,
    GET_ALL_USERCOMPANYDETAILS,
    GET_USERCOMPANYDETAILS,
    DELETE_USERCOMPANYDETAILS,
    UPDATE_USERCOMPANYDETAILS
} from '../../actions/types';

const userCompanyDetailsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_USERCOMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_USERCOMPANYDETAILS:
            return {...action.payload};
        case GET_USERCOMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_USERCOMPANYDETAILS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_USERCOMPANYDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default userCompanyDetailsReducer;