import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateRegion from './CreateRegion';
import EditRegion from './EditRegion';
import { addDialogRegion, editDialogRegion, deleteRegion, getAllRegions } from "../../actions/regionAction";
//import { getSelectBoxCompanies } from '../../actions/companyAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableRegion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    //this.props.getSelectBoxCompanies();

    this.props.getAllRegions();
  }

  // companyName = (props) => {
  //   return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  // }
  columns = [
     { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },

    // {
    //   field: 'CompanyID',
    //   headerName: i18next.t('Company'),
    //   width: 160,
    //   valueGetter: this.companyName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
    // },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogRegion();
  }
  edit = (params) => {
    this.props.editDialogRegion(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.regions}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateRegion></CreateRegion>
        <EditRegion></EditRegion>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteRegion(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    regions: Object.values(state.regions),
    companies: Object.values(state.companiesSelectBox)
  };
}

export default connect(mapStateToProps, {
  addDialogRegion,
  editDialogRegion,
  deleteRegion,
  getAllRegions,
  //getSelectBoxCompanies,
  showLoadingDataInProgress
})(TableRegion);