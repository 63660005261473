import { 
    CREATE_LOANPARTNER,
    GET_ALL_LOANPARTNER_BY_LOAN,
    GET_LOANPARTNER,
    DELETE_LOANPARTNER,
    UPDATE_LOANPARTNER,
    GET_LOAN,
    CLEAR_LOANPARTNER,
    UPDATE_LOAN
} from '../../actions/types';

const loanPartnerReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANPARTNER:           
            if(action.payload.id === undefined){
                window.test = state;
                let min = Math.min(...Object.keys(state).map(Number));
                action.payload.id = min > 0 ? -1 : min - 1;
            }
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANPARTNER_BY_LOAN:{
            let result = { };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        }
        case GET_LOAN: 
        case UPDATE_LOAN:{          
            let result = { };
            for(let i=0; i<action.payload.loanPartner.length;i++) {result[action.payload.loanPartner[i].id]=action.payload.loanPartner[i];}
            return result;
        }
        case GET_LOANPARTNER:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANPARTNER: {
            let result3 = {...state};
            delete result3[action.payload];
            return result3;
        }
        case UPDATE_LOANPARTNER:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_LOANPARTNER:
            return { };
        default:
            return state;
    }
}

export default loanPartnerReducer;