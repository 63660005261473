//Mojo-AG
import { 
    CREATE_EARLYREPAYMENTSETTINGS,
    GET_ALL_EARLYREPAYMENTSETTINGS,
    GET_EARLYREPAYMENTSETTINGS,
    DELETE_EARLYREPAYMENTSETTINGS,
    UPDATE_EARLYREPAYMENTSETTINGS
} from '../../actions/types';

const earlyRepaymentSettingsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_EARLYREPAYMENTSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_EARLYREPAYMENTSETTINGS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_EARLYREPAYMENTSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_EARLYREPAYMENTSETTINGS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_EARLYREPAYMENTSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default earlyRepaymentSettingsReducer;