import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import ComponentInLine from '../Share/ComponentInLine';
import CreateBankStatementImport from './CreateBankStatementImport';
import EditBankStatementImport from './EditBankStatementImport';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import {
  addDialogBankStatementImport,
  editDialogBankStatementImport,
  deleteBankStatementImport,
  getAllBankStatementImports,
  fileBankStatementImport,
  connectLoanBankStatementImport,
  connectPartnerBankStatementImport,
  approveBankStatementImport,
  importProcessBankStatementImport
} from "../../actions/bankStatementImportAction";
//import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCashiers } from '../../actions/cashierAction';
import { getSelectBoxPartners, getAllPartners } from '../../actions/partnerAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getAllBaseLoansData } from '../../actions/loanAction';
import { Field, reduxForm, change } from "redux-form";
import { EditSelectField, EditDatePickerField, EditCheckbox2 } from '../Share/EditComponents';
import TableBankStatementImportLoanFilter from './TableBankStatementImportLoanFilter';
import TableBankStatementImportPartnerFilter from './TableBankStatementImportPartnerFilter';
import TableBankStatementImportSplitDialog from '../BankStatementImportSplit/TableBankStatementImportSplitDialog';
import i18next from '../../language/i18n';


class TableBankStatementImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      cashierID: null,
      date: null,
      isLoanFilterOpen: false,
      isPartnerFilterOpen: false,
      isSplitBankStatementOpen: false,
      selectedRow: null,
      selectedRowFilter: null,
      showOnlyUnconnected: false
    }
  }


  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    // this.props.getSelectBoxCompanies();
    this.props.getSelectBoxCashiers();
    this.props.getSelectBoxPartners();
    this.props.getAllPartners();
    this.props.getSelectBoxBranches();
    this.props.getAllBaseLoansData();

    //this.props.getAllBankStatementImports();
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  cashierName = (props) => {
    return this.props.cashiers.find(el => el.value === props.row.cashierID)?.text;
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  branchName = (props) => {
    return this.props.branches.find(el => el.value === props.row.branchID)?.text;
  }
  loanName = (props) => {
    return this.props.loansBaseData.find(el => el.id === props.row.loanID)?.loanNumber;
  }
  onSubmit = (formValues) => {
    this.setState({ cashierID: formValues.cashierID, date: formValues.date });
    if (formValues.date !== undefined && formValues.date !== null && formValues.cashierID !== undefined && formValues.cashierID !== null) {
      this.props.getAllBankStatementImports(formValues.cashierID, formValues.date);
    }
    /*if(formValues.year  && formValues.cashierID && formValues.cashierID !== {null}){
      this.setState({cashierID: formValues.cashierID})
      this.props.getAllCashBalancesByYearAndCashier(formValues);
    }*/
  }


  findLoan = (params) => {
    this.setState({
      isLoanFilterOpen: true, selectedRow: params.row, selectedRowFilter: {
        branchID: params.row.branchID,
        loanAppID: params.row.loanAppID
      }
    });
  }
  findPartner = (params) => {
    this.setState({
      isPartnerFilterOpen: true,
      selectedRow: params.row,
      selectedRowFilter: {
        partnerID: params.row.partnerID
      }
    });
  }
  splitBankStatement = (params) => {
    this.setState({
      isSplitBankStatementOpen: true,
      selectedRow: params.row,
      selectedRowFilter: {
        partnerID: params.row.partnerID
      }
    });
  }
  onFindLoanSubmit = (loanData) => {
    if (loanData !== undefined) {
      this.props.connectLoanBankStatementImport({
        id: this.state.selectedRow.id,
        loanID: loanData.id
      });
      //this.state.selectedRow.loanID = loanData.id;
      this.setState({ isLoanFilterOpen: false });
    }
  }
  onFindPartnerSubmit = (partnerData) => {
    if (partnerData !== undefined) {
      this.props.connectPartnerBankStatementImport({
        id: this.state.selectedRow.id,
        partnerID: partnerData.id
      });
      this.setState({ isPartnerFilterOpen: false });
    }
  }
  approve = (params) => {
    this.props.approveBankStatementImport({ id: params.row.id });
  }
  importProcess = () => {
    this.props.importProcessBankStatementImport(this.state.cashierID, this.state.date);
  }

  //selectFile(event) {
  async selectFile(event) {
    let response = await this.props.fileBankStatementImport(event.target.files[0], this.state.cashierID);
    if (response.data.succeeded) {
      this.setState({
        cashierID: response.data.data.cashierID,
        date: response.data.data.date,
      });//response.data.data
      this.props.change('cashierID', response.data.data.cashierID);
      this.props.change('date', response.data.data.date);
    }
    /*this.setState({
      selectedFiles: event.target.files,
    });*/
  }
  columns = [

    /*{
        field: 'CompanyID',
        headerName: i18next.t('Company'),
        width: 160,
        valueGetter: this.companyName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
      },

    {
        field: 'CashierID',
        headerName: i18next.t('Cashier'),
        width: 160,
        valueGetter: this.cashierName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cashierName(cellParams1).localeCompare(this.cashierName(cellParams2))
      },
{ field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1 },*/

    { field: 'principal', headerName: i18next.t('Principal2'), minWidth: 350, flex: 4 },
    { field: 'purposeOfPayment', headerName: i18next.t('PurposeOfPayment'), minWidth: 450, flex: 4 },
    { field: 'payIn', headerName: i18next.t('PayIn'), minWidth: 100, flex: 1 },
    { field: 'payOut', headerName: i18next.t('PayOut'), minWidth: 100, flex: 1 },
    {
      field: 'branchID',
      headerName: i18next.t('Branch'),
      minWidth: 160, flex: 1,
      valueGetter: this.branchName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.branchName(cellParams1).localeCompare(this.branchName(cellParams2))
    },
    {
      field: 'loanID',
      headerName: i18next.t('Loan'),
      minWidth: 160, flex: 1,
      valueGetter: this.loanName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
    },
    //{ field: 'loanAppID', headerName: i18next.t('LoanApp'), minWidth: 150, flex: 1 },
    //{ field: 'revisionID', headerName: i18next.t('Revision'), minWidth: 150, flex: 1 },
    //{ field: 'loanID', headerName: i18next.t('Loan'), minWidth: 150, flex: 1 },

    {
      field: 'partnerID',
      headerName: i18next.t('Partner'),
      minWidth: 350, flex: 1,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    //{ field: 'link', headerName: i18next.t('Link'), minWidth: 150, flex: 1 },



    //{ field: 'bRVD', headerName: i18next.t('BRVD'), minWidth: 150, flex: 1 },
    //{ field: 'dPayIn', headerName: i18next.t('DPayIn'), minWidth: 150, flex: 1 },
    //{ field: 'dPayOut', headerName: i18next.t('DPayOut'), minWidth: 150, flex: 1 },
    //{ field: 'account', headerName: i18next.t('Account'), minWidth: 150, flex: 1 },
    //{ field: 'bankAccount', headerName: i18next.t('BankAccount'), minWidth: 150, flex: 1 },
    { field: 'isApproved', headerName: i18next.t('IsApproved'), minWidth: 150, flex: 1, type: 'boolean' },
    //{ field: 'order', headerName: i18next.t('Order'), minWidth: 150, flex: 1 },
    //{ field: 'transactionTypeEnum', headerName: i18next.t('TransactionTypeEnum'), minWidth: 150, flex: 1 },
    //{ field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'find',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.findLoan(params)}
          >
            {i18next.t('Loan')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'findPartner',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.findPartner(params)}
          >
            {i18next.t('Partner')}
          </Button>
        </strong>
      )
    },
    {
      field: 'podeli',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.splitBankStatement(params)}
          >
            {i18next.t('Split')}
          </Button>
        </strong>
      )
    },
    // {
    //   field: 'approve',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="success"
    //         size="small"
    //         style={{ marginLeft: 0 }}
    //         onClick={() => this.approve(params)}
    //       >
    //         {i18next.t('Approve')}
    //       </Button>
    //     </strong>
    //   )
    // }
  ];
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={1}

        >
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <ComponentInLine>
              <Field
                onChange={(data) => {
                  setTimeout(() => this.props.handleSubmit(this.onSubmit)());
                }}
                name='date'
                component={this.props.EditDatePickerField}
                label='Date'
                style={{
                  minWidth: '200px'
                }}
              />
              <Field
                onChange={(data) => {
                  setTimeout(() => this.props.handleSubmit(this.onSubmit)());
                }}
                name='cashierID'
                component={this.props.EditSelectField}
                label='Blagajna'
                dataSource={this.props.cashiers}

                style={{
                  minWidth: '300px'
                }}
              >
              </Field>
            </ComponentInLine>
          </form>

          <div>
            <EditCheckbox2
              name='showOnlyUnconnected'
              value={this.state.showOnlyUnconnected}
              label='ShowOnlyUnconnected'
              onChange={(e) => { this.setState({showOnlyUnconnected: !this.state.showOnlyUnconnected}) }}>
            </EditCheckbox2></div>
          <div>
            <div style={{ padding: '15px' }}>
              <label htmlFor="contained-button-file">
                <Button variant="contained" component="span" >
                  {i18next.t('Import')}
                </Button>
              </label>
              <input style={{ display: 'none' }}
                accept=".txt"
                id="contained-button-file"
                //multiple
                type="file"
                onChange={(event) => { this.selectFile(event) }}
              />
              <Button color="success" variant="contained" onClick={this.importProcess} style={{ marginLeft: '20px' }}>
                {i18next.t('Submit')}
              </Button>
            </div>
          </div>
        </Stack>

        <DataGrid
          rows={this.state.showOnlyUnconnected ? this.props.bankStatementImports?.filter(el => el.loanID == null && el.partnerID == null) : this.props.bankStatementImports}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
        //componentsProps={{ toolbar: { createNew:this.create } }}
        //checkboxSelection
        //disableSelectionOnClick
        />
        <TableBankStatementImportLoanFilter
          isOpen={this.state.isLoanFilterOpen}
          selectedRowInput={this.state.selectedRow}
          onClose={() => this.setState({ isLoanFilterOpen: false })}
          onSubmit={this.onFindLoanSubmit}
          initialValues={this.state.selectedRowFilter}
        >
        </TableBankStatementImportLoanFilter>

        <TableBankStatementImportPartnerFilter
          isOpen={this.state.isPartnerFilterOpen}
          selectedRowInput={this.state.selectedRow}
          onClose={() => this.setState({ isPartnerFilterOpen: false })}
          onSubmit={this.onFindPartnerSubmit}
          initialValues={this.state.selectedRowFilter}
        >
        </TableBankStatementImportPartnerFilter>
        <TableBankStatementImportSplitDialog
          isOpen={this.state.isSplitBankStatementOpen}
          selectedRowInput={this.state.selectedRow}
          onClose={() => this.setState({ isSplitBankStatementOpen: false })}>

        </TableBankStatementImportSplitDialog>
      </div>
    );
  }
}

const formWrapper = reduxForm({
  form: 'formBankImportSearch',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TableBankStatementImport);

const mapStateToProps = state => {
  return {
    bankStatementImports: Object.values(state.bankStatementImports),
    //companies: Object.values(state.companiesSelectBox), 
    cashiers: Object.values(state.cashiersSelectBox),
    partners: Object.values(state.partnersSelectBox),
    loans: Object.values(state.loansSelectBox),
    branches: Object.values(state.branchesSelectBox),
    loansBaseData: Object.values(state.loansBaseData)
  };
}

export default connect(mapStateToProps,
  {
    addDialogBankStatementImport,
    editDialogBankStatementImport,
    deleteBankStatementImport,
    getAllBankStatementImports,
    fileBankStatementImport,
    connectLoanBankStatementImport,
    connectPartnerBankStatementImport,
    approveBankStatementImport,
    importProcessBankStatementImport,
    //getSelectBoxCompanies, 
    getSelectBoxCashiers,
    getSelectBoxPartners,
    getAllPartners,
    getSelectBoxBranches,
    getAllBaseLoansData,
    change,
    EditSelectField,
    EditDatePickerField,
    EditCheckbox2,
    showLoadingDataInProgress
  })(formWrapper);
