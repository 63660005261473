import { 
    CREATE_CITY,
    GET_ALL_CITY,
    GET_CITY,
    DELETE_CITY,
    UPDATE_CITY
} from '../../actions/types';

const cityReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_CITY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_CITY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_CITY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_CITY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_CITY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default cityReducer;