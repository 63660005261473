import { 
    GET_LOAN
} from '../../actions/types';

 const partnerLoansSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_LOAN:
            return [...action.payload.partnerLoans];
        default:
            return state;
    }
}
export default partnerLoansSelectBoxReducer;