import { 
    CREATE_JOURNAL,
    //GET_ALL_JOURNAL,
    GET_ALL_JOURNAL_BY_ORDER,
    GET_JOURNAL,
    DELETE_JOURNAL,
    UPDATE_JOURNAL
} from '../../actions/types';

const journalReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_JOURNAL:
            return { ...state, [action.payload.id]: action.payload };
        // case GET_ALL_JOURNAL:
        //     let result = { ...state };
        //     for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
        //     return result;
        case GET_ALL_JOURNAL_BY_ORDER:
            let resultOrder = { };
            for(let i=0; i<action.payload.journals.length;i++) {resultOrder[action.payload.journals[i].id]=action.payload.journals[i];}
            return resultOrder;
        case GET_JOURNAL:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_JOURNAL: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_JOURNAL:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default journalReducer;