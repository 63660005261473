import React from 'react';
import { connect } from 'react-redux';
import { closeDialogLegalService_ExecutionType, updateLegalService_ExecutionType } from '../../actions/legalService_ExecutionTypeAction';
import FormLegalService_ExecutionType from './FormLegalService_ExecutionType';
import i18next from '../../language/i18n';

class EditLegalService_ExecutionType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateLegalService_ExecutionType(formValues);
    }
    
    render(){
        return (
            <FormLegalService_ExecutionType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormLegalService_ExecutionType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LegalService_ExecutionTypeDialog === undefined || state.dialog.LegalService_ExecutionTypeDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.LegalService_ExecutionTypeDialog};
}


export default connect(mapStateToProps, { closeDialogLegalService_ExecutionType, updateLegalService_ExecutionType })(EditLegalService_ExecutionType);
