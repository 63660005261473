//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogUserCompanyDetails } from '../../actions/userCompanyDetailsAction';
import { EditSelectField } from '../Share/EditComponents';

class FormUserCompanyDetails extends React.Component{
    handleClose = () => {
        this.props.closeDialogUserCompanyDetails();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='companyDetailsID'
                                    component ={this.props.EditSelectField}
                                    label = 'CompanyDetails'
                                    dataSource={this.props.companyDetailsSelectBox}
                                    />
                            </div>
            
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};   
    
    if(!formValues.companyDetailsID){
        errors.companyDetailsID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companyDetailsSelectBox: Object.values(state.companyDetailsSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formUserCompanyDetails',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormUserCompanyDetails);

export default connect(mapStateToProps, { closeDialogUserCompanyDetails, EditSelectField})(formWrapper);