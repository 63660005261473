import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { dateFormat } from '../Share/Table/DateFormat';

class TableLoanRepaymentScheduleRead extends React.Component {

  loanRepaymentScheduleTypeName = (props) => {
    return this.props.loanRepaymentScheduleTypes.find(el => el.value === props.row.loanRepaymentScheduleTypeID)?.text;
  }
  planName = (props) => {
    return this.props.plans.find(el => el.value === props.row.principalAccountID)?.text;
  }
  columns = [
    // {
    //     field: 'LoanRepaymentScheduleTypeID',
    //     headerName: i18next.t('LoanRepaymentScheduleType'),
    //     width: 160,
    //     valueGetter: this.loanRepaymentScheduleTypeName,
    //     sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.loanRepaymentScheduleTypeName(cellParams1).localeCompare(this.loanRepaymentScheduleTypeName(cellParams2))
    //   },
    { field: 'description', headerName: i18next.t('Description'), minWidth: 150, flex: 1 },
    { field: 'repaymentDate', headerName: i18next.t('RepaymentDate'), width: 150, valueGetter: dateFormat },
    { field: 'principalRepayment', headerName: i18next.t('PrincipalRepayment'), minWidth: 150, flex: 1 },
    { field: 'interestRepayment', headerName: i18next.t('InterestRepayment'), minWidth: 150, flex: 1 },
    { field: 'unpaidPrincipal', headerName: i18next.t('UnpaidPrincipal'), minWidth: 150, flex: 1 },
    { field: 'penaltyInterest', headerName: i18next.t('PenaltyInterest'), minWidth: 150, flex: 1 },
    { field: 'approvalCost', headerName: i18next.t('ApprovalCost'), minWidth: 150, flex: 1 },
    { field: 'repaid', headerName: i18next.t('Repaid'), minWidth: 150, flex: 1 },
    { field: 'referenceNumber', headerName: i18next.t('ReferenceNumber'), minWidth: 150, flex: 1 },



  ];
  create = () => {
    this.props.addDialogLoanRepaymentSchedule();
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.loanRepaymentSchedules}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loanRepaymentSchedules: Object.values(state.loanRepaymentSchedules),
    loanRepaymentScheduleTypes: Object.values(state.loanRepaymentScheduleTypesSelectBox),
    plans: Object.values(state.plansSelectBox)
  };
}

export default connect(mapStateToProps, {
})(TableLoanRepaymentScheduleRead);