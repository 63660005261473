import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogPartner, updatePartner } from '../../actions/partnerAction';
import FormPartner from './FormPartner';

class EditPartner extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updatePartner(formValues);
    }
    
    render(){
        return (
            <FormPartner 
                IsOpen = {this.props.IsOpen}
                IsNew = { false }
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormPartner>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PartnerDialog === undefined || state.dialog.PartnerDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.PartnerDialog};
}

export default connect(mapStateToProps, { closeDialogPartner, updatePartner })(EditPartner);
