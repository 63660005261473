import { 
    CREATE_VEHICLE,
    GET_ALL_VEHICLE,
    GET_VEHICLE,
    DELETE_VEHICLE,
    UPDATE_VEHICLE,
    GET_LOAN
} from '../../actions/types';

const vehicleReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_VEHICLE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_VEHICLE:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_VEHICLE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_VEHICLE: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_VEHICLE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = {  };
                for(let i=0; i<action.payload.vehicle.length;i++) {result3[action.payload.vehicle[i].id]=action.payload.vehicle[i];}
                return result3;
        default:
            return state;
    }
}

export default vehicleReducer;