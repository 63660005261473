//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_SOURCEOFFINANCING,
    GET_ALL_SOURCEOFFINANCING,
    GET_SOURCEOFFINANCING,
    GET_ALL_SELECTBOX_SOURCEOFFINANCING,
    DELETE_SOURCEOFFINANCING,
    UPDATE_SOURCEOFFINANCING,
    ADD_DIALOG_SOURCEOFFINANCING,
    EDIT_DIALOG_SOURCEOFFINANCING,
    CLOSE_DIALOG_SOURCEOFFINANCING
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createSourceOfFinancing = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/SourceOfFinancing/Create', formValues);    
    dispatch(responseHandler(response, CREATE_SOURCEOFFINANCING));
    if(response.data?.succeeded){
        dispatch(reset('formSourceOfFinancing'));
    }
}

export const getAllSourceOfFinancings = () => async (dispatch) => {
    const response = await api.get('/api/v1/SourceOfFinancing/GetAll');
    dispatch(responseHandler(response, GET_ALL_SOURCEOFFINANCING));
}

export const getSourceOfFinancing = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/SourceOfFinancing/Get/' + id);
    dispatch(responseHandler(response, GET_SOURCEOFFINANCING));
}

export const getSelectBoxSourceOfFinancings = () => async (dispatch) => {
    const response = await api.get('/api/v1/SourceOfFinancing/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_SOURCEOFFINANCING));
}

export const updateSourceOfFinancing = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/SourceOfFinancing/Update', formValues);
    dispatch(responseHandler(response, UPDATE_SOURCEOFFINANCING));
}

export const deleteSourceOfFinancing = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/SourceOfFinancing/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_SOURCEOFFINANCING));
}

export const addDialogSourceOfFinancing = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_SOURCEOFFINANCING,
        payload: null
    });
}

export const editDialogSourceOfFinancing = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_SOURCEOFFINANCING,
        payload: item
    });
}

export const closeDialogSourceOfFinancing = () => async (dispatch) => {
    dispatch(reset('formSourceOfFinancing'));
    dispatch({
        type: CLOSE_DIALOG_SOURCEOFFINANCING,
        payload: undefined
    });
}