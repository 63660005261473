import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createBLTurnover } from '../../actions/bLTurnoverAction';
import FormBLTurnover from './FormBLTurnover';

class CreateBLTurnover extends React.Component{
    onSubmit  = (formValues) => {
        formValues.CashBalanceID = this.props.CashBalanceID;
        this.props.createBLTurnover(formValues);
    }
    
    render(){
        return (
            <FormBLTurnover 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormBLTurnover>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BLTurnoverDialog === undefined || state.dialog.BLTurnoverDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BLTurnoverDialog};
}


export default connect(mapStateToProps, { createBLTurnover })(CreateBLTurnover);
