//Mojo-AG
import { 
    CREATE_LOANREQUESTHISTORY,
    GET_ALL_LOANREQUESTHISTORY,
    GET_LOANREQUESTHISTORY,
    DELETE_LOANREQUESTHISTORY,
    UPDATE_LOANREQUESTHISTORY
} from '../../actions/types';

const loanRequestHistoryReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANREQUESTHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANREQUESTHISTORY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANREQUESTHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANREQUESTHISTORY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANREQUESTHISTORY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanRequestHistoryReducer;