//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_ORDERTYPE,
    GET_ALL_ORDERTYPE,
    GET_ORDERTYPE,
    GET_ALL_SELECTBOX_ORDERTYPE,
    DELETE_ORDERTYPE,
    UPDATE_ORDERTYPE,
    ADD_DIALOG_ORDERTYPE,
    EDIT_DIALOG_ORDERTYPE,
    CLOSE_DIALOG_ORDERTYPE
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createOrderType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/OrderType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_ORDERTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formOrderType'));
    }
}

export const getAllOrderTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/OrderType/GetAll');
    dispatch(responseHandler(response, GET_ALL_ORDERTYPE));
}

export const getOrderType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/OrderType/Get/' + id);
    dispatch(responseHandler(response, GET_ORDERTYPE));
}

export const getSelectBoxOrderTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/OrderType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_ORDERTYPE));
}

export const updateOrderType = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/OrderType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_ORDERTYPE));
}

export const deleteOrderType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/OrderType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_ORDERTYPE));
}

export const addDialogOrderType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_ORDERTYPE,
        payload: null
    });
}

export const editDialogOrderType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_ORDERTYPE,
        payload: item
    });
}

export const closeDialogOrderType = () => async (dispatch) => {
    dispatch(reset('formOrderType'));
    dispatch({
        type: CLOSE_DIALOG_ORDERTYPE,
        payload: undefined
    });
}