import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogOpinionOfTheReferent } from '../../actions/opinionOfTheReferentAction';
import { EditSelectField, EditTextField } from '../Share/EditComponents';

class FormOpinionOfTheReferent extends React.Component{
    handleClose = () => {
        this.props.closeDialogOpinionOfTheReferent();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    maxWidth={'md'}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            {/*<div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    >
                                </Field>
                                </div>*/}
            
                            <div>
                                <Field
                                    name='text'
                                    component={this.props.EditTextField}
                                    label='Text'
                                    multiline={true}
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    
    if(!formValues.text){
        errors.text = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formOpinionOfTheReferent',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormOpinionOfTheReferent);

export default connect(mapStateToProps, { closeDialogOpinionOfTheReferent, EditSelectField, EditTextField})(formWrapper);