import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCity from './CreateCity';
import EditCity from './EditCity';
import i18next from '../../language/i18n';
import { addDialogCity, editDialogCity, deleteCity, getAllCities } from "../../actions/cityAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import DeleteButton from '../Share/Button/DeleteButton';


class TableCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getAllCities();
  }
  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    { field: 'cityCode', headerName: i18next.t('CityCode'), minWidth: 150, flex: 1 },
    { field: 'zipCode', headerName: i18next.t('ZipCode'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>

        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogCity();
  }
  edit = (params) => {
    this.props.editDialogCity(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.cities}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateCity></CreateCity>
        <EditCity></EditCity>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteCity(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { cities: Object.values(state.cities) };
}

export default connect(mapStateToProps, { addDialogCity, editDialogCity, deleteCity, getAllCities, createTheme, showLoadingDataInProgress })(TableCity);