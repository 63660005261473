import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogBLTurnover, updateBLTurnover } from '../../actions/bLTurnoverAction';
import FormBLTurnover from './FormBLTurnover';

class EditBLTurnover extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBLTurnover(formValues);
    }
    
    render(){
        return (
            <FormBLTurnover 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormBLTurnover>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BLTurnoverDialog === undefined || state.dialog.BLTurnoverDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BLTurnoverDialog};
}


export default connect(mapStateToProps, { closeDialogBLTurnover, updateBLTurnover })(EditBLTurnover);
