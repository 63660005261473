import { 
    CREATE_LOAN,
    GET_ALL_LOAN,
    //GET_LOAN,
    DELETE_LOAN,
    UPDATE_LOAN,
    UPDATE_LOAN_START_DATE,
    UPDATE_LOAN_MADE_ACTION,
    CREATE_LOANAPPROVAL,
    UPDATE_LOANAPPROVAL
} from '../../actions/types';

const loanReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOAN:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOAN:
            let result = {  };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        //case GET_LOAN:
        //    return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOAN: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOAN:
        case UPDATE_LOAN_START_DATE:
            return { ...state, [action.payload.id]: action.payload.loan };
        case UPDATE_LOAN_MADE_ACTION:
            return { ...state, [action.payload.id]: action.payload };
        case CREATE_LOANAPPROVAL:
        case UPDATE_LOANAPPROVAL:
            return { ...state, [action.payload.loan.id]: action.payload.loan };
            
        default:
            return state;
    }
}

export default loanReducer;