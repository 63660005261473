import { 
    CREATE_DEBTCOLLECTIONCOST,
    GET_ALL_DEBTCOLLECTIONCOST,
    GET_DEBTCOLLECTIONCOST,
    DELETE_DEBTCOLLECTIONCOST,
    UPDATE_DEBTCOLLECTIONCOST
} from '../../actions/types';

const debtCollectionCostReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_DEBTCOLLECTIONCOST:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_DEBTCOLLECTIONCOST:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_DEBTCOLLECTIONCOST:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_DEBTCOLLECTIONCOST: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_DEBTCOLLECTIONCOST:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default debtCollectionCostReducer;