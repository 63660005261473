import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { connect } from 'react-redux';

class CustomProgressBar extends React.Component{
    render() {
        return(this.props.progressBar.isLoadingDataInProgress ? (<LinearProgress></LinearProgress>) : (null));
    }
}

const mapStateToProps = state => {
    return {
        progressBar: state.progressBar
    }
}
export default connect(mapStateToProps, {})(CustomProgressBar);