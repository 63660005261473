import React from "react";
import { connect } from 'react-redux';
import {
  Avatar,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
  IconButton,
  Tooltip
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import i18next from '../../language/i18n';
import DateProvider from '../../share/dateProvider';
import { readNotification } from '../../actions/notificationAction'

class NotificationItem extends React.Component {
  markAsRead = () => {
    console.log(this.props.data.id)
    this.props.readNotification(this.props.data.id)
  }
  render() {
    return (
      <ListItemButton>
        <ListItemAvatar>
          <Avatar
            sx={{
              color: 'success.main',
              bgcolor: 'success.lighter'
            }}
          >
            {/* <GiftOutlined /> */}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography variant="h6">
              {`${this.props.data.createFullName}: ${this.props.data.title}`}
            </Typography>
          }
          secondary={`${this.props.data.text} - ${i18next.t('CreateDate')}: ${DateProvider.GetDateTime(this.props.data.createDate)}`}
        />
        <ListItemSecondaryAction>
          {this.props.data.isReaded ? null : (

            <Tooltip title={i18next.t('MarkAsRead')}>
              <IconButton size="small" onClick={this.markAsRead}>
                <CircleIcon sx={{ fontSize: "10px" }} color="primary" />
              </IconButton>
            </Tooltip>
          )}
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  };
}

const mapStateToProps = state => {
  return {
    title: state.title?.text,
    userName: state.authUser?.userName
  };
}


export default connect(mapStateToProps, {
  readNotification
})(NotificationItem);