import { 
    CREATE_PARTNER
} from '../actions/types';
const lastPartnerReducer = (state = null, action) => {
    switch(action.type){
        case CREATE_PARTNER:
            return action.payload.id;
        default:
            return state;
    }
}
export default lastPartnerReducer;