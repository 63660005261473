import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createOpinionOfTheReferent } from '../../actions/opinionOfTheReferentAction';
import FormOpinionOfTheReferent from './FormOpinionOfTheReferent';

class CreateOpinionOfTheReferent extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createOpinionOfTheReferent(formValues);
    }
    
    render(){
        return (
            <FormOpinionOfTheReferent 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormOpinionOfTheReferent>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.OpinionOfTheReferentDialog === undefined || state.dialog.OpinionOfTheReferentDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.OpinionOfTheReferentDialog};
}


export default connect(mapStateToProps, { createOpinionOfTheReferent })(CreateOpinionOfTheReferent);
