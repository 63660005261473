import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogPenaltyInterestSettings, updatePenaltyInterestSettings } from '../../actions/penaltyInterestSettingsAction';
import FormPenaltyInterestSettings from './FormPenaltyInterestSettings';

class EditPenaltyInterestSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updatePenaltyInterestSettings(formValues);
    }
    
    render(){
        return (
            <FormPenaltyInterestSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormPenaltyInterestSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PenaltyInterestSettingsDialog === undefined || state.dialog.PenaltyInterestSettingsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.PenaltyInterestSettingsDialog};
}


export default connect(mapStateToProps, { closeDialogPenaltyInterestSettings, updatePenaltyInterestSettings })(EditPenaltyInterestSettings);
