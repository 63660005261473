//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_LOANWRITEOFF,
    GET_ALL_LOANWRITEOFF,
    GET_LOANWRITEOFF,
    GET_ALL_SELECTBOX_LOANWRITEOFF,
    DELETE_LOANWRITEOFF,
    UPDATE_LOANWRITEOFF,
    ADD_DIALOG_LOANWRITEOFF,
    EDIT_DIALOG_LOANWRITEOFF,
    CLOSE_DIALOG_LOANWRITEOFF
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanWriteOff = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanWriteOff/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANWRITEOFF));
    if(response.data?.succeeded){
        dispatch(reset('formLoanWriteOff'));
    }
}

export const getAllLoanWriteOffs = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanWriteOff/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANWRITEOFF));
}

export const getLoanWriteOff = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanWriteOff/Get/' + id);
    dispatch(responseHandler(response, GET_LOANWRITEOFF));
}

export const getSelectBoxLoanWriteOffs = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanWriteOff/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANWRITEOFF));
}

export const updateLoanWriteOff = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanWriteOff/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANWRITEOFF));
}

export const deleteLoanWriteOff = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanWriteOff/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANWRITEOFF));
}

export const addDialogLoanWriteOff = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANWRITEOFF,
        payload: null
    });
}

export const editDialogLoanWriteOff = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANWRITEOFF,
        payload: item
    });
}

export const closeDialogLoanWriteOff = () => async (dispatch) => {
    dispatch(reset('formLoanWriteOff'));
    dispatch({
        type: CLOSE_DIALOG_LOANWRITEOFF,
        payload: undefined
    });
}