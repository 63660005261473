import React from 'react';
//import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { closeDialogBLTurnover } from '../../actions/bLTurnoverAction';
import { EditIntegerNumberField, EditSelectField, EditDecimalNumberField, EditTextField } from '../Share/EditComponents';
import { getLoansByPartner } from '../../actions/loanAction';

class FormBLTurnover extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.formValues !== this.props.formValues && prevProps.formValues?.id !== this.props.formValues?.id) {
            if (this.props.formValues?.id > 0) {
                this.props.getLoansByPartner(this.props.formValues.partnerID);
                //this.getLinks(this.props.formValues.planID, this.props.formValues.partnerID, this.props.formValues.link, this.props.formValues.id);
            }
        }
    }
    handleClose = () => {
        this.props.closeDialogBLTurnover();
    }
    onPartnerChange = (e, child) => {
        this.props.getLoansByPartner(child);
        //this.getLinks(this.props.formValues.planID, child, this.props.formValues.link, this.props.formValues.id);
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='documentTurnoverTypeID'
                                    component={this.props.EditSelectField}
                                    label='DocumentTurnoverType'
                                    dataSource={this.props.documentTurnoverTypesSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='partnerID'
                                    component={this.props.EditSelectField}
                                    label='Partner'
                                    onChange={this.onPartnerChange}
                                    dataSource={this.props.partnersSelectBox}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='loanID'
                                    component={this.props.EditSelectField}
                                    label='Loan'
                                    dataSource={this.props.loansByPartner?.map(el => ({value: el.id, text: el.loanNumber}))}
                                >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='planID'
                                    component={this.props.EditSelectField}
                                    label='Plan'
                                    dataSource={this.props.plansSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='link'
                                    component={this.props.EditTextField}
                                    label='Link'
                                />
                            </div>

                            <div>
                                <Field
                                    name='payIn'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayIn'
                                />
                            </div>
                            <div>
                                <Field
                                    name='payOut'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayOut'
                                />
                            </div>
                            <div>
                                <Field
                                    name='bankAccount'
                                    component={this.props.EditTextField}
                                    label='BankAccount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='purposeOfPayment'
                                    component={this.props.EditTextField}
                                    label='PurposeOfPayment'
                                />
                            </div>

                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.documentTurnoverTypeID) {
        errors.documentTurnoverTypeID = 'Polje je obavezno!';
    }
    if (formValues.payIn === null || formValues.payIn === undefined) {
        errors.payIn = 'Polje je obavezno!';
    }
    if (formValues.payOut === null || formValues.payOut === undefined) {
        errors.payOut = 'Polje je obavezno!';
    }
    if (!formValues.partnerID) {
        errors.partnerID = 'Polje je obavezno!';
    }
    // if (!formValues.bRVD) {
    //     errors.bRVD = 'Polje je obavezno!';
    // }
    if (!formValues.planID) {
        errors.planID = 'Polje je obavezno!';
    }
    if (!formValues.link) {
        errors.link = 'Polje je obavezno!';
    }
    if (!formValues.bankAccount) {
        errors.bankAccount = 'Polje je obavezno!';
    }
    if (!formValues.purposeOfPayment) {
        errors.purposeOfPayment = 'Polje je obavezno!';
    }
    if (!formValues.cashBalanceID) {
        errors.cashBalanceID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        documentTurnoverTypesSelectBox: Object.values(state.documentTurnoverTypesSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        plansSelectBox: Object.values(state.plansSelectBox),
        cashBalancesSelectBox: Object.values(state.cashBalancesSelectBox),
        branchesSelectBox: Object.values(state.branchesSelectBox),
        loansByPartner: Array.isArray(state.loansByPartner) ? state.loansByPartner : Object.values(state.loansByPartner),
        formValues: getFormValues('formBLTurnover')(state)
    }
}
const formWrapper = reduxForm({
    form: 'formBLTurnover',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormBLTurnover);

export default connect(mapStateToProps, {
    closeDialogBLTurnover,
    EditIntegerNumberField,
    EditSelectField,
    EditDecimalNumberField,
    EditTextField,
    getLoansByPartner,
    change,
    getFormValues
})(formWrapper);