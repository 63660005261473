//Mojo-AG
import { 
    CREATE_FILEUPLOAD,
    //GET_ALL_FILEUPLOAD,
    //GET_FILEUPLOAD,
    DELETE_FILEUPLOAD,
    //UPDATE_FILEUPLOAD,
    GET_LOAN
} from '../../actions/types';

const fileUploadReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_FILEUPLOAD:
            return { ...state, [action.payload.id]: action.payload };
        // case GET_ALL_FILEUPLOAD:
        //     let result = { ...state };
        //     for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
        //     return result;
        // case GET_FILEUPLOAD:
        //     return { ...state, [action.payload.id]: action.payload };
        case DELETE_FILEUPLOAD: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        // case UPDATE_FILEUPLOAD:
        //     return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
            return {...action.payload.fileUpload}
        default:
            return state;
    }
}

export default fileUploadReducer;