//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateSourceOfFinancing from './CreateSourceOfFinancing';
import EditSourceOfFinancing from './EditSourceOfFinancing';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogSourceOfFinancing, editDialogSourceOfFinancing, deleteSourceOfFinancing, getAllSourceOfFinancings } from "../../actions/sourceOfFinancingAction";


class TableSourceOfFinancing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getAllSourceOfFinancings();
  }

  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex:1 },
    // { field: 'createDate', headerName: i18next.t('CreateDate'), width: 150 },
    // { field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogSourceOfFinancing();
  }
  edit = (params) => {
    this.props.editDialogSourceOfFinancing(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.sourceOfFinancings}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateSourceOfFinancing></CreateSourceOfFinancing>
        <EditSourceOfFinancing></EditSourceOfFinancing>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteSourceOfFinancing(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { sourceOfFinancings: Object.values(state.sourceOfFinancings), companies: Object.values(state.companiesSelectBox) };
}

export default connect(mapStateToProps, { addDialogSourceOfFinancing, editDialogSourceOfFinancing, deleteSourceOfFinancing, getAllSourceOfFinancings })(TableSourceOfFinancing);