//Mojo-AG
import React from "react";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import i18next from '../../language/i18n';
import { getAllLoanApprovals } from "../../actions/loanApprovalAction";
import DeleteButton from '../Share/Button/DeleteButton';
import TableLoanApproval from './TableLoanApproval';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import { styled, Stack } from '@mui/system';
import { createLoanApproval } from '../../actions/loanApprovalAction';
import { getLoanType } from '../../actions/loanTypeAction';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  padding: theme.spacing(1),
  textAlign: 'center',
}));


class DialogTableLoanApproval extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  // componentDidMount = () => {
  //   this.props.getAllLoanApprovals();
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.item?.id !== this.props.item?.id) {
      if (this.props.item?.id != null && this.props.item?.id != undefined && this.props.item?.id > 0) {
        this.props.getAllLoanApprovals(this.props.item?.id);
        this.props.getLoanType(this.props.item?.loanTypeID);
      }
    }
  }

  approve = () => {
    this.props.createLoanApproval({
      loanID: this.props.item.id,
      amount: this.props.item.requestedAmount,
      isApproved: true,
      note: null,
      loanTermMonths: this.props.item.loanTermMonths,
      interestRate: this.props.item.interestRate,
      approvalCost: this.props.item.approvalCost,
    });
  }
  render() {
    let loanInfo = this.props.loanTypes.find(el => el.id == this.props.item?.loanTypeID);
    return (<Dialog
      fullWidth
      maxWidth="xl"
      open={this.props.IsOpen}

      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">{this.props.item?.loanNumber}</DialogTitle>
      <DialogContent style={{ height: '80vh' }}>
        <div style={{ marginBottom: '15px' }}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Item>{i18next.t('RequestedAmount')}:<br />{this.props.item?.requestedAmount} KM</Item>
            <Item>{i18next.t('LoanTermMonths')}:<br />{this.props.item?.loanTermMonths}</Item>
            <Item>{i18next.t('ProposedInterestRate')}:<br />{this.props.item?.interestRate} %</Item>
            <Item>{i18next.t('CreditConditions')}<br />{i18next.t('InterestRate')}: {loanInfo?.interestRate} %</Item>
            <Item>{i18next.t('ProposedApprovalCost')}:<br />{this.props.item?.approvalCost} KM</Item>
            <Item>{i18next.t('ProposedApprovalCostPercent')}:<br />{this.props.item?.approvalCostPercent} %</Item>
            <Item>{i18next.t('CreditConditions')}<br />{i18next.t('ApprovalCostPercentShort')}: {loanInfo?.approvalCostPercent} %</Item>
          </Stack>
        </div>
        <TableLoanApproval
          approve={this.approve}
          loanID={this.props.item?.id}
        ></TableLoanApproval>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={this.props.close}>
            {i18next.t('Close')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    loanTypes: Object.values(state.loanTypes),
  };
}

export default connect(mapStateToProps, {
  createLoanApproval,
  getLoanType,
  getAllLoanApprovals
})(DialogTableLoanApproval);