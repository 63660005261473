import api from "../api/myApi";
import { 
    CREATE_USERBRANCH,
    GET_ALL_USERBRANCH,
    GET_USERBRANCH,
    GET_ALL_SELECTBOX_USERBRANCH,
    DELETE_USERBRANCH,
    UPDATE_USERBRANCH,
    ADD_DIALOG_USERBRANCH,
    EDIT_DIALOG_USERBRANCH,
    CLOSE_DIALOG_USERBRANCH,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createUserBranch = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/UserBranch/Create', formValues);    
    dispatch(responseHandler(response, CREATE_USERBRANCH));
    if(response.data?.succeeded){
        dispatch(reset('formUserBranch'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllUserBranches = (userID) => async (dispatch) => {
    const response = await api.get('/api/v1/UserBranch/GetAll?userID='+userID);
    dispatch(responseHandler(response, GET_ALL_USERBRANCH));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getUserBranch = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/UserBranch/Get/' + id);
    dispatch(responseHandler(response, GET_USERBRANCH));
}

export const getSelectBoxUserBranches = () => async (dispatch) => {
    const response = await api.get('/api/v1/UserBranch/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_USERBRANCH));
}

export const updateUserBranch = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/UserBranch/Update', formValues);
    dispatch(responseHandler(response, UPDATE_USERBRANCH));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteUserBranch = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/UserBranch/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_USERBRANCH));
}

export const addDialogUserBranch = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_USERBRANCH,
        payload: null
    });
}

export const editDialogUserBranch = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_USERBRANCH,
        payload: item
    });
}

export const closeDialogUserBranch = () => async (dispatch) => {
    dispatch(reset('formUserBranch'));
    dispatch({
        type: CLOSE_DIALOG_USERBRANCH,
        payload: undefined
    });
}