import api from "../api/myApi";
import { 
    CREATE_LOANTYPE,
    GET_ALL_LOANTYPE,
    GET_LOANTYPE,
    GET_ALL_SELECTBOX_LOANTYPE,
    GET_ALL_SELECTBOX_EXTENDED_LOANTYPE,
    DELETE_LOANTYPE,
    UPDATE_LOANTYPE,
    ADD_DIALOG_LOANTYPE,
    EDIT_DIALOG_LOANTYPE,
    CLOSE_DIALOG_LOANTYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formLoanType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLoanTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanType/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANTYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLoanType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanType/Get/' + id);
    dispatch(responseHandler(response, GET_LOANTYPE));
}

export const getSelectBoxLoanTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANTYPE));
}

export const getSelectBoxExtendedLoanTypes = (branchID) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanType/GetSelectBoxExtended?BranchID='+branchID);
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_EXTENDED_LOANTYPE));
}

export const updateLoanType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LoanType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANTYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLoanType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANTYPE));
}

export const addDialogLoanType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANTYPE,
        payload: null
    });
}

export const editDialogLoanType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANTYPE,
        payload: item
    });
}

export const closeDialogLoanType = () => async (dispatch) => {
    dispatch(reset('formLoanType'));
    dispatch({
        type: CLOSE_DIALOG_LOANTYPE,
        payload: undefined
    });
}