//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCRKRequest } from '../../actions/cRKRequestAction';
import { EditSelectField } from '../Share/EditComponents';

class FormCRKRequest extends React.Component{
    handleClose = () => {
        this.props.closeDialogCRKRequest();
    }
crkCreateRequestType=[
    {value: 1, text: "Tekući krediti"},
    {value: 2, text: "Otplaćeni krediti"},
    {value: 3, text: "Oba"}]
    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='partnerID'
                                    component ={this.props.EditSelectField}
                                    label = 'Partner'
                                    dataSource={this.props.partnersSelectBox?.filter(el => this.props.partners.indexOf(el.value) != -1)}
                                    />
                            </div>
            
                            <div>
                                <Field
                                    name='cRKCreateRequestTypeID'
                                    component ={this.props.EditSelectField}
                                    label = 'CRKRequestType'
                                    dataSource={this.crkCreateRequestType}
                                    />
                            </div>
            
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.partnerID){
        errors.partnerID = 'Polje je obavezno!';
    }
    if(!formValues.cRKRequestTypeID){
        errors.cRKRequestTypeID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        partnersSelectBox: Object.values(state.partnersSelectBox), 
        cRKRequestTypesSelectBox: Object.values(state.cRKRequestTypesSelectBox),
        //loanPartners: Object.values(state.loanPartners)
      }
}
const formWrapper = reduxForm({
    form: 'formCRKRequest',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormCRKRequest);

export default connect(mapStateToProps, { closeDialogCRKRequest, EditSelectField})(formWrapper);