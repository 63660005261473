import api from "../api/myApi";
import { 
    CREATE_CASHIER,
    GET_ALL_CASHIER,
    GET_CASHIER,
    GET_ALL_SELECTBOX_CASHIER,
    DELETE_CASHIER,
    UPDATE_CASHIER,
    ADD_DIALOG_CASHIER,
    EDIT_DIALOG_CASHIER,
    CLOSE_DIALOG_CASHIER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCashier = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Cashier/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CASHIER));
    if(response.data?.succeeded){
        dispatch(reset('formCashier'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllCashiers = () => async (dispatch) => {
    const response = await api.get('/api/v1/Cashier/GetAll');
    dispatch(responseHandler(response, GET_ALL_CASHIER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getCashier = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Cashier/Get/' + id);
    dispatch(responseHandler(response, GET_CASHIER));
}

export const getSelectBoxCashiers = () => async (dispatch) => {
    const response = await api.get('/api/v1/Cashier/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CASHIER));
}

export const updateCashier = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Cashier/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CASHIER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteCashier = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Cashier/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CASHIER));
}

export const addDialogCashier = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CASHIER,
        payload: null
    });
}

export const editDialogCashier = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CASHIER,
        payload: item
    });
}

export const closeDialogCashier = () => async (dispatch) => {
    dispatch(reset('formCashier'));
    dispatch({
        type: CLOSE_DIALOG_CASHIER,
        payload: undefined
    });
}