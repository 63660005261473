//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogFileUpload } from '../../actions/fileUploadAction';
import { EditSelectFieldClear } from '../Share/EditComponents';
import i18next from '../../language/i18n';
import Button from '@mui/material/Button';
import { createFileUpload } from '../../actions/fileUploadAction';

class FormFileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileUploadTypeID: null,
            fileName: '',
            file: null
        };
    }
    componentWillUnmount(){
       this.setState({
        fileUploadTypeID: null,
        fileName: '',
        file: null
    }) ;
    }
    onSubmit = () => {
        this.props.createFileUpload(this.props.loan.loan.id, this.state.fileUploadTypeID, this.state.file);
    }
    handleClose = () => {
        this.props.closeDialogFileUpload();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <EditSelectFieldClear name='fileUploadTypeID'
                            label='FileUploadType'
                            onChange={
                                (event) => {
                                    this.setState({ fileUploadTypeID: isNaN(parseInt(event.target.value)) ? null : parseInt(event.target.value) })
                                }
                            }
                            dataSource={this.props.fileUploadTypesSelectBox}
                        />
                        {this.state.fileName}
                        <div>
                            <label htmlFor="loan-file-upload">
                                <Button variant="contained" component="span" fullWidth>
                                    {i18next.t('ChooseFile')}
                                </Button>
                            </label>

                            <input style={{ display: 'none' }}
                                accept=".txt, .pdf, image/png, image/gif, image/jpeg, .doc, .docx"
                                id="loan-file-upload"
                                //multiple
                                type="file"
                                onChange={(event) => {
                                    this.setState({
                                        fileName: event.target.files[0].name,
                                        file: event.target.files[0]
                                    });
                                }}
                            />
                        </div>
                        <DialogActions>
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <Button                             
                                disabled={this.state.fileUploadTypeID == null || this.state.fileUploadTypeID == undefined || this.state.file == null}
                                variant="outlined" //outlined contained
                                color="primary"
                                onClick={this.onSubmit}>
                                    {i18next.t('Upload')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        fileUploadTypesSelectBox: Object.values(state.fileUploadTypesSelectBox),
        loan: state.loanSelected
    }
}
export default connect(
    mapStateToProps,
    {
        closeDialogFileUpload,
        createFileUpload,
        EditSelectFieldClear
    })(FormFileUpload);