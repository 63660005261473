import { 
    LOANMAINTENANCE_LOAN_POSTING,
    LOANMAINTENANCE_CLOSING_AVANS,
    LOANMAINTENANCE_RECLASSIFICATION,
    LOANMAINTENANCE_MONTHLY_RESERVATIONS,
    LOANMAINTENANCE_CRK_EXPORT,
    LOANMAINTENANCE_GET_INFO
} from '../../actions/types';

const loanMaintenanceReducer = (state = {
    lastLoanPostingDate: null,
    lastAdvanseClosingDate: null,
    lastReclasificationDate: null,
    lastReservationDate: null,
    lastCRKExportDate: null
}, action) => {
    switch(action.type){
        case LOANMAINTENANCE_GET_INFO:
            return { ...action.payload };
        case LOANMAINTENANCE_LOAN_POSTING:
            return { ...state, lastLoanPostingDate: action.payload };
        case LOANMAINTENANCE_CLOSING_AVANS:
            return { ...state, lastAdvanseClosingDate: action.payload };
        case LOANMAINTENANCE_RECLASSIFICATION:
            return { ...state, lastReclasificationDate: action.payload, lastAdvanseClosingDate: action.payload };
        case LOANMAINTENANCE_MONTHLY_RESERVATIONS:
            return { ...state, lastReservationDate: action.payload };
        case LOANMAINTENANCE_CRK_EXPORT:
            return { ...state, lastCRKExportDate: action.payload };
        default:
            return state;
    }
}

export default loanMaintenanceReducer;