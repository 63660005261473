//Mojo-AG
import { 
    CREATE_CRKRESPONSE,
    GET_ALL_CRKRESPONSE,
    GET_CRKRESPONSE,
    DELETE_CRKRESPONSE,
    UPDATE_CRKRESPONSE
} from '../../actions/types';

const cRKResponseReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_CRKRESPONSE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_CRKRESPONSE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_CRKRESPONSE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_CRKRESPONSE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_CRKRESPONSE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default cRKResponseReducer;