import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogPenaltyInterestSettings } from '../../actions/penaltyInterestSettingsAction';
import { EditDecimalNumberField, EditDatePickerField, EditSelectField } from '../Share/EditComponents';

class FormPenaltyInterestSettings extends React.Component{
    handleClose = () => {
        this.props.closeDialogPenaltyInterestSettings();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='companyDetailsID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companyDetails}
                                    >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='interesPercent'
                                    component={this.props.EditDecimalNumberField}
                                    label='InteresPercent'
                                />
                            </div>
                            <div>
                                <Field
                                    name='penaltyInterestPeriodID'
                                    component ={this.props.EditSelectField}
                                    label = 'Period'
                                    dataSource={this.props.penaltyInterestPeriod}
                                    >
                                </Field>
                            </div>
                            <div>
                                <Field
                                    name='daysGranted'
                                    component={this.props.EditDecimalNumberField}
                                    label='DaysGranted'
                                />
                            </div>
                            <div>
                                <Field
                                    name='minimumInterestToCharge'
                                    component={this.props.EditDecimalNumberField}
                                    label='MinimumInterestToCharge'
                                />
                            </div>
                            <div>
                                <Field
                                    name='validFrom'
                                    component={this.props.EditDatePickerField}
                                    label='ValidFrom'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.dailyInteresPercent){
        errors.dailyInteresPercent = 'Polje je obavezno!';
    }
    if(!formValues.minimumInterestToCharge){
        errors.minimumInterestToCharge = 'Polje je obavezno!';
    }
    if(!formValues.fBiHAnnualRate){
        errors.fBiHAnnualRate = 'Polje je obavezno!';
    }
    if(!formValues.validFrom){
        errors.validFrom = 'Polje je obavezno!';
    }
    return errors;
}



const mapStateToProps = state => {
    return { 
        penaltyInterestPeriod: Object.values(state.penaltyInterestPeriodSelectBox),
        companyDetails: Object.values(state.companyDetailsSelectBox),
      }
}
const formWrapper = reduxForm({
    form: 'formPenaltyInterestSettings',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormPenaltyInterestSettings);

export default connect(mapStateToProps, { closeDialogPenaltyInterestSettings, EditDecimalNumberField, EditDatePickerField, EditSelectField})(formWrapper);