import React from "react";
import { connect } from 'react-redux';
import {
    Box,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import LoanStatusInfo from '../../layout/thema/LoanStatusInfo';
import MainCard from '../../layout/thema/MainCard';
import { getLoanStatusStatistic, getRealizedLoans, getLoanClassification} from '../../actions/statisticAction';
import ReactApexChart from 'react-apexcharts';
import i18next from '../../language/i18n';


class DashboardPage extends React.Component {
    componentDidMount = () => {
        this.props.getLoanStatusStatistic();
        this.props.getRealizedLoans();
        this.props.getLoanClassification();
    }
    month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    areaOptions = () => {
        return {
            chart: {
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                }
            },
            xaxis: {
                categories: this.props.statistics.realizedLoans.map(el => `${el.year}-${this.month[el.month - 1]}`),
                axisBorder: {
                    show: false,
                },
            },
            tooltip: {
                theme: 'dark'
            }
        }
    };
    pieOptions = () => {
        return {
            chart: {
                toolbar: {
                    show: true,
                    tools: {
                        download: false
                    }
                }
            },
            labels: this.props.statistics.loanClassification.map(el => el.clasification),
            tooltip: {
                theme: 'dark'
            }
        };
    }
    areaSeries = () => {
        let data = [
            {
                name: i18next.t('LoanCount'),
                data: this.props.statistics.realizedLoans.map(el => el.count)
            }, {
                name: `${i18next.t('RequestedAmountSum')} [1000KM]`,
                data: this.props.statistics.realizedLoans.map(el => parseFloat((el.requestedAmountSum / 1000).toFixed(2)))
            },
        ];
        return data;
    };
    pieSeries = () => {
        return this.props.statistics.loanClassification.map(el => el.number);
    }
    render() {
        return (
            <Grid container rowSpacing={4.5} columnSpacing={2.75}>
                <Grid item xs={12} sx={{ mb: -2.25 }}>
                    <Typography variant="h5">Dashboard</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <LoanStatusInfo title={i18next.t('Draft')} dataInfo={this.props.statistics.loanStatusStatistic.find(el => el.loanStatusID === 0)} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <LoanStatusInfo title={i18next.t('CreditBoard')}  dataInfo={this.props.statistics.loanStatusStatistic.find(el => el.loanStatusID === 1)}  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <LoanStatusInfo title={i18next.t('ApprovedLoan')}  dataInfo={this.props.statistics.loanStatusStatistic.find(el => el.loanStatusID === 3)}  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <LoanStatusInfo title={i18next.t('LoanRealization')}  dataInfo={this.props.statistics.loanStatusStatistic.find(el => el.loanStatusID === 4)}  />
                </Grid>

                <Grid item xs={12} md={7} lg={8}>
                    <MainCard style={{ height: '600px' }}>
                        <Box>
                            <ReactApexChart options={this.areaOptions()} series={this.areaSeries()} type="area" height={450} />
                        </Box>
                    </MainCard>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <MainCard style={{ height: '600px' }}>
                        <Box >
                            <Stack spacing={2}>
                                <Typography variant="h6" color="textSecondary">
                                    {i18next.t('LoanCategory')}
                                </Typography>
                                <Typography variant="h5">{i18next.t('ActiveLoansCount')}: {this.props.statistics.loanClassification.map(el => el.number).reduce((a, b) => a + b, 0)}</Typography>
                            </Stack>
                            <ReactApexChart options={this.pieOptions()} series={this.pieSeries()} type="pie" height={450} />
                        </Box>
                    </MainCard>
                </Grid>
            </Grid>);
    }
}


const mapStateToProps = state => {
    return {
        statistics: state.statistics,
    };
}
export default connect(mapStateToProps, {
    getLoanStatusStatistic,
    getRealizedLoans,
    getLoanClassification
})(DashboardPage);
