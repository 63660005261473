//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createUserCompanyDetails } from '../../actions/userCompanyDetailsAction';
import FormUserCompanyDetails from './FormUserCompanyDetails';

class CreateUserCompanyDetails extends React.Component{
    onSubmit  = (formValues) => {
        formValues.userID = this.props.userID;
        this.props.createUserCompanyDetails(formValues);
    }
    
    render(){
        return (
            <FormUserCompanyDetails 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormUserCompanyDetails>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserCompanyDetailsDialog === undefined || state.dialog.UserCompanyDetailsDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.UserCompanyDetailsDialog};
}


export default connect(mapStateToProps, { createUserCompanyDetails })(CreateUserCompanyDetails);
