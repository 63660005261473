//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogCRKRequest, updateCRKRequest } from '../../actions/cRKRequestAction';
import FormCRKRequest from './FormCRKRequest';

class EditCRKRequest extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCRKRequest(formValues);
    }
    
    render(){
        return (
            <FormCRKRequest 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormCRKRequest>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKRequestDialog === undefined || state.dialog.CRKRequestDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CRKRequestDialog};
}


export default connect(mapStateToProps, { closeDialogCRKRequest, updateCRKRequest })(EditCRKRequest);
