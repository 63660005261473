import React from "react";
import { EditSelectField, EditTextField, EditDatePickerField, EditCheckbox, EditIntegerNumberField } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Validation from '../../share/validation';
import {
    getFinancialCard
} from '../../actions/reportAction';
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { getSelectBoxPlans } from '../../actions/planAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { selectClasses } from "@mui/material";

class FinancialCardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedCompany: null}
    }
    componentDidMount = () => {
        this.props.getSelectBoxCompanyDetails();
        this.props.getSelectBoxPlans();
        this.props.getSelectBoxBranches();
    }
    reports = [
        { value: 1, text: i18next.t('View') },
        { value: 2, text: i18next.t('MonthlyView') },
        { value: 3, text: i18next.t('FinancialCardPerSubject') }
    ];
    onSubmit = (formValues) => {
        if(formValues.companyDetailsID === 'Sve'){
            formValues.companyDetailsID = null;
        }
        if(formValues.branchID === 'Sve'){
            formValues.branchID = null;
        }
        formValues.reportTypeID = parseInt(formValues.reportTypeID);
        this.props.getFinancialCard(formValues);
    }
    render() {
        return (<div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                    name='reportTypeID'
                    component={this.props.EditSelectField}
                    onChange={(e, child) => {this.setState({selectedCompany: parseInt(child)})}}
                    label='ReportType'
                    dataSource={this.reports}
                    hasEmpty={false}
                >
                </Field>
                <Field
                    name='companyDetailsID'
                    component={this.props.EditSelectField}
                    onChange={(e, child) => {this.setState({selectedCompany: parseInt(child)})}}
                    label='Company'
                    dataSource={this.props.companyDetails}
                    hasEmpty={false}
                >
                    <option value={null} >
                        Sve
                    </option>
                </Field>
                <Field
                    name='branchID'
                    component={this.props.EditSelectField}
                    label='Branch'
                    dataSource={(this.state.selectedCompany == null ? this.props.branches : this.props.branches?.filter(el => el.data == this.state.selectedCompany))}
                    hasEmpty={false}
                >
                    <option value={null} >
                        Sve
                    </option>
                </Field>
                <Field
                    name='planID'
                    component={this.props.EditSelectField}
                    label='Plan'
                    dataSource={this.props.plans}
                >
                </Field>
                    
                <Field
                    name='fromDate'
                    component={this.props.EditDatePickerField}
                    label='FromDate'
                />
                <Field
                    name='toDate'
                    component={this.props.EditDatePickerField}
                    label='ToDate'
                />
                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                        {i18next.t('Report')}
                    </Button>
                </Box>
            </form>
        </div>);
    }
}


const validate = (formValues) => {
    const errors = {};
    if (!formValues.reportType) {
        errors.reportType = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.fromAccount)) {
        errors.fromAccount = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.toAccount)) {
        errors.toAccount = 'Polje je obavezno!';
    }
    if(!formValues.planID){
        errors.planID = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formGeneralLedgerReport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportTypeID: '1',
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31)
    }
})(FinancialCardPage);
const mapStateToProps = state => {
    return {
        companyDetails: Object.values(state.companyDetailsSelectBox),
        plans: Object.values(state.plansSelectBox),        
        branches: Object.values(state.branchesSelectBox)
    }
}
export default connect(mapStateToProps, {
    getSelectBoxCompanyDetails,
    getSelectBoxPlans,
    getSelectBoxBranches,
    getFinancialCard,

    EditSelectField,
    EditDatePickerField
})(formWrapper);
