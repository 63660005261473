import React from 'react';
import { connect } from 'react-redux';
import { createPartnerNote } from '../../actions/partnerNoteAction';
import FormPartnerNote from './FormPartnerNote';
import history from "../../share/history";

class CreatePartnerNote extends React.Component{
    onSubmit  = (formValues) => {
        if(this.isPartnerLogic()){
            formValues.partnerID = this.props.partnerSelected.partnerData.id;
        }
        else{
            formValues.loanID = this.props.loan.loan.id;
        }
        this.props.createPartnerNote(formValues);
    }
    isPartnerLogic = () => {
        return history.location.pathname === '/PartnerDetails';
    }
    
    render(){
        return (
            <FormPartnerNote 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create"
                showPartner={!this.isPartnerLogic()}>
            </FormPartnerNote>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.PartnerNoteDialog === undefined || state.dialog.PartnerNoteDialog.type !== 'add'? 
  {
      IsOpen:false,
      loan: state.loanSelected,
      partnerSelected: state.partnerSelected
    } : 
    {
        ...state.dialog.PartnerNoteDialog,
        loan: state.loanSelected,
        partnerSelected: state.partnerSelected
    };
}


export default connect(mapStateToProps, { createPartnerNote })(CreatePartnerNote);
