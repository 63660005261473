import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateKUF from './CreateKUF';
import EditKUF from './EditKUF';
import { addDialogKUF, editDialogKUF, deleteKUF, getAllKUFs } from "../../actions/kUFAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableKUF extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxPartners();

    this.props.getAllKUFs();
  }

  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  columns = [
    {
      field: 'PartnerID',
      headerName: i18next.t('Supplier'),
      minWidth: 200, flex: 2,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    { field: 'orderNumber', headerName: i18next.t('OrderNumber'), minWidth: 150, flex: 1 },
    { field: 'dateArrive', headerName: i18next.t('DateArrive'), minWidth: 150, flex: 1, type: 'date', valueGetter: dateFormat },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, type: 'date', valueGetter: dateFormat },


    { field: 'bankAccount', headerName: i18next.t('BankAccount'), minWidth: 150, flex: 1 },
    { field: 'amountExVAT', headerName: i18next.t('AmountExVAT'), minWidth: 150, flex: 1 },
    { field: 'vAT', headerName: i18next.t('VAT'), minWidth: 150, flex: 1 },
    { field: 'totalAmount', headerName: i18next.t('TotalAmount'), minWidth: 150, flex: 1 },
    { field: 'paymentDueDate', headerName: i18next.t('PaymentDueDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: dateFormat },
    { field: 'paymentDate', headerName: i18next.t('PaymentDate'), minWidth: 150, flex: 1, type: 'date', valueGetter: dateFormat },
    { field: 'description', headerName: i18next.t('Description'), minWidth: 150, flex: 1 },
    { field: 'note', headerName: i18next.t('Note'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogKUF();
  }
  edit = (params) => {
    this.props.editDialogKUF(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.kUFs}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateKUF></CreateKUF>
        <EditKUF></EditKUF>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteKUF(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    kUFs: Object.values(state.kUFs),
    partners: Object.values(state.partnersSelectBox)
  };
}

export default connect(mapStateToProps, { addDialogKUF, editDialogKUF, deleteKUF, getAllKUFs, getSelectBoxPartners, showLoadingDataInProgress })(TableKUF);