//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createBankStatementImportSplit } from '../../actions/bankStatementImportSplitAction';
import FormBankStatementImportSplit from './FormBankStatementImportSplit';

class CreateBankStatementImportSplit extends React.Component{
    onSubmit  = (formValues) => {
        formValues.bankStatementImportID = this.props.bankStatementImportID;
        this.props.createBankStatementImportSplit(formValues);
    }
    
    render(){
        return (
            <FormBankStatementImportSplit 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormBankStatementImportSplit>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BankStatementImportSplitDialog === undefined || state.dialog.BankStatementImportSplitDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BankStatementImportSplitDialog};
}


export default connect(mapStateToProps, { createBankStatementImportSplit })(CreateBankStatementImportSplit);
