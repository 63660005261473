import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogRealEstates, updateRealEstates } from '../../actions/realEstatesAction';
import FormRealEstates from './FormRealEstates';

class EditRealEstates extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateRealEstates(formValues);
    }
    
    render(){
        return (
            <FormRealEstates 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormRealEstates>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RealEstatesDialog === undefined || state.dialog.RealEstatesDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.RealEstatesDialog};
}


export default connect(mapStateToProps, { closeDialogRealEstates, updateRealEstates })(EditRealEstates);
