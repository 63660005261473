//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCommissionSettings } from '../../actions/commissionSettingsAction';
import { EditSelectField, EditDatePickerField, EditIntegerNumberField, EditDecimalNumberField } from '../Share/EditComponents';

class FormCommissionSettings extends React.Component{
    handleClose = () => {
        this.props.closeDialogCommissionSettings();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
            
                            <div>
                                <Field
                                    name='loanTypeID'
                                    component ={this.props.EditSelectField}
                                    label = 'LoanType'
                                    dataSource={this.props.loanTypesSelectBox}
                                    />
                            </div>
            
                            <div>
                                <Field
                                    name='validFrom'
                                    component={this.props.EditDatePickerField}
                                    label='ValidFrom'
                                />
                            </div>
                            <div>
                                <Field
                                    name='validTo'
                                    component={this.props.EditDatePickerField}
                                    label='ValidTo'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fromLoanTermMonths'
                                    component={this.props.EditIntegerNumberField}
                                    label='FromLoanTermMonths'
                                />
                            </div>
                            <div>
                                <Field
                                    name='toLoanTermMonths'
                                    component={this.props.EditIntegerNumberField}
                                    label='ToLoanTermMonths'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fromRequestedAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='FromRequestedAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='toRequestedAmount'
                                    component={this.props.EditDecimalNumberField}
                                    label='ToRequestedAmount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='percentage'
                                    component={this.props.EditDecimalNumberField}
                                    label='Percentage'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.loanTypeID){
        errors.loanTypeID = 'Polje je obavezno!';
    }
    if(!formValues.validFrom){
        errors.validFrom = 'Polje je obavezno!';
    }
    if(!formValues.fromLoanTermMonths){
        errors.fromLoanTermMonths = 'Polje je obavezno!';
    }
    if(!formValues.toLoanTermMonths){
        errors.toLoanTermMonths = 'Polje je obavezno!';
    }
    if(!formValues.fromRequestedAmount){
        errors.fromRequestedAmount = 'Polje je obavezno!';
    }
    if(!formValues.toRequestedAmount){
        errors.toRequestedAmount = 'Polje je obavezno!';
    }
    if(!formValues.percentage){
        errors.percentage = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { partnersSelectBox: Object.values(state.partnersSelectBox), loanTypesSelectBox: Object.values(state.loanTypesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formCommissionSettings',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormCommissionSettings);

export default connect(mapStateToProps, { closeDialogCommissionSettings, EditSelectField, EditDatePickerField, EditIntegerNumberField, EditDecimalNumberField})(formWrapper);