//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createCRKRequest } from '../../actions/cRKRequestAction';
import FormCRKRequest from './FormCRKRequest';

class CreateCRKRequest extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createCRKRequest(formValues);
    }
    
    render(){
        return (
            <FormCRKRequest 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create"
                partners={this.props.partners}>
            </FormCRKRequest>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKRequestDialog === undefined || state.dialog.CRKRequestDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CRKRequestDialog};
}


export default connect(mapStateToProps, { createCRKRequest })(CreateCRKRequest);
