import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, change, getFormValues } from "redux-form";
import { closeDialogPartner } from '../../actions/partnerAction';
import { EditSelectField, EditTextField, EditCheckbox, EditDatePickerField } from '../Share/EditComponents';
import ComponentInLine from '../Share/ComponentInLine';
import i18next from '../../language/i18n';

class FormPartnerPerson extends React.Component {

    isDisabled = () => {
        return this.props.disabled === true;
    }

    render() {
        return (
            <form onSubmit={
                this.props.handleSubmit(this.props.onSubmit)
            }>
                {/*<div>
                                <Field name='companyID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Company'
                                    dataSource={this.props.companiesSelectBox}
                                    ></Field>
                            </div>*/}
                <ComponentInLine>
                    <div>
                        <Field name='firstName'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='FirstName' />
                    </div>
                    <div>
                        <Field name='parentsName'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='ParentsName' />
                    </div>
                    <div>
                        <Field name='lastName'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='LastName' />
                    </div>
                </ComponentInLine>

                <ComponentInLine>
                    <div>
                        <Field name='jib'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            onChange={(e) => {
                                if (this.props.useJmbgChecker) {
                                    let result = jmbgCheck(e.target.value);
                                    if (result.isCorrect) {
                                        this.props.change('genderID', result.pol);
                                        this.props.change('birthDate', result.birthDate);
                                    }
                                }
                            }}
                            label='JIB' />
                    </div>
                    <div>
                        <Field name='identityCardNo'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='IdentityCardNo' />
                    </div>
                    <div>
                        <Field name='idExpireDate'
                            component={
                                this.props.EditDatePickerField
                            }
                            disabled={this.isDisabled()}
                            label='IDExpireDate' />
                    </div>
                </ComponentInLine>
                {
                    this.props.onlyAddress === false ?
                        (
                            <ComponentInLine>
                                <div>
                                    <Field name='entityID'
                                        component={
                                            this.props.EditSelectField
                                        }
                                        disabled={this.isDisabled()}
                                        label='Entity'
                                        dataSource={this.props.entitiesSelectBox}
                                    >
                                    </Field>
                                </div>
                                <div>
                                    <Field name='cityID'
                                        component={
                                            this.props.EditSelectField
                                        }
                                        disabled={this.isDisabled()}
                                        label='City'
                                        dataSource={this.props.citiesSelectBox}
                                    > </Field>
                                </div>
                                <div>
                                    <Field name='address'
                                        component={
                                            this.props.EditTextField
                                        }
                                        disabled={this.isDisabled()}
                                        label='Address' />
                                </div>
                            </ComponentInLine>
                        ) :
                        (
                            <div>
                                <Field name='address'
                                    component={
                                        this.props.EditTextField
                                    }
                                    disabled={this.isDisabled()}
                                    label='Address' />
                            </div>
                        )
                }
                <ComponentInLine>
                    <div>
                        <Field name='residenceCityID'
                            component={
                                this.props.EditSelectField
                            }
                            disabled={this.isDisabled()}
                            label='ResidenceCity'
                            dataSource={this.props.citiesSelectBox}
                        ></Field>
                    </div>
                    <div>
                        <Field name='residenceAddress'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='ResidenceAddress' />
                    </div>
                </ComponentInLine>

                <ComponentInLine>
                    <div>
                        <Field name='birthDate'
                            component={
                                this.props.EditDatePickerField
                            }
                            disabled={this.isDisabled()}
                            label='BirthDate' />
                    </div>
                    <div>
                        <Field name='birthCity'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='BirthCity' />
                    </div>
                </ComponentInLine>
                <ComponentInLine>
                    <div>
                        <Field name='genderID'
                            component={
                                this.props.EditSelectField
                            }
                            disabled={this.isDisabled()}
                            label='Gender'
                            dataSource={this.props.gendersSelectBox}
                        ></Field>
                    </div>

                    <div>
                        <Field name='maritalStatusID'
                            component={
                                this.props.EditSelectField
                            }
                            disabled={this.isDisabled()}
                            label='MaritalStatus'
                            dataSource={this.props.maritalStatusSelectBox}
                        ></Field>
                    </div>
                </ComponentInLine>


                <ComponentInLine>
                    <div>
                        <Field name='email'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Email' />
                    </div>
                    <div>
                        <Field name='phone'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Phone' />
                    </div>
                    <div>
                        <Field name='mobilePhone'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='MobilePhone' />
                    </div>
                </ComponentInLine>


                <ComponentInLine></ComponentInLine>
                <ComponentInLine>
                    <div>
                        <Field name='profession'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='Profession' />
                    </div>
                    <div>
                        <Field name='qualificationsID'
                            component={
                                this.props.EditSelectField
                            }
                            disabled={this.isDisabled()}
                            label='Qualifications'
                            dataSource={this.props.qualificationsSelectBox}
                        ></Field>
                    </div>
                </ComponentInLine>
                <ComponentInLine>
                    <div>
                        <Field name='bankAccount'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='BankAccount' />
                    </div>
                    <div>
                        <Field name='subAccount'
                            component={
                                this.props.EditTextField
                            }
                            disabled={this.isDisabled()}
                            label='SubAccount' />
                    </div>
                </ComponentInLine>
                <ComponentInLine>
                    <div>
                        <Field name='isPoliticallyExposed'
                            component={
                                this.props.EditCheckbox
                            }
                            disabled={this.isDisabled()}
                            label='IsPoliticallyExposed' />
                    </div>
                    <div>
                        <Field name='isEmployedInTheCompany'
                            component={
                                this.props.EditCheckbox
                            }
                            disabled={this.isDisabled()}
                            label='IsEmployedInTheCompany' />
                    </div>
                    <div>
                        <Field name='isAssignee'
                            component={
                                this.props.EditCheckbox
                            }
                            disabled={this.isDisabled()}
                            label='Assignee' />
                    </div>                    
                </ComponentInLine>


                <div>
                    <Field name='note'
                        component={
                            this.props.EditTextField
                        }
                        disabled={this.isDisabled()}
                        label='Note' />
                </div>


                {/* 
                            <div>
                                <Field name='name'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='Name'/>
                            </div>
                            
                            

                           
                            
                            <div>
                                <Field name='contactPerson'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ContactPerson'/>
                            </div>
                            
                            <div>
                                <Field name='pib'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='PIB'/>
                            </div>
                            <div>
                                <Field name='fax'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='Fax'/>
                            </div>
                           
                            <div>
                                <Field name='phone2'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='Phone2'/>
                            </div>
                            
                            
                            <div>
                                <Field name='bankAccount2'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='BankAccount2'/>
                            </div>
                            <div>
                                <Field name='foreignCurrencyAccount'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ForeignCurrencyAccount'/>
                            </div>
                            <div>
                                <Field name='isPaymentAccount'
                                    component={
                                        this.props.EditCheckbox
                                    }
                                    label='IsPaymentAccount'/>
                            </div>
                            <div>
                                <Field name='groupID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Group'
                                    dataSource={this.props.groupsSelectBox}
                                    > </Field>
                            </div>

                            <div>
                                <Field name='ownerID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Partner'
                                    dataSource={this.props.partnersSelectBox}
                                    ></Field>
                            </div>

                            
                            
                            
                            
                            

                            

                            <div>
                                <Field name='partnerTypeID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='PartnerType'
                                    dataSource={this.props.partnerTypesSelectBox}
                                    > </Field>
                            </div>

                            

                            <div>
                                <Field name='cEOID'
                                    component={
                                        this.props.EditSelectField
                                    }
                                    label='Partner'
                                    dataSource={this.props.partnersSelectBox}
                                    > </Field>
                            </div>

                            <div>
                                <Field name='activityCode'
                                    component={
                                        this.props.EditTextField
                                    }
                                    label='ActivityCode'/>
                            </div>
                           
                            
                            

                            <div>
                                <Field name='isOnUNSanctionList'
                                    component={
                                        this.props.EditCheckbox
                                    }
                                    label='IsOnUNSanctionList'/>
                            </div>
                           */}
                {this.isDisabled() ? null : (<DialogActions>
                    <CancelButton onClick={this.props.handleClose}></CancelButton>
                    <Button color="primary" variant="contained" type="Submit">
                        {i18next.t('Submit')}
                    </Button>
                </DialogActions>)}

            </form>
        );
    }
}
const jmbgCheck = (jmbg) => {
    if (jmbg.length === 13) {
        let dan = Number(jmbg[0] + jmbg[1]);
        let mesec = Number(jmbg[2] + jmbg[3]);
        let godina = Number(jmbg[4] + jmbg[5] + jmbg[6]);
        let regija = Number(jmbg[7] + jmbg[8]);
        let jedinstveniBroj = Number(jmbg[9] + jmbg[10] + jmbg[11]);
        let kontrolna = Number(jmbg[12]);
        let ispravnaKontrolna = 11 - (7 * (Number(jmbg[0]) + Number(jmbg[6])) + 6 * (Number(jmbg[1]) + Number(jmbg[7])) + 5 * (Number(jmbg[2]) + Number(jmbg[8])) +
            4 * (Number(jmbg[3]) + Number(jmbg[9])) + 3 * (Number(jmbg[4]) + Number(jmbg[10])) + 2 * (Number(jmbg[5]) + Number(jmbg[11]))) % 11;
        if (ispravnaKontrolna > 9) ispravnaKontrolna = 0;

        if (dan < 1 || dan > 31) {
            return { isCorrect: false, message: "Neispravan dan rođenja" };
        } else if (mesec < 1 || mesec > 12) {
            return { isCorrect: false, message: "Neispravan mesec rođenja" };
        } else if (godina < 900 && godina > 17) {
            return { isCorrect: false, message: "Neispravna godina rođenja" };
        } else if ((regija > 50 && regija < 70) || regija > 99) {
            return { isCorrect: false, message: "Neispravna regija rođenja" };
        } else if (kontrolna !== ispravnaKontrolna) {
            return { isCorrect: false, message: "Neispravan kontrolni broj" };
        } else {
            return { isCorrect: true, message: "", birthDate: new Date((godina > 800) ? godina + 1000 : godina + 2000, mesec - 1, dan), pol: jedinstveniBroj < 500 ? 1 : 2 };
        }

    }
    else {
        return { isCorrect: false, message: "JMBG mora imati 13 cifara!" };
    }
};
const validate = (formValues) => {
    const errors = {};

    // if (!formValues.companyID) {
    //     errors.companyID = 'Polje je obavezno!';
    // }
    // if (!formValues.name) {
    //     errors.name = 'Polje je obavezno!';
    // }
    if (!formValues.address) {
        errors.address = 'Polje je obavezno!';
    }
    // if (!formValues.entityID) {
    //     errors.entityID = 'Polje je obavezno!';
    // }
    // if (!formValues.cityID) {
    //     errors.cityID = 'Polje je obavezno!';
    // }
    // if (!formValues.phone) {
    //     errors.phone = 'Polje je obavezno!';
    // }
    if (!formValues.bankAccount) {
        errors.bankAccount = 'Polje je obavezno!';
    }
    // if (!formValues.contactPerson) {
    //     errors.contactPerson = 'Polje je obavezno!';
    // }
    if (!formValues.jib) {
        errors.jib = 'Polje je obavezno!';
    }
    // else {
    //     let result = jmbgCheck(formValues.jib);
    //     if (result.isCorrect == false) {
    //         errors.jib = result.message;
    //     }
    // }
    // if (!formValues.pIB) {
    //     errors.pIB = 'Polje je obavezno!';
    // }
    // if (!formValues.fax) {
    //     errors.fax = 'Polje je obavezno!';
    // }
    // if (!formValues.mobilePhone) {
    //     errors.mobilePhone = 'Polje je obavezno!';
    // }
    // if (!formValues.phone2) {
    //     errors.phone2 = 'Polje je obavezno!';
    // }
    // if (!formValues.email) {
    //     errors.email = 'Polje je obavezno!';
    // }
    // if (!formValues.note) {
    //     errors.note = 'Polje je obavezno!';
    // }
    // if (!formValues.bankAccount2) {
    //     errors.bankAccount2 = 'Polje je obavezno!';
    // }
    // if (!formValues.foreignCurrencyAccount) {
    //     errors.foreignCurrencyAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.isPaymentAccount) {
    //     errors.isPaymentAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.groupID) {
    //     errors.groupID = 'Polje je obavezno!';
    // }
    // if (!formValues.birthCity) {
    //     errors.birthCity = 'Polje je obavezno!';
    // }
    if (!formValues.birthDate) {
        errors.birthDate = 'Polje je obavezno!';
    }
    if (!formValues.identityCardNo) {
        errors.identityCardNo = 'Polje je obavezno!';
    }
    if (!formValues.genderID) {
        errors.genderID = 'Polje je obavezno!';
    }
    // if (!formValues.partnerTypeID) {
    //     errors.partnerTypeID = 'Polje je obavezno!';
    // }
    // if (!formValues.activityCode) {
    //     errors.activityCode = 'Polje je obavezno!';
    // }
    if (!formValues.firstName) {
        errors.firstName = 'Polje je obavezno!';
    }
    if (!formValues.parentsName) {
        errors.parentsName = 'Polje je obavezno!';
    }
    if (!formValues.lastName) {
        errors.lastName = 'Polje je obavezno!';
    }
    // if (!formValues.subAccount) {
    //     errors.subAccount = 'Polje je obavezno!';
    // }
    // if (!formValues.isOnUNSanctionList) {
    //     errors.isOnUNSanctionList = 'Polje je obavezno!';
    // }
    return errors;
}


const mapStateToProps = state => {
    return {
        companiesSelectBox: Object.values(state.companiesSelectBox),
        citiesSelectBox: Object.values(state.citiesSelectBox),
        groupsSelectBox: Object.values(state.groupsSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
        gendersSelectBox: Object.values(state.gendersSelectBox),
        maritalStatusSelectBox: Object.values(state.maritalStatusSelectBox),
        partnerTypesSelectBox: Object.values(state.partnerTypesSelectBox),
        entitiesSelectBox: Object.values(state.entitiesSelectBox),
        employmentTypesSelectBox: Object.values(state.employmentTypesSelectBox),
        qualificationsSelectBox: Object.values(state.qualificationsSelectBox),

    }
}
const formWrapper = reduxForm(
    {
        form: 'formPartnerPerson',
        validate: validate,
        destroyOnUnmount: true,
        enableReinitialize: true
    })(FormPartnerPerson);

export default connect(mapStateToProps, {
    closeDialogPartner,
    EditSelectField,
    EditTextField,
    EditCheckbox,
    EditDatePickerField,
    change,
})(formWrapper);
