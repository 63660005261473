import { 
    GET_LOANS_BY_SEARCH
} from '../../actions/types';

 const loansBySearchReducer = (state = {}, action) => {
    switch(action.type){
        case GET_LOANS_BY_SEARCH:
            return [...action.payload];
        default:
            return state;
    }
}
export default loansBySearchReducer;