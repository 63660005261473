import React from "react";
import { connect } from 'react-redux';
import {
    Avatar,
    Badge,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    Paper,
    Popper
} from '@mui/material';
import Transitions from './Transitions'
import MainCard from '../thema/MainCard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationItem from './NotificationItem';
import CloseIcon from '@mui/icons-material/Close';
import i18next from '../../language/i18n';
import { getAllNotifications } from '../../actions/notificationAction'

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false }
        this.anchorRef = React.createRef();
        //this.matchesXs = useMediaQuery(theme.breakpoints.down('md'));
        this.iconBackColorOpen = 'grey.300';
        this.iconBackColor = 'grey.100';
        this.startPolling();
    }
    startPolling = () => {
        setTimeout(function () {
            //if (!this.isMounted()) { return; } // abandon             
            this.props.getAllNotifications(); // do it once and then start it up ...
            setInterval(this.props.getAllNotifications.bind(this), 60000); //1 min
        }.bind(this), 1000);
    }

    avatarSX = {
        width: 36,
        height: 36,
        fontSize: '1rem'
    };

    actionSX = {
        mt: '6px',
        ml: 1,
        top: 'auto',
        right: 'auto',
        alignSelf: 'flex-start',

        transform: 'none'
    };

    handleClose = (event) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
            return;
        }
        this.setState({ isOpen: false });
    };
    handleToggle = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };

    render() {
        return (
            <Box sx={{ flexShrink: 0, ml: 0.75 }}>
                <IconButton color="inherit"
                    ref={this.anchorRef}
                    onClick={this.handleToggle}>
                    <Badge color="error" badgeContent={this.props.notifications?.filter(el => !el.isReaded).length}>
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <Popper
                    // placement={this.matchesXs ? 'bottom' : 'bottom-end'}
                    open={this.state.isOpen}
                    anchorEl={this.anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    // offset: [this.matchesXs ? -5 : 0, 9]
                                }
                            }
                        ]
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions type="fade" in={this.state.isOpen} {...TransitionProps}>
                            <Paper
                                sx={{
                                    // boxShadow: theme.customShadows.z1,
                                    width: '100%',
                                    minWidth: 285,
                                    maxWidth: 420,
                                    // [theme.breakpoints.down('md')]: {
                                    //     maxWidth: 285
                                    // }
                                }}
                            >
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MainCard
                                        title={i18next.t('Notifications')}
                                        elevation={0}
                                        border={false}
                                        content={false}
                                        secondary={
                                            <IconButton size="small" onClick={this.handleToggle}>
                                                <CloseIcon />
                                            </IconButton>
                                        }
                                    >
                                        <List
                                            component="nav"
                                            sx={{
                                                p: 0,
                                                '& .MuiListItemButton-root': {
                                                    py: 0.5,
                                                    '& .MuiAvatar-root': this.avatarSX,
                                                    '& .MuiListItemSecondaryAction-root': { ...this.actionSX, position: 'relative' }
                                                }
                                            }}
                                        >
                                            {this.props.notifications?.map(el => (<>
                                                <Divider />
                                                <NotificationItem data={el}>
                                                </NotificationItem></>))}
                                        </List>
                                    </MainCard>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </Box>

        );
    };
}

const mapStateToProps = state => {
    return {
        title: state.title?.text,
        notifications: Object.values(state.notifications)
    };
}


export default connect(mapStateToProps, { getAllNotifications })(Notification);

//   <IconButton color="inherit">
//   <Badge badgeContent={4} color="secondary">
//     <NotificationsIcon />
//   </Badge>
// </IconButton>