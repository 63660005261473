//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateBoardMember from './CreateBoardMember';
import EditBoardMember from './EditBoardMember';
import i18next from '../../language/i18n';
import { addDialogBoardMember, editDialogBoardMember, deleteBoardMember, getAllBoardMembers } from "../../actions/boardMemberAction";
import { getSelectBoxUsers } from '../../actions/userAction';
import { getSelectBoxBoardMemberTypes } from '../../actions/boardMemberTypeAction';
import DeleteButton from '../Share/Button/DeleteButton';


class TableBoardMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxUsers();
    this.props.getSelectBoxBoardMemberTypes();

    this.props.getAllBoardMembers();
  }

  userName = (props) => {
    return this.props.users.find(el => el.value == props.row.userID)?.text;
  }
  boardMemberTypeName = (props) => {
    return this.props.boardMemberTypes.find(el => el.value == props.row.boardMemberTypeID)?.text;
  }
  columns = [

    {
      field: 'UserID',
      headerName: i18next.t('User'),
      minWidth: 160,
      flex: 1,
      valueGetter: this.userName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.userName(cellParams1).localeCompare(this.userName(cellParams2))
    },

    {
      field: 'BoardMemberTypeID',
      headerName: i18next.t('BoardMemberType'),
      width: 160,
      valueGetter: this.boardMemberTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.boardMemberTypeName(cellParams1).localeCompare(this.boardMemberTypeName(cellParams2))
    },
    { field: 'isActive', headerName: 'IsActive', width: 150, type: 'boolean', headerName: i18next.t('IsActive') },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogBoardMember();
  }
  edit = (params) => {
    this.props.editDialogBoardMember(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.boardMembers}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateBoardMember></CreateBoardMember>
        <EditBoardMember></EditBoardMember>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteBoardMember(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { boardMembers: Object.values(state.boardMembers), users: Object.values(state.usersSelectBox), boardMemberTypes: Object.values(state.boardMemberTypesSelectBox) };
}

export default connect(mapStateToProps, { addDialogBoardMember, editDialogBoardMember, deleteBoardMember, getAllBoardMembers, getSelectBoxUsers, getSelectBoxBoardMemberTypes })(TableBoardMember);