//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogUNPartnerDetails } from '../../actions/uNPartnerDetailsAction';
import { EditSelectField, EditTextField, EditDatePickerField } from '../Share/EditComponents';

class FormUNPartnerDetails extends React.Component{
    handleClose = () => {
        this.props.closeDialogUNPartnerDetails();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='partnerID'
                                    component ={this.props.EditSelectField}
                                    label = 'Partner'
                                    dataSource={this.props.partnersSelectBox}
                                    />
                            </div>
            
                            <div>
                                <Field
                                    name='serializedObject'
                                    component={this.props.EditTextField}
                                    label='SerializedObject'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastUpdate'
                                    component={this.props.EditDatePickerField}
                                    label='LastUpdate'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.partnerID){
        errors.partnerID = 'Polje je obavezno!';
    }
    if(!formValues.serializedObject){
        errors.serializedObject = 'Polje je obavezno!';
    }
    if(!formValues.lastUpdate){
        errors.lastUpdate = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { partnersSelectBox: Object.values(state.partnersSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formUNPartnerDetails',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormUNPartnerDetails);

export default connect(mapStateToProps, { closeDialogUNPartnerDetails, EditSelectField, EditTextField, EditDatePickerField})(formWrapper);