import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Link from '@mui/material/Link';
import CustomToolbar from '../Share/Table/CustomToolbar';
import { addDialogOrder, editDialogOrder, deleteOrder, getAllOrders } from "../../actions/orderAction";
import { getSelectBoxOrderTypes } from "../../actions/orderTypeAction";
import i18next from '../../language/i18n';
import { dateFormat } from '../Share/Table/DateFormat';


class TableOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxOrderTypes();
    this.props.getAllOrders();
  }
  orderTypeName = (props) => {
    return this.props.orderTypes.find(el => el.value === props.row.orderTypeID)?.text;
  }
  columns = [
    { field: 'year', headerName: i18next.t('Year'), minWidth: 150, flex: 1 },
    {
      field: 'number',
      headerName: i18next.t('Number'),
      minWidth: 125,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Link href={"#/PostingAccount/" + params.row.year+"/"+params.row.number}
          >
            {params.row.number ?? i18next.t('Number')}
          </Link>
        </strong>
      ),
    },
    {
      field: 'OrderTypeID',
      headerName: i18next.t('OrderType'),
      width: 160,
      valueGetter: this.orderTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.orderTypeName(cellParams1).localeCompare(this.orderTypeName(cellParams2))
    },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'owes', headerName: i18next.t('Owes'), minWidth: 150, flex: 1 },
    { field: 'claim', headerName: i18next.t('Claim'), minWidth: 150, flex: 1 },
    { field: 'saldo', headerName: i18next.t('Difference'), minWidth: 150, flex: 1 },
    { field: 'locked', headerName: i18next.t('Locked'), minWidth: 150, flex: 1, type: 'boolean' },
    { field: 'cashFlow', headerName: i18next.t('CashFlow'), minWidth: 150, flex: 1, type: 'boolean' }
    
  ];
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.orders}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: Object.values(state.orders).sort((a, b) => {
      if (a.year !== b.year) {
          return b.year - a.year;
      }
      return b.number - a.number;
  }),
    orderTypes: Object.values(state.orderTypesSelectBox)
    
  };
}

export default connect(mapStateToProps, 
  { 
    addDialogOrder, 
    editDialogOrder, 
    deleteOrder, 
    getAllOrders,
    getSelectBoxOrderTypes
  })(TableOrder);