import api from "../api/myApi";
import { 
    CREATE_PAYMENTNOTETYPE,
    GET_ALL_PAYMENTNOTETYPE,
    GET_PAYMENTNOTETYPE,
    GET_ALL_SELECTBOX_PAYMENTNOTETYPE,
    DELETE_PAYMENTNOTETYPE,
    UPDATE_PAYMENTNOTETYPE,
    ADD_DIALOG_PAYMENTNOTETYPE,
    EDIT_DIALOG_PAYMENTNOTETYPE,
    CLOSE_DIALOG_PAYMENTNOTETYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createPaymentNoteType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PaymentNoteType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PAYMENTNOTETYPE));
    if(response.data?.succeeded){
        dispatch(reset('formPaymentNoteType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPaymentNoteTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentNoteType/GetAll');
    dispatch(responseHandler(response, GET_ALL_PAYMENTNOTETYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getPaymentNoteType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentNoteType/Get/' + id);
    dispatch(responseHandler(response, GET_PAYMENTNOTETYPE));
}

export const getSelectBoxPaymentNoteTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/PaymentNoteType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PAYMENTNOTETYPE));
}

export const updatePaymentNoteType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PaymentNoteType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PAYMENTNOTETYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePaymentNoteType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/PaymentNoteType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PAYMENTNOTETYPE));
}

export const addDialogPaymentNoteType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PAYMENTNOTETYPE,
        payload: null
    });
}

export const editDialogPaymentNoteType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PAYMENTNOTETYPE,
        payload: item
    });
}

export const closeDialogPaymentNoteType = () => async (dispatch) => {
    dispatch(reset('formPaymentNoteType'));
    dispatch({
        type: CLOSE_DIALOG_PAYMENTNOTETYPE,
        payload: undefined
    });
}