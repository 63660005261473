import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreatePenaltyInterest from './CreatePenaltyInterestSettings';
import EditPenaltyInterest from './EditPenaltyInterestSettings';
import i18next from '../../language/i18n';
import { getSelectBoxInterestPeriod } from '../../actions/interestPeriodAction';
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { addDialogPenaltyInterestSettings, editDialogPenaltyInterestSettings, deletePenaltyInterestSettings, getAllPenaltyInterestSettings } from "../../actions/penaltyInterestSettingsAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TablePenaltyInterestSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxInterestPeriod();
    this.props.getSelectBoxCompanyDetails();
    this.props.getAllPenaltyInterestSettings();
  }

  penaltyInterestPeriodName = (props) => {
    return this.props.penaltyInterestPeriod.find(el => el.value == props.row.penaltyInterestPeriodID)?.text;
  }

  companyDetailsName = (props) => {
    return this.props.companyDetails.find(el => el.value == props.row.companyDetailsID)?.text;
  }
  columns = [
    {
      field: 'companyDetailsID',
      headerName: i18next.t('Company'),
      width: 160,
      flex: 4,
      valueGetter: this.companyDetailsName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyDetailsName(cellParams1).localeCompare(this.companyDetailsName(cellParams2))
    },
    { field: 'interesPercent', headerName: i18next.t('InteresPercent'), minWidth: 150, flex: 1 },
    {
      field: 'penaltyInterestPeriodID',
      headerName: i18next.t('Period'),
      width: 160,
      flex: 4,
      valueGetter: this.penaltyInterestPeriodName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.penaltyInterestPeriodName(cellParams1).localeCompare(this.penaltyInterestPeriodName(cellParams2))
    },
    { field: 'daysGranted', headerName: i18next.t('DaysGranted'), minWidth: 150, flex: 1 },
    { field: 'minimumInterestToCharge', headerName: i18next.t('MinimumInterestToCharge'), minWidth: 150, flex: 1 },

    { field: 'validFrom', headerName: i18next.t('ValidFrom'), minWidth: 150, flex: 1, valueGetter: dateFormat },

    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogPenaltyInterestSettings();
  }
  edit = (params) => {
    this.props.editDialogPenaltyInterestSettings(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.penaltyInterests}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreatePenaltyInterest></CreatePenaltyInterest>
        <EditPenaltyInterest></EditPenaltyInterest>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deletePenaltyInterestSettings(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    penaltyInterests: Object.values(state.penaltyInterests),
    penaltyInterestPeriod: Object.values(state.penaltyInterestPeriodSelectBox),
    companyDetails: Object.values(state.companyDetailsSelectBox),

  };
}

export default connect(mapStateToProps, {
  addDialogPenaltyInterestSettings,
  editDialogPenaltyInterestSettings,
  deletePenaltyInterestSettings,
  getAllPenaltyInterestSettings,
  getSelectBoxInterestPeriod,
  getSelectBoxCompanyDetails,
  showLoadingDataInProgress
})(TablePenaltyInterestSettings);