//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createBoardMember } from '../../actions/boardMemberAction';
import FormBoardMember from './FormBoardMember';

class CreateBoardMember extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createBoardMember(formValues);
    }
    
    render(){
        return (
            <FormBoardMember 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormBoardMember>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BoardMemberDialog === undefined || state.dialog.BoardMemberDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BoardMemberDialog};
}


export default connect(mapStateToProps, { createBoardMember })(CreateBoardMember);
