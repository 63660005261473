import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton,  GridToolbarDensitySelector } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import i18next from '../../language/i18n';

class TableSMSToolbar extends React.Component{

//<GridToolbarExport />
    render(){
        return(
            <GridToolbarContainer>
                <Button color="primary" startIcon={<DoneIcon />} onClick={this.props.approve}>
                    {i18next.t('ApproveWithTheRequiredConditions')}
                </Button>
                <Button color="primary" startIcon={<DoneIcon />} onClick={this.props.approveNewConditions}>
                    {i18next.t('ApproveWithNewConditions')}
                </Button>
                {/* <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector /> */}
            </GridToolbarContainer>
        );
    }
}

export default TableSMSToolbar;