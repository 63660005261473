//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogCommissionSettings, updateCommissionSettings } from '../../actions/commissionSettingsAction';
import FormCommissionSettings from './FormCommissionSettings';

class EditCommissionSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCommissionSettings(formValues);
    }
    
    render(){
        return (
            <FormCommissionSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormCommissionSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CommissionSettingsDialog === undefined || state.dialog.CommissionSettingsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CommissionSettingsDialog};
}


export default connect(mapStateToProps, { closeDialogCommissionSettings, updateCommissionSettings })(EditCommissionSettings);
