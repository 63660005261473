//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm, getFormValues, change } from "redux-form";
import { closeDialogLoanApproval } from '../../actions/loanApprovalAction';
import { EditSelectField, EditCheckbox, EditTextField, EditDecimalNumberField, EditIntegerNumberField } from '../Share/EditComponents';
import Validation from '../../share/validation';

class FormLoanApproval extends React.Component {
    handleClose = () => {
        this.props.closeDialogLoanApproval();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            <div>
                                <Field
                                    name='amount'
                                    component={this.props.EditDecimalNumberField}
                                    label='Amount'
                                    onChange={(e) => {
                                        try {
                                            this.props.change('approvalCost', parseFloat(e.target.value) * this.props.formValues.approvalCostPercent / 100);
                                        } catch (e) { }
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanTermMonths'
                                    component={this.props.EditIntegerNumberField}
                                    label='LoanTermMonths'
                                />
                            </div>
                            <div>
                                <Field
                                    name='interestRate'
                                    component={this.props.EditDecimalNumberField}
                                    label='InterestRate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCostPercent'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCostPercent'
                                    onChange={(e) => {
                                        try {
                                            this.props.change('approvalCost', parseFloat(e.target.value) * this.props.formValues.amount / 100);
                                        } catch (e) { }
                                    }}
                                />
                            </div>
                            <div>
                                <Field
                                    name='approvalCost'
                                    component={this.props.EditDecimalNumberField}
                                    label='ApprovalCost'
                                    onChange={(e) => {
                                        try {
                                            this.props.change('approvalCostPercent', (parseFloat(e.target.value) / this.props.formValues.amount * 100).toFixed(2));
                                        } catch (e) { }
                                    }}
                                />
                            </div>
                            {/* <div>
                                <Field
                                    name='boardMemberID'
                                    component ={this.props.EditSelectField}
                                    label = 'BoardMember'
                                    dataSource={this.props.boardMembersSelectBox}
                                    >
                                </Field>
                            </div> */}
                            <div>
                                <Field
                                    name='note'
                                    component={this.props.EditTextField}
                                    label='Note'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if (!Validation.hasNonNegativeValue(formValues.amount)) {
        errors.amount = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.loanTermMonths)) {
        errors.loanTermMonths = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.interestRate)) {
        errors.interestRate = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.approvalCostPercent)) {
        errors.approvalCostPercent = 'Polje je obavezno!';
    }
    if (!Validation.hasNonNegativeValue(formValues.approvalCost)) {
        errors.approvalCost = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        loansSelectBox: Object.values(state.loansSelectBox),
        boardMembersSelectBox: Object.values(state.boardMembersSelectBox),
        formValues: getFormValues('formLoanApproval')(state),
    }
}
const formWrapper = reduxForm({
    form: 'formLoanApproval',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormLoanApproval);

export default connect(mapStateToProps, {
    closeDialogLoanApproval,
    EditSelectField,
    EditCheckbox,
    EditTextField,
    EditDecimalNumberField,
    EditIntegerNumberField,
    change
})(formWrapper);