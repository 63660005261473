import api from "../api/myApi";
import { 
    CREATE_COMPANYDETAILS,
    GET_ALL_COMPANYDETAILS,
    GET_COMPANYDETAILS,
    GET_ALL_SELECTBOX_COMPANYDETAILS,
    DELETE_COMPANYDETAILS,
    UPDATE_COMPANYDETAILS,
    ADD_DIALOG_COMPANYDETAILS,
    EDIT_DIALOG_COMPANYDETAILS,
    CLOSE_DIALOG_COMPANYDETAILS,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCompanyDetails = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/CompanyDetails/Create', formValues);    
    dispatch(responseHandler(response, CREATE_COMPANYDETAILS));
    if(response.data?.succeeded){
        dispatch(reset('formCompanyDetails'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllCompanyDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyDetails/GetAll');
    dispatch(responseHandler(response, GET_ALL_COMPANYDETAILS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getCompanyDetails = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyDetails/Get/' + id);
    dispatch(responseHandler(response, GET_COMPANYDETAILS));
}

export const getSelectBoxCompanyDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/CompanyDetails/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_COMPANYDETAILS));
}

export const updateCompanyDetails = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/CompanyDetails/Update', formValues);
    dispatch(responseHandler(response, UPDATE_COMPANYDETAILS));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteCompanyDetails = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CompanyDetails/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_COMPANYDETAILS));
}

export const addDialogCompanyDetails = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_COMPANYDETAILS,
        payload: null
    });
}

export const editDialogCompanyDetails = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_COMPANYDETAILS,
        payload: item
    });
}

export const closeDialogCompanyDetails = () => async (dispatch) => {
    dispatch(reset('formCompanyDetails'));
    dispatch({
        type: CLOSE_DIALOG_COMPANYDETAILS,
        payload: undefined
    });
}