import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogBankStatementImport } from '../../actions/bankStatementImportAction';
import { EditSelectField, EditDatePickerField, EditDecimalNumberField, EditTextField, EditCheckbox, EditIntegerNumberField } from '../Share/EditComponents';

class FormBankStatementImport extends React.Component{
    handleClose = () => {
        this.props.closeDialogBankStatementImport();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='cashierID'
                                    component ={this.props.EditSelectField}
                                    label = 'Cashier'
                                    dataSource={this.props.cashiersSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='date'
                                    component={this.props.EditDatePickerField}
                                    label='Date'
                                />
                            </div>
                            <div>
                                <Field
                                    name='payIn'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayIn'
                                />
                            </div>
                            <div>
                                <Field
                                    name='payOut'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayOut'
                                />
                            </div>
                            <div>
                                <Field
                                    name='partnerID'
                                    component ={this.props.EditSelectField}
                                    label = 'Partner'
                                    dataSource={this.props.partnersSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='bRVD'
                                    component={this.props.EditTextField}
                                    label='BRVD'
                                />
                            </div>
                            <div>
                                <Field
                                    name='dPayIn'
                                    component={this.props.EditDecimalNumberField}
                                    label='DPayIn'
                                />
                            </div>
                            <div>
                                <Field
                                    name='dPayOut'
                                    component={this.props.EditDecimalNumberField}
                                    label='DPayOut'
                                />
                            </div>
                            <div>
                                <Field
                                    name='account'
                                    component={this.props.EditTextField}
                                    label='Account'
                                />
                            </div>
                            <div>
                                <Field
                                    name='link'
                                    component={this.props.EditTextField}
                                    label='Link'
                                />
                            </div>
                            <div>
                                <Field
                                    name='bankAccount'
                                    component={this.props.EditTextField}
                                    label='BankAccount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='purposeOfPayment'
                                    component={this.props.EditTextField}
                                    label='PurposeOfPayment'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanID'
                                    component ={this.props.EditSelectField}
                                    label = 'Loan'
                                    dataSource={this.props.loansSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='branchID'
                                    component ={this.props.EditSelectField}
                                    label = 'Branch'
                                    dataSource={this.props.branchesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='loanAppID'
                                    component ={this.props.EditSelectField}
                                    label = 'LoanApp'
                                    dataSource={this.props.loanAppsSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='revisionID'
                                    component ={this.props.EditSelectField}
                                    label = 'Revision'
                                    dataSource={this.props.revisionsSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='isApproved'
                                    component={this.props.EditCheckbox}
                                    label='IsApproved'
                                />
                            </div>
                            <div>
                                <Field
                                    name='principal'
                                    component={this.props.EditTextField}
                                    label='Principal'
                                />
                            </div>
                            <div>
                                <Field
                                    name='order'
                                    component={this.props.EditTextField}
                                    label='Order'
                                />
                            </div>
                            <div>
                                <Field
                                    name='transactionTypeEnum'
                                    component={this.props.EditIntegerNumberField}
                                    label='TransactionTypeEnum'
                                />
                            </div>
                            <DialogActions>
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.companyID){
        errors.companyID = 'Polje je obavezno!';
    }
    if(!formValues.cashierID){
        errors.cashierID = 'Polje je obavezno!';
    }
    if(!formValues.date){
        errors.date = 'Polje je obavezno!';
    }
    if(!formValues.payIn){
        errors.payIn = 'Polje je obavezno!';
    }
    if(!formValues.payOut){
        errors.payOut = 'Polje je obavezno!';
    }
    if(!formValues.partnerID){
        errors.partnerID = 'Polje je obavezno!';
    }
    if(!formValues.bRVD){
        errors.bRVD = 'Polje je obavezno!';
    }
    if(!formValues.exchangeRate){
        errors.exchangeRate = 'Polje je obavezno!';
    }
    if(!formValues.dPayIn){
        errors.dPayIn = 'Polje je obavezno!';
    }
    if(!formValues.dPayOut){
        errors.dPayOut = 'Polje je obavezno!';
    }
    if(!formValues.account){
        errors.account = 'Polje je obavezno!';
    }
    if(!formValues.link){
        errors.link = 'Polje je obavezno!';
    }
    if(!formValues.bankAccount){
        errors.bankAccount = 'Polje je obavezno!';
    }
    if(!formValues.purposeOfPayment){
        errors.purposeOfPayment = 'Polje je obavezno!';
    }
    if(!formValues.isApproved){
        errors.isApproved = 'Polje je obavezno!';
    }
    if(!formValues.principal){
        errors.principal = 'Polje je obavezno!';
    }
    if(!formValues.order){
        errors.order = 'Polje je obavezno!';
    }
    if(!formValues.transactionTypeEnum){
        errors.transactionTypeEnum = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox), cashiersSelectBox: Object.values(state.cashiersSelectBox), partnersSelectBox: Object.values(state.partnersSelectBox), loansSelectBox: Object.values(state.loansSelectBox), branchesSelectBox: Object.values(state.branchesSelectBox), loanAppsSelectBox: Object.values(state.loanAppsSelectBox), revisionsSelectBox: Object.values(state.revisionsSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formBankStatementImport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormBankStatementImport);

export default connect(mapStateToProps, { closeDialogBankStatementImport, EditSelectField, EditDatePickerField, EditDecimalNumberField, EditTextField, EditCheckbox, EditIntegerNumberField})(formWrapper);