import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogBranch, updateBranch } from '../../actions/branchAction';
import FormBranch from './FormBranch';

class EditBranch extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBranch(formValues);
    }
    
    render(){
        return (
            <FormBranch 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormBranch>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BranchDialog === undefined || state.dialog.BranchDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BranchDialog};
}


export default connect(mapStateToProps, { closeDialogBranch, updateBranch })(EditBranch);
