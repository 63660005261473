import { 
    CREATE_BLTURNOVER,
    GET_ALL_BLTURNOVER,
    GET_BLTURNOVER,
    DELETE_BLTURNOVER,
    UPDATE_BLTURNOVER,
    CLEAR_BLTURNOVER,
    GET_LOAN
} from '../../actions/types';

const bLTurnoverReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_BLTURNOVER:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_BLTURNOVER:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_BLTURNOVER:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_BLTURNOVER: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case GET_LOAN:
                let result3 = { };
                for(let i=0; i<action.payload.blTurnover.length;i++) {result3[action.payload.blTurnover[i].id]=action.payload.blTurnover[i];}
                return result3;
        case UPDATE_BLTURNOVER:
            return { ...state, [action.payload.id]: action.payload };
        case CLEAR_BLTURNOVER:
            return {};
        default:
            return state;
    }
}

export default bLTurnoverReducer;