import api from "../api/myApi";
import { 
    GET_ALL_PAYWARNING,
    GET_PAYWARNING,
    GET_ALL_SELECTBOX_PAYWARNING,
    DELETE_PAYWARNING,
    UPDATE_PAYWARNING,
    ADD_DIALOG_PAYWARNING,
    EDIT_DIALOG_PAYWARNING,
    CLOSE_DIALOG_PAYWARNING,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import DateProvider from '../share/dateProvider';

export const createPayWarning = (formValues) => async (dispatch) => {
    /*const response = await api.post('/api/v1/PayWarning/Create', formValues);    
    dispatch(responseHandler(response, CREATE_PAYWARNING));
    if(response.data?.succeeded){
        dispatch(reset('formPayWarning'));
    }*/    
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Warning/CreateWarning', formValues);    
    dispatch(responseHandler(response, GET_ALL_PAYWARNING));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllPayWarnings = (date) => async (dispatch) => {
    const response = await api.get(`/api/v1/PayWarning/GetAll?date=${DateProvider.GetDateJSON(date)}`); 
    dispatch(responseHandler(response, GET_ALL_PAYWARNING));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const postingPayWarning = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Warning/PostingWarning', formValues);    
    dispatch(responseHandler(response, 'success', 'OK'));    
}

export const getPayWarning = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/PayWarning/Get/' + id);
    dispatch(responseHandler(response, GET_PAYWARNING));
}

export const getSelectBoxPayWarnings = () => async (dispatch) => {
    const response = await api.get('/api/v1/PayWarning/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_PAYWARNING));
}

export const updatePayWarning = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/PayWarning/Update', formValues);
    dispatch(responseHandler(response, UPDATE_PAYWARNING));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deletePayWarning = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/PayWarning/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_PAYWARNING));
}

export const addDialogPayWarning = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_PAYWARNING,
        payload: null
    });
}

export const editDialogPayWarning = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_PAYWARNING,
        payload: item
    });
}

export const closeDialogPayWarning = () => async (dispatch) => {
    dispatch(reset('formPayWarning'));
    dispatch({
        type: CLOSE_DIALOG_PAYWARNING,
        payload: undefined
    });
}