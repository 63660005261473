import api from "../api/myApi";
import { 
    CREATE_GENDER,
    GET_ALL_GENDER,
    GET_GENDER,
    GET_ALL_SELECTBOX_GENDER,
    DELETE_GENDER,
    UPDATE_GENDER,
    ADD_DIALOG_GENDER,
    EDIT_DIALOG_GENDER,
    CLOSE_DIALOG_GENDER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createGender = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Gender/Create', formValues);    
    dispatch(responseHandler(response, CREATE_GENDER));
    if(response.data?.succeeded){
        dispatch(reset('formGender'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllGenders = () => async (dispatch) => {
    const response = await api.get('/api/v1/Gender/GetAll');
    dispatch(responseHandler(response, GET_ALL_GENDER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getGender = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Gender/Get/' + id);
    dispatch(responseHandler(response, GET_GENDER));
}

export const getSelectBoxGenders = () => async (dispatch) => {
    const response = await api.get('/api/v1/Gender/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_GENDER));
}

export const updateGender = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Gender/Update', formValues);
    dispatch(responseHandler(response, UPDATE_GENDER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteGender = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Gender/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_GENDER));
}

export const addDialogGender = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_GENDER,
        payload: null
    });
}

export const editDialogGender = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_GENDER,
        payload: item
    });
}

export const closeDialogGender = () => async (dispatch) => {
    dispatch(reset('formGender'));
    dispatch({
        type: CLOSE_DIALOG_GENDER,
        payload: undefined
    });
}