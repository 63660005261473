import axios, { AxiosRequestTransformer } from "axios"
import { logout } from '../actions/loginAction';
import DateProvider from '../share/dateProvider';

const dateTransformer = data => {
  
  if (data instanceof Date) {
    // do your specific formatting here
    //return data.toLocaleString()
    return DateProvider.GetDateJSON(data);
  }
  if (Array.isArray(data)) {
    return data.map(val => dateTransformer(val))
  }
  if (typeof data === "object" && data !== null && !(data instanceof FormData)) {
    return Object.fromEntries(Object.entries(data).map(([ key, val ]) =>
      [ key, dateTransformer(val) ]))
  }
  return data;
}
/*
export default axios.create({
    baseURL:'http://localhost:57712',
    headers: {'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ1c2VyIiwianRpIjoiMzE0NTkxMzctMDA5NC00OGMyLWIzYTktNzJmNGMwMTc3MDlmIiwiZW1haWwiOiJ1c2VyQG1vam9zb2Z0LmRldiIsInVpZCI6IjRkNTE3NDc0LTNiNjktNDA0OC04ZmZjLTgxMzI2NTEwMjU3NiIsImlwIjoiMTkyLjE2OC4xOTcuMSIsInJvbGVzIjoiQmFzaWMiLCJleHAiOjE2MzQxODEwMDYsImlzcyI6IkNvcmVJZGVudGl0eSIsImF1ZCI6IkNvcmVJZGVudGl0eVVzZXIifQ.HBYlZpnDYRyBx2zbjEVT9aZ2wsqBkM4EAILLa8wtMJY'}
});
*/
class BasicService {
    constructor(url) {
      //http://localhost/mkdmojo //http://localhost:57712 //http://10.147.18.205/mkdmojo
      
      
      
      
      //this.baseURL = 'http://localhost:57712';  //dev
      //this.baseURL = 'http://10.147.18.205/mkdmojo'; //test
      //this.baseURL = 'http://185.217.126.148/mkdmojo'; //prod
      this.baseURL = 'https://mkdmojo.com/mkdmojo';
      const options = {
        baseURL: this.baseURL,
        transformRequest: [ dateTransformer ].concat(axios.defaults.transformRequest)
        // any options that you would want for all axios requests,
        // like (proxy, etc...)
      };
      
      this.fetcher = axios.create(options);
  
      // Your default config
      this.fetcher.interceptors.request.use(
        config => {
          //const { origin } = new URL(this.baseURL);
          //const allowedOrigins = [this.baseURL];
          const token = localStorage.getItem('token');
          //if (allowedOrigins.includes(origin)) {
            config.headers.authorization = `Bearer ${token}`;
          //}
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

      this.fetcher.interceptors.response.use(
        config => {
          if(config.data?.Status === 401){
            //console.error(401);
            //alert('Problem sa permisijama: 401!');
            //localStorage.clear();
            //window.location.reload(true);
          }
          return config;
        },
        (error) => {
          if(error.message === 'Network Error'){
            console.error('Network error');
            //alert('Problem sa permisijama!');
            //localStorage.clear();
            //window.location.reload(true);
          }
          return Promise.reject(error);
        }
      );
  }
}

const baseService = new BasicService();
export default baseService.fetcher;