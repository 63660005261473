import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogLoanQualityStatus } from '../../actions/loanQualityStatusAction';
import { EditSelectField, EditDecimalNumberField, EditTextField, EditDatePickerField, EditCheckbox } from '../Share/EditComponents';

class FormLoanQualityStatus extends React.Component{
    handleClose = () => {
        this.props.closeDialogLoanQualityStatus();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='loanID'
                                    component ={this.props.EditSelectField}
                                    label = 'Loan'
                                    dataSource={this.props.loansSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='remainingPrincipal'
                                    component={this.props.EditDecimalNumberField}
                                    label='RemainingPrincipal'
                                />
                            </div>
                            <div>
                                <Field
                                    name='principalDue'
                                    component={this.props.EditDecimalNumberField}
                                    label='PrincipalDue'
                                />
                            </div>
                            <div>
                                <Field
                                    name='interestDue'
                                    component={this.props.EditDecimalNumberField}
                                    label='InterestDue'
                                />
                            </div>
                            <div>
                                <Field
                                    name='loanQualityID'
                                    component ={this.props.EditSelectField}
                                    label = 'LoanQuality'
                                    dataSource={this.props.loanQualitiesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='previousClasification'
                                    component={this.props.EditTextField}
                                    label='PreviousClasification'
                                />
                            </div>
                            <div>
                                <Field
                                    name='currentClasification'
                                    component={this.props.EditTextField}
                                    label='CurrentClasification'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastClasificationChangeDate'
                                    component={this.props.EditDatePickerField}
                                    label='LastClasificationChangeDate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='active'
                                    component={this.props.EditCheckbox}
                                    label='Active'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastGarantorRepayingDate'
                                    component={this.props.EditDatePickerField}
                                    label='LastGarantorRepayingDate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='cRKDate'
                                    component={this.props.EditDatePickerField}
                                    label='CRKDate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='exportedToCRK'
                                    component={this.props.EditCheckbox}
                                    label='ExportedToCRK'
                                />
                            </div>
                            <div>
                                <Field
                                    name='remainingPrincipalChange'
                                    component={this.props.EditDecimalNumberField}
                                    label='RemainingPrincipalChange'
                                />
                            </div>
                            <div>
                                <Field
                                    name='interestDueChange'
                                    component={this.props.EditDecimalNumberField}
                                    label='InterestDueChange'
                                />
                            </div>
                            <div>
                                <Field
                                    name='penalties'
                                    component={this.props.EditDecimalNumberField}
                                    label='Penalties'
                                />
                            </div>
                            <div>
                                <Field
                                    name='warningCosts'
                                    component={this.props.EditDecimalNumberField}
                                    label='WarningCosts'
                                />
                            </div>
                            <div>
                                <Field
                                    name='isCurrentVersion'
                                    component={this.props.EditCheckbox}
                                    label='IsCurrentVersion'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.loanID){
        errors.loanID = 'Polje je obavezno!';
    }
    if(!formValues.remainingPrincipal){
        errors.remainingPrincipal = 'Polje je obavezno!';
    }
    if(!formValues.principalDue){
        errors.principalDue = 'Polje je obavezno!';
    }
    if(!formValues.interestDue){
        errors.interestDue = 'Polje je obavezno!';
    }
    if(!formValues.loanQualityID){
        errors.loanQualityID = 'Polje je obavezno!';
    }
    if(!formValues.previousClasification){
        errors.previousClasification = 'Polje je obavezno!';
    }
    if(!formValues.currentClasification){
        errors.currentClasification = 'Polje je obavezno!';
    }
    if(!formValues.active){
        errors.active = 'Polje je obavezno!';
    }
    if(!formValues.exportedToCRK){
        errors.exportedToCRK = 'Polje je obavezno!';
    }
    if(!formValues.remainingPrincipalChange){
        errors.remainingPrincipalChange = 'Polje je obavezno!';
    }
    if(!formValues.interestDueChange){
        errors.interestDueChange = 'Polje je obavezno!';
    }
    if(!formValues.penalties){
        errors.penalties = 'Polje je obavezno!';
    }
    if(!formValues.warningCosts){
        errors.warningCosts = 'Polje je obavezno!';
    }
    if(!formValues.isCurrentVersion){
        errors.isCurrentVersion = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { loansSelectBox: Object.values(state.loansSelectBox), loanQualitiesSelectBox: Object.values(state.loanQualitiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formLoanQualityStatus',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormLoanQualityStatus);

export default connect(mapStateToProps, { closeDialogLoanQualityStatus, EditSelectField, EditDecimalNumberField, EditTextField, EditDatePickerField, EditCheckbox})(formWrapper);