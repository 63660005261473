import React from "react";
import Button from '@mui/material/Button';
import ConfirmDialog from '../../components/Share/ConfirmDialog';
import TableLoan from '../../components/Loan/TableLoan';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { returnLoanToDraft, refuseLoan, sendLoanToRealization } from '../../actions/loanAction';
import { showError } from '../../actions/messageAction';

class ApprovedLoanPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmDialog: false,
      loanData: null,
      message: null,
      action: null
    }
  }

  returnToDraft = (params) => {
    this.setState({
      isOpenConfirmDialog: true,
      loanData: params.row,
      action: this.returnToDraftAction,
      message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite vratiti u nacrt?`
    });
  }
  returnToDraftAction = () => {
    this.props.returnLoanToDraft(this.state.loanData.id);
  }
  refuse = (params) => {
    this.setState({
      isOpenConfirmDialog: true,
      loanData: params.row,
      action: this.refuseAction,
      message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite odbiti?`
    });
  }
  refuseAction = () => {
    this.props.refuseLoan(this.state.loanData.id);
  }

  sendLoanToRealization = (params) => {
    this.setState({
      isOpenConfirmDialog: true,
      loanData: params.row,
      action: this.sendLoanToRealizationAction,
      message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite poslati na realizaciju?`
    });
  }
  sendLoanToRealizationAction = () => {
    this.props.sendLoanToRealization(this.state.loanData.id);
  }

  columns = () => {
    return [{
      field: 'returnToDraft',
      headerName: ' ',
      minWidth:150,
      flex: 1,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.returnToDraft(params)}
          >
            {i18next.t('ReturnToDraft')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'refuse',
      headerName: ' ',
      hide: !this.hasPermission(),
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.refuse(params)}
          >
            {i18next.t('Refuse')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'sendLoanToRealization',
      headerName: ' ',
      minWidth:200,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.sendLoanToRealization(params)}
          >
            {i18next.t('SendToRealization')}
          </Button>
        </strong>
      ),
    }
    ];
  }
  hasPermission = () => {
    let roles = ['Admin', 'ClientAdmin', 'CompanyAdmin'];
    for (let i = 0; i < roles?.length; i++) {
      if (this.props.authUser.roles?.indexOf(roles[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  render() {
    return (
      <>
        <TableLoan
          Status={3}
          columns={this.columns()}
        />
        <ConfirmDialog
          IsOpen={this.state.isOpenConfirmDialog}
          close={() => this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null })}
          confirm={() => {
            this.state.action();
            this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null });
          }}
          message={this.state.message}
        />
      </>);
  }
}

const mapStateToProps = state => {
  return {
    authUser: state.authUser
  }
}

export default connect(mapStateToProps, {
  returnLoanToDraft,
  refuseLoan,
  sendLoanToRealization,
  showError
})(ApprovedLoanPage);