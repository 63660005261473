//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCRKResponse from './CreateCRKResponse';
import EditCRKResponse from './EditCRKResponse';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogCRKResponse, editDialogCRKResponse, deleteCRKResponse, getAllCRKResponses } from "../../actions/cRKResponseAction";
import { getSelectBoxCRKRequests } from '../../actions/cRKRequestAction';
import { getCRK } from '../../actions/reportAction';
import { dateFormat } from '../Share/Table/DateFormat';



class TableCRKResponseDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
  }
  cRKRequestTypeName = (props) => {
    return this.props.cRKRequestTypes.find(el => el.value == props.row.crkRequestTypeID)?.text;
  }
  getPDF = () => {
    this.props.getCRK(this.props.crkRequestID);
  }
  columns = [
    { field: 'loanPartnerType', headerName: i18next.t('LoanPartnerType'), minWidth: 50 },
    {
      field: 'CRKRequestTypeID',
      headerName: i18next.t('CRKRequestType'),
      width: 160,
      valueGetter: this.cRKRequestTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cRKRequestTypeName(cellParams1).localeCompare(this.cRKRequestTypeName(cellParams2))
    },
    { field: 'loanType', headerName: i18next.t('LoanType'), minWidth: 50 },
    { field: 'openingDate', headerName: i18next.t('OpeningDate'), minWidth: 50 },
    { field: 'loanTermMonths', headerName: i18next.t('LoanTermMonths'), minWidth: 50 },
    { field: 'actualClosingDate', headerName: i18next.t('ActualClosingDate'), minWidth: 50 },
    { field: 'requestedAmount', headerName: i18next.t('RequestedAmount'), minWidth: 50 },
    { field: 'remainingPrincipal', headerName: i18next.t('RemainingPrincipal'), minWidth: 50 },
    { field: 'unusedLimit', headerName: i18next.t('UnusedLimit'), minWidth: 50 },
    { field: 'principalDue', headerName: i18next.t('PrincipalDue'), minWidth: 50 },
    { field: 'interestDue', headerName: i18next.t('InterestDue'), minWidth: 50 },
    { field: 'latePaymentDays', headerName: i18next.t('LatePaymentDays'), minWidth: 50 },
    { field: 'currentClasification', headerName: i18next.t('CurrentClasification'), minWidth: 50 },
    { field: 'previousClasification', headerName: i18next.t('PreviousClasification'), minWidth: 50 },
    { field: 'currentIFRSClasification', headerName: i18next.t('CurrentIFRSClasification'), minWidth: 50 },
    { field: 'previousIFRSClasification', headerName: i18next.t('PreviousIFRSClasification'), minWidth: 50 },
    { field: 'updateTS', headerName: i18next.t('LastUpdate'), minWidth: 150 },

  ];

  render() {
    return (
      <div>
        <Dialog
          maxWidth={'xl'}
          fullWidth={true}
          open={this.props.isOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <div style={{ height: 700, width: '100%' }}>
              <DataGrid
                rows={this.props.data}
                columns={this.columns}
                components={{
                  Toolbar: CustomToolbar
                }}
                checkboxSelection
                disableSelectionOnClick
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.getPDF} color="primary" variant="outlined">
              {i18next.t('PDF')}
            </Button>
            <Button onClick={this.props.onClose} color="secondary" variant="outlined">
              {i18next.t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cRKResponses: Object.values(state.cRKResponses),
    cRKRequestTypes: Object.values(state.cRKRequestTypesSelectBox),
    cRKRequests: Object.values(state.cRKRequestsSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogCRKResponse,
    editDialogCRKResponse,
    deleteCRKResponse,
    getAllCRKResponses,
    getSelectBoxCRKRequests,
    getCRK
  })(TableCRKResponseDialog);