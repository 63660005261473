//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogBankStatementImportSplit } from '../../actions/bankStatementImportSplitAction';
import { EditSelectField, EditDecimalNumberField } from '../Share/EditComponents';

class FormBankStatementImportSplit extends React.Component{
    handleClose = () => {
        this.props.closeDialogBankStatementImportSplit();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            {/* <div>
                                <Field
                                    name='bankStatementImportID'
                                    component ={this.props.EditSelectField}
                                    label = 'BankStatementImport'
                                    dataSource={this.props.bankStatementImportsSelectBox}
                                    >
                                </Field>
                            </div> */}
            
                            <div>
                                <Field
                                    name='loanID'
                                    component ={this.props.EditSelectField}
                                    label = 'Loan'
                                    dataSource={this.props.loansSelectBox?.filter(el => el.data.loanStatusID >= 5)}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='payIn'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayIn'
                                />
                            </div> <div>
                                <Field
                                    name='payOut'
                                    component={this.props.EditDecimalNumberField}
                                    label='PayOut'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.bankStatementImportID){
        errors.bankStatementImportID = 'Polje je obavezno!';
    }
    if(!formValues.loanID){
        errors.loanID = 'Polje je obavezno!';
    }
    if(!formValues.payIn){
        errors.payIn = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        //bankStatementImportsSelectBox: Object.values(state.bankStatementImportsSelectBox), 
        loansSelectBox: Object.values(state.loansSelectBox)
     }
}
const formWrapper = reduxForm({
    form: 'formBankStatementImportSplit',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormBankStatementImportSplit);

export default connect(mapStateToProps, { closeDialogBankStatementImportSplit, EditSelectField, EditDecimalNumberField})(formWrapper);