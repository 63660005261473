//Mojo-AG
import { 
    CREATE_ORDERTYPE,
    GET_ALL_ORDERTYPE,
    GET_ORDERTYPE,
    DELETE_ORDERTYPE,
    UPDATE_ORDERTYPE
} from '../../actions/types';

const orderTypeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_ORDERTYPE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_ORDERTYPE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_ORDERTYPE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_ORDERTYPE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_ORDERTYPE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default orderTypeReducer;