//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogLoanApprovalSettings, updateLoanApprovalSettings } from '../../actions/loanApprovalSettingsAction';
import FormLoanApprovalSettings from './FormLoanApprovalSettings';

class EditLoanApprovalSettings extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateLoanApprovalSettings(formValues);
    }
    
    render(){
        return (
            <FormLoanApprovalSettings 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormLoanApprovalSettings>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanApprovalSettingsDialog === undefined || state.dialog.LoanApprovalSettingsDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.LoanApprovalSettingsDialog};
}


export default connect(mapStateToProps, { closeDialogLoanApprovalSettings, updateLoanApprovalSettings })(EditLoanApprovalSettings);
