import api from "../api/myApi";
import { 
    CREATE_LEGALSERVICE_EXECUTIONTYPE,
    GET_ALL_LEGALSERVICE_EXECUTIONTYPE,
    GET_LEGALSERVICE_EXECUTIONTYPE,
    GET_ALL_SELECTBOX_LEGALSERVICE_EXECUTIONTYPE,
    DELETE_LEGALSERVICE_EXECUTIONTYPE,
    UPDATE_LEGALSERVICE_EXECUTIONTYPE,
    ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
    EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
    CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLegalService_ExecutionType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_ExecutionType/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LEGALSERVICE_EXECUTIONTYPE));
    if(response.data?.succeeded){
        dispatch(reset('formLegalService_ExecutionType'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllLegalService_ExecutionTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_ExecutionType/GetAll');
    dispatch(responseHandler(response, GET_ALL_LEGALSERVICE_EXECUTIONTYPE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getLegalService_ExecutionType = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_ExecutionType/Get/' + id);
    dispatch(responseHandler(response, GET_LEGALSERVICE_EXECUTIONTYPE));
}

export const getSelectBoxLegalService_ExecutionTypes = () => async (dispatch) => {
    const response = await api.get('/api/v1/LegalService_ExecutionType/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LEGALSERVICE_EXECUTIONTYPE));
}

export const updateLegalService_ExecutionType = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/LegalService_ExecutionType/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LEGALSERVICE_EXECUTIONTYPE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteLegalService_ExecutionType = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LegalService_ExecutionType/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LEGALSERVICE_EXECUTIONTYPE));
}

export const addDialogLegalService_ExecutionType = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
        payload: null
    });
}

export const editDialogLegalService_ExecutionType = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
        payload: item
    });
}

export const closeDialogLegalService_ExecutionType = () => async (dispatch) => {
    dispatch(reset('formLegalService_ExecutionType'));
    dispatch({
        type: CLOSE_DIALOG_LEGALSERVICE_EXECUTIONTYPE,
        payload: undefined
    });
}