import api from "../api/myApi";
import { 
    CREATE_CASHBALANCE,
    GET_ALL_CASHBALANCE,
    GET_CASHBALANCE,
    GET_ALL_SELECTBOX_CASHBALANCE,
    DELETE_CASHBALANCE,
    UPDATE_CASHBALANCE,
    ADD_DIALOG_CASHBALANCE,
    EDIT_DIALOG_CASHBALANCE,
    CLOSE_DIALOG_CASHBALANCE,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCashBalance = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/CashBalance/Create', formValues);    
    dispatch(responseHandler(response, CREATE_CASHBALANCE));
    if(response.data?.succeeded){
        dispatch(reset('formCashBalance'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllCashBalances = () => async (dispatch) => {
    const response = await api.get('/api/v1/CashBalance/GetAll');
    dispatch(responseHandler(response, GET_ALL_CASHBALANCE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getAllCashBalancesByYearAndCashier = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/CashBalance/GetAllByYearAndCashier', formValues);
    dispatch(responseHandler(response, GET_ALL_CASHBALANCE));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getCashBalance = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/CashBalance/Get/' + id);
    dispatch(responseHandler(response, GET_CASHBALANCE));
}

export const getSelectBoxCashBalances = () => async (dispatch) => {
    const response = await api.get('/api/v1/CashBalance/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_CASHBALANCE));
}

export const updateCashBalance = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/CashBalance/Update', formValues);
    dispatch(responseHandler(response, UPDATE_CASHBALANCE));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteCashBalance = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/CashBalance/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_CASHBALANCE));
}

export const addDialogCashBalance = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_CASHBALANCE,
        payload: null
    });
}

export const editDialogCashBalance = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_CASHBALANCE,
        payload: item
    });
}

export const closeDialogCashBalance = () => async (dispatch) => {
    dispatch(reset('formCashBalance'));
    dispatch({
        type: CLOSE_DIALOG_CASHBALANCE,
        payload: undefined
    });
}

export const importCashBalance = (data) => async (dispatch) => {
    const response = await api.post('/api/v1/CashBalance/Import', data);
    dispatch(responseHandler(response, UPDATE_CASHBALANCE));
}

export const postingCashBalance = (data) => async (dispatch) => {
    const response = await api.post('/api/v1/CashBalance/Posting', data);
    dispatch(responseHandler(response, UPDATE_CASHBALANCE));
}