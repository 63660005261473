//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_BANKSTATEMENTIMPORTSPLIT,
    GET_ALL_BANKSTATEMENTIMPORTSPLIT,
    GET_BANKSTATEMENTIMPORTSPLIT,
    GET_ALL_SELECTBOX_BANKSTATEMENTIMPORTSPLIT,
    DELETE_BANKSTATEMENTIMPORTSPLIT,
    UPDATE_BANKSTATEMENTIMPORTSPLIT,
    ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT,
    EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT,
    CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBankStatementImportSplit = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImportSplit/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BANKSTATEMENTIMPORTSPLIT));
    if(response.data?.succeeded){
        dispatch(reset('formBankStatementImportSplit'));
    }
}

export const getAllBankStatementImportSplits = (bankStatementImportID) => async (dispatch) => {
    const response = await api.get('/api/v1/BankStatementImportSplit/GetAll?bankStatementImportID=' + bankStatementImportID);
    dispatch(responseHandler(response, GET_ALL_BANKSTATEMENTIMPORTSPLIT));
}

export const getBankStatementImportSplit = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/BankStatementImportSplit/Get/' + id);
    dispatch(responseHandler(response, GET_BANKSTATEMENTIMPORTSPLIT));
}

export const getSelectBoxBankStatementImportSplits = () => async (dispatch) => {
    const response = await api.get('/api/v1/BankStatementImportSplit/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BANKSTATEMENTIMPORTSPLIT));
}

export const updateBankStatementImportSplit = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImportSplit/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BANKSTATEMENTIMPORTSPLIT));
}

export const deleteBankStatementImportSplit = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImportSplit/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BANKSTATEMENTIMPORTSPLIT));
}

export const addDialogBankStatementImportSplit = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BANKSTATEMENTIMPORTSPLIT,
        payload: null
    });
}

export const editDialogBankStatementImportSplit = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BANKSTATEMENTIMPORTSPLIT,
        payload: item
    });
}

export const closeDialogBankStatementImportSplit = () => async (dispatch) => {
    dispatch(reset('formBankStatementImportSplit'));
    dispatch({
        type: CLOSE_DIALOG_BANKSTATEMENTIMPORTSPLIT,
        payload: undefined
    });
}