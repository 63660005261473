import React from "react";
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import i18next from '../../language/i18n';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import ComponentInLine from "../../components/Share/ComponentInLine";
import { EditIntegerNumberField2, EditDatePickerField2, EditCheckbox2 } from '../../components/Share/EditComponents';
import TableJournal from '../../components/Journal/TableJournal';
import { getAllJournalsByOrder } from "../../actions/journalAction";
import { maxNumberByYear, createOrder, copyOrder, cancelOrder, deleteOrder, updateOrder } from "../../actions/orderAction";
import { getSelectBoxOrderTypes } from "../../actions/orderTypeAction";
import { EditSelectFieldClear } from '../../components/Share/EditComponents';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class PostingAccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear(),
            number: 1,
            max: 1,
            date: new Date(),
            locked: false,
            cashFlow: false,
            isFromParam: false,
            orderTypeID: 0,
            IsOpen: false,
            dialogOrderTypeID: 0
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.order !== undefined && this.props?.order !== null && prevProps.order?.id !== this.props?.order?.id) {
            this.setState({
                date: this.props.order.date,
                locked: this.props.order.locked,
                cashFlow: this.props.order.cashFlow,
                orderTypeID: this.props.order.orderTypeID
            });
        }

        if (this.props.match.params.year != prevProps.match.params.year || this.props.match.params.number != prevProps.match.params.number) {
            this.setState({
                year: this.props.match.params.year,
                number: this.props.match.params.number,
                isFromParam: true,
                orderTypeID: this.props.order.orderTypeID
            });
        }
    }
    goToStart = () => {
        this.changeNumber(1);
    }
    goToEnd = () => {
        this.changeNumber(this.state.max);
    }
    decreaseNumber = () => {
        if (this.state.number > 1) {
            this.changeNumber(this.state.number - 1);
        }
    }
    increaseNumber = () => {
        if (this.state.number < this.state.max) {
            this.changeNumber(this.state.number + 1);
        }
    }
    componentDidMount = () => {
        this.props.getSelectBoxOrderTypes();
        if (this.props.match.params.year != undefined) {
            this.setState({
                year: this.props.match.params.year,
                number: this.props.match.params.number,
                isFromParam: true
            }, () => { this.changeYear(this.state.year) });
        }
        else {
            this.changeYear(this.state.year);
        }
    }
    changeYear = (newValue) => {
        this.setState({ year: newValue }, () => {
            this.props.maxNumberByYear(newValue).then(res => {
                if (!isNaN(res)) {
                    this.setState({ max: res, number: this.state.isFromParam ? this.state.number : res, isFromParam: false }, this.refreshJournalData);
                }
            });
        });
    }
    changeNumber = (newValue) => {
        this.setState({ number: newValue, isFromParam: false }, this.refreshJournalData);
    }
    refreshJournalData = () => {
        this.props.getAllJournalsByOrder({
            number: this.state.number,
            year: this.state.year
        });
    }
    createOrder = () => {
        this.props.createOrder(this.state.year, this.state.dialogOrderTypeID).then(res => {
            if (!isNaN(res) && res != -1) {
                this.setState({ max: res, number: res, isFromParam: false }, this.refreshJournalData);
            }
        });
    }
    copyOrder = () => {
        this.props.copyOrder(this.state.year, this.state.number).then(res => {
            this.setState({ max: res.number, number: res.number, year: res.year, isFromParam: false }, this.refreshJournalData);
        });
    }
    cancelOrder = () => {
        this.props.cancelOrder(this.state.year, this.state.number).then(res => {
            this.setState({ max: res.number, number: res.number, year: res.year, isFromParam: false }, this.refreshJournalData);
        });
    }
    deleteOrder = () => {
        this.props.deleteOrder(this.state.year, this.state.number).then(response => {
            if (this.state.number === this.state.max) {
                this.props.maxNumberByYear(this.state.year).then(res => {
                    if (!isNaN(res)) {
                        this.setState({ max: res, number: res }, this.refreshJournalData);
                    }
                });
            }
        });
    }
    updateOrder = () => {
        this.props.updateOrder({
            year: this.state.year,
            number: this.state.number,
            date: this.state.date,
            locked: this.state.locked,
            cashFlow: this.state.cashFlow,
            orderTypeID: this.state.orderTypeID
        });
    }
    render() {
        return (
            <>
                <ComponentInLine>
                    <EditIntegerNumberField2 name='year' label='Year' value={this.state.year} onChange={(e) => { this.changeYear(e.target.value) }}></EditIntegerNumberField2>
                    <EditIntegerNumberField2 name='number' label='Number' value={this.state.number} onChange={(e) => { this.changeNumber(parseInt(e.target.value)) }}></EditIntegerNumberField2>
                    <EditSelectFieldClear name='orderTypeID'
                        label='OrderType'
                        value={this.state.orderTypeID}
                        onChange={
                            (event) => {
                                this.setState({ orderTypeID: parseInt(event.target.value) })
                            }
                        }
                        dataSource={this.props.orderTypesSelectBox}
                    ></EditSelectFieldClear>
                    <EditDatePickerField2 name='date' label='Date' value={this.state.date} onChange={(e) => { this.setState({ date: e }) }}></EditDatePickerField2>
                    <EditCheckbox2 name='locked' value={this.state.locked} label='Locked' onChange={(e) => { this.setState({ locked: !this.state.locked }) }}></EditCheckbox2>
                    <EditCheckbox2 name='cashFlow' value={this.state.cashFlow} label='CashFlow' onChange={(e) => { this.setState({ cashFlow: !this.state.cashFlow }) }}></EditCheckbox2>
                </ComponentInLine>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                    <Button onClick={this.goToStart}><SkipPreviousIcon /></Button>
                    <Button><ArrowLeftIcon fontSize="medium" onClick={this.decreaseNumber} /></Button>
                    <Button><ArrowRightIcon fontSize="medium" onClick={this.increaseNumber} /></Button>
                    <Button onClick={this.goToEnd}><SkipNextIcon /></Button>
                    <Button startIcon={<AddIcon />} onClick={() => { this.setState({ IsOpen: true }); }}>{i18next.t('AddNewRecord')}</Button>
                    <Button startIcon={<SaveIcon />} onClick={this.updateOrder}>{i18next.t('Update')}</Button>
                    <Button startIcon={<CancelIcon />} onClick={this.cancelOrder}>{i18next.t('Cancel')}</Button>
                    <Button startIcon={<ContentCopyIcon />} onClick={this.copyOrder}>{i18next.t('Copy')}</Button>
                    <Button startIcon={<DeleteIcon />} onClick={this.deleteOrder}>{i18next.t('Delete')}</Button>
                </ButtonGroup>

                <div style={{ marginTop: '20px' }}>
                    <TableJournal
                        year={this.state.year}
                        number={this.state.number}
                    ></TableJournal>
                </div>

                <Dialog
                    fullWidth
                    maxWidth="xl"
                    open={this.state.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{i18next.t('Create')}</DialogTitle>
                    <DialogContent>
                        <EditSelectFieldClear name='dialogOrderTypeID'
                            label='OrderType'
                            value={this.state.dialogOrderTypeID}
                            onChange={
                                (event) => {
                                    this.setState({ dialogOrderTypeID: parseInt(event.target.value) })
                                }
                            }
                            dataSource={this.props.orderTypesSelectBox}
                        ></EditSelectFieldClear>

                        <DialogActions>
                            <Button color="primary" variant="contained" onClick={() => { this.setState({ IsOpen: false }) }}>
                                {i18next.t('Close')}
                            </Button>
                            <Button color="success" variant="contained" onClick={() => {
                                this.createOrder();
                                this.setState({ IsOpen: false, dialogOrderTypeID: 0 });
                            }}>
                                {i18next.t('Create')}
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </>);
    }
}

const mapStateToProps = state => {
    return {
        paymentWays: Object.values(state.paymentWays),
        plans: Object.values(state.plansSelectBox),
        order: state.orderSelected,
        orderTypesSelectBox: Object.values(state.orderTypesSelectBox),
    };
}

export default connect(mapStateToProps, {
    getAllJournalsByOrder,
    maxNumberByYear,
    createOrder,
    copyOrder,
    cancelOrder,
    deleteOrder,
    updateOrder,
    getSelectBoxOrderTypes
})(PostingAccountPage);