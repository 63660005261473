import api from "../api/myApi";
import { 
    //CREATE_ASSIGNEE,
    //GET_ALL_ASSIGNEE,
    //GET_ASSIGNEE,
    GET_ALL_SELECTBOX_ASSIGNEE,
    //DELETE_ASSIGNEE,
    //UPDATE_ASSIGNEE,
    //ADD_DIALOG_ASSIGNEE,
    //EDIT_DIALOG_ASSIGNEE,
    //CLOSE_DIALOG_ASSIGNEE
} from './types';
//import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
/*
export const createAssignee = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Assignee/Create', formValues);    
    dispatch(responseHandler(response, CREATE_ASSIGNEE));
    if(response.data?.succeeded){
        dispatch(reset('formAssignee'));
    }
}

export const getAllAssignees = () => async (dispatch) => {
    const response = await api.get('/api/v1/Assignee/GetAll');
    dispatch(responseHandler(response, GET_ALL_ASSIGNEE));
}

export const getAssignee = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Assignee/Get/' + id);
    dispatch(responseHandler(response, GET_ASSIGNEE));
}
*/
export const getSelectBoxAssignees = () => async (dispatch) => {
    const response = await api.get('/api/v1/Partner/GetAssigneeSelectBox');//await api.get('/api/v1/Assignee/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_ASSIGNEE));
}
/*
export const updateAssignee = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Assignee/Update', formValues);
    dispatch(responseHandler(response, UPDATE_ASSIGNEE));
}

export const deleteAssignee = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Assignee/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_ASSIGNEE));
}

export const addDialogAssignee = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_ASSIGNEE,
        payload: null
    });
}

export const editDialogAssignee = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_ASSIGNEE,
        payload: item
    });
}

export const closeDialogAssignee = () => async (dispatch) => {
    dispatch(reset('formAssignee'));
    dispatch({
        type: CLOSE_DIALOG_ASSIGNEE,
        payload: undefined
    });
}
*/