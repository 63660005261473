import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCompany from './CreateCompany';
import EditCompany from './EditCompany';
import i18next from '../../language/i18n';
import { addDialogCompany, editDialogCompany, deleteCompany, getAllCompanies } from "../../actions/companyAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getAllCompanies();
  }

  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },
    { field: 'web', headerName: i18next.t('Web'), minWidth: 150, flex: 1 },
    { field: 'email', headerName: i18next.t('Email'), minWidth: 150, flex: 1 },
    { field: 'director', headerName: i18next.t('Director'), minWidth: 150, flex: 1 },
    { field: 'cRKCode', headerName: i18next.t('CRKCode'), minWidth: 150, flex: 1 },
    { field: 'logoURL', headerName: i18next.t('LogoURL'), minWidth: 150, flex: 1 },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogCompany();
  }
  edit = (params) => {
    this.props.editDialogCompany(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.companies}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateCompany></CreateCompany>
        <EditCompany></EditCompany>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteCompany(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { companies: Object.values(state.companies) };
}

export default connect(mapStateToProps,
  {
    addDialogCompany,
    editDialogCompany,
    deleteCompany,
    getAllCompanies,
    showLoadingDataInProgress
  })(TableCompany);