import api from "../api/myApi";
import { 
    CREATE_REGION,
    GET_ALL_REGION,
    GET_REGION,
    GET_ALL_SELECTBOX_REGION,
    DELETE_REGION,
    UPDATE_REGION,
    ADD_DIALOG_REGION,
    EDIT_DIALOG_REGION,
    CLOSE_DIALOG_REGION,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createRegion = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Region/Create', formValues);    
    dispatch(responseHandler(response, CREATE_REGION));
    if(response.data?.succeeded){
        dispatch(reset('formRegion'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllRegions = () => async (dispatch) => {
    const response = await api.get('/api/v1/Region/GetAll');
    dispatch(responseHandler(response, GET_ALL_REGION));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getRegion = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Region/Get/' + id);
    dispatch(responseHandler(response, GET_REGION));
}

export const getSelectBoxRegions = () => async (dispatch) => {
    const response = await api.get('/api/v1/Region/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_REGION));
}

export const updateRegion = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Region/Update', formValues);
    dispatch(responseHandler(response, UPDATE_REGION));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteRegion = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Region/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_REGION));
}

export const addDialogRegion = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_REGION,
        payload: null
    });
}

export const editDialogRegion = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_REGION,
        payload: item
    });
}

export const closeDialogRegion = () => async (dispatch) => {
    dispatch(reset('formRegion'));
    dispatch({
        type: CLOSE_DIALOG_REGION,
        payload: undefined
    });
}