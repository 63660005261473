import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogBank } from '../../actions/bankAction';
import { EditTextField, EditSelectField, EditCheckbox } from '../Share/EditComponents';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';

class FormBank extends React.Component{
    handleClose = () => {
        this.props.closeDialogBank();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            <div>
                                <Field
                                    name='address'
                                    component={this.props.EditTextField}
                                    label='Address'
                                />
                            </div>
                            <div>
                                <Field
                                    name='phone'
                                    component={this.props.EditTextField}
                                    label='Phone'
                                />
                            </div>
                            <div>
                                <Field
                                    name='fax'
                                    component={this.props.EditTextField}
                                    label='Fax'
                                />
                            </div>
                            <div>
                                <Field
                                    name='email'
                                    component={this.props.EditTextField}
                                    label='Email'
                                />
                            </div>
                            <div>
                                <Field
                                    name='web'
                                    component={this.props.EditTextField}
                                    label='Web'
                                />
                            </div>
                            <div>
                                <Field
                                    name='director'
                                    component={this.props.EditTextField}
                                    label='Director'
                                />
                            </div>
                            <div>
                                <Field
                                    name='cityID'
                                    component ={this.props.EditSelectField}
                                    label = 'City'
                                    dataSource={this.props.citiesSelectBox}
                                    />
                            </div>
                            <div>
                                <Field
                                    name='isInternal'
                                    component={this.props.EditCheckbox}
                                    label='IsInternal'
                                />
                            </div>
                            <div>
                                <Field
                                    name='crkCode'
                                    component={this.props.EditTextField}
                                    label='CRKCode'
                                />
                            </div>
                            <DialogActions>                            
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.name){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.address){
        errors.address = 'Polje je obavezno!';
    }
    if(!formValues.phone){
        errors.phone = 'Polje je obavezno!';
    }
    if(!formValues.fax){
        errors.fax = 'Polje je obavezno!';
    }
    if(!formValues.email){
        errors.email = 'Polje je obavezno!';
    }
    if(!formValues.web){
        errors.web = 'Polje je obavezno!';
    }
    if(!formValues.director){
        errors.director = 'Polje je obavezno!';
    }
    if(!formValues.cityID){
        errors.cityID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { citiesSelectBox: Object.values(state.citiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formBank',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormBank);

export default connect(mapStateToProps, { closeDialogBank, EditTextField, EditSelectField, EditCheckbox})(formWrapper);