//Mojo-AG
import { 
    CREATE_REFINANCING,
    GET_ALL_REFINANCING,
    GET_REFINANCING,
    DELETE_REFINANCING,
    UPDATE_REFINANCING,
    GET_LOAN
} from '../../actions/types';

const refinancingReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_REFINANCING:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_REFINANCING:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_REFINANCING:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_REFINANCING: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_REFINANCING:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = { };
                for(let i=0; i<action.payload.refinancing.length;i++) {result3[action.payload.refinancing[i].id]=action.payload.refinancing[i];}
                return result3;
        default:
            return state;
    }
}

export default refinancingReducer;