//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createCRKArchive } from '../../actions/cRKArchiveAction';
import FormCRKArchive from './FormCRKArchive';

class CreateCRKArchive extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createCRKArchive(formValues);
    }
    
    render(){
        return (
            <FormCRKArchive 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormCRKArchive>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CRKArchiveDialog === undefined || state.dialog.CRKArchiveDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.CRKArchiveDialog};
}


export default connect(mapStateToProps, { createCRKArchive })(CreateCRKArchive);
