//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createLoanApproval } from '../../actions/loanApprovalAction';
import FormLoanApproval from './FormLoanApproval';

class CreateLoanApproval extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loanID;
        this.props.createLoanApproval(formValues);
    }
    
    render(){
        return (
            <FormLoanApproval 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormLoanApproval>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanApprovalDialog === undefined || state.dialog.LoanApprovalDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.LoanApprovalDialog};
}


export default connect(mapStateToProps, { createLoanApproval })(CreateLoanApproval);
