//Mojo-AG
import { 
    GET_ALL_SELECTBOX_BOARDMEMBER
} from '../../actions/types';

 const boardMemberSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_BOARDMEMBER:
            return [...action.payload];
        default:
            return state;
    }
}
export default boardMemberSelectBoxReducer;