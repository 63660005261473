//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogOrderType, updateOrderType } from '../../actions/orderTypeAction';
import FormOrderType from './FormOrderType';

class EditOrderType extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateOrderType(formValues);
    }
    
    render(){
        return (
            <FormOrderType 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormOrderType>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.OrderTypeDialog === undefined || state.dialog.OrderTypeDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.OrderTypeDialog};
}


export default connect(mapStateToProps, { closeDialogOrderType, updateOrderType })(EditOrderType);
