import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogVehicle, updateVehicle } from '../../actions/vehicleAction';
import FormVehicle from './FormVehicle';

class EditVehicle extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateVehicle(formValues);
    }
    
    render(){
        return (
            <FormVehicle 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormVehicle>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.VehicleDialog === undefined || state.dialog.VehicleDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.VehicleDialog};
}


export default connect(mapStateToProps, { closeDialogVehicle, updateVehicle })(EditVehicle);
