import api from "../api/myApi";
import { 
    CREATE_WAYOFKNOWING,
    GET_ALL_WAYOFKNOWING,
    GET_WAYOFKNOWING,
    GET_ALL_SELECTBOX_WAYOFKNOWING,
    DELETE_WAYOFKNOWING,
    UPDATE_WAYOFKNOWING,
    ADD_DIALOG_WAYOFKNOWING,
    EDIT_DIALOG_WAYOFKNOWING,
    CLOSE_DIALOG_WAYOFKNOWING,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createWayOfKnowing = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/WayOfKnowing/Create', formValues);    
    dispatch(responseHandler(response, CREATE_WAYOFKNOWING));
    if(response.data?.succeeded){
        dispatch(reset('formWayOfKnowing'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllWayOfKnowings = () => async (dispatch) => {
    const response = await api.get('/api/v1/WayOfKnowing/GetAll');
    dispatch(responseHandler(response, GET_ALL_WAYOFKNOWING));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getWayOfKnowing = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/WayOfKnowing/Get/' + id);
    dispatch(responseHandler(response, GET_WAYOFKNOWING));
}

export const getSelectBoxWayOfKnowings = () => async (dispatch) => {
    const response = await api.get('/api/v1/WayOfKnowing/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_WAYOFKNOWING));
}

export const updateWayOfKnowing = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/WayOfKnowing/Update', formValues);
    dispatch(responseHandler(response, UPDATE_WAYOFKNOWING));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteWayOfKnowing = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/WayOfKnowing/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_WAYOFKNOWING));
}

export const addDialogWayOfKnowing = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_WAYOFKNOWING,
        payload: null
    });
}

export const editDialogWayOfKnowing = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_WAYOFKNOWING,
        payload: item
    });
}

export const closeDialogWayOfKnowing = () => async (dispatch) => {
    dispatch(reset('formWayOfKnowing'));
    dispatch({
        type: CLOSE_DIALOG_WAYOFKNOWING,
        payload: undefined
    });
}