//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCRKRequest from './CreateCRKRequest';
import EditCRKRequest from './EditCRKRequest';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogCRKRequest, editDialogCRKRequest, deleteCRKRequest, getAllCRKRequests } from "../../actions/cRKRequestAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { importFromCRK } from '../../actions/loanTakenOutAction';
import { getSelectBoxCRKRequestTypes } from '../../actions/cRKRequestTypeAction';
import { dateFormat } from '../Share/Table/DateFormat';
import TableCRKResponseDialog from '../CRKResponse/TableCRKResponseDialog';


class TableCRKRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      data: [],
      isOpen: false,
      crkRequestID: null
    }
  }
  componentDidMount = () => {
    //this.props.getSelectBoxPartners();
    this.props.getSelectBoxCRKRequestTypes();
    if (this.props.partners != null && this.props.partners != undefined && this.props.partners.length > 0) {
      this.props.getAllCRKRequests({ partnerIDs: this.props.partners });
    }
  }
  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.partners?.toString() !== nextProps.partners?.toString()) {
      this.props.getAllCRKRequests({ partnerIDs: nextProps.partners });
    }
    return true;
  }
  partnerName = (props) => {
    return this.props.partnersSelectBox.find(el => el.value == props.row.partnerID)?.text;
  }
  cRKRequestTypeName = (props) => {
    return this.props.cRKRequestTypes.find(el => el.value == props.row.crkRequestTypeID)?.text;
  }
  columns = [

    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      minWidth: 160,
      flex: 1,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },

    {
      field: 'crkRequestTypeID',
      headerName: i18next.t('CRKRequestType'),
      minWidth: 160,
      flex: 1,
      valueGetter: this.cRKRequestTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cRKRequestTypeName(cellParams1).localeCompare(this.cRKRequestTypeName(cellParams2))
    },
    { field: 'createDate', headerName: i18next.t('CreateDate'), width: 150, valueGetter: dateFormat },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },
    {
      field: 'details',
      headerName: ' ',
      width: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.details(params)}
          >
            {i18next.t('Details')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'import',
      headerName: ' ',
      width: 250,
      hide: this.props.disabled,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.importToLoanTakenOut(params)}
          >
            {i18next.t('ImportToLoanTakenOut')}
          </Button>
        </strong>
      ),
    },
    // {
    //   field: 'edit',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="small"
    //         style={{ marginLeft: 16 }}
    //         onClick={() => this.edit(params)}
    //       >
    //         {i18next.t('Edit')}
    //       </Button>
    //     </strong>
    //   ),
    // },
    // {
    //   field: 'delete',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>
    //       <DeleteButton onClick={() => this.delete(params)} />
    //     </strong>
    //   )
    // }
  ];
  create = () => {
    this.props.addDialogCRKRequest();
  }
  onClose = () => {
    this.setState({
      isOpen: false,
      data: [],
      crkRequestID: null
    });
  }
  details = (params) => {
    this.setState({
      isOpen: true,
      data: this.props.cRKRequests.find(el => el.request.id == params.id)?.responses,
      crkRequestID: params.id
    });
  }
  importToLoanTakenOut = (params) => {
    this.props.importFromCRK({
      crkRequestID: params.id,
      loanID: this.props.loan.loan.id
    });
  }
  // edit = (params) => {
  //   this.props.editDialogCRKRequest(params.row);
  // }
  // delete = (params) => {
  //   this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  // }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.cRKRequests.map(el => el.request)}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateCRKRequest partners={this.props.partners}></CreateCRKRequest>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteCRKRequest(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
        <TableCRKResponseDialog
          data={this.state.data}
          isOpen={this.state.isOpen}
          crkRequestID={this.state.crkRequestID}
          onClose={this.onClose}
        ></TableCRKResponseDialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cRKRequests: Object.values(state.cRKRequests),
    partnersSelectBox: Object.values(state.partnersSelectBox),
    cRKRequestTypes: Object.values(state.cRKRequestTypesSelectBox),
    loan: state.loanSelected
  };
}

export default connect(
  mapStateToProps,
  {
    addDialogCRKRequest,
    editDialogCRKRequest,
    deleteCRKRequest,
    getAllCRKRequests,
    getSelectBoxPartners,
    getSelectBoxCRKRequestTypes,
    importFromCRK
  })(TableCRKRequest);