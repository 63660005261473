import React from 'react';
import { connect } from 'react-redux';
import { createUser } from '../../actions/userAction';
import FormUser from './FormUser';

class CreateUser extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createUser(formValues);
    }
    
    render(){
        return (
            <FormUser 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create"
                IsNew={true}>
            </FormUser>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserDialog === undefined || state.dialog.UserDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.UserDialog};
}


export default connect(mapStateToProps, { createUser })(CreateUser);
