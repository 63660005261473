import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateRealEstates from './CreateRealEstates';
import EditRealEstates from './EditRealEstates';
import { addDialogRealEstates, editDialogRealEstates, deleteRealEstates, getAllRealEstates } from "../../actions/realEstatesAction";
import { getSelectBoxPartners } from '../../actions/partnerAction';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableRealEstates extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
  }

  partnerName = (props) => {
    return this.props.partners.find(el => el.value === props.row.partnerID)?.text;
  }
  columns = () => {
    return ([
    {
      field: 'PartnerID',
      headerName: i18next.t('Partner'),
      width: 160,
      valueGetter: this.partnerName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
    },
    { field: 'description', headerName: i18next.t('Description'), minWidth: 150, flex: 1 },
    { field: 'estimatedValue', headerName: i18next.t('EstimatedValue'), minWidth: 150, flex: 1 },
    { field: 'isValid', headerName: i18next.t('IsValid'), minWidth: 150, flex: 1, type: 'boolean' },

    {
      field: 'edit',
      headerName: ' ',
      hide: this.props.disabled,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      hide: this.props.disabled,
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ])}
  create = () => {
    this.props.addDialogRealEstates();
  }
  edit = (params) => {
    this.props.editDialogRealEstates(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={this.props.realEstates}
          columns={this.columns()}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.props.disabled ? null : this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateRealEstates></CreateRealEstates>
        <EditRealEstates></EditRealEstates>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteRealEstates(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    realEstates: Object.values(state.realEstates),
    partners: Object.values(state.partnersSelectBox)
  };
}

export default connect(mapStateToProps, { addDialogRealEstates, editDialogRealEstates, deleteRealEstates, getAllRealEstates, getSelectBoxPartners })(TableRealEstates);