import api from "../api/myApi";
import { 
    CREATE_COMPANY,
    GET_ALL_COMPANY,
    GET_COMPANY,
    GET_ALL_SELECTBOX_COMPANY,
    DELETE_COMPANY,
    UPDATE_COMPANY,
    ADD_DIALOG_COMPANY,
    EDIT_DIALOG_COMPANY,
    CLOSE_DIALOG_COMPANY,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS,
    GET_MYCOMPANY_DATA
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createCompany = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Company/Create', formValues);    
    dispatch(responseHandler(response, CREATE_COMPANY));
    if(response.data?.succeeded){
        dispatch(reset('formCompany'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllCompanies = () => async (dispatch) => {
    const response = await api.get('/api/v1/Company/GetAll');
    dispatch(responseHandler(response, GET_ALL_COMPANY));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getCompany = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Company/Get/' + id);
    dispatch(responseHandler(response, GET_COMPANY));
}

export const getSelectBoxCompanies = () => async (dispatch) => {
    const response = await api.get('/api/v1/Company/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_COMPANY));
}

export const updateCompany = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Company/Update', formValues);
    dispatch(responseHandler(response, UPDATE_COMPANY));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteCompany = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Company/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_COMPANY));
}

export const addDialogCompany = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_COMPANY,
        payload: null
    });
}

export const getMyCompanyData = () => async (dispatch) => {
    const response = await api.get('/api/v1/Company/GetMyCompanyData');
    dispatch(responseHandler(response, GET_MYCOMPANY_DATA));
}

export const editDialogCompany = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_COMPANY,
        payload: item
    });
}

export const closeDialogCompany = () => async (dispatch) => {
    dispatch(reset('formCompany'));
    dispatch({
        type: CLOSE_DIALOG_COMPANY,
        payload: undefined
    });
}