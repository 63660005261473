import { 
    GET_ALL_NOTIFICATION,
    READ_NOTIFICATION,
    READ_ALL_NOTIFICATION
} from '../../actions/types';

const notificationReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_NOTIFICATION:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case READ_NOTIFICATION:
            return { ...state, [action.payload.id]: action.payload };        
        case READ_ALL_NOTIFICATION:
            let result2 = {...state };
            for(let i=0; i<action.payload.length;i++){
                result[action.payload[i].id]=action.payload[i];
            }
            return result2;
        default:
            return state;
    }
}

export default notificationReducer;