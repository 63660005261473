//Mojo-AG
import { 
    CREATE_UNPARTNERDETAILS,
    GET_ALL_UNPARTNERDETAILS,
    GET_UNPARTNERDETAILS,
    DELETE_UNPARTNERDETAILS,
    UPDATE_UNPARTNERDETAILS
} from '../../actions/types';

const uNPartnerDetailsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_UNPARTNERDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_UNPARTNERDETAILS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_UNPARTNERDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_UNPARTNERDETAILS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_UNPARTNERDETAILS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default uNPartnerDetailsReducer;