//Mojo-AG
import { 
    CREATE_LOANAPPROVAL,
    GET_ALL_LOANAPPROVAL,
    GET_LOANAPPROVAL,
    DELETE_LOANAPPROVAL,
    UPDATE_LOANAPPROVAL
} from '../../actions/types';

const loanApprovalReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANAPPROVAL:
            return { ...state, [action.payload.loanApproval.id]: action.payload.loanApproval };
        case GET_ALL_LOANAPPROVAL:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANAPPROVAL:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANAPPROVAL: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_LOANAPPROVAL:
            return { ...state, [action.payload.loanApproval.id]: action.payload.loanApproval };
        default:
            return state;
    }
}

export default loanApprovalReducer;