import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogLoanPartner, updateLoanPartner } from '../../actions/loanPartnerAction';
import FormLoanPartner from './FormLoanPartner';

class EditLoanPartner extends React.Component {
    onSubmit = (formValues) => {
        let keys = Object.keys(formValues).filter(el => el.indexOf('billNumber') > -1 && el != 'billNumber').sort();
        let billNumber = [];
        for (let i = 0; i < keys.length; i++) {
            if (formValues[keys[i]] != null && formValues[keys[i]] != '') {
                billNumber.push(formValues[keys[i]]);
            }
        }
        formValues.billNumber = billNumber.join(';');
        this.props.updateLoanPartner(formValues);
    }
    getInitialValues = () => {
        const data = this.props.item?.billNumber.split(";");
        for (let i = 0; i < data?.length; i++) {
            this.props.item['billNumber' + i] = data[i];
        }
        return { ...this.props.item };
    }
    render() {
        return ( this.props.IsOpen ? 
            <FormLoanPartner
                IsOpen={this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.getInitialValues()}
                title={i18next.t('Edit')}>
            </FormLoanPartner> : null
        );
    }
}

const mapStateToProps = state => {
    return state.dialog.LoanPartnerDialog === undefined || state.dialog.LoanPartnerDialog.type !== 'edit' ? 
    { IsOpen: false } : 
    { ...state.dialog.LoanPartnerDialog };
}


export default connect(mapStateToProps, { closeDialogLoanPartner, updateLoanPartner })(EditLoanPartner);
