import { 
    GET_LOAN
} from '../../actions/types';

const bankStatementImportByLoanReducer = (state = {}, action) => {
    switch(action.type){        
        case GET_LOAN:
                let result3 = { };
                for(let i=0; i<action.payload.bankStatementImport.length;i++) {result3[action.payload.bankStatementImport[i].id]=action.payload.bankStatementImport[i];}
                return result3;
        default:
            return state;
    }
}

export default bankStatementImportByLoanReducer;