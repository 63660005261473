import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogBank, updateBank } from '../../actions/bankAction';
import FormBank from './FormBank';

class EditBank extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBank(formValues);
    }
    
    render(){
        return (
            <FormBank 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormBank>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BankDialog === undefined || state.dialog.BankDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BankDialog};
}


export default connect(mapStateToProps, { closeDialogBank, updateBank })(EditBank);
