//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_UNPARTNERDETAILS,
    GET_ALL_UNPARTNERDETAILS,
    GET_UNPARTNERDETAILS,
    GET_ALL_SELECTBOX_UNPARTNERDETAILS,
    DELETE_UNPARTNERDETAILS,
    UPDATE_UNPARTNERDETAILS,
    ADD_DIALOG_UNPARTNERDETAILS,
    EDIT_DIALOG_UNPARTNERDETAILS,
    CLOSE_DIALOG_UNPARTNERDETAILS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createUNPartnerDetails = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/UNPartnerDetails/Create', formValues);    
    dispatch(responseHandler(response, CREATE_UNPARTNERDETAILS));
    if(response.data?.succeeded){
        dispatch(reset('formUNPartnerDetails'));
    }
}

export const getAllUNPartnerDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/UNPartnerDetails/GetAll');
    dispatch(responseHandler(response, GET_ALL_UNPARTNERDETAILS));
}

export const getUNPartnerDetails = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/UNPartnerDetails/Get/' + id);
    dispatch(responseHandler(response, GET_UNPARTNERDETAILS));
}

export const getSelectBoxUNPartnerDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/UNPartnerDetails/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_UNPARTNERDETAILS));
}

export const updateUNPartnerDetails = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/UNPartnerDetails/Update', formValues);
    dispatch(responseHandler(response, UPDATE_UNPARTNERDETAILS));
}

export const deleteUNPartnerDetails = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/UNPartnerDetails/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_UNPARTNERDETAILS));
}

export const addDialogUNPartnerDetails = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_UNPARTNERDETAILS,
        payload: null
    });
}

export const editDialogUNPartnerDetails = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_UNPARTNERDETAILS,
        payload: item
    });
}

export const closeDialogUNPartnerDetails = () => async (dispatch) => {
    dispatch(reset('formUNPartnerDetails'));
    dispatch({
        type: CLOSE_DIALOG_UNPARTNERDETAILS,
        payload: undefined
    });
}