import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createIncomeSource } from '../../actions/incomeSourceAction';
import FormIncomeSource from './FormIncomeSource';

class CreateIncomeSource extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loan.loan.id;
        this.props.createIncomeSource(formValues);
    }
    
    render(){
        return (
            <FormIncomeSource 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormIncomeSource>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.IncomeSourceDialog === undefined || state.dialog.IncomeSourceDialog.type !== 'add'? 
    {
      IsOpen:false,
      loan: state.loanSelected
    } : 
    {
        ...state.dialog.IncomeSourceDialog,
        loan: state.loanSelected
    };
}


export default connect(mapStateToProps, { createIncomeSource })(CreateIncomeSource);
