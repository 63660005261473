import { 
    CREATE_PAYMENTNOTETYPE,
    GET_ALL_PAYMENTNOTETYPE,
    GET_PAYMENTNOTETYPE,
    DELETE_PAYMENTNOTETYPE,
    UPDATE_PAYMENTNOTETYPE
} from '../../actions/types';

const paymentNoteTypeReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_PAYMENTNOTETYPE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_PAYMENTNOTETYPE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_PAYMENTNOTETYPE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_PAYMENTNOTETYPE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_PAYMENTNOTETYPE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default paymentNoteTypeReducer;