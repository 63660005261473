import api from "../api/myApi";
import { 
    CREATE_BROKER,
    GET_ALL_BROKER,
    GET_BROKER,
    GET_ALL_SELECTBOX_BROKER,
    DELETE_BROKER,
    UPDATE_BROKER,
    ADD_DIALOG_BROKER,
    EDIT_DIALOG_BROKER,
    CLOSE_DIALOG_BROKER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBroker = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Broker/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BROKER));
    if(response.data?.succeeded){
        dispatch(reset('createFormBroker'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllBrokers = () => async (dispatch) => {
    const response = await api.get('/api/v1/Broker/GetAll');
    dispatch(responseHandler(response, GET_ALL_BROKER));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

// export const getBroker = (id) => async (dispatch) => {
//     const response = await api.get('/api/v1/Broker/Get/' + id);
//     dispatch(responseHandler(response, GET_BROKER));
// }

export const getSelectBoxBrokers = () => async (dispatch) => {
    const response = await api.get('/api/v1/Broker/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BROKER));
}

export const updateBroker = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Broker/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BROKER));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteBroker = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Broker/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BROKER));
}

export const addDialogBroker = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BROKER,
        payload: null
    });
}

export const editDialogBroker = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BROKER,
        payload: item
    });
}

export const closeDialogBroker = () => async (dispatch) => {
    dispatch(reset('createFrmBroker'));
    dispatch(reset('editFormBroker'));
    dispatch({
        type: CLOSE_DIALOG_BROKER,
        payload: undefined
    });
}