import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createLoanTakenOut } from '../../actions/loanTakenOutAction';
import FormLoanTakenOut from './FormLoanTakenOut';

class CreateLoanTakenOut extends React.Component{
    onSubmit  = (formValues) => {
        formValues.loanID = this.props.loan.loan.id;
        this.props.createLoanTakenOut(formValues);
    }
    
    render(){
        return (
            <FormLoanTakenOut 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormLoanTakenOut>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanTakenOutDialog === undefined || state.dialog.LoanTakenOutDialog.type !== 'add'? 
  {
      IsOpen:false,
      loan: state.loanSelected
    } : 
    {
        ...state.dialog.LoanTakenOutDialog,
        loan: state.loanSelected
    };
}


export default connect(mapStateToProps, { createLoanTakenOut })(CreateLoanTakenOut);
