//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogLoanWriteOff, updateLoanWriteOff } from '../../actions/loanWriteOffAction';
import FormLoanWriteOff from './FormLoanWriteOff';

class EditLoanWriteOff extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateLoanWriteOff(formValues);
    }
    
    render(){
        return (
            <FormLoanWriteOff 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormLoanWriteOff>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanWriteOffDialog === undefined || state.dialog.LoanWriteOffDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.LoanWriteOffDialog};
}


export default connect(mapStateToProps, { closeDialogLoanWriteOff, updateLoanWriteOff })(EditLoanWriteOff);
