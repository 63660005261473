import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateOpinionOfTheReferent from './CreateOpinionOfTheReferent';
import EditOpinionOfTheReferent from './EditOpinionOfTheReferent';
import { addDialogOpinionOfTheReferent, editDialogOpinionOfTheReferent, deleteOpinionOfTheReferent, getAllOpinionOfTheReferents } from "../../actions/opinionOfTheReferentAction";
import { getSelectBoxCompanies } from '../../actions/companyAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';


class TableOpinionOfTheReferent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxCompanies();

    this.props.getAllOpinionOfTheReferents();
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  columns = [

    /* {
         field: 'CompanyID',
         headerName: i18next.t('Company'),
         width: 160,
         valueGetter: this.companyName,
         sortComparator: (v1, v2, cellParams1, cellParams2) =>
         this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
       },*/
    { field: 'text', headerName: i18next.t('Text'), minWidth: 150, flex: 1 },
    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogOpinionOfTheReferent();
  }
  edit = (params) => {
    this.props.editDialogOpinionOfTheReferent(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.opinionOfTheReferents}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateOpinionOfTheReferent></CreateOpinionOfTheReferent>
        <EditOpinionOfTheReferent></EditOpinionOfTheReferent>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteOpinionOfTheReferent(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    opinionOfTheReferents: Object.values(state.opinionOfTheReferents),
    companies: Object.values(state.companiesSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogOpinionOfTheReferent,
    editDialogOpinionOfTheReferent,
    deleteOpinionOfTheReferent,
    getAllOpinionOfTheReferents,
    getSelectBoxCompanies,
    showLoadingDataInProgress
  })(TableOpinionOfTheReferent);