import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { addDialogBankStatementImport, editDialogBankStatementImport, deleteBankStatementImport, getAllBankStatementImports, fileBankStatementImport } from "../../actions/bankStatementImportAction";
import { Field, reduxForm, change } from "redux-form";
import { EditSelectField, EditDatePickerField, EditTextField, EditIntegerNumberField, EditDecimalNumberField } from '../Share/EditComponents';
import i18next from '../../language/i18n';
import ComponentInLine from '../Share/ComponentInLine';

class TableBankStatementImportPartnerFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterData: {},
      selectedRow: []
    }
  }
  componentDidMount = () => {
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.initialValues?.partnerID !== prevProps.initialValues?.partnerID) {
      this.setState({
        filterData: {
          partnerID: this.props.initialValues?.partnerID
        }
      })
    }
  }
  onSubmit = (formValues) => {
    this.setState({ filterData: formValues });
  }
  partnersBaseDataRows = () => {
    let data = this.props.partners;
    let hasFilter = false;
    if(this.state.filterData.parnterID !== undefined && this.state.filterData.parnterID !== null){
      data = data.filter(el => el.id == this.state.filterData.parnterID);
      hasFilter = true;
    }
    if (this.state.filterData.firstName !== undefined && this.state.filterData.firstName !== '') {
      data = data.filter(el => el.name.toLowerCase().includes(this.state.filterData.firstName.toLowerCase()));
      hasFilter = true;
    }
    if (this.state.filterData.lastName !== undefined && this.state.filterData.lastName !== '') {
      data = data.filter(el => el.name.toLowerCase().includes(this.state.filterData.lastName.toLowerCase()));
      hasFilter = true;
    }
    if (this.state.filterData.jib !== undefined && this.state.filterData.jib !== '') {
      data = data.filter(el => el.jib.includes(this.state.filterData.jib));
      hasFilter = true;
    }

    return hasFilter ? data : [];
  }
  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 4 },
    { field: 'jib', headerName: i18next.t('JIB'), minWidth: 150, flex: 1 }
  ];
  getSelectedData = () => {
    return this.partnersBaseDataRows().find((row) =>
      this.state.selectedRow.includes(row.id)
    );
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.isOpen}

          maxWidth={'md'}
          fullWidth={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <form //onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
              onSubmit={this.props.handleSubmit(this.onSubmit)}>
              <ComponentInLine>
                <div>
                  <Field
                    name='firstName'
                    component={this.props.EditTextField}
                    label='Name'
                  />
                </div>
                <div>
                  <Field
                    name='lastName'
                    component={this.props.EditTextField}
                    label='LastName'
                  />
                </div>
                <div>
                  <Field
                    name='jib'
                    component={this.props.EditTextField}
                    label='JIB'
                  />
                </div>
                <DialogActions style={{ justifyContent: "center", paddingTop: "24px" }}>
                    <Button color="primary" variant="contained" type="Submit">
                      Find
                    </Button>
                  </DialogActions>
              </ComponentInLine>              

            </form>
            <DataGrid
              rows={this.partnersBaseDataRows()}
              columns={this.columns}
              autoHeight
              onSelectionModelChange={(e) => {
                let newValue = e;
                if (e.length === 1 && this.state.selectedRow.length === 1 && e[0] === this.state.selectedRow[0]) {
                  newValue = [];
                }
                this.setState({ selectedRow: newValue });
              }}
              selectionModel={this.state.selectedRow}
            />
            <DialogActions>
              <Button onClick={this.props.onClose} color="secondary" variant="outlined">
                {i18next.t('Cancel')}
              </Button>
              <Button onClick={() => this.props.onSubmit(this.getSelectedData())} color="primary" variant="contained">
                {i18next.t('Submit')}
              </Button>
            </DialogActions>

          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const formWrapper = reduxForm({
  form: 'formBankImportSearchPartnerFilter',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TableBankStatementImportPartnerFilter);

const mapStateToProps = state => {
  return {
    partners: Object.values(state.partners), //
  };
}

export default connect(mapStateToProps,
  {
    addDialogBankStatementImport,
    editDialogBankStatementImport,
    deleteBankStatementImport,
    getAllBankStatementImports,
    fileBankStatementImport,
    change,
    EditSelectField,
    EditDatePickerField,
    EditTextField,
    EditIntegerNumberField,
    EditDecimalNumberField
  })(formWrapper);
