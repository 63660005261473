import { 
    CREATE_USERBRANCH,
    GET_ALL_USERBRANCH,
    GET_USERBRANCH,
    DELETE_USERBRANCH,
    UPDATE_USERBRANCH
} from '../../actions/types';

const userBranchReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_USERBRANCH:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_USERBRANCH:
            return {...action.payload};
        case GET_USERBRANCH:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_USERBRANCH: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_USERBRANCH:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default userBranchReducer;