import React from 'react';
import './App.css';
import MainLayoutWrapper from './layout/MainLayoutWrapper';
import LoginPage from './pages/Login/LoginPage';
import { connect } from 'react-redux';
import { getUserInfo } from './actions/loginAction';
import { withSnackbar } from 'notistack';
/*import useNotifier from './layout/useNotifier';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from './actions/snackbarAction';
*/
class App extends React.Component {
  componentDidMount = () =>{
    this.props.getUserInfo();
  }
  layout = () => {
    if(this.props.authUser.isAuth){
      return  <MainLayoutWrapper></MainLayoutWrapper>
    }
    else{
      return <LoginPage></LoginPage>
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.snackbarMessage !== this.props.snackbarMessage) {
        if(this.props.snackbarMessage?.message !== null && this.props.snackbarMessage?.message !== '' && this.props.snackbarMessage?.message !== undefined){
          this.props.snackbarMessage.message.split('<br/>').forEach(element => 
            this.props.enqueueSnackbar(element, {variant:this.props.snackbarMessage?.type, autoHideDuration:7000}) //default | error | success | warning | info
            );
        }
      }
  }
  render(){
    return this.layout();
  }
}


const mapStateToProps = state => {
  return { authUser: state.authUser,
    snackbarMessage: state.snackbarMessage };
}

export default connect(mapStateToProps, { getUserInfo } )(withSnackbar(App));
