//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createUNPartnerDetails } from '../../actions/uNPartnerDetailsAction';
import FormUNPartnerDetails from './FormUNPartnerDetails';

class CreateUNPartnerDetails extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createUNPartnerDetails(formValues);
    }
    
    render(){
        return (
            <FormUNPartnerDetails 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title="Create">
            </FormUNPartnerDetails>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UNPartnerDetailsDialog === undefined || state.dialog.UNPartnerDetailsDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.UNPartnerDetailsDialog};
}


export default connect(mapStateToProps, { createUNPartnerDetails })(CreateUNPartnerDetails);
