//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogLoanApproval, updateLoanApproval } from '../../actions/loanApprovalAction';
import FormLoanApproval from './FormLoanApproval';

class EditLoanApproval extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateLoanApproval(formValues);
    }
    
    render(){
        return (
            <FormLoanApproval 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormLoanApproval>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.LoanApprovalDialog === undefined || state.dialog.LoanApprovalDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.LoanApprovalDialog};
}


export default connect(mapStateToProps, { closeDialogLoanApproval, updateLoanApproval })(EditLoanApproval);
