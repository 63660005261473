//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateUNPartnerDetails from './CreateUNPartnerDetails';
import EditUNPartnerDetails from './EditUNPartnerDetails';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogUNPartnerDetails, editDialogUNPartnerDetails, deleteUNPartnerDetails } from "../../actions/uNPartnerDetailsAction";
import { dateFormat } from '../Share/Table/DateFormat';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


class TableUNPartnerDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isDialogVisible: false, xml: '' }
  }
  componentDidMount = () => {
  }

  partnerName = (props) => {
    return this.props.partners.find(el => el.value == props.row.partnerID)?.text;
  }
  columns = [
    { field: 'lastUpdate', headerName: i18next.t('LastUpdate'), minWidth: 150, type: 'date', valueGetter: dateFormat },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, type: 'date', valueGetter: dateFormat },
    { field: 'serializedObject', headerName: i18next.t('SerializedObject'), minWidth: 150, flex: 1 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },

    {
      field: 'show',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.show(params)}
          >
            {i18next.t('Show')}
          </Button>
        </strong>
      ),
    },
    // {
    //   field: 'delete',
    //   headerName: ' ',
    //   renderCell: (params) => (
    //     <strong>                
    //       <DeleteButton onClick={() => this.delete(params)} />
    //     </strong>
    //   )
    // }
  ];
  // create = () => {
  //   this.props.addDialogUNPartnerDetails();
  // }
  show = (params) => {
    this.setState({ isDialogVisible: true, xml: params.row.serializedObject });
  }
  // delete = (params) => {
  //   this.setState({IsOpenConfirmDialog: true, ID: params.row.id});
  // }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.partnerSelected.unPartnerDetails}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          checkboxSelection
          disableSelectionOnClick
        />

        <Dialog
          fullWidth
          maxWidth="l"
          open={this.state.isDialogVisible}
                    
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
          <DialogTitle id="alert-dialog-title">{i18next.t('Details')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <pre>{this.state.xml}</pre>
            </DialogContentText>

            <DialogActions>
              <Button
                    onClick={() => { this.setState({ isDialogVisible: false }) }}
                    variant="contained" //outlined contained
                    color="primary">
                    {i18next.t('Cancel')}
                </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partnerSelected: state.partnerSelected
  };
}

export default connect(mapStateToProps,
  {
    addDialogUNPartnerDetails,
    editDialogUNPartnerDetails,
    deleteUNPartnerDetails
  })(TableUNPartnerDetails);