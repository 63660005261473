import React from "react";
import { connect } from 'react-redux';
import {
    Box,
    Grid,
    Stack,
    Typography,
    List,
    ListItem,
    ListItemText,
    Button
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import i18next from '../../language/i18n';
import { getDebt } from "../../actions/reportAction";

class LoanDetails extends React.Component {
    createData = (name, calories, fat, carbs, protein, dayLate) => {
        return { name, calories: calories.toFixed(2), fat: fat.toFixed(2), carbs: carbs.toFixed(2), protein: protein.toFixed(2), dayLate: dayLate };
    }
    calculateData = () => {
        console.log(this.props.loan);
        let principalDayLate = 0;
        let interestDayLate = 0;
        try {
            if (this.props.loan?.loanQualityStatus?.length > 0) {
                let last = this.props.loan.loanQualityStatus.sort(function (a, b) {
                    return b.sequence - a.sequence
                })[0];
                console.log(last);
                principalDayLate = last.calculateLatePaymentDays;
                interestDayLate = last.calculateInterestLatePaymentDays;
            }
        }
        catch (err) { console.log(err); }
        return [
            this.createData('Glavnica',
                this.props.loan.loan.requestedAmount,
                this.props.loan.debtCalculationData.principalRepaid,
                this.props.loan.debtCalculationData.principalDue,
                this.props.loan.loan.requestedAmount - this.props.loan.debtCalculationData.principalRepaid,
                principalDayLate),
            this.createData('Kamata',
                this.props.loan.loan.totalToRepay - this.props.loan.loan.requestedAmount, //this.props.loan.debtCalculationData.interestRepaymentSum,  //this.props.loan.debtCalculationData.interestRepaymentSum
                this.props.loan.debtCalculationData.interestRepaid,
                this.props.loan.debtCalculationData.interestDue,
                this.props.loan.loan.loanStatusID == 7 ? 0 : (this.props.loan.loan.totalToRepay - this.props.loan.loan.requestedAmount - this.props.loan.debtCalculationData.interestRepaid),
                interestDayLate),
            this.createData('Ukupno',
                this.props.loan.loan.totalToRepay,  //this.props.loan.loan.requestedAmount + this.props.loan.debtCalculationData.interestRepaymentSum
                this.props.loan.debtCalculationData.principalRepaid + this.props.loan.debtCalculationData.interestRepaid,
                this.props.loan.debtCalculationData.principalDue + this.props.loan.debtCalculationData.interestDue,
                this.props.loan.loan.loanStatusID == 7 ? (this.props.loan.loan.requestedAmount - this.props.loan.debtCalculationData.principalRepaid) : (this.props.loan.loan.totalToRepay - this.props.loan.debtCalculationData.principalRepaid - this.props.loan.debtCalculationData.interestRepaid),
                principalDayLate > interestDayLate ? principalDayLate : interestDayLate),
        ]
    };
    render() {
        let rows = this.calculateData();
        return (
            <Grid container rowSpacing={2} columnSpacing={2.75}>
                 <Grid item xs={12} sm={12} md={12} lg={12}>
                 <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Zaduženje</TableCell>
                            <TableCell align="right">Uplaćeno</TableCell>
                            <TableCell align="right">Dospjeli dug</TableCell>
                            <TableCell align="right">Ukupan dug</TableCell>
                            <TableCell align="right">Kašnjenje</TableCell>
                        </TableRow>
                        
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                            <TableCell align="right">{row.dayLate}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
                <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ margin: 16, marginLeft: 22}}
            onClick={() => this.props.getDebt(this.props.loan.loan.id)}
          >
            {i18next.t('DebtStatus')}
          </Button>
                </Grid>
        );
    }
}

const mapStateToProps = state => {
    return {
        loan: state.loanSelected
    };
}


export default connect(mapStateToProps, { getDebt })(LoanDetails);