//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_BOARDMEMBER,
    GET_ALL_BOARDMEMBER,
    GET_BOARDMEMBER,
    GET_ALL_SELECTBOX_BOARDMEMBER,
    DELETE_BOARDMEMBER,
    UPDATE_BOARDMEMBER,
    ADD_DIALOG_BOARDMEMBER,
    EDIT_DIALOG_BOARDMEMBER,
    CLOSE_DIALOG_BOARDMEMBER
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createBoardMember = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMember/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BOARDMEMBER));
    if(response.data?.succeeded){
        dispatch(reset('formBoardMember'));
    }
}

export const getAllBoardMembers = () => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMember/GetAll');
    dispatch(responseHandler(response, GET_ALL_BOARDMEMBER));
}

export const getBoardMember = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMember/Get/' + id);
    dispatch(responseHandler(response, GET_BOARDMEMBER));
}

export const getSelectBoxBoardMembers = () => async (dispatch) => {
    const response = await api.get('/api/v1/BoardMember/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_BOARDMEMBER));
}

export const updateBoardMember = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMember/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BOARDMEMBER));
}

export const deleteBoardMember = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/BoardMember/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BOARDMEMBER));
}

export const addDialogBoardMember = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BOARDMEMBER,
        payload: null
    });
}

export const editDialogBoardMember = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BOARDMEMBER,
        payload: item
    });
}

export const closeDialogBoardMember = () => async (dispatch) => {
    dispatch(reset('formBoardMember'));
    dispatch({
        type: CLOSE_DIALOG_BOARDMEMBER,
        payload: undefined
    });
}