import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogPartnerNote } from '../../actions/partnerNoteAction';
import { EditSelectField, EditTextField, EditDatePickerField } from '../Share/EditComponents';

class FormPartnerNote extends React.Component{
    handleClose = () => {
        this.props.closeDialogPartnerNote();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            {this.props.showPartner === true ? (<div>
                                <Field
                                    name='partnerID'
                                    component ={this.props.EditSelectField}
                                    label = 'Partner'
                                    dataSource={this.props.partnersSelectBox?.filter(el => this.props.loanPartners.filter(el => el.id > 0).map(m => parseInt(m.partnerID)).indexOf(el.value) != -1)}
                                    >
                                </Field>
                            </div>) : null}
                            
            
                            <div>
                                <Field
                                    name='note'
                                    component={this.props.EditTextField}
                                    label='Note'
                                />
                            </div>
                            <div>
                                <Field
                                    name='newCall'
                                    component={this.props.EditDatePickerField}
                                    label='NewCall'
                                />
                            </div>
                            <div>
                                <Field
                                    name='strategy'
                                    component={this.props.EditTextField}
                                    label='Strategy'
                                />
                            </div>
                            <div>
                                <Field
                                    name='paymentNoteTypeID'
                                    component ={this.props.EditSelectField}
                                    label = 'PaymentNoteType'
                                    dataSource={this.props.paymentNoteTypesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.partnerID){
        errors.partnerID = 'Polje je obavezno!';
    }
    if(!formValues.note){
        errors.note = 'Polje je obavezno!';
    }
    if(!formValues.strategy){
        errors.strategy = 'Polje je obavezno!';
    }
    if(!formValues.paymentNoteTypeID){
        errors.paymentNoteTypeID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        partnersSelectBox: Object.values(state.partnersSelectBox), 
        paymentNoteTypesSelectBox: Object.values(state.paymentNoteTypesSelectBox),  
        loanPartners: Object.values(state.loanPartners)
     }
}
const formWrapper = reduxForm({
    form: 'formPartnerNote',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormPartnerNote);

export default connect(mapStateToProps, { closeDialogPartnerNote, EditSelectField, EditTextField, EditDatePickerField})(formWrapper);