import api from "../api/myApi";
import { 
    LOANMAINTENANCE_LOAN_POSTING,
    LOANMAINTENANCE_CLOSING_AVANS,
    LOANMAINTENANCE_RECLASSIFICATION,
    LOANMAINTENANCE_MONTHLY_RESERVATIONS,
    LOANMAINTENANCE_CRK_EXPORT,
    LOANMAINTENANCE_GET_INFO,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types'; //
import { responseHandler } from "../api/responseHandler";
import DateProvider from '../share/dateProvider';
import { showZipFile } from "../share/fileHandler";

export const getInfo = () => async (dispatch) => {
    const response = await api.post('/api/v1/ActionLog/GetLastActionsLogInfo', {companyID: null});
    dispatch(responseHandler(response, LOANMAINTENANCE_GET_INFO));
}

export const loanPosting = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/LoanPosting', {date: DateProvider.GetDateJSON(formValues.date)});
    dispatch(responseHandler(response, LOANMAINTENANCE_LOAN_POSTING, formValues.date));
}

// export const advanceMove = (formValues) => async (dispatch) => {
// const response = await api.post('/api/v1/Loan/AdvanceMove', {date: DateProvider.GetDateJSON(formValues.date)});
// dispatch(responseHandler(response, LOANMAINTENANCE_CLOSING_AVANS, formValues.date));
// }
export const advanceClosing = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/AdvanceClosing', {date: DateProvider.GetDateJSON(formValues.date)});
    dispatch(responseHandler(response, LOANMAINTENANCE_CLOSING_AVANS, formValues.date));
}

export const reclasification = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/Reclasification', {
        date: DateProvider.GetDateJSON(formValues.date),
        changeAccounts: false
    });
    dispatch(responseHandler(response, LOANMAINTENANCE_RECLASSIFICATION, formValues.date));
}

export const monthlyReservations = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Loan/Reclasification', {
        date: DateProvider.GetDateJSON(formValues.date),
        changeAccounts: true
    });
    dispatch(responseHandler(response, LOANMAINTENANCE_MONTHLY_RESERVATIONS, formValues.date));
}

export const crkExport = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanQualityStatus/GetCRK', {
        date: DateProvider.GetDateJSON(formValues.date)
    }, {responseType: 'blob'});
    dispatch({
        type: LOANMAINTENANCE_CRK_EXPORT,
        payload: formValues.date
    })
    showZipFile(response);
}
