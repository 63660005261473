import { Upcoming } from '@mui/icons-material';
import { 
   UPDATE_LOAN,
   UPDATE_LOAN_START_DATE,
   UPDATE_LOAN_MADE_ACTION,
   LOANMAINTENANCE_LOAN_POSTING,
   LOANMAINTENANCE_CLOSING_AVANS,
   LOANMAINTENANCE_RECLASSIFICATION,
   LOANMAINTENANCE_MONTHLY_RESERVATIONS
} from '../actions/types';

const snackbarMessageReducer = (state = {message: "", type:""}, action) => {
    switch(action.type){
        case "error":
            return {message: action.payload, type:"error"};
        case "success":
            return {message: action.payload, type:"success"};
        case UPDATE_LOAN:
        case UPDATE_LOAN_START_DATE:
        case UPDATE_LOAN_MADE_ACTION:
        case LOANMAINTENANCE_LOAN_POSTING:
        case LOANMAINTENANCE_CLOSING_AVANS:
        case LOANMAINTENANCE_RECLASSIFICATION:
        case LOANMAINTENANCE_MONTHLY_RESERVATIONS:
            return {message: "Operacija uspešno izvršena!", type:"success"}
         default:
            return state;
    }
}
export default snackbarMessageReducer;