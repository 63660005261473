//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogRefinancing, updateRefinancing } from '../../actions/refinancingAction';
import FormRefinancing from './FormRefinancing';

class EditRefinancing extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateRefinancing(formValues);
    }
    
    render(){
        return (
            <FormRefinancing 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormRefinancing>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.RefinancingDialog === undefined || state.dialog.RefinancingDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.RefinancingDialog};
}


export default connect(mapStateToProps, { closeDialogRefinancing, updateRefinancing })(EditRefinancing);
