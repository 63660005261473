import api from "../api/myApi";
import { 
    CREATE_BANKSTATEMENTIMPORT,
    GET_ALL_BANKSTATEMENTIMPORT,
    UPLOAD_BANKSTATEMENTIMPORT,
    GET_BANKSTATEMENTIMPORT,
    GET_ALL_SELECTBOX_BANKSTATEMENTIMPORT,
    DELETE_BANKSTATEMENTIMPORT,
    UPDATE_BANKSTATEMENTIMPORT,
    ADD_DIALOG_BANKSTATEMENTIMPORT,
    EDIT_DIALOG_BANKSTATEMENTIMPORT,
    CLOSE_DIALOG_BANKSTATEMENTIMPORT,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";
import DateProvider from '../share/dateProvider';

export const createBankStatementImport = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/BankStatementImport/Create', formValues);    
    dispatch(responseHandler(response, CREATE_BANKSTATEMENTIMPORT));
    if(response.data?.succeeded){
        dispatch(reset('formBankStatementImport'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllBankStatementImports = (cashierID, date) => async (dispatch) => {    
    const response = await api.get(`/api/v1/BankStatementImport/GetAll?cashierID=${cashierID}&date=${DateProvider.GetDateJSON(date)}`);
    dispatch(responseHandler(response, GET_ALL_BANKSTATEMENTIMPORT));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

// export const getBankStatementImport = (id) => async (dispatch) => {
//     const response = await api.get('/api/v1/BankStatementImport/Get/' + id);
//     dispatch(responseHandler(response, GET_BANKSTATEMENTIMPORT));
// }

// export const getSelectBoxBankStatementImports = () => async (dispatch) => {
//     const response = await api.get('/api/v1/BankStatementImport/GetSelectBox');
//     dispatch(responseHandler(response, GET_ALL_SELECTBOX_BANKSTATEMENTIMPORT));
// }

export const updateBankStatementImport = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/BankStatementImport/Update', formValues);
    dispatch(responseHandler(response, UPDATE_BANKSTATEMENTIMPORT));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteBankStatementImport = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImport/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_BANKSTATEMENTIMPORT));
}

export const addDialogBankStatementImport = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_BANKSTATEMENTIMPORT,
        payload: null
    });
}

export const editDialogBankStatementImport = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_BANKSTATEMENTIMPORT,
        payload: item
    });
}

export const closeDialogBankStatementImport = () => async (dispatch) => {
    dispatch(reset('formBankStatementImport'));
    dispatch({
        type: CLOSE_DIALOG_BANKSTATEMENTIMPORT,
        payload: undefined
    });
}

export const fileBankStatementImport = (file, cashierID) => async (dispatch) => {
    let formData = new FormData();
    formData.append("File", file);
    formData.append("CashierID", cashierID);

    const response = await api.post("/api/v1/BankStatementImport/ImportFile/", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    dispatch(responseHandler(response, UPLOAD_BANKSTATEMENTIMPORT));
    return response;
    //console.log(response);
    /*
    dispatch(reset('formBankStatementImport'));
    dispatch({
        type: CLOSE_DIALOG_BANKSTATEMENTIMPORT,
        payload: undefined
    });*/
}

export const connectLoanBankStatementImport = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImport/ConnectLoan', formValues);
    dispatch(responseHandler(response, UPDATE_BANKSTATEMENTIMPORT));
}

export const connectPartnerBankStatementImport = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImport/ConnectPartner', formValues);
    dispatch(responseHandler(response, UPDATE_BANKSTATEMENTIMPORT));
}


export const approveBankStatementImport = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImport/Approve', formValues);
    dispatch(responseHandler(response, UPDATE_BANKSTATEMENTIMPORT));
}

export const importProcessBankStatementImport = (cashierID, date) => async (dispatch) => {
    const response = await api.post('/api/v1/BankStatementImport/ImportProcess', {cashierID:cashierID, date:DateProvider.GetDateJSON(date)});
    dispatch(responseHandler(response, 'success'));
}

