//Mojo-AG
import { 
    CREATE_LOANWRITEOFF,
    GET_ALL_LOANWRITEOFF,
    GET_LOANWRITEOFF,
    DELETE_LOANWRITEOFF,
    UPDATE_LOANWRITEOFF,
    GET_LOAN
} from '../../actions/types';

const loanWriteOffReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANWRITEOFF:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANWRITEOFF:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_LOANWRITEOFF:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANWRITEOFF: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case GET_LOAN:
                let result3 = {  };
                for(let i=0; i<action.payload.loanWriteOff.length;i++) {result3[action.payload.loanWriteOff[i].id]=action.payload.loanWriteOff[i];}
                return result3;
        case UPDATE_LOANWRITEOFF:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default loanWriteOffReducer;