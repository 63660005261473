import { 
    GET_ALL_DEBS_LEGALSERVICE,
    CREATE_LEGALSERVICE_WARNING
} from '../../actions/types';

const legalService_DebsReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_DEBS_LEGALSERVICE:{
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        }
        case CREATE_LEGALSERVICE_WARNING:{
            let result = {...state};
            delete result[action.payload.loanID];
            return result;
        }
        default:
            return state;
    }
}

export default legalService_DebsReducer;