import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogBankStatementImport, updateBankStatementImport } from '../../actions/bankStatementImportAction';
import FormBankStatementImport from './FormBankStatementImport';

class EditBankStatementImport extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateBankStatementImport(formValues);
    }
    
    render(){
        return (
            <FormBankStatementImport 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormBankStatementImport>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BankStatementImportDialog === undefined || state.dialog.BankStatementImportDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.BankStatementImportDialog};
}


export default connect(mapStateToProps, { closeDialogBankStatementImport, updateBankStatementImport })(EditBankStatementImport);
