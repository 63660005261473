import React from "react";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton,  GridToolbarDensitySelector } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AddIcon from '@material-ui/icons/Add';
import PrintIcon from '@mui/icons-material/Print';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import i18next from '../../language/i18n';

class TablePayWarningToolbar extends React.Component{

//<GridToolbarExport />
    render(){
        return(
            <GridToolbarContainer>
                <Button color="primary" startIcon={<AddIcon />} onClick={this.props.create}>
                    {i18next.t('CreateAWarning')}
                </Button>
                <Button color="primary" startIcon={<LibraryBooksIcon />} onClick={this.props.posting}>
                    {i18next.t('Posting')}
                </Button>
                <Button color="primary" startIcon={<PrintIcon />} onClick={this.props.print}>
                    {i18next.t('Print')}
                </Button>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    }
}

export default TablePayWarningToolbar;