import React from "react";
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { changePassword } from '../../actions/loginAction';
import { EditTextField } from '../../components/Share/EditComponents';
import i18next from '../../language/i18n';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

class ChangePasswordPage extends React.Component{
    onSubmit = (formValues) => {
      this.props.changePassword(formValues);
    };
    render(){
        return(<Box component="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate sx={{ mt: 1 }}>
         <Field
            name='currentPassword'
            component={this.props.EditTextField}
            label='CurrentPassword'
            type="password"
          />
          <Field
          name='password'
          component={this.props.EditTextField}
          label='Password'
          type="password"
        />
          <Field
            name='confirmPassword'
            component={this.props.EditTextField}
            label='ConfirmPassword'
            type="password"
          />
        
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {i18next.t('Submit')}
          </Button>
        </Box>);
    }
}


const formWrapper = reduxForm({
    form: 'formChangePassword',
    destroyOnUnmount: false,
    enableReinitialize : true
  })(ChangePasswordPage)
  export default connect(null, { changePassword, EditTextField})(formWrapper);