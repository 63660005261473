//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateFileUpload from './CreateFileUpload';
import i18next from '../../language/i18n';
import { addDialogFileUpload, getFile, deleteFileUpload, getAllFileUploads } from "../../actions/fileUploadAction";
import { getSelectBoxLoans } from '../../actions/loanAction';
import DeleteButton from '../Share/Button/DeleteButton';
import { getSelectBoxFileUploadTypes } from '../../actions/fileUploadTypeAction';


class TableFileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    //this.props.getSelectBoxLoans();
    this.props.getSelectBoxFileUploadTypes();
    //this.props.getAllFileUploads();
  }

  loanName = (props) => {
    return this.props.loans.find(el => el.value == props.row.loanID)?.text;
  }
  fileUploadTypeName = (props) => {
    return this.props.fileUploadTypes.find(el => el.value == props.row.fileUploadTypeID)?.text;
  }
  columns = [

    // {
    //   field: 'LoanID',
    //   headerName: i18next.t('Loan'),
    //   width: 160,
    //   valueGetter: this.loanName,
    //   sortComparator: (v1, v2, cellParams1, cellParams2) =>
    //     this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
    // },
    { field: 'fileName', headerName: i18next.t('FileName'), minWidth: 150, flex: 1 },
    //{ field: 'fileLocation', headerName: i18next.t('FileLocation'), width: 150 },

    {
      field: 'FileUploadTypeID',
      headerName: i18next.t('FileUploadType'),
      minWidth: 160, flex: 1,
      valueGetter: this.fileUploadTypeName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.fileUploadTypeName(cellParams1).localeCompare(this.fileUploadTypeName(cellParams2))
    },
    //{ field: 'contentType', headerName: i18next.t('ContentType'), width: 150 },
    // { field: 'createDate', headerName: i18next.t('CreateDate'), width: 150 },
    // { field: 'editDate', headerName: i18next.t('EditDate'), width: 150 },

    {
      field: 'download',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.download(params)}
          >
            {i18next.t('Download')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogFileUpload();
  }
  download = (params) => {
    this.props.getFile(params.row.id, params.row.fileName);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.fileUploads}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateFileUpload></CreateFileUpload>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteFileUpload(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fileUploads: Object.values(state.fileUploads),
    //loans: Object.values(state.loansSelectBox), 
    fileUploadTypes: Object.values(state.fileUploadTypesSelectBox)
  };
}

export default connect(
  mapStateToProps,
  {
    addDialogFileUpload,
    getFile,
    deleteFileUpload,
    getAllFileUploads,
    getSelectBoxLoans,
    getSelectBoxFileUploadTypes
  })(TableFileUpload);