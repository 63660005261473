//Mojo-AG
import api from "../api/myApi";
import {
    GET_ALL_NOTIFICATION,
    READ_NOTIFICATION,
    READ_ALL_NOTIFICATION
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const getAllNotifications = () => async (dispatch) => {
    try {
        const response = await api.get('/api/v1/Notification/GetAll');
        dispatch(responseHandler(response, GET_ALL_NOTIFICATION));
    } catch { }
}

export const readNotification = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Notification/Read', { ID: id });
    dispatch(responseHandler(response, READ_NOTIFICATION));
}


export const readAllNotification = () => async (dispatch) => {
    const response = await api.post('/api/v1/Notification/ReadAll');
    dispatch(responseHandler(response, READ_ALL_NOTIFICATION));
}