import { 
    GET_ALL_ORDER_IN_IMBALANCE
} from '../../actions/types';

const orderInImbalanceReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_ORDER_IN_IMBALANCE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        default:
            return state;
    }
}

export default orderInImbalanceReducer;