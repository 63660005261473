import { 
    CREATE_GENDER,
    GET_ALL_GENDER,
    GET_GENDER,
    DELETE_GENDER,
    UPDATE_GENDER
} from '../../actions/types';

const genderReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_GENDER:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_GENDER:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_GENDER:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_GENDER: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_GENDER:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default genderReducer;