import * as React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from '../../actions/loginAction';
import { EditTextField } from '../../components/Share/EditComponents';
import i18next from '../../language/i18n';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mojosoft.dev">
        MojoSoft
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

class LoginPage extends React.Component {
  onSubmit = (formValues) => {
    this.props.login(formValues);
  };
  render(){
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {i18next.t('SignIn')}
          </Typography>
          <Box component="form" onSubmit={this.props.handleSubmit(this.onSubmit)} noValidate sx={{ mt: 1 }}>
          <Field
              name='email'
              component={this.props.EditTextField}
              label='Email'
            />
            <Field
              name='password'
              component={this.props.EditTextField}
              label='Password'
              type="password"
            />
            {/*<FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
        />*/}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {i18next.t('SignIn')}
            </Button>
           { /*<Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
        </Grid>*/}
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  )
}
}


const formWrapper = reduxForm({
  form: 'formLogin',
  destroyOnUnmount: false,
  enableReinitialize : true
})(LoginPage)
export default connect(null, { login, EditTextField})(formWrapper);