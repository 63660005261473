import { 
    GET_ALL_SELECTBOX_TAXGROUPS
} from '../../actions/types';

 const taxGroupsSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_TAXGROUPS:
            return [...action.payload];
        default:
            return state;
    }
}
export default taxGroupsSelectBoxReducer;