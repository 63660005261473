import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateBranch from './CreateBranch';
import EditBranch from './EditBranch';
import { addDialogBranch, editDialogBranch, deleteBranch, getAllBranches } from "../../actions/branchAction";
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { getSelectBoxEntities } from '../../actions/entityAction';
import { getSelectBoxCities } from '../../actions/cityAction';
import { getSelectBoxRegions } from '../../actions/regionAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableBranch extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxCompanyDetails();
    this.props.getSelectBoxEntities();
    this.props.getSelectBoxCities();
    this.props.getSelectBoxRegions();

    this.props.getAllBranches();
  }

  companyName = (props) => {
    return this.props.companyDetails.find(el => el.value === props.row.companyDetailsID)?.text;
  }
  entityName = (props) => {
    return this.props.entities.find(el => el.value === props.row.entityID)?.text;
  }
  cityName = (props) => {
    return this.props.cities.find(el => el.value === props.row.cityID)?.text;
  }
  regionName = (props) => {
    return this.props.regions.find(el => el.value === props.row.regionID)?.text;
  }
  columns = [
    { field: 'name', headerName: i18next.t('Name'), minWidth: 150, flex: 1 },

    {
      field: 'companyDetailsID',
      headerName: i18next.t('CompanyDetails'),
      width: 160,
      valueGetter: this.companyName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
    },
    {
      field: 'EntityID',
      headerName: i18next.t('Entity'),
      width: 160,
      valueGetter: this.entityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.entityName(cellParams1).localeCompare(this.entityName(cellParams2))
    },
    {
      field: 'CityID',
      headerName: i18next.t('City'),
      width: 160,
      valueGetter: this.cityName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.cityName(cellParams1).localeCompare(this.cityName(cellParams2))
    },
    { field: 'address', headerName: i18next.t('Address'), minWidth: 150, flex: 1 },
    {
      field: 'RegionID',
      headerName: i18next.t('Region'),
      width: 160,
      valueGetter: this.regionName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.regionName(cellParams1).localeCompare(this.regionName(cellParams2))
    },
    { field: 'hasCashRegister', headerName: i18next.t('HasCashRegister'), minWidth: 150, flex: 1, type: 'boolean' },
    //{ field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1, valueGetter: dateFormat },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogBranch();
  }
  edit = (params) => {
    this.props.editDialogBranch(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.branches}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateBranch></CreateBranch>
        <EditBranch></EditBranch>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteBranch(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    branches: Object.values(state.branches),
    companyDetails: Object.values(state.companyDetailsSelectBox),
    entities: Object.values(state.entitiesSelectBox),
    cities: Object.values(state.citiesSelectBox),
    regions: Object.values(state.regionsSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogBranch,
    editDialogBranch,
    deleteBranch,
    getAllBranches,
    getSelectBoxCompanyDetails,
    getSelectBoxEntities,
    getSelectBoxCities,
    getSelectBoxRegions,
    showLoadingDataInProgress
  })(TableBranch);