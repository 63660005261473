import React from 'react';
import { connect } from 'react-redux';
import { closeDialogUserBranch, updateUserBranch } from '../../actions/userBranchAction';
import FormUserBranch from './FormUserBranch';
import i18next from '../../language/i18n';

class EditUserBranch extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateUserBranch(formValues);
    }
    
    render(){
        return (
            <FormUserBranch 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormUserBranch>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserBranchDialog === undefined || state.dialog.UserBranchDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.UserBranchDialog};
}


export default connect(mapStateToProps, { closeDialogUserBranch, updateUserBranch })(EditUserBranch);
