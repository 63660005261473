//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { createFileUpload } from '../../actions/fileUploadAction';
import FormFileUpload from './FormFileUpload';

class CreateFileUpload extends React.Component{
    // onSubmit  = (formValues) => {
    //     this.props.createFileUpload(formValues);
    // }
    
    render(){
        return ( this.props.IsOpen ?
            <FormFileUpload 
                IsOpen = {this.props.IsOpen}
                //onSubmit={this.onSubmit}
                title="Create">
            </FormFileUpload> : null
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.FileUploadDialog === undefined || state.dialog.FileUploadDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.FileUploadDialog};
}


export default connect(mapStateToProps, { createFileUpload })(CreateFileUpload);
