import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TableBankStatementImportSplit from './TableBankStatementImportSplit';
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';

class TableBankStatementImportSplitDialog extends React.Component {
    render() {
        return (<div>
            <Dialog
                open={this.props.isOpen}

                maxWidth={'md'}
                fullWidth={true}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Nalogodavac: {this.props.selectedRowInput?.principal}<br/>
                    Svrha: {this.props.selectedRowInput?.purposeOfPayment}<br/>
                    Iznos: {this.props.selectedRowInput?.payIn}<br/>
                    </DialogTitle>
                <DialogContent>
                    <TableBankStatementImportSplit
                        bankStatementImportID={this.props.selectedRowInput?.id}
                    ></TableBankStatementImportSplit>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="secondary" variant="outlined">
                            {i18next.t('Close')}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </div>)
    }
}

export default TableBankStatementImportSplitDialog;