import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCashBalance } from '../../actions/cashBalanceAction';
import { getUserInfo } from '../../actions/loginAction';
import { EditSelectField, EditIntegerNumberField, EditDecimalNumberField, EditCheckbox, EditDatePickerField } from '../Share/EditComponents';

class FormCashBalance extends React.Component{
    componentDidMount(){
        this.props.getUserInfo();
    }
    handleClose = () => {
        this.props.closeDialogCashBalance();
    }
    renderCompany = () => {
        if(this.props.userData.roles.includes('Admin')){
            return (<div>
                <Field
                    name='companyID'
                    component ={this.props.EditSelectField}
                    label = 'Company'
                    dataSource={this.props.companiesSelectBox}
                    >
                </Field>
            </div>)
        }
        else{
            return null;
        }
        
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>                            
                            {this.renderCompany()}
                            <div>
                                <Field
                                    name='date'
                                    component={this.props.EditDatePickerField}
                                    label='Date'
                                />
                            </div>
                            <DialogActions>
                            <CancelButton onClick={this.handleClose}></CancelButton>
                            <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.date){
        errors.date = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox), cashiersSelectBox: Object.values(state.cashiersSelectBox), userData: state.authUser  }
}
const formWrapper = reduxForm({
    form: 'formCashBalance',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormCashBalance);

export default connect(mapStateToProps, { getUserInfo, closeDialogCashBalance, EditSelectField, EditIntegerNumberField, EditDecimalNumberField, EditCheckbox, EditDatePickerField})(formWrapper);