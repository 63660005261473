import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { closeDialogCompany, updateCompany } from '../../actions/companyAction';
import FormCompany from './FormCompany';

class EditCompany extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateCompany(formValues);
    }
    
    render(){
        return (
            <FormCompany 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title={i18next.t('Edit')}>
            </FormCompany>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.CompanyDialog === undefined || state.dialog.CompanyDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.CompanyDialog};
}


export default connect(mapStateToProps, { closeDialogCompany, updateCompany })(EditCompany);
