import { 
    GET_ALL_SELECTBOX_BLTURNOVER
} from '../../actions/types';

 const bLTurnoverSelectBoxReducer = (state = {}, action) => {
    switch(action.type){
        case GET_ALL_SELECTBOX_BLTURNOVER:
            return [...action.payload];
        default:
            return state;
    }
}
export default bLTurnoverSelectBoxReducer;