import { 
    GET_MYCOMPANY_DATA,
    GET_MYCOMPANY_DATA_YEAR,
} from '../../actions/types';

const myCompanyDataReducer = (state = {name: '', currentYear: 0}, action) => {
    switch(action.type){
        case GET_MYCOMPANY_DATA:
            return { ...action.payload };
        case GET_MYCOMPANY_DATA_YEAR:
            return { ...state, currentYear: action.payload };
        default:
            return state;
    }
}

export default myCompanyDataReducer;