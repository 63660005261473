import React from "react";
import { EditSelectFieldClear } from '../../components/Share/EditComponents';
import TablePartner from '../../components/Partner/TablePartner';
import TableLoanByPartner from '../../components/Loan/TableLoanByPartner';
import TablePartnerNote from '../../components/PartnerNote/TablePartnerNote';
import ViewPartner from '../../components/Partner/ViewPartner';
import TableIOS from '../../components/IOS/TableIOS';
import TableCRKRequest from '../../components/CRKRequest/TableCRKRequest';
import TableCommissionSettings from '../../components/CommissionSettings/TableCommissionSettings';
import TableUNPartnerDetails from '../../components/UNPartnerDetails/TableUNPartnerDetails';
import PartnerReports from './PartnerReports';
import { connect } from 'react-redux';
import { getSelectBoxPartners, getPartner } from "../../actions/partnerAction";
import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCities } from '../../actions/cityAction';
import { getSelectBoxGenders } from '../../actions/genderAction';
import { getSelectBoxMaritalStatus } from '../../actions/maritalStatusAction';
import { getSelectBoxPartnerTypes } from '../../actions/partnerTypeAction';
import { getSelectBoxEntities } from '../../actions/entityAction';
import { getSelectBoxEmploymentTypes } from '../../actions/employmentTypeAction';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import i18next from '../../language/i18n';

class PartnerDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { partnerID: null, tabValue: '1' }
    }

    componentDidMount = () => {
        this.props.getSelectBoxPartners();
        this.props.getSelectBoxCities();
        this.props.getSelectBoxGenders();
        this.props.getSelectBoxMaritalStatus();
        this.props.getSelectBoxPartnerTypes();
        this.props.getSelectBoxEntities();
        this.props.getSelectBoxEmploymentTypes();

        if (this.props.match.params.id !== null && this.props.match.params.id !== undefined) {
            let partnerID = parseInt(this.props.match.params.id);
            this.setState({ partnerID: partnerID });
            this.props.getPartner(partnerID);
        }
    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.match.params.id != prevProps.match.params.id) {
            let partnerID = parseInt(this.props.match.params.id);
            this.setState({ partnerID: partnerID });
            this.props.getPartner(partnerID);
        }
    }
    handleChange = (event, newValue) => {
        this.setState({ tabValue: newValue });
    };
    render() {
        return (
            <div>
                <EditSelectFieldClear
                    value={this.state.partnerID}
                    dataSource={this.props.partnersSelectBox}
                    name='partnerID'
                    label='Partner'
                    onChange={
                        (event, child) => {
                            let partnerID = parseInt(event.target.value);
                            this.setState({ partnerID: partnerID });
                            this.props.getPartner(partnerID);
                        }
                    }
                    autoFocus 
                />
                {
                    this.props.partnerSelected?.partnerData != undefined ? (
                        <Box sx={{ width: '100%', typography: 'body1' }}>
                            <TabContext value={this.state.tabValue}>
                                <TabList onChange={this.handleChange} variant="fullWidth">
                                    <Tab label={i18next.t('Partner')} value="1" />
                                    <Tab label={i18next.t('Loans')} value="2" />
                                    <Tab label={i18next.t('PartnerNote')} value="3" />
                                    {this.props.authUser.roles?.indexOf('Seller') > -1 ? null : <Tab label={i18next.t('IOS')} value="4" />}
                                    {this.props.authUser.roles?.indexOf('Seller') > -1 ? null : <Tab label={i18next.t('Reports')} value="5" />}
                                    {this.props.partnerSelected?.partnerData.isAssignee ? <Tab label={i18next.t('CommissionSettings')} value="6" /> : null}                                     
                                    <Tab label={i18next.t('CRK')} value="7" />
                                    <Tab label={i18next.t('UNRestrictions')} value="8" />
                                </TabList>
                                <TabPanel value="1"><ViewPartner /></TabPanel>
                                <TabPanel value="2"><TableLoanByPartner /></TabPanel>
                                <TabPanel value="3"><TablePartnerNote /></TabPanel>
                                    {this.props.authUser.roles?.indexOf('Seller') > -1 ? null : <TabPanel value="4"><TableIOS /></TabPanel>}
                                    {this.props.authUser.roles?.indexOf('Seller') > -1 ? null : <TabPanel value="5"><PartnerReports /></TabPanel>}  
                                {this.props.partnerSelected?.partnerData.isAssignee ? <TabPanel value="6"><TableCommissionSettings/></TabPanel> : null}                                
                                <TabPanel value="7">
                                    <TableCRKRequest
                                        disabled={true}
                                        partners={[this.props.partnerSelected?.partnerData?.id]}
                                    />
                                </TabPanel>                                
                                <TabPanel value="8"><TableUNPartnerDetails /></TabPanel>
                            </TabContext>
                        </Box>) : null

                }
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        partnersSelectBox: Object.values(state.partnersSelectBox),
        partnerSelected: state.partnerSelected,
        authUser: state.authUser //Seller
    }
}
export default connect(mapStateToProps, {
    EditSelectFieldClear,
    getSelectBoxPartners,
    getPartner,
    getSelectBoxCompanies,
    getSelectBoxCities,
    getSelectBoxGenders,
    getSelectBoxMaritalStatus,
    getSelectBoxPartnerTypes,
    getSelectBoxEntities,
    getSelectBoxEmploymentTypes
})(PartnerDetailsPage);