import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TableDebtCollectionCostDetailsToolbar from './TableDebtCollectionCostDetailsToolbar';
import { dateFormat } from '../Share/Table/DateFormat';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateDebtCollectionCostDetails from './CreateDebtCollectionCostDetails';
import EditDebtCollectionCostDetails from './EditDebtCollectionCostDetails';
import { addDialogDebtCollectionCostDetails, editDialogDebtCollectionCostDetails, deleteDebtCollectionCostDetails, getAllDebtCollectionCostDetails, postingDebtCollectionCostWarning } from "../../actions/debtCollectionCostDetailsAction";
import { getSelectBoxLoans } from '../../actions/loanAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import { getSelectBoxDebtCollectionCosts } from '../../actions/debtCollectionCostAction';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { EditDatePickerField2 } from '../../components/Share/EditComponents';


class TableDebtCollectionCostDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      IsOpenConfirmDialog: false,
      ID: null,
      date: new Date()
    }
  }
  componentDidMount = () => {
    this.props.getSelectBoxPartners();
    this.props.getSelectBoxDebtCollectionCosts();
    this.props.showLoadingDataInProgress();
    this.props.getSelectBoxLoans();
    this.loadData();
  }
  loanName = (props) => {
    return this.props.loans.find(el => el.value === props.row.loanID)?.text;
  }
  debtCollectionCostName = (props) => {
    return this.props.debtCollectionCosts.find(el => el.value === props.row.debtCollectionCostID)?.text;
  }
  columns = [
    {
      field: 'loanID',
      headerName: i18next.t('Loan'),
      minWidth: 150, flex: 2,
      valueGetter: this.loanName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
    },
    {
      field: 'debtCollectionCostID',
      headerName: i18next.t('DebtCollectionCostType'),
      minWidth: 150, flex: 2,
      valueGetter: this.debtCollectionCostName,
      sortComparator: (v1, v2, cellParams1, cellParams2) =>
        this.debtCollectionCostName(cellParams1).localeCompare(this.debtCollectionCostName(cellParams2))
    },
    { field: 'costAmount', headerName: i18next.t('CostAmount'), minWidth: 150, flex: 1 },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, valueGetter: dateFormat },


    //{ field: 'journalPageNumber', headerName: i18next.t('JournalPageNumber'), minWidth: 150, flex: 1 },


    //{ field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    //{ field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogDebtCollectionCostDetails();
  }
  posting = () => {
    this.props.postingDebtCollectionCostWarning(this.state.date);
  }
  edit = (params) => {
    this.props.editDialogDebtCollectionCostDetails(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  loadData = () => {
    this.props.getAllDebtCollectionCostDetails(this.state.date);
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <EditDatePickerField2
          date={this.state.date}
          onChange={(e) => { this.setState({ date: e }, this.loadData); }}
          value={this.state.date}
        >
        </EditDatePickerField2>

        <DataGrid
          rows={this.props.debtCollectionCostDetails}
          columns={this.columns}
          components={{
            Toolbar: TableDebtCollectionCostDetailsToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create, posting: this.posting } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateDebtCollectionCostDetails></CreateDebtCollectionCostDetails>
        <EditDebtCollectionCostDetails></EditDebtCollectionCostDetails>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteDebtCollectionCostDetails(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    debtCollectionCostDetails: Object.values(state.debtCollectionCostDetails),
    partners: Object.values(state.partnersSelectBox),
    loans: Object.values(state.loansSelectBox),
    debtCollectionCosts: Object.values(state.debtCollectionCostsSelectBox),
  };
}

export default connect(mapStateToProps,
  {
    addDialogDebtCollectionCostDetails,
    editDialogDebtCollectionCostDetails,
    deleteDebtCollectionCostDetails,
    getAllDebtCollectionCostDetails,
    getSelectBoxLoans,
    showLoadingDataInProgress,
    getSelectBoxPartners,
    getSelectBoxDebtCollectionCosts,
    postingDebtCollectionCostWarning
  })(TableDebtCollectionCostDetails);