import { 
    STATISTIC_LOAN_STATUS_STATISTIC,
    STATISTIC_REALIZED_LOANS,
    STATISTIC_LOAN_CLASSIFICATION
} from '../actions/types';
const statisticReducer = (state = {loanStatusStatistic:[], realizedLoans:[], loanClassification:[]}, action) => {
    switch(action.type){        
        case STATISTIC_LOAN_STATUS_STATISTIC:
            return {...state, loanStatusStatistic: action.payload};
        case STATISTIC_REALIZED_LOANS:
            return {...state, realizedLoans: action.payload};
        case STATISTIC_LOAN_CLASSIFICATION:
            return {...state, loanClassification: action.payload};
        default:
            return state;
    }
}
export default statisticReducer;