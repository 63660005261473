import React from "react";
import { EditSelectField, EditTextField, EditDatePickerField, EditCheckbox } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
    getDetailsByAccount,
    getDetailsByAccountWithoutGrouping,
    getDetailsByConnections,
    getSumByAccount,
    getIOS,
    getIOSNeto,
    getReview,
    getPartnerLoans
} from '../../actions/reportAction';

class PartnerReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentValue: 1 }
    }
    reports = [
        { value: 1, text: 'Detalji po kontima' },
        { value: 2, text: 'Detalji po kontima bez grupisanja' },    //Bo
        { value: 3, text: 'Zbirno po kontima' },
        { value: 4, text: 'Detalji po vezama' },
        { value: 5, text: 'IOS' },
        { value: 6, text: 'IOS Neto' },
        { value: 7, text: 'Pregled' },
        { value: 8, text: 'Karton klijenta' }
    ];
    onSubmit = (formValues) => {
        let reportType = parseInt(formValues.reportType);

        let data = [1, 2, 3].indexOf(reportType) != -1 ? {
            partnerID: this.props.partnerSelected.partnerData.id,
            fromDate: formValues.fromDate,
            toDate: formValues.toDate,
            link: formValues.link,
            isOffBalance: formValues.offBalance
        } :
            {
                partnerID: this.props.partnerSelected.partnerData.id,
                fromDate: formValues.fromDate,
                toDate: formValues.toDate
            }
        if (reportType === 1) {
            this.props.getDetailsByAccount(data);
        }
        else if (reportType === 2) {
            this.props.getDetailsByAccountWithoutGrouping(data);
        }
        else if (reportType === 3) {
            this.props.getDetailsByConnections(data);
        }
        else if (reportType === 4) {
            this.props.getSumByAccount(data);
        }
        else if (reportType === 5) {
            this.props.getIOS(data);
        }
        else if (reportType === 6) {
            this.props.getIOSNeto(data);
        }
        else if (reportType === 7) {
            this.props.getReview(data);
        }
        else if (reportType === 8) {
            this.props.getPartnerLoans({
                partnerID: this.props.partnerSelected.partnerData.id
            });

        }
    }
    render() {
        return (<div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                    name='reportType'
                    component={this.props.EditSelectField}
                    label='Reports'
                    onChange={
                        (e, child) => {
                            this.setState({ currentValue: parseInt(child) })
                        }
                    }
                    dataSource={this.reports}
                    hasEmpty={false}
                    >
                </Field>
                {this.state.currentValue != 7 ?
                    <><Field
                        name='fromDate'
                        component={this.props.EditDatePickerField}
                        label='FromDate'
                    />
                        <Field
                            name='toDate'
                            component={this.props.EditDatePickerField}
                            label='ToDate'
                        />
                    </> : null
                }
                {
                    [1, 2, 3].indexOf(this.state.currentValue) !== -1 ? (
                        <>
                            <Field
                                name='link'
                                component={this.props.EditTextField}
                                label='Link'
                            />
                            <Field
                                name='offBalance'
                                component={this.props.EditCheckbox}
                                label='OffBalance'
                            />
                        </>
                    ) : null
                }
                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                        {i18next.t('Report')}
                    </Button>
                </Box>
            </form>
        </div>);
    }
}


const validate = (formValues) => {
    const errors = {};
    if (!formValues.reportType) {
        errors.reportType = 'Polje je obavezno!';
    }
    if (!formValues.fromDate) {
        errors.fromDate = 'Polje je obavezno!';
    }
    if (!formValues.toDate) {
        errors.toDate = 'Polje je obavezno!';
    }
    if (!formValues.paymentNoteTypeID) {
        errors.paymentNoteTypeID = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formPartnerReport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportType: 1,
        fromDate: new Date(new Date().getFullYear(), 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
        link: '',
        offBalance: false
    }
})(PartnerReports);
const mapStateToProps = state => {
    return {
        partnerSelected: state.partnerSelected
    }
}
export default connect(mapStateToProps, {
    EditSelectField,
    EditTextField,
    EditDatePickerField,
    EditCheckbox,
    getDetailsByAccount,
    getDetailsByAccountWithoutGrouping,
    getDetailsByConnections,
    getSumByAccount,
    getIOS,
    getIOSNeto,
    getReview,
    getPartnerLoans
})(formWrapper);
