import { 
    CREATE_PARTNERNOTE,
    GET_ALL_PARTNERNOTE,
    GET_PARTNERNOTE,
    DELETE_PARTNERNOTE,
    UPDATE_PARTNERNOTE,
    GET_LOAN,
    GET_PARTNER
} from '../../actions/types';

const partnerNoteReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_PARTNERNOTE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_PARTNERNOTE:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_PARTNERNOTE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_PARTNERNOTE: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_PARTNERNOTE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = {  };
                for(let i=0; i<action.payload.partnerNote.length;i++) {result3[action.payload.partnerNote[i].id]=action.payload.partnerNote[i];}
                return result3;
        case GET_PARTNER:
            let result4 = {  };
            for(let i=0; i<action.payload.partnerNotes.length;i++) {result4[action.payload.partnerNotes[i].id]=action.payload.partnerNotes[i];}
            return result4;
        default:
            return state;
    }
}

export default partnerNoteReducer;