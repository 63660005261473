import api from "../api/myApi";
import { 
    CREATE_DEBTCOLLECTIONCOSTDETAILS,
    GET_ALL_DEBTCOLLECTIONCOSTDETAILS,
    GET_DEBTCOLLECTIONCOSTDETAILS,
    GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS,
    DELETE_DEBTCOLLECTIONCOSTDETAILS,
    UPDATE_DEBTCOLLECTIONCOSTDETAILS,
    ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
    EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
    CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createDebtCollectionCostDetails = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DebtCollectionCostDetails/Create', formValues);    
    dispatch(responseHandler(response, CREATE_DEBTCOLLECTIONCOSTDETAILS));
    if(response.data?.succeeded){
        dispatch(reset('formDebtCollectionCostDetails'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllDebtCollectionCostDetails = (date) => async (dispatch) => {
    const response = await api.post('/api/v1/DebtCollectionCostDetails/GetAll', {date: date});
    dispatch(responseHandler(response, GET_ALL_DEBTCOLLECTIONCOSTDETAILS));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getDebtCollectionCostDetails = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/DebtCollectionCostDetails/Get/' + id);
    dispatch(responseHandler(response, GET_DEBTCOLLECTIONCOSTDETAILS));
}

export const getSelectBoxDebtCollectionCostDetails = () => async (dispatch) => {
    const response = await api.get('/api/v1/DebtCollectionCostDetails/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_DEBTCOLLECTIONCOSTDETAILS));
}

export const updateDebtCollectionCostDetails = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/DebtCollectionCostDetails/Update', formValues);
    dispatch(responseHandler(response, UPDATE_DEBTCOLLECTIONCOSTDETAILS));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteDebtCollectionCostDetails = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/DebtCollectionCostDetails/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_DEBTCOLLECTIONCOSTDETAILS));
}


export const postingDebtCollectionCostWarning = (date) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Warning/PostingDebtCollectionCostWarning', {date: date});  
    dispatch(responseHandler(response, 'success', 'OK'));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}



export const addDialogDebtCollectionCostDetails = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
        payload: null
    });
}

export const editDialogDebtCollectionCostDetails = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
        payload: item
    });
}

export const closeDialogDebtCollectionCostDetails = () => async (dispatch) => {
    dispatch(reset('formDebtCollectionCostDetails'));
    dispatch({
        type: CLOSE_DIALOG_DEBTCOLLECTIONCOSTDETAILS,
        payload: undefined
    });
}