//Mojo-AG
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogRevenueRecognition } from '../../actions/revenueRecognitionAction';
import { EditSelectField, EditDecimalNumberField, EditDatePickerField } from '../Share/EditComponents';

class FormRevenueRecognition extends React.Component{
    handleClose = () => {
        this.props.closeDialogRevenueRecognition();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='amount'
                                    component={this.props.EditDecimalNumberField}
                                    label='Amount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='date'
                                    component={this.props.EditDatePickerField}
                                    label='Date'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if(!formValues.amount){
        errors.amount = 'Polje je obavezno!';
    }
    if(!formValues.date){
        errors.date = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {  }
}
const formWrapper = reduxForm({
    form: 'formRevenueRecognition',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormRevenueRecognition);

export default connect(mapStateToProps, { closeDialogRevenueRecognition, EditSelectField, EditDecimalNumberField, EditDatePickerField})(formWrapper);