import React from "react";
import CreateLoan from '../../components/Loan/CreateLoan';

import Box from '@mui/material/Box';

class CreateLoanPage extends React.Component{
      handleChange = (event, newValue) => {
          this.setState({value:newValue});
      };
    render(){
        return(<Box sx={{ width: '100%', typography: 'body1' }}>
        <CreateLoan/>
      </Box>);
    }
}

export default CreateLoanPage;