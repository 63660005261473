import { 
    CREATE_COMPANY,
    GET_ALL_COMPANY,
    GET_COMPANY,
    DELETE_COMPANY,
    UPDATE_COMPANY
} from '../../actions/types';

const companyReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_COMPANY:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_COMPANY:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_COMPANY:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_COMPANY: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_COMPANY:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default companyReducer;