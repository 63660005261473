import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateCashBalance from './CreateCashBalance';
import TableBLTurnoverDetails from '../BLTurnover/TableBLTurnoverDetails';
import { addDialogCashBalance, editDialogCashBalance, deleteCashBalance, getAllCashBalancesByYearAndCashier, importCashBalance, postingCashBalance } from "../../actions/cashBalanceAction";
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCashiers } from '../../actions/cashierAction';
import { Field, reduxForm, change } from "redux-form";
import { EditSelectField, EditIntegerNumberField } from '../Share/EditComponents';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { dateFormat } from '../Share/Table/DateFormat';


class TableCashBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, IsOpenDetailsDialog: false, ID: null, cashierID: null }
  }
  componentDidMount = () => {
    this.props.getSelectBoxCompanies();
    this.props.getSelectBoxCashiers();

    this.props.change('year', new Date().getYear() + 1900);
  }

  companyName = (props) => {
    return this.props.companies.find(el => el.value === props.row.companyID)?.text;
  }
  cashierName = (props) => {
    return this.props.cashiers.find(el => el.value === props.row.cashierID)?.text;
  }
  columns = [
    /* {
         field: 'CompanyID',
         headerName: i18next.t('Company'),
         width: 160,
         valueGetter: this.companyName,
         sortComparator: (v1, v2, cellParams1, cellParams2) =>
         this.companyName(cellParams1).localeCompare(this.companyName(cellParams2))
       },*/
    { field: 'pageNumber', headerName: i18next.t('PageNumber'), minWidth: 150, flex: 1 },
    { field: 'date', headerName: i18next.t('Date'), width: 150, type: 'date', valueGetter: dateFormat  },
    { field: 'initialBalance', headerName: i18next.t('InitialBalance'), width: 170, type: 'number' },
    { field: 'numberOfAccounts', headerName: i18next.t('NumberOfAccounts'), width: 200, type: 'number' },
    { field: 'postingDate', headerName: i18next.t('PostingDate'), minWidth: 150, flex: 1, valueGetter: dateFormat  },
    { field: 'locked', headerName: i18next.t('Locked'), width: 150, type: 'boolean' },
    /*{ field: 'payIn', headerName: i18next.t('PayIn'), minWidth: 150, flex: 1 },
    { field: 'payOut', headerName: i18next.t('PayOut'), minWidth: 150, flex: 1 },
    { field: 'balance', headerName: i18next.t('Balance'), minWidth: 150, flex: 1 },
    { field: 'd_InitialBalance', headerName: 'D_InitialBalance', minWidth: 150, flex: 1 },
    { field: 'd_PayIn', headerName: 'D_PayIn', minWidth: 150, flex: 1 },
    { field: 'd_PayOut', headerName: 'D_PayOut', minWidth: 150, flex: 1 },
    { field: 'd_Balance', headerName: 'D_Balance', minWidth: 150, flex: 1 },
    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1 },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1 },
    */
    {
      field: 'details',
      headerName: ' ',
      type: 'actions',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.details(params)}
          >
            Detalji
          </Button>
        </strong>
      ),
    },
    {
      field: 'report',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.report(params)}
          >
            Izvještaj
          </Button>
        </strong>
      ),
    },
    {
      field: 'import',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.import(params)}
          >
            Importuj
          </Button>
        </strong>
      )
    },
    {
      field: 'posting',
      headerName: ' ',
      width: 110,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => this.posting(params)}
          >
            Knjiženje
          </Button>
        </strong>
      )
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton
            onClick={() => this.delete(params)}
          ></DeleteButton>
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogCashBalance();
  }
  details = (params) => {
    this.setState({ IsOpenDetailsDialog: true, ID: params.row.id });
  }
  report = (params) => {
    //this.props.editDialogCashBalance(params.row);
  }
  import = (params) => {
    this.props.importCashBalance({ CashBalanceID: params.row.id });
  }
  posting = (params) => {
    this.props.postingCashBalance({ CashBalanceID: params.row.id });
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  onSubmit = (formValues) => {
    if (formValues.year && formValues.cashierID && formValues.cashierID !== null) {
      this.setState({ cashierID: formValues.cashierID });

      this.props.showLoadingDataInProgress();
      this.props.getAllCashBalancesByYearAndCashier(formValues);
    }
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}>
          <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
            <div>
              <Field
                onChange={(data) => {
                  setTimeout(() => this.props.handleSubmit(this.onSubmit)());
                }}
                name='year'
                component={this.props.EditIntegerNumberField}
                label='Year'
              />
              <Field
                onChange={(data) => {
                  setTimeout(() => this.props.handleSubmit(this.onSubmit)());
                }}
                name='cashierID'
                component={this.props.EditSelectField}
                label='Blagajna'
                dataSource={this.props.cashiers}
              >
              </Field>
            </div>
          </form>
        </Box>
        <DataGrid
          rows={this.props.cashBalances}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateCashBalance cashierID={this.state.cashierID}></CreateCashBalance>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteCashBalance(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
        <TableBLTurnoverDetails
          IsOpen={this.state.IsOpenDetailsDialog}
          CashBalanceID={this.state.ID}
          close={() => this.setState({ IsOpenDetailsDialog: false, ID: null })}
        />
      </div>
    );
  }
}

const formWrapper = reduxForm({
  form: 'formCashBalanceSearch',
  destroyOnUnmount: false,
  enableReinitialize: true
})(TableCashBalance);

const mapStateToProps = state => {
  return {
    cashBalances: Object.values(state.cashBalances),
    companies: Object.values(state.companiesSelectBox),
    cashiers: Object.values(state.cashiersSelectBox)
  };
}

export default connect(mapStateToProps,
  {
    addDialogCashBalance,
    editDialogCashBalance,
    deleteCashBalance,
    getAllCashBalancesByYearAndCashier,
    importCashBalance,
    postingCashBalance,
    getSelectBoxCompanies,
    getSelectBoxCashiers,
    EditSelectField,
    EditIntegerNumberField,
    change,
    showLoadingDataInProgress
  })(formWrapper);