import { 
    CREATE_INCOMESOURCE,
    GET_ALL_INCOMESOURCE,
    GET_INCOMESOURCE,
    DELETE_INCOMESOURCE,
    UPDATE_INCOMESOURCE,
    GET_LOAN
} from '../../actions/types';

const incomeSourceReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_INCOMESOURCE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_INCOMESOURCE:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_INCOMESOURCE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_INCOMESOURCE: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_INCOMESOURCE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = { };
                for(let i=0; i<action.payload.incomeSource.length;i++) {result3[action.payload.incomeSource[i].id]=action.payload.incomeSource[i];}
                return result3;
        default:
            return state;
    }
}

export default incomeSourceReducer;