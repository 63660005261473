import React from "react";
import i18next from '../../language/i18n';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { EditDatePickerField2 } from '../../components/Share/EditComponents';
import DateProvider from '../../share/dateProvider';
import { getInfo, loanPosting, advanceClosing, reclasification, monthlyReservations, crkExport } from '../../actions/loanMaintenanceAction';


class LoanMaintenancePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {date: new Date()}
      }
    componentDidMount = () => {
        this.props.getInfo();
    }

    loanPosting = () => {
        this.props.loanPosting({date: this.state.date});
    }
    closingAvans = () => {
        this.props.advanceClosing({date: this.state.date});
    }
    reclassification = () => {
        this.props.reclasification({date: this.state.date});
    }
    monthlyReservations = () => {
        this.props.monthlyReservations({date: this.state.date});
    }
    crkExport = () => {
        this.props.crkExport({date: this.state.date});        
    }
    render() {
        return (
            <>
                <EditDatePickerField2
                    date={this.state.date}
                    onChange={(e) => { this.setState({date: e}); }}
                    value={this.state.date}
                >
                </EditDatePickerField2>
                <table className="dataTable">
                <thead>
                    <tr>
                        <th>{i18next.t('Name')}</th>
                        <th>{i18next.t('DateOfLastAction')}</th>
                        <th>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{i18next.t('LoanPosting')}</td>
                        <td>{DateProvider.GetDate(this.props.loanMaintenance.lastLoanPostingDate)}</td>
                        <td>
                            <Button onClick={this.loanPosting} color="primary" variant="contained"
                                style={{ width: '250px' }}>
                                {i18next.t('LoanPosting')}
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>{i18next.t('ClosingAvans')}</td>
                        <td>{DateProvider.GetDate(this.props.loanMaintenance.lastAdvanseClosingDate)}</td>
                        <td>
                            <Button onClick={this.closingAvans} color="primary" variant="contained"
                                style={{ width: '250px' }}>
                                {i18next.t('ClosingAvans')}
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>{i18next.t('Reclassification')}</td>
                        <td>{DateProvider.GetDate(this.props.loanMaintenance.lastReclasificationDate)}</td>
                        <td>
                            <Button onClick={this.reclassification} color="primary" variant="contained"
                                style={{ width: '250px' }}>
                                {i18next.t('Reclassification')}
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>{i18next.t('MonthlyReservations')}</td>
                        <td>{DateProvider.GetDate(this.props.loanMaintenance.lastReservationDate)}</td>
                        <td>
                            <Button onClick={this.monthlyReservations} color="primary" variant="contained"
                                style={{ width: '250px' }}>
                                {i18next.t('MonthlyReservations')}
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td>{i18next.t('CRK')}</td>
                        <td>{DateProvider.GetDate(this.props.loanMaintenance.lastCRKExportDate)}</td>
                        <td>
                            <Button onClick={this.crkExport} color="primary" variant="contained"
                                style={{ width: '250px' }}>
                                {i18next.t('CRK export')}
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                    
                </table>
            </>
        );
    }
}


const mapStateToProps = state => {
    return {
        loanMaintenance: state.loanMaintenance
    }
}
export default connect(mapStateToProps, {
    EditDatePickerField2, getInfo, loanPosting, advanceClosing, reclasification, monthlyReservations, crkExport
})(LoanMaintenancePage);
