import React from 'react';
import { connect } from 'react-redux';
import { closeDialogUser, updateUser } from '../../actions/userAction';
import FormUser from './FormUser';

class EditUser extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateUser(formValues);
    }
    
    render(){
        return (
            <FormUser 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit"
                IsNew={false}>
            </FormUser>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.UserDialog === undefined || state.dialog.UserDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.UserDialog};
}


export default connect(mapStateToProps, { closeDialogUser, updateUser })(EditUser);
