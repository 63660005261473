//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_REFINANCING,
    GET_ALL_REFINANCING,
    GET_REFINANCING,
    GET_ALL_SELECTBOX_REFINANCING,
    DELETE_REFINANCING,
    UPDATE_REFINANCING,
    ADD_DIALOG_REFINANCING,
    EDIT_DIALOG_REFINANCING,
    CLOSE_DIALOG_REFINANCING
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createRefinancing = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Refinancing/Create', formValues);    
    dispatch(responseHandler(response, CREATE_REFINANCING));
    if(response.data?.succeeded){
        dispatch(reset('formRefinancing'));
    }
}

export const getAllRefinancings = () => async (dispatch) => {
    const response = await api.get('/api/v1/Refinancing/GetAll');
    dispatch(responseHandler(response, GET_ALL_REFINANCING));
}

export const getRefinancing = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Refinancing/Get/' + id);
    dispatch(responseHandler(response, GET_REFINANCING));
}

export const getSelectBoxRefinancings = () => async (dispatch) => {
    const response = await api.get('/api/v1/Refinancing/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_REFINANCING));
}

export const updateRefinancing = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Refinancing/Update', formValues);
    dispatch(responseHandler(response, UPDATE_REFINANCING));
}

export const deleteRefinancing = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Refinancing/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_REFINANCING));
}

export const addDialogRefinancing = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_REFINANCING,
        payload: null
    });
}

export const editDialogRefinancing = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_REFINANCING,
        payload: item
    });
}

export const closeDialogRefinancing = () => async (dispatch) => {
    dispatch(reset('formRefinancing'));
    dispatch({
        type: CLOSE_DIALOG_REFINANCING,
        payload: undefined
    });
}

export const recalculateInternalRefinancingAmount = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/Refinancing/RecalculateInternalRefinancingAmount', formValues);
    dispatch(responseHandler(response, GET_ALL_REFINANCING));
}
