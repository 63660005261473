//Mojo-AG
import React from 'react';
import { connect } from 'react-redux';
import { closeDialogSourceOfFinancing, updateSourceOfFinancing } from '../../actions/sourceOfFinancingAction';
import FormSourceOfFinancing from './FormSourceOfFinancing';

class EditSourceOfFinancing extends React.Component{
    onSubmit  = (formValues) => {
        this.props.updateSourceOfFinancing(formValues);
    }
    
    render(){
        return (
            <FormSourceOfFinancing 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                initialValues={this.props.item}
                title="Edit">
            </FormSourceOfFinancing>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.SourceOfFinancingDialog === undefined || state.dialog.SourceOfFinancingDialog.type !== 'edit'? {IsOpen:false} : {...state.dialog.SourceOfFinancingDialog};
}


export default connect(mapStateToProps, { closeDialogSourceOfFinancing, updateSourceOfFinancing })(EditSourceOfFinancing);
