import api from "../api/myApi";
import { 
    STATISTIC_LOAN_STATUS_STATISTIC,
    STATISTIC_REALIZED_LOANS,
    STATISTIC_LOAN_CLASSIFICATION
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const getLoanStatusStatistic = () => async (dispatch) => {
    const response = await api.get('/api/v1/Statistic/LoanStatusStatistic');
    dispatch(responseHandler(response, STATISTIC_LOAN_STATUS_STATISTIC));
}

export const getRealizedLoans = () => async (dispatch) => {
    const response = await api.get('/api/v1/Statistic/RealizedLans');
    dispatch(responseHandler(response, STATISTIC_REALIZED_LOANS));
}

export const getLoanClassification = () => async (dispatch) => {
    const response = await api.get('/api/v1/Statistic/LoanClassification');
    dispatch(responseHandler(response, STATISTIC_LOAN_CLASSIFICATION));
}
