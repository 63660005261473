import { 
    CREATE_LOANPARTNER,
    GET_ALL_LOANPARTNER_BY_LOAN,
    DELETE_LOANPARTNER,
    UPDATE_LOANPARTNER,
    CLEAR_LOANPARTNER,
    ADD_DIALOG_LOANPARTNER,
    EDIT_DIALOG_LOANPARTNER,
    CLOSE_DIALOG_LOANPARTNER,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";

export const createLoanPartner = (formValues) => (dispatch) => { 
    dispatch({
        type: CREATE_LOANPARTNER,
        payload: formValues
    }); 
    dispatch(reset('formLoanPartner'));
}

export const getAllLoanPartners = (loanPartnerByLoan) => (dispatch) => {
    dispatch({
        type: GET_ALL_LOANPARTNER_BY_LOAN,
        payload: loanPartnerByLoan
    }); 
}
export const clearLoanPartners = () => (dispatch) => {
    dispatch({
        type: CLEAR_LOANPARTNER,
        payload: null
    }); 
}

export const updateLoanPartner = (formValues) => async (dispatch) => {
    dispatch({
        type: UPDATE_LOANPARTNER,
        payload: formValues
    });
}

export const deleteLoanPartner = (id) => async (dispatch) => {
    dispatch({
        type: DELETE_LOANPARTNER,
        payload: id
    });
}

export const addDialogLoanPartner = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANPARTNER,
        payload: null
    });
}

export const editDialogLoanPartner = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANPARTNER,
        payload: item
    });
}

export const closeDialogLoanPartner = () => async (dispatch) => {
    dispatch(reset('formLoanPartner'));
    dispatch({
        type: CLOSE_DIALOG_LOANPARTNER,
        payload: undefined
    });
}