import React from "react";
import Button from '@mui/material/Button';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import i18next from '../../../language/i18n';
import { createTheme, ThemeProvider } from '@mui/material/styles';


class CancelButton extends React.Component {
    theme = createTheme({
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#dc3545',
            },
            secondary: {
                // This is green.A700 as hex.
                main: '#218838',
            },
            neutral: {
                main: '#FF0000',
                contrastText: '#fff',
            }
        },
    });
    render() {
        return (
            <ThemeProvider theme={this.theme}>
                <Button
                    onClick={this.props.onClick}
                    variant="outlined" //outlined contained
                    color="primary"
                    startIcon={<NotInterestedIcon />}>
                    {i18next.t('Cancel')}
                </Button>
            </ThemeProvider>
        );
    }
}

export default CancelButton;