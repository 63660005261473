import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogRelatedPerson } from '../../actions/relatedPersonAction';
import { EditSelectField, EditTextField, EditDatePickerField } from '../Share/EditComponents';

class FormRelatedPerson extends React.Component{
    handleClose = () => {
        this.props.closeDialogRelatedPerson();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    //onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>                            
            
                            <div>
                                <Field
                                    name='firstName'
                                    component={this.props.EditTextField}
                                    label='FirstName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='parentsName'
                                    component={this.props.EditTextField}
                                    label='ParentsName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='lastName'
                                    component={this.props.EditTextField}
                                    label='LastName'
                                />
                            </div>
                            <div>
                                <Field
                                    name='jib'
                                    component={this.props.EditTextField}
                                    label='JIB'
                                />
                            </div>
                            <div>
                                <Field
                                    name='relatedPersonTypeID'
                                    component ={this.props.EditSelectField}
                                    label = 'RelatedPersonType'
                                    dataSource={this.props.relatedPersonTypesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <div>
                                <Field
                                    name='fromDate'
                                    component={this.props.EditDatePickerField}
                                    label='FromDate'
                                />
                            </div>
                            <div>
                                <Field
                                    name='toDate'
                                    component={this.props.EditDatePickerField}
                                    label='ToDate'
                                />
                            </div>
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    if(!formValues.firstName){
        errors.firstName = 'Polje je obavezno!';
    }
    if(!formValues.parentsName){
        errors.parentsName = 'Polje je obavezno!';
    }
    if(!formValues.lastName){
        errors.lastName = 'Polje je obavezno!';
    }
    if(!formValues.jIB){
        errors.jIB = 'Polje je obavezno!';
    }
    if(!formValues.relatedPersonTypeID){
        errors.relatedPersonTypeID = 'Polje je obavezno!';
    }
    if(!formValues.fromDate){
        errors.fromDate = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { 
        relatedPersonTypesSelectBox: Object.values(state.relatedPersonTypesSelectBox)
     }
}
const formWrapper = reduxForm({
    form: 'formRelatedPerson',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormRelatedPerson);

export default connect(mapStateToProps, { closeDialogRelatedPerson, EditSelectField, EditTextField, EditDatePickerField})(formWrapper);