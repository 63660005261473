import { 
    CREATE_PAYWARNING,
    GET_ALL_PAYWARNING,
    GET_PAYWARNING,
    DELETE_PAYWARNING,
    UPDATE_PAYWARNING
} from '../../actions/types';

const payWarningReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_PAYWARNING:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_PAYWARNING:
            let result = {  };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_PAYWARNING:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_PAYWARNING: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_PAYWARNING:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default payWarningReducer;