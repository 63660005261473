import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createBankStatementImport } from '../../actions/bankStatementImportAction';
import FormBankStatementImport from './FormBankStatementImport';

class CreateBankStatementImport extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createBankStatementImport(formValues);
    }
    
    render(){
        return (
            <FormBankStatementImport 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </FormBankStatementImport>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BankStatementImportDialog === undefined || state.dialog.BankStatementImportDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BankStatementImportDialog};
}


export default connect(mapStateToProps, { createBankStatementImport })(CreateBankStatementImport);
