import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogRegion } from '../../actions/regionAction';
import { EditTextField, EditSelectField } from '../Share/EditComponents';

class FormRegion extends React.Component{
    handleClose = () => {
        this.props.closeDialogRegion();
    }

    render(){
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}
                    
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>
                            
                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            <div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    >
                                </Field>
                            </div>
            
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>                    
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};
    
    if(!formValues.name){
        errors.name = 'Polje je obavezno!';
    }
    if(!formValues.companyID){
        errors.companyID = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return { companiesSelectBox: Object.values(state.companiesSelectBox),  }
}
const formWrapper = reduxForm({
    form: 'formRegion',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize : true
})(FormRegion);

export default connect(mapStateToProps, { closeDialogRegion, EditTextField, EditSelectField})(formWrapper);