import React from "react";
import Button from '@mui/material/Button';
import ConfirmDialog from '../../components/Share/ConfirmDialog';
import TableLoan from '../../components/Loan/TableLoan';
import DialogTableLoanApproval from '../../components/LoanApproval/DialogTableLoanApproval';
//import TableLoanApproval from '../../components/LoanApproval/TableLoanApproval';
import i18next from '../../language/i18n';
import { EditSelectFieldClear } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { returnLoanToDraft, refuseLoan, approveLoan } from '../../actions/loanAction';
import { showError } from '../../actions/messageAction';
import { getSelectBoxOpinionOfTheReferents } from "../../actions/opinionOfTheReferentAction";
import { getPartnerAnalysisForTheCreditBoard } from '../../actions/reportAction';

class LoanForApprovalPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenConfirmDialog: false,
      isOpenApproveDialog: false,
      isOpenDialogTableLoanApproval: false,
      loanData: null,
      message: null,
      action: null,
      opinionOfTheReferentsID: null
    }
  }

  componentDidMount = () => {
    this.props.getSelectBoxOpinionOfTheReferents();
  }

  returnToDraft = (params) => {
    this.setState({
      isOpenConfirmDialog: true,
      loanData: params.row,
      action: this.returnToDraftAction,
      message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite vratiti u nacrt?`
    });
  }
  returnToDraftAction = () => {
    this.props.returnLoanToDraft(this.state.loanData.id);
  }
  refuse = (params) => {
    this.setState({
      isOpenConfirmDialog: true,
      loanData: params.row,
      action: this.refuseAction,
      message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite odbiti?`
    });
  }
  refuseAction = () => {
    this.props.refuseLoan(this.state.loanData.id);
  }
  approve = (params) => {
    //mišljenje referenta

    // this.setState({
    //   isOpenApproveDialog: true,
    //   loanData: params.row,
    //   action: this.approveAction,
    //   message: `Da li ste sigurni da kredit ${params.row.branchID}/${params.row.loanAppID} želite odobriti?`
    // });

    //zahteva odobrenja
    this.setState({
      isOpenDialogTableLoanApproval: true,
      loanData: params.row
    });

  }
  approveAction = () => {
    if (this.state.opinionOfTheReferentsID !== null && this.state.opinionOfTheReferentsID !== null) {
      this.props.approveLoan(this.state.loanData.id, this.state.opinionOfTheReferentsID);
      this.setState({ isOpenApproveDialog: false, loanData: null, message: null, action: null });
    }
    else {
      this.props.showError('Morate odabrati mišljenje referenta!');
    }
  }
  columns = () => {
    return [//
      { field: 'loanTermMonths', headerName: i18next.t('LoanTermMonths'), minWidth: 50 },
      { field: 'interestRate', headerName: i18next.t('InterestRate'), minWidth: 50 },
      { field: 'approvalCost', headerName: i18next.t('ApprovalCost'), minWidth: 100 },
      { field: 'theNumberOfRequiredApprovals', headerName: i18next.t('TheNumberOfRequiredApprovals'), minWidth: 100 },
      {
        field: 'returnToDraft',
        headerName: ' ',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 0 }}
              onClick={() => this.returnToDraft(params)}
            >
              {i18next.t('ReturnToDraft')}
            </Button>
          </strong>
        ),
      },
      {
        field: 'refuse',
        headerName: ' ',
        minWidth: 100,
        hide: !this.hasPermission(),
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              style={{ marginLeft: 0 }}
              onClick={() => this.refuse(params)}
            >
              {i18next.t('Refuse')}
            </Button>
          </strong>
        ),
      },
      {
        field: 'approve',
        headerName: ' ',
        flex: 1,
        minWidth: 100,
        hide: !this.hasPermission(),
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 0 }}
              onClick={() => this.approve(params)}
            >
              {i18next.t('Approve')}
            </Button>
          </strong>
        ),
      },
      // {
      //   field: 'kartonKlijenta',
      //   headerName: ' ',
      //   width: 175,
      //   hide: !this.hasPermission(),
      //   renderCell: (params) => (
      //     <strong>
      //       <Button
      //         variant="contained"
      //         color="primary"
      //         size="small"
      //         style={{ marginLeft: 0 }}
      //         onClick={() => this.getPartnerLoans(params)}
      //       >
      //         {i18next.t('PartnerLoans')}
      //       </Button>
      //     </strong>
      //   ),
      // }
    ];
  }
  hasPermission = () => {
    let roles = ['Admin', 'ClientAdmin', 'CompanyAdmin'];
    for (let i = 0; i < roles?.length; i++) {
      if (this.props.authUser.roles?.indexOf(roles[i]) > -1) {
        return true;
      }
    }
    return false;
  }
  getPartnerCreditBoardReport = (data) => {
    this.props.getPartnerAnalysisForTheCreditBoard(data);
  }
  render() {
    return (<>
      <TableLoan
        Status={1}
        columns={this.columns()}
        getPartnerCreditBoardReport={this.getPartnerCreditBoardReport}
      />
      <ConfirmDialog
        IsOpen={this.state.isOpenConfirmDialog}
        close={() => this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null })}
        confirm={() => {
          this.state.action();
          this.setState({ isOpenConfirmDialog: false, loanData: null, message: null, action: null });
        }}
        message={this.state.message}
      />

      <ConfirmDialog
        IsOpen={this.state.isOpenApproveDialog}
        close={() => this.setState({ isOpenApproveDialog: false, loanData: null, message: null, action: null })}
        confirm={() => {
          this.state.action();
        }}
        message={this.state.message}
      >
        <EditSelectFieldClear name='OpinionOfTheReferentsID'
          label='OpinionOfTheReferent'
          onChange={
            (event) => {
              this.setState({ opinionOfTheReferentsID: parseInt(event.target.value) })
            }
          }
          dataSource={this.props.opinionOfTheReferentsSelectBox}
        />
      </ConfirmDialog>

      <DialogTableLoanApproval
        IsOpen={this.state.isOpenDialogTableLoanApproval}
        item={this.state.loanData}
        close={() => this.setState({ isOpenDialogTableLoanApproval: false, ID: null })}
      />
    </>);
  }
}

const mapStateToProps = state => {
  return {
    opinionOfTheReferentsSelectBox: Object.values(state.opinionOfTheReferentsSelectBox),
    authUser: state.authUser
  }
}

export default connect(mapStateToProps, {
  returnLoanToDraft,
  refuseLoan,
  approveLoan,
  showError,
  EditSelectFieldClear,
  getSelectBoxOpinionOfTheReferents,
  getPartnerAnalysisForTheCreditBoard
})(LoanForApprovalPage);

