import React from 'react';
import i18next from '../../language/i18n';
import { connect } from 'react-redux';
import { createBroker } from '../../actions/brokerAction';
import CreateFormBroker from './CreateFormBroker';

class CreateBroker extends React.Component{
    onSubmit  = (formValues) => {
        this.props.createBroker(formValues);
    }
    
    render(){
        return (
            <CreateFormBroker 
                IsOpen = {this.props.IsOpen}
                onSubmit={this.onSubmit}
                title={i18next.t('CreateNew')}>
            </CreateFormBroker>
        );
    }
}

const mapStateToProps = state => {
  return state.dialog.BrokerDialog === undefined || state.dialog.BrokerDialog.type !== 'add'? {IsOpen:false} : {...state.dialog.BrokerDialog};
}


export default connect(mapStateToProps, { createBroker })(CreateBroker);
