//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateRevenueRecognition from './CreateRevenueRecognition';
import EditRevenueRecognition from './EditRevenueRecognition';
import i18next from '../../language/i18n';
import DeleteButton from '../Share/Button/DeleteButton';
import { addDialogRevenueRecognition, editDialogRevenueRecognition, deleteRevenueRecognition, getAllRevenueRecognitions } from "../../actions/revenueRecognitionAction";
import { getSelectBoxLoans } from '../../actions/loanAction';
import { getSelectBoxOrders } from '../../actions/orderAction';
import { dateFormat, dateTimeFormat } from '../Share/Table/DateFormat';


class TableRevenueRecognition extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null }
  }
  componentDidMount = () => {
  }
  columns = [

    { field: 'amount', headerName: i18next.t('Amount'), minWidth: 150, flex: 1 },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, valueGetter: dateFormat  },

    { field: 'createDate', headerName: i18next.t('CreateDate'), minWidth: 150, flex: 1, valueGetter: dateTimeFormat  },
    { field: 'editDate', headerName: i18next.t('EditDate'), minWidth: 150, flex: 1, valueGetter: dateTimeFormat  },

    {
      field: 'edit',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={() => this.edit(params)}
          >
            {i18next.t('Edit')}
          </Button>
        </strong>
      ),
    },
    {
      field: 'delete',
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <DeleteButton onClick={() => this.delete(params)} />
        </strong>
      )
    }
  ];
  create = () => {
    this.props.addDialogRevenueRecognition();
  }
  edit = (params) => {
    this.props.editDialogRevenueRecognition(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  render() {
    return (
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={this.props.revenueRecognitions}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateRevenueRecognition></CreateRevenueRecognition>
        <EditRevenueRecognition></EditRevenueRecognition>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteRevenueRecognition(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { revenueRecognitions: Object.values(state.revenueRecognitions), loans: Object.values(state.loansSelectBox), orders: Object.values(state.ordersSelectBox) };
}

export default connect(mapStateToProps, { addDialogRevenueRecognition, editDialogRevenueRecognition, deleteRevenueRecognition, getAllRevenueRecognitions, getSelectBoxLoans, getSelectBoxOrders })(TableRevenueRecognition);