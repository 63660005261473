import { 
    CREATE_BRANCH,
    GET_ALL_BRANCH,
    GET_BRANCH,
    DELETE_BRANCH,
    UPDATE_BRANCH
} from '../../actions/types';

const branchReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_BRANCH:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_BRANCH:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_BRANCH:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_BRANCH: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_BRANCH:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default branchReducer;