import { 
    CREATE_LOANQUALITYSTATUS,
    GET_ALL_LOANQUALITYSTATUS,
    GET_LOANQUALITYSTATUS,
    DELETE_LOANQUALITYSTATUS,
    UPDATE_LOANQUALITYSTATUS,
    GET_LOAN
} from '../../actions/types';

const loanQualityStatusReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_LOANQUALITYSTATUS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_LOANQUALITYSTATUS:
            let result1 = { ...state };
            for(let i=0; i<action.payload.length;i++) {result1[action.payload[i].id]=action.payload[i];}
            return result1;
        case GET_LOANQUALITYSTATUS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_LOANQUALITYSTATUS: {
            let result2 = {...state};
            delete result2[action.payload];
            return result2;
        }
        case UPDATE_LOANQUALITYSTATUS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_LOAN:
                let result3 = { };
                for(let i=0; i<action.payload.loanQualityStatus.length;i++) {result3[action.payload.loanQualityStatus[i].id]=action.payload.loanQualityStatus[i];}
                return result3;
        default:
            return state;
    }
}

export default loanQualityStatusReducer;