//Mojo-AG
import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import CreateRefinancing from './CreateRefinancing';
import EditRefinancing from './EditRefinancing';
import i18next from '../../language/i18n';
import { addDialogRefinancing, editDialogRefinancing, deleteRefinancing, recalculateInternalRefinancingAmount } from "../../actions/refinancingAction";
import { getSelectBoxLoans } from '../../actions/loanAction';
import { getSelectBoxPartners } from '../../actions/partnerAction';
import DeleteButton from '../Share/Button/DeleteButton';
import { EditDatePickerField2 } from '../../components/Share/EditComponents';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ComponentInLine from '../Share/ComponentInLine';


class TableRefinancing extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpenConfirmDialog: false, ID: null, date: new Date() }
  }
  componentDidMount = () => {
    this.props.getSelectBoxLoans();
    this.props.getSelectBoxPartners();
  }

  // loanName = (props) => {
  //   return this.props.loans.find(el => el.value == props.row.loanID)?.text;
  // }
  oldLoanName = (props) => {
    return this.props.partnerLoansSelectBox.find(el => el.id == props.row.oldLoanID)?.loanNumber;
  }
  partnerName = (props) => {
    return this.props.partners.find(el => el.value == props.row.partnerID)?.text;
  }
  columns = () => {
    return ([

      // {
      //     field: 'LoanID',
      //     headerName: i18next.t('Loan'),
      //     width: 160,
      //     valueGetter: this.loanName,
      //     sortComparator: (v1, v2, cellParams1, cellParams2) =>
      //     this.loanName(cellParams1).localeCompare(this.loanName(cellParams2))
      //   },

      {
        field: 'OldLoanID',
        headerName: i18next.t('Loan'),
        minWidth: 160, flex: 1,
        valueGetter: this.oldLoanName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.oldLoanName(cellParams1).localeCompare(this.oldLoanName(cellParams2))
      },

      {
        field: 'PartnerID',
        headerName: i18next.t('Partner'),
        minWidth: 160, flex: 1,
        valueGetter: this.partnerName,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
          this.partnerName(cellParams1).localeCompare(this.partnerName(cellParams2))
      },
      { field: 'amount', headerName: i18next.t('Amount'), width: 150 },
      { field: 'loanNumber', headerName: i18next.t('LoanNumber'), width: 150, flex: 1 },

      {
        field: 'edit',
        hide: this.props.loan.loan.loanStatusID >= 4,//this.props.disabled,
        headerName: ' ',
        renderCell: (params) => (
          <strong>
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={() => this.edit(params)}
            >
              {i18next.t('Edit')}
            </Button>
          </strong>
        ),
      },
      {
        field: 'delete',
        headerName: ' ',
        hide: this.props.loan.loan.loanStatusID >= 4,//this.props.disabled,
        renderCell: (params) => (
          <strong>
            <DeleteButton onClick={() => this.delete(params)} />
          </strong>
        )
      }
    ])
  }
  create = () => {
    this.props.addDialogRefinancing();
  }
  edit = (params) => {
    this.props.editDialogRefinancing(params.row);
  }
  delete = (params) => {
    this.setState({ IsOpenConfirmDialog: true, ID: params.row.id });
  }
  recalculate = () => {
    this.props.recalculateInternalRefinancingAmount({
      loanID: this.props.loan.loan.id,
      date: this.state.date
    });
  }
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        {this.props.loan.loan.loanStatusID < 4 ?
          (<Stack
            direction="row"
            justifyContent="space-between"
            spacing={1}

          >
            <ComponentInLine>
              <EditDatePickerField2 label='Date'
                date={this.state.date}
                onChange={(e) => { this.setState({ date: e }); }}
                value={this.state.date}
              >
              </EditDatePickerField2>
              <div>
                <div style={{ padding: '25px' }}>
                  <Button color="success" variant="contained" onClick={this.recalculate} style={{ marginLeft: '20px' }}>
                    {i18next.t('RecalculateInternalRefinancingAmount')}
                  </Button>
                </div></div>
            </ComponentInLine>
          </Stack>) : null
        }

        <DataGrid
          rows={this.props.refinancings}
          columns={this.columns()}
          components={{
            Toolbar: CustomToolbar
          }}
          componentsProps={{ toolbar: { createNew: this.props.loan.loan.loanStatusID >= 4/*this.props.disabled*/ ? null : this.create } }}
          checkboxSelection
          disableSelectionOnClick
        />
        <CreateRefinancing></CreateRefinancing>
        <EditRefinancing></EditRefinancing>
        <ConfirmDialog
          IsOpen={this.state.IsOpenConfirmDialog}
          close={() => this.setState({ IsOpenConfirmDialog: false, ID: null })}
          confirm={() => {
            this.props.deleteRefinancing(this.state.ID);
            this.setState({ IsOpenConfirmDialog: false, ID: null });
          }}
          message={'Da li ste sigurni da želite da obrišete ovaj unos?'}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    refinancings: Object.values(state.refinancings),
    //loans: Object.values(state.loansSelectBox), 
    partnerLoansSelectBox: Object.values(state.partnerLoansSelectBox),
    partners: Object.values(state.partnersSelectBox),
    loan: state.loanSelected
  };
}

export default connect(mapStateToProps,
  {
    addDialogRefinancing,
    editDialogRefinancing,
    deleteRefinancing,
    getSelectBoxLoans,
    getSelectBoxPartners,
    recalculateInternalRefinancingAmount
  })(TableRefinancing);