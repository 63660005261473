//Mojo-AG
import { 
    CREATE_COMMISSIONSETTINGS,
    GET_ALL_COMMISSIONSETTINGS,
    GET_COMMISSIONSETTINGS,
    DELETE_COMMISSIONSETTINGS,
    UPDATE_COMMISSIONSETTINGS
} from '../../actions/types';

const commissionSettingsReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_COMMISSIONSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_COMMISSIONSETTINGS:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_COMMISSIONSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_COMMISSIONSETTINGS: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_COMMISSIONSETTINGS:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default commissionSettingsReducer;