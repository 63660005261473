import PropTypes from 'prop-types';

// material-ui
import { Box, Chip, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from './MainCard';

// assets
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import i18next from '../../language/i18n';
//import { RiseOutlined, FallOutlined } from '@ant-design/icons';

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const LoanStatusInfo = ({ color, title, count, percentage, isLoss, extra, dataInfo }) => (
    <MainCard contentSX={{ p: 2.25 }}>
        <Stack spacing={0.5}>
            <Typography variant="h6" color="textSecondary">
                {title}
            </Typography>
            <Grid container alignItems="center">
                <Grid item>
                    <Typography variant="h5" color="inherit">
                        {i18next.t('LoansCount')}: {dataInfo?.count ?? 0}
                    </Typography>
                </Grid>
            </Grid>
        </Stack>
        <Box sx={{ pt: 2.25 }}>
            <Typography variant="subtitle1" color="textSecondary">
                {i18next.t('RequestedAmountSum')}{': '}
                <Typography component="span" variant="subtitle1" sx={{ color: `${color || 'primary'}.main` }}>
                    {(dataInfo?.requestedAmountSum ?? 0).toLocaleString('rs-RS', { style: 'currency', currency: 'BAM' })}
                </Typography>
            </Typography>
        </Box>
    </MainCard>
);

LoanStatusInfo.defaultProps = {
    color: 'primary'
};

export default LoanStatusInfo;
