import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import i18next from '../../language/i18n';
import SubmitButton from '../Share/Button/SubmitButton';
import CancelButton from '../Share/Button/CancelButton';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import { closeDialogCashier } from '../../actions/cashierAction';
import { EditTextField, EditSelectField, EditCheckbox, EditSelectFieldVirtual } from '../Share/EditComponents';

class FormCashier extends React.Component {
    handleClose = () => {
        this.props.closeDialogCashier();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.IsOpen}

                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={this.props.handleSubmit(this.props.onSubmit)}>

                            <div>
                                <Field
                                    name='name'
                                    component={this.props.EditTextField}
                                    label='Name'
                                />
                            </div>
                            {/* <div>
                                <Field
                                    name='companyID'
                                    component ={this.props.EditSelectField}
                                    label = 'Company'
                                    dataSource={this.props.companiesSelectBox}
                                    >
                                </Field>
                            </div> */}

                            <div>
                                <Field
                                    name='planID'
                                    component={this.props.EditSelectField}
                                    label='Plan'
                                    dataSource={this.props.plansSelectBox}
                                >
                                </Field>
                            </div>

                            <div>
                                <Field
                                    name='currency'
                                    component={this.props.EditTextField}
                                    label='Currency'
                                />
                            </div>
                            <div>
                                <Field
                                    name='bankAccount'
                                    component={this.props.EditTextField}
                                    label='BankAccount'
                                />
                            </div>
                            <div>
                                <Field
                                    name='isCashRegister'
                                    component={this.props.EditCheckbox}
                                    label='IsCashRegister'
                                />
                            </div>
                            <div>
                                <Field
                                    name='mainBranchID'
                                    component={this.props.EditSelectField}
                                    label='Branch'
                                    dataSource={this.props.branchesSelectBox}
                                >
                                </Field>
                            </div>                            
                            <div>
                                <Field
                                    name='partnerID'
                                    component={this.props.EditSelectFieldVirtual}
                                    label='Partner'
                                    dataSource={this.props.partnersSelectBox}
                                >
                                </Field>
                            </div> 
                            <DialogActions>
                                <CancelButton onClick={this.handleClose}></CancelButton>
                                <SubmitButton></SubmitButton>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}
const validate = (formValues) => {
    const errors = {};

    if (!formValues.name) {
        errors.name = 'Polje je obavezno!';
    }
    // if(!formValues.companyID){
    //     errors.companyID = 'Polje je obavezno!';
    // }
    if (!formValues.planID) {
        errors.planID = 'Polje je obavezno!';
    }
    if (!formValues.currency) {
        errors.currency = 'Polje je obavezno!';
    }
    if (!formValues.bankAccount) {
        errors.bankAccount = 'Polje je obavezno!';
    }
    return errors;
}


const mapStateToProps = state => {
    return {
        companiesSelectBox: Object.values(state.companiesSelectBox),
        plansSelectBox: Object.values(state.plansSelectBox),
        branchesSelectBox: Object.values(state.branchesSelectBox),
        partnersSelectBox: Object.values(state.partnersSelectBox),
    }
}
const formWrapper = reduxForm({
    form: 'formCashier',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true
})(FormCashier);

export default connect(mapStateToProps, {
    closeDialogCashier,
    EditTextField,
    EditSelectField,
    EditCheckbox,
    EditSelectFieldVirtual
})(formWrapper);