import api from "../api/myApi";
import { 
    CREATE_ENTITY,
    GET_ALL_ENTITY,
    GET_ENTITY,
    GET_ALL_SELECTBOX_ENTITY,
    DELETE_ENTITY,
    UPDATE_ENTITY,
    ADD_DIALOG_ENTITY,
    EDIT_DIALOG_ENTITY,
    CLOSE_DIALOG_ENTITY,
    SHOW_SUBMIT_BUTTON_PROGRESS,
    HIDE_SUBMIT_BUTTON_PROGRESS,
    HIDE_LOADING_DATA_PROGRESS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createEntity = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Entity/Create', formValues);    
    dispatch(responseHandler(response, CREATE_ENTITY));
    if(response.data?.succeeded){
        dispatch(reset('formEntity'));
    }
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const getAllEntities = () => async (dispatch) => {
    const response = await api.get('/api/v1/Entity/GetAll');
    dispatch(responseHandler(response, GET_ALL_ENTITY));
    dispatch({type: HIDE_LOADING_DATA_PROGRESS});
}

export const getEntity = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/Entity/Get/' + id);
    dispatch(responseHandler(response, GET_ENTITY));
}

export const getSelectBoxEntities = () => async (dispatch) => {
    const response = await api.get('/api/v1/Entity/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_ENTITY));
}

export const updateEntity = (formValues) => async (dispatch) => {
    dispatch({type: SHOW_SUBMIT_BUTTON_PROGRESS});
    const response = await api.post('/api/v1/Entity/Update', formValues);
    dispatch(responseHandler(response, UPDATE_ENTITY));
    dispatch({type: HIDE_SUBMIT_BUTTON_PROGRESS});
}

export const deleteEntity = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/Entity/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_ENTITY));
}

export const addDialogEntity = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_ENTITY,
        payload: null
    });
}

export const editDialogEntity = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_ENTITY,
        payload: item
    });
}

export const closeDialogEntity = () => async (dispatch) => {
    dispatch(reset('formEntity'));
    dispatch({
        type: CLOSE_DIALOG_ENTITY,
        payload: undefined
    });
}