//Mojo-AG
import { 
    CREATE_CRKARCHIVE,
    GET_ALL_CRKARCHIVE,
    GET_CRKARCHIVE,
    DELETE_CRKARCHIVE,
    UPDATE_CRKARCHIVE
} from '../../actions/types';

const cRKArchiveReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE_CRKARCHIVE:
            return { ...state, [action.payload.id]: action.payload };
        case GET_ALL_CRKARCHIVE:
            let result = { ...state };
            for(let i=0; i<action.payload.length;i++) {result[action.payload[i].id]=action.payload[i];}
            return result;
        case GET_CRKARCHIVE:
            return { ...state, [action.payload.id]: action.payload };
        case DELETE_CRKARCHIVE: {
            let result = {...state};
            delete result[action.payload];
            return result;
        }
        case UPDATE_CRKARCHIVE:
            return { ...state, [action.payload.id]: action.payload };
        default:
            return state;
    }
}

export default cRKArchiveReducer;