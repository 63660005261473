//Mojo-AG
import api from "../api/myApi";
import { 
    CREATE_LOANAPPROVALSETTINGS,
    GET_ALL_LOANAPPROVALSETTINGS,
    GET_LOANAPPROVALSETTINGS,
    GET_ALL_SELECTBOX_LOANAPPROVALSETTINGS,
    DELETE_LOANAPPROVALSETTINGS,
    UPDATE_LOANAPPROVALSETTINGS,
    ADD_DIALOG_LOANAPPROVALSETTINGS,
    EDIT_DIALOG_LOANAPPROVALSETTINGS,
    CLOSE_DIALOG_LOANAPPROVALSETTINGS
} from './types';
import { reset } from "redux-form";
import { responseHandler } from "../api/responseHandler";

export const createLoanApprovalSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApprovalSettings/Create', formValues);    
    dispatch(responseHandler(response, CREATE_LOANAPPROVALSETTINGS));
    if(response.data?.succeeded){
        dispatch(reset('formLoanApprovalSettings'));
    }
}

export const getAllLoanApprovalSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApprovalSettings/GetAll');
    dispatch(responseHandler(response, GET_ALL_LOANAPPROVALSETTINGS));
}

export const getLoanApprovalSettings = (id) => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApprovalSettings/Get/' + id);
    dispatch(responseHandler(response, GET_LOANAPPROVALSETTINGS));
}

export const getSelectBoxLoanApprovalSettings = () => async (dispatch) => {
    const response = await api.get('/api/v1/LoanApprovalSettings/GetSelectBox');
    dispatch(responseHandler(response, GET_ALL_SELECTBOX_LOANAPPROVALSETTINGS));
}

export const updateLoanApprovalSettings = (formValues) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApprovalSettings/Update', formValues);
    dispatch(responseHandler(response, UPDATE_LOANAPPROVALSETTINGS));
}

export const deleteLoanApprovalSettings = (id) => async (dispatch) => {
    const response = await api.post('/api/v1/LoanApprovalSettings/Delete/' + id, { ID: id });
    dispatch(responseHandler(response, DELETE_LOANAPPROVALSETTINGS));
}

export const addDialogLoanApprovalSettings = () => async (dispatch) => {
    dispatch({
        type: ADD_DIALOG_LOANAPPROVALSETTINGS,
        payload: null
    });
}

export const editDialogLoanApprovalSettings = (item) => async (dispatch) => {
    dispatch({
        type: EDIT_DIALOG_LOANAPPROVALSETTINGS,
        payload: item
    });
}

export const closeDialogLoanApprovalSettings = () => async (dispatch) => {
    dispatch(reset('formLoanApprovalSettings'));
    dispatch({
        type: CLOSE_DIALOG_LOANAPPROVALSETTINGS,
        payload: undefined
    });
}