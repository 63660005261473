import React from "react";
import { DataGrid } from '@mui/x-data-grid';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CustomToolbar from '../Share/Table/CustomToolbar';
import ConfirmDialog from '../Share/ConfirmDialog';
import ComponentInLine from '../Share/ComponentInLine';
import CreateBankStatementImport from './CreateBankStatementImport';
import EditBankStatementImport from './EditBankStatementImport';
import TableBLTurnover from '../BLTurnover/TableBLTurnover';
import { showLoadingDataInProgress } from "../../actions/progressBarAction";
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { dateFormat } from '../Share/Table/DateFormat';


import {
  addDialogBankStatementImport,
  editDialogBankStatementImport,
  deleteBankStatementImport,
  getAllBankStatementImports,
  fileBankStatementImport,
  connectLoanBankStatementImport,
  connectPartnerBankStatementImport,
  approveBankStatementImport,
  importProcessBankStatementImport
} from "../../actions/bankStatementImportAction";
//import { getSelectBoxCompanies } from '../../actions/companyAction';
import { getSelectBoxCashiers } from '../../actions/cashierAction';
import { getSelectBoxPartners, getAllPartners } from '../../actions/partnerAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getAllBaseLoansData } from '../../actions/loanAction';
import { Field, reduxForm, change } from "redux-form";
import { EditSelectField, EditDatePickerField } from '../Share/EditComponents';
import i18next from '../../language/i18n';


class TableBankStatementImportByLoan extends React.Component {
  constructor(props) {
    super(props);
    this.state = { IsOpen: false, distributionItems: [] }
  }

  componentDidMount = () => {
    this.props.getSelectBoxCashiers();
  }

  cashierName = (props) => {
    return this.props.cashiers.find(el => el.value === props.row.cashierID)?.text;
  }
  showDistribution = (params) => {
    console.log(this.props.bLTurnovers);
    this.setState({ IsOpen: true, distributionItems: this.props.bLTurnovers.filter(el => el.bankStatementImportID == params.id) });
  }
  columns = [
    { field: 'principal', headerName: i18next.t('Principal2'), minWidth: 350, flex: 4 },
    { field: 'payIn', headerName: i18next.t('PayIn'), minWidth: 100, flex: 1 },
    { field: 'payOut', headerName: i18next.t('PayOut'), minWidth: 100, flex: 1 },
    { field: 'purposeOfPayment', headerName: i18next.t('PurposeOfPayment'), minWidth: 450, flex: 4 },
    { field: 'date', headerName: i18next.t('Date'), minWidth: 150, flex: 1, type: 'date', valueGetter: dateFormat },
    {
      field: 'distribution', minWidth: 150, flex: 1,
      headerName: ' ',
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 0 }}
            onClick={() => this.showDistribution(params)}
          >
            {i18next.t('Distribution')}
          </Button>
        </strong>
      ),
    },
  ];
  render() {
    return (
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid
          rows={this.props.bankStatementImports}
          columns={this.columns}
          components={{
            Toolbar: CustomToolbar
          }}
        //componentsProps={{ toolbar: { createNew:this.create } }}
        //checkboxSelection
        //disableSelectionOnClick
        />

        <Dialog
          fullWidth
          maxWidth="xl"
          open={this.state.IsOpen}

          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <TableBLTurnover
              items={this.state.distributionItems}
            ></TableBLTurnover>

            <DialogActions>
              <Button color="primary" variant="contained" onClick={() => {this.setState({IsOpen: false})}}>
                {i18next.t('Close')}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    bLTurnovers: Object.values(state.bLTurnovers),
    bankStatementImports: Object.values(state.bankStatementImportsByLoan),
    //companies: Object.values(state.companiesSelectBox), 
    cashiers: Object.values(state.cashiersSelectBox),
    partners: Object.values(state.partnersSelectBox),
    loans: Object.values(state.loansSelectBox),
    branches: Object.values(state.branchesSelectBox),
    loansBaseData: Object.values(state.loansBaseData)
  };
}

export default connect(mapStateToProps,
  {
    addDialogBankStatementImport,
    editDialogBankStatementImport,
    deleteBankStatementImport,
    getAllBankStatementImports,
    fileBankStatementImport,
    connectLoanBankStatementImport,
    connectPartnerBankStatementImport,
    approveBankStatementImport,
    importProcessBankStatementImport,
    //getSelectBoxCompanies, 
    getSelectBoxCashiers,
    getSelectBoxPartners,
    getAllPartners,
    getSelectBoxBranches,
    getAllBaseLoansData,
    change,
    EditSelectField,
    EditDatePickerField,
    showLoadingDataInProgress
  })(TableBankStatementImportByLoan);
